import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import ErrorHandler from "../Helper/ErrorHandler";
import LoadingSpinner from "../Helper/LoadingSpinner";

const AddInvoiceNumberModal = ({ invoice, handleCancel, handleSave }) => {
  // let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);

  const [invoice_number_set, set_invoice_number_set] = useState();

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true)

    axios.put(`/invoices/${invoice?.id}/invoice_number`, null, {
      params: {
        recreate_pdf: true,
        invoice_number_set: invoice_number_set,
      }
    })
      .then(function (response) {
        console.log(response.status, "/invoices/invoice_number");
        handleSave()
        // refreshPage();
        setIsLoading(false)
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error });
        console.log(error);
        setIsLoading(false)
      });
  }

  return (
    <Modal
      show
      centered
      backdrop="static" // Ensure the backdrop is rendered
      style={{ zIndex: 1080 }} // Higher z-index to ensure it appears above the first modal
      onHide={handleCancel}
      size="sm"
    >
      {/* <Modal.Header closeButton>{t("Mark as \"Paid\"")}</Modal.Header> */}
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          {
          isLoading 
          ? <LoadingSpinner withText={t("Generating updated invoice...")} /> 
          : <Row>
            <Col >
              <Form.Group className="mb-3">
                <Form.Label>{t("Invoice Number")}</Form.Label>
                <Form.Control
                  placeholder={t("123/456/789")}
                  defaultValue={invoice.invoice_number_set}
                  onChange={e => set_invoice_number_set(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>}
          <hr />
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button variant="outline-secondary" onClick={handleCancel}>
                {t("Cancel")}
              </Button>{" "}
              <Button variant={"primary"} type="submit">
                {t("Apply")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AddInvoiceNumberModal;
