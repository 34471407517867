import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { CheckCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const TwinSnapshotModalSuccess = ({ show, handleClose }) => {
  const { t } = useTranslation();

  const palette = usePalette();
  
  return (
    <Modal size="ms" show={show} onHide={handleClose} style={{ zIndex: 1060 }}>
      <Modal.Body className="m-3">
        <Row>
          <Row className="mt-5 mb-5">
            <Col>
              <div className="text-center" >
                <CheckCircle style={{ color:palette["success"], width: '120px', height: '120px' }} /> {/* Inline style */}
                <div className="h3 mt-4">{t("Successfully added a snapshot in the \"Documents\" tab.")}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="primary" className="me-2" onClick={handleClose}>
                {t("OK")}
              </Button>
            </Col>
          </Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TwinSnapshotModalSuccess;