import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Button, Col, Dropdown, Form, InputGroup, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";
import UserDataManager from "../Helper/UserDataManager";
import { Crosshair, Plus } from "react-feather";
import { isMobile } from "react-device-detect";
import NotyfContext from "../../contexts/NotyfContext";
import AssetCategories from "../Helper/AssetCategories";
import { de } from "date-fns/locale";
import { getCoreBaseURL } from "../../config";

const AssetMoveToContractModal = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [contracts, setContracts] = useState([]);
  const [selected_contract_id, set_selected_contract_id] = useState("id");
  const notyf = useContext(NotyfContext);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (openModal) {
      axios
        .get("/contracts/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/contracts");
          setContracts(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [openModal]);


  function handleSubmit(e) {
    e.preventDefault();
    setError({ showMessage: false, errorData: null })

    axios
      .put("/assets/" + id, {
        "contract_id": selected_contract_id
      })
      .then(function (response) {
        // handle success
        navigate("/assets/" + response.data.id);
        console.log(response.status, "/assets");

        notyf.open({
          type: "success",
          message: t("Item updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        navigate("/contracts/" + selected_contract_id)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

  }

  const loadContracts = () => {
    return contracts?.map((asset) => {
      return (
        <option key={asset.id} value={asset.id} selected={asset.id === id}>{asset.title}</option>
      );
    });
  };

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Move asset")}</Dropdown.Item>
      <Modal size="m" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Add Asset to existing contract")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Contract")}</Form.Label>
                <Form.Select
                  name="state"
                  required
                  onChange={e => set_selected_contract_id(e.target.value)}>
                  <option value="">{t("-- Select --")}</option>
                  {loadContracts()}
                </Form.Select>
              </Form.Group>
            </Col>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button className="mb-2" variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button className="mb-2" variant="primary" type="submit">
                  {t("Move")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssetMoveToContractModal;
