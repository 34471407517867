import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, Col, Form } from "react-bootstrap";
import mainLogo from "../../assets/company/cap-on_logo_black.png";
import mainLogoLight from "../../assets/company/cap-on_logo.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import axios from "axios";
import NotyfContext from "../../contexts/NotyfContext";
import { Helmet } from "react-helmet-async";
import ErrorHandler from "../Helper/ErrorHandler";
import { getIAMBaseURL } from "../../config";

const ConfirmSignInPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { theme } = useTheme(); //onClick={setTheme(THEME.DEFAULT)}
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  const location = useLocation();
  const userData = location.state?.userData;

  const [confirmedToS, setConfirmToS] = useState(false);
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword1, setNewPassword1] = useState("");
  const [NewPassword2, setNewPassword2] = useState("");;

  const handleChangePassword = event => {
    changePasswordAfterEmail(event);
  };

  // useEffect(() => {
  //   refreshPage();
  // }, []);

  function changePasswordAfterEmail(e) {
    e.preventDefault();

    if (!confirmedToS) {
      setError({ showMessage: true, errorData: error, customMessage: t("In order to proceed, please agree to the Terms of Service.") })
    } else if (NewPassword1 === "" | NewPassword1 === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Password cannot be empty") })
    } else {
      if (NewPassword1 !== NewPassword2) {
        setError({ showMessage: true, errorData: error, customMessage: t("The two passwords must be the same") })
      } else {
        axios
          .put(getIAMBaseURL() + "/users/me/password", {
            "old_password": OldPassword,
            "new_password": NewPassword1,
            "consent": confirmedToS
          })
          .then(function (response) {
            // handle success
            console.log("Changed password", response.status);

            notyf.open({
              type: "success",
              message: t("Updated password successfully. Please log in with your new password."),
              duration: "4500",
              ripple: false,
              dismissible: true,
              position: {
                x: "right",
                y: "bottom",
              },
            })

            navigate("/dashboard");
          })
          .catch(function (error) {
            console.log(error?.response);
            setError({ showMessage: true, errorData: error })
          });
      }
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Confirm Registration" />
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center mt-4">
              <div className="text-center">
                {theme === "dark"
                  ? <img src={mainLogoLight} padding-top="50" alt="cap-on" style={{ height: "160px", paddingTop: "10px", paddingBottom: "10px" }} />
                  : <img src={mainLogo} padding-top="50" alt="cap-on" style={{ height: "160px", paddingTop: "10px", paddingBottom: "10px" }} />
                }
              </div>
              <h1 className="h2">{t("Welcome to cap-on,")} {userData?.first_name} {userData?.last_name}!</h1>
              <br/>
              <p className="lead">{t("Since this is your first login approach, we need you to finish your registration by setting your own password and agree to the terms and conditions.")}</p>
              <br />
              <br />
            </div>
            <Col>
              <ErrorHandler error={error} />
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Enter your current password")}</Form.Label>
                <Form.Control
                  type="password"
                  value={OldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder={t("Current Password")} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Enter a new password")}</Form.Label>
                <Form.Control
                  type="password"
                  value={NewPassword1}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  placeholder={t("New Password")}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  value={NewPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  placeholder={t("Confirm New Password")} />
              </Form.Group>
            </Col>
            <Col>
              <div>
                <Form.Check
                  label={
                    <span>
                      {t("I have read and agree to the ")} <a href={"https://www.cap-on.de/terms-and-conditions"} target="_blank" rel="noopener noreferrer">
                        {t("Terms and Conditions")}</a>{t(" and the ")}<a href={"https://www.cap-on.de/datenschutz"} target="_blank" rel="noopener noreferrer">
                        {t("Privacy Policy")}.
                      </a>
                    </span>
                  }
                  type="checkbox"
                  onClick={() => setConfirmToS(!confirmedToS)} />
              </div>
            </Col>
            <br />
            <Col>
              <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                {t("Cancel")}
              </Button>
              <Button type="submit" onClick={(e) => handleChangePassword(e)} variant="primary"
              >
                {t("Save")}
              </Button>
            </Col>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment >
  );
}

export default ConfirmSignInPage;
