import { Card, Spinner } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const LoadingSpinner = (props) => {

  const { t } = useTranslation();

  if (props?.withText) {
    return (
      <Card.Body>
        <div className="text-center">
          <Spinner animation="border" variant="secondary" size="sm" /> {t(props?.withText)}
        </div>
      </Card.Body>
    )
  }

  if (props?.withStandardText) {
    return (
      <Card.Body>
        <div className="text-center">
          <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
        </div>
      </Card.Body>
    )
  }

  return (
    <div className="text-center">
      <br />
      <Spinner animation="border" size={props?.small ? "sm" : "ms"} variant="secondary" />
    </div>
  );
};

export default LoadingSpinner;
