import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ArrowUpRight, RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import axios from "axios";

const DataIntegration = () => {
  const { t } = useTranslation();

  const [iframe_url, set_iframe_url] = useState();

  function open(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  useEffect(() => {
    axios
      .post("https://br.climategrid.de/services/verso/v1/company_data", {
        "company_id": "cap-on",
        "email": "philipp.lenz@cap-on.de",
      }, {
        headers: {
          'x-api-key': "gRpYl9H3vxs46os3RUSYUor9CqWxuQvICTG0J85AYfPzpo7oDGWqzXqx7d0XBO8h"
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "CG-Integration");
        set_iframe_url(response.data.iframe_url)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  return (
    <React.Fragment>
      <Helmet title="cap-on • System Configuration" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("ClimateGrid")}</h3>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <Card className="flex-fill w-100">
              {/* <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Update backend-configuration")}
                </Card.Title>
              </Card.Header> */}
              <Card.Body>
                <Col>
                  <iframe title="Test" height={"1200"} width={"100%"} src={iframe_url}></iframe>
                </Col>
                {/* <div id='swagger-editor'></div> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default DataIntegration;
