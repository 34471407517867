import React from "react";
import NavbarDropdown from "./NavbarDropdown";
import { Bell, Check, Server } from "react-feather";
import NavbarDropdownItem from "./NavbarDropdownItem";
import { useTranslation } from "react-i18next";
import { Col, Dropdown, ListGroup, Row } from "react-bootstrap";
import { Icon } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NavbarTenants = (tenants) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  function updateTenant(id) {
    localStorage.setItem("tenant_id", id);
    navigate("/dashboard");
    window.location.reload(false);
  }

  const TenantAvatar = (props) => {
    return (
      <img
        src={props?.url || null}
        className="center"
        alt="avatar"
        width={28} height={28}
      />);
  };

  return (

    <Dropdown className="me-2 nav-item" align="end">
      <Dropdown.Toggle as="a" className="nav-link nav-flag">
        <Server className="align-middle" size={18} />
      </Dropdown.Toggle>
      <Dropdown.Menu>

        {
          tenants?.map(item => {
            return (

              <Dropdown.Item
                key={item}
                onClick={() => updateTenant(item.id)}
                style={{ backgroundColor: item.id === localStorage.getItem("tenant_id") ? "rgba(0, 0, 0, 0.05)" : "" }}
              >

                <TenantAvatar url={item?.avatar_url} />
                &nbsp;&nbsp;&nbsp;
                <span className="align-middle">
                  {item.name.toUpperCase()}
                  &nbsp;&nbsp;
                  {localStorage.getItem("tenant_id") === item.id && <Check className="align-middle me-1" size={18} />}
                </span>
              </Dropdown.Item>

            );
          }
          )
        }

      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NavbarTenants;
