import {
  Card,
  Col,
  Row,
  Table,
  Carousel,
  Badge,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import React, { useContext, useState } from "react";
import {
  Clipboard,
  RefreshCw,
} from "react-feather";
import { Button } from "react-bootstrap";
import usePalette from "../../../hooks/usePalette";
import { VectorMap } from "react-jvectormap";
import { format, formatDistanceToNow, parseISO, } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import NotyfContext from "../../../contexts/NotyfContext";
import GoogleMapReact from 'google-map-react';
import SingleIoTDataPointModal from "../IoT_Data_Setup/SingleIoTDataPointModal";
import { useNavigate, useParams } from "react-router-dom";
import AssetDigitalTwinChartModal from "../AssetDigitalTwin/AssetDigitalTwinChartModal";
import axios from "axios";
import { isMobile } from "react-device-detect";
import AssetHealthCheckSection from "./AssetHealthCheckSection";
import UserDataManager from "../../Helper/UserDataManager";

const AssetGeneralSection = ({ assetData, loadData }) => {

  const { t, i18n } = useTranslation();
  const notyf = useContext(NotyfContext);
  let { id } = useParams();
  const navigate = useNavigate();
  // const [showDataPointModal, setShowDataPointModal] = useState(null)

  const ImagesSliderWithControls = () => {
    return (
      <Card className="flex-fill">
        <Card.Body className=" py-4">
          {/* <Col className="mt-0">
            <h5 className="card-title">{t("Images")}</h5>
          </Col> */}
          <div className="mb-3 text-center" >
            <Carousel indicators={false} controls={true}>
              <Carousel.Item key={assetData?.avatar.id} >
                <img key={assetData?.avatar.id} alt={assetData?.avatar?.title}
                  height={"200"}
                  className="center" // Add "img-fluid" here
                  src={assetData?.avatar?.url} />
              </Carousel.Item>
            </Carousel>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const palette = usePalette();

  const options = {
    map: "world_mill",
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
      },
    },
    markerStyle: {
      initial: {
        r: 9,
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.white,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
      hover: {
        fill: palette.primary,
        "fill-opacity": 0.9,
        stroke: palette.primary,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
    },
    backgroundColor: "transparent",
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    zoomOnScroll: true,
    markers: [
      {
        latLng: assetData?.location?.coordinates,
        name: assetData?.title,
      },
    ]
  };

  const Marker = ({ text, lat, lng }) => {
    const markerStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '30px',
      height: '30px',
      backgroundColor: palette.primary,
      border: '2px solid #fff',
      borderRadius: '50%',
      userSelect: 'none',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      fontSize: '12px',
    };

    return (
      <div style={markerStyle} title={text}>
        <div className="px-2 py-2 mb-7 flex-grow-1" style={{ borderRadius: '15px', backgroundColor: "rgba(0, 0, 0, 0.4)" }}>
          {text}
        </div>
        {/* You can use an SVG or an image tag (<img src="path_to_icon" alt="marker"/>) for custom icons */}
      </div>
    );
  }

  const getMapOptions = (maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.DEFAULT,
      scaleControl: true,
      scrollwheel: false,
      streetViewControl: false,
    };
  };

  const renderHealthState = (status) => {

    switch (status) {
      case "healthy":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Healthy")}</Badge>
        )
      case "warning":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Warning")}</Badge>
        )
      case "unhealthy":
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t("Warning")}</Badge>
        )
      case "offline":
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("Offline")}</Badge>
        )
      case "no_asset_connection":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Not connected")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
        )
    }
  }

  const loadAvatar = (url) => {
    return (
      <img
        src={url == null ? null : url}
        className="center me-1"
        alt="avatar"
        width={25} height={25}
      />);
  };

  const AssetState = () => {

    const renderState = (status) => {

      switch (status) {
        case "state_running":
          return (
            <Badge bg="" className="badge-soft-success me-2">{t("running")}</Badge>
          )
        case "state_setup":
          return (
            <Badge bg="" className="badge-soft-primary me-2">{t("setup")}</Badge>
          )
        case "state_error":
          return (
            <Badge bg="" className="badge-soft-danger me-2">{t("short_error")}</Badge>
          )
        case "no state_maintenance":
          return (
            <Badge bg="" className="badge-soft-warning me-2">{t("short_maintenance")}</Badge>
          )
        default:
          return (
            <Badge bg="" className="badge-soft-warning me-2">{t("n/a")}</Badge>
          )
      }
    }

    return (
      assetData?.health?.current_states?.length > 0 ?
        <div className="d-flex flex-wrap">
          {
            assetData?.health?.current_states?.map((reason) => {
              return (<h3 key={reason} className="mb-2" >{renderState(reason)}</h3>)
            })
          }
        </div>
        :
        <div><Badge bg="" className="badge-soft-secondary me-2">{t("-")}</Badge></div>
    )
  }

  const GEOCODING_API_KEY = process.env.REACT_APP_MAPS_API_KEY;

  return (<div style={{ background: palette["gray-200"], padding: "20px" }}>

    {/* <Row>
      <div className="h3 me-4 mt-3 mb-4">{t("General")}</div>
    </Row> */}
    <Row>
      <Col md="6" className="flex-fill">

        {/* <Card className="d-flex flex-column flex-grow-1">
          <Card.Header>
            <h5 className="card-title">{t("Health Check")}</h5>
          </Card.Header>
          <Card.Body className="d-flex flex-column">
            <Row>
              <Col md={6} >
                <p className="mb-2">{t("Overall condition")}</p>
                <OverlayTrigger
                  placement='bottom'
                  trigger="click"
                  rootClose
                  style={{ cursor: "pointer" }}
                  overlay={
                    <Popover>
                      <Popover.Header>{t("Recorded data points")}</Popover.Header>
                      <Popover.Body>
                        <Row>
                          <br />
                          {console.log("----->", assetData?.health)}
                        </Row>
                      </Popover.Body>
                    </Popover>}
                >
                  <h3 style={{ cursor: "pointer" }} >

                    {renderHealthState(assetData?.health?.status)}
                  </h3>
                </OverlayTrigger>
              </Col>
            </Row>
          </Card.Body>
        </Card> */}


        {/* About Tile */}
        <Card className="flex-fill">
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t("About")}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Table>
              <tbody>
                {/* <tr>
                  <th>{t("Status")}</th>
                  <td>
                    <Badge bg="" className={assetData?.status === "active" || assetData?.status === "approved" ? "badge-soft-success me-2" : "badge-soft-warning me-2"}>{getStatus(assetData?.status)}</Badge>
                  </td>
                </tr> */}
                <tr>
                  <th>{t("Model")}</th>
                  <td>{assetData?.machine_model || "-"}</td>
                </tr>
                <tr>
                  <th>{t("Manufacturing date")}</th>
                  <td>{assetData?.build_date ? format(parseISO(assetData?.build_date), "MM/yyyy", { locale: i18n.language === "de" ? de : null }) : "-"}</td>
                </tr>
                <tr>
                  <th>{t("Commissioning date")}</th>
                  <td>{assetData?.commissioning_date ? format(parseISO(assetData?.commissioning_date), "MM/yyyy", { locale: i18n.language === "de" ? de : null }) : "-"}</td>
                </tr>
                <tr>
                  <th>{t("Serial No.")}</th>
                  <td>{assetData?.serial || "-"}</td>
                </tr>
                {UserDataManager.getInstance().isCapOn() && <>
                  <tr>
                    <th>{t("Asset Number")}</th>
                    <td>{assetData?.asset_number || "-"}</td>
                  </tr>
                  <tr>
                    <th>{t("Asset ID")}</th>
                    <td>
                      {assetData?.id} &nbsp; &nbsp; &nbsp;
                      <span><Button
                        onClick={() => {
                          navigator.clipboard.writeText(assetData?.id)

                          notyf.open({
                            type: "success",
                            message: t("Asset-ID copied!"),
                            duration: "4500",
                            ripple: false,
                            dismissible: true,
                            position: {
                              x: "right",
                              y: "bottom",
                            },
                          })
                        }
                        }
                        size="sm"
                        variant="outline-primary">
                        <Clipboard className="feather" />
                      </Button></span></td>
                  </tr>
                </>}

                <tr>
                  <th>{t("Description")}</th>
                  <td>{assetData?.description || "-"}</td>
                </tr>

                <tr>
                  <th>{t("Category")}</th>
                  <td>{assetData?.category || "-"}</td>
                </tr>

                <tr>
                  <th>{t("Client")} / {t("Operator")}</th>
                  <td style={{ cursor: "pointer" }} onClick={() => navigate("/clients/" + assetData?.client_id)}>{assetData == null ? null : assetData?.client_avatar_url == null ? "" : loadAvatar(assetData?.client_avatar_url)} {" "} {assetData?.client_name || "-"}</td>
                </tr>

                <tr>
                  <th>{t("Manufacturer")}</th>
                  <td style={{ cursor: "pointer" }} onClick={() => navigate("/manufacturers/" + assetData?.manufacturer_id)}>{assetData == null ? null : assetData?.manufacturer_avatar_url == null ? "" : loadAvatar(assetData?.manufacturer_avatar_url)} {" "} {assetData?.manufacturer_name || "-"}</td>
                </tr>

                <tr>
                  <th>{t("Investor")}</th>
                  <td style={{ cursor: "pointer" }} onClick={() => navigate("/investors/" + assetData?.investor_id)}>{assetData == null ? null : assetData?.investor_avatar_url == null ? "" : loadAvatar(assetData?.investor_avatar_url)} {" "} {assetData?.investor_name || "-"}</td>
                </tr>

                {assetData?.usage_section && <>
                  <tr>
                    <th>{t("cap-on Activation Date (start of billing cycle, SOP, etc.)")}</th>
                    <td>{assetData?.activation_date == null ? "n/a" : format(parseISO(assetData?.activation_date), `eee, d${i18n.language === "de" ? "." : ""} MMMM yyyy`, { locale: i18n.language === "de" ? de : null })}</td>
                  </tr>
                  <tr>
                    <th>{t("Investment Volume")}</th>
                    <td>{assetData?.investment_price == null ? "n/a" : assetData?.investment_price.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      maximumFractionDigits: 2,
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("Pay-per-Use Price")}</th>
                    <td>{assetData?.per_use_price == null ? "n/a" : assetData?.per_use_price.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("monthly_base_fee")}</th>
                    <td>{assetData?.monthly_base_fee == null ? "n/a" : assetData?.monthly_base_fee.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                </>}
                <tr>
                  <th>{t("CO\u2082 Compensation")}</th>
                  <td>
                    {assetData?.co2_compensation === false
                      ? t("Not included")
                      : t("Included")} ({assetData?.co2_per_use || "-"} g CO<sub>2</sub>/{t("unit")})
                  </td>
                </tr>
                {/* <tr>
                  <th>{t("Operating Times")}</th>
                  <td>{getOperatingTimes("ns=5;s=PlantState.General.PlantRunning")}</td>
                </tr> */}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>

      <Col md="6" className="flex-fill">

        {/* Image Tile */}
        {assetData?.avatar
          ? <ImagesSliderWithControls />
          : <Card className="flex-fill">
            <Card.Header>
              <h5 className="card-title">{t("Images")}</h5>
            </Card.Header>
            <Card.Body className=" py-4">
              <p>{t("No avatar available.")}</p>
            </Card.Body>
          </Card>}

        {/* <Card className="flex-fill">
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t("Location")}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div style={{ height: 450 }}>
              <VectorMap {...options} />
            </div>

          </Card.Body>
        </Card> */}

        <Card className="flex-fill">
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t("Location")}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div style={{ height: 300, width: "100%" }}>
              <GoogleMapReact
                options={getMapOptions}
                bootstrapURLKeys={{
                  key: GEOCODING_API_KEY,
                }}
                defaultCenter={{
                  lat: assetData?.location?.coordinates[0],
                  lng: assetData?.location?.coordinates[1],
                }}
                defaultZoom={10}
              >
                <Marker
                  lat={assetData?.location?.coordinates[0]}
                  lng={assetData?.location?.coordinates[1]}
                  text={assetData?.title}
                />
              </GoogleMapReact>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row >
  </div >


  );
};

export default AssetGeneralSection;
