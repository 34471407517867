import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, ProgressBar, Button, Form } from "react-bootstrap";
import React, { useEffect, useState, } from "react";
import { format, formatDuration, intervalToDuration, formatDistanceToNow, parseISO } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from "date-fns/locale"
import { Inbox } from "react-feather";
import ErrorHandler from "../Helper/ErrorHandler";

const GatewayDetailsPublic = () => {
  let { id, code } = useParams();
  const [gatewayData, setGatewayData] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  var passcode = "";

  useEffect(() => {

    console.log("-->", code);

    if (code !== null && code !== undefined) {
      passcode = code
      // console.log("Trying..", passcode)
      handlePasscodeSubmit();
    }
  }, []);

  function handleChangePasscode(event) {
    passcode = event.target.value;
  }

  function handleSubmitButton(e) {
    e.preventDefault();

    const url = "mailto:theo.schuetz@cap-on.de?subject=cap-on%20Support%20" + " | " + gatewayData.client_name + "&body=%0D%0A%0D%0A%0D%0A--------------------------------------------------%0D%0APlease%20don't%20delete%20this%20part%20of%20the%20message%3A%0D%0AClient%3A%20" + gatewayData.client_name + "%20(" + gatewayData.client_id + ")%0D%0AGateway%3A%20" + gatewayData.serial + "%20(" + id + ")%0D%0A";
    open(url);
  }

  function open(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  function handlePasscodeSubmit() {

    setError({ showMessage: false, errorData: null })

    axios
      .get("/public/iot_gateways/" + id, {
        params: {
          passcode: passcode
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/public/iot_gateways/x");
        setGatewayData(response.data);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: null, customMessage: error.response.data.detail })
        console.log(error);
      });
  }

  const renderConnectionState = (status) => {

    switch (status) {
      case "connected":
        return (
          <span className="badge bg-success">{t(status)}</span>
        )
      case "connection_lost":
        return (
          <span className="badge bg-warning">{t(status)}</span>
        )
      default:
        break;
    }
  }

  const Header = () => {

    return (
      <Row>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">
                  {gatewayData == null ? "n/a" : renderConnectionState(gatewayData.connection_status)}
                </h3>
                <p className="mb-0">{t("Connection")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{gatewayData.last_handshake === undefined || gatewayData.last_handshake.time === undefined ? "-" : format(parseISO(gatewayData.last_handshake.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</h3>
                <p className="mb-0">{t("Last handshake")} ({gatewayData.last_handshake === undefined || gatewayData.last_handshake.time === undefined ? "-" : formatDistanceToNow(parseISO(gatewayData.last_handshake.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })})</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{gatewayData.last_handshake === undefined ? "-" : gatewayData.last_handshake.internet_speed_down == null ? "-" : gatewayData.last_handshake.internet_speed_down.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: "decimal",
                  maximumFractionDigits: 2
                }) + " Mbit/s"}</h3>
                <p className="mb-0">{t("Internet speed (Download)")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{gatewayData.last_handshake === undefined ? "-" : gatewayData.last_handshake.internet_speed_up == null ? "-" : gatewayData.last_handshake.internet_speed_up.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: "decimal",
                  maximumFractionDigits: 2
                }) + " Mbit/s"}</h3>
                <p className="mb-0">{t("Internet speed (Upload)")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  const loadProgressBars = () => {
    return (
      <Col md="3">
        <Card className="flex-fill">
          <Card.Body>
            <div className="flex-grow-1">
              <h3 className="mb-3">{t("RAM Usage")}</h3>
              <div></div>
              <ProgressBar
                className="mb-3"
                variant="primary"
                striped
                animated
                now={gatewayData.last_handshake === undefined ? "-" : gatewayData.last_handshake.ram_usage}
              />
              <p className="mb-3">{gatewayData.last_handshake === undefined ? "-" : (gatewayData.last_handshake.ram_usage / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'percent',
                maximumFractionDigits: 2
              })}</p>
            </div>
          </Card.Body>
        </Card>
        <Card className="flex-fill">
          <Card.Body>
            <div >
              <h3 className="mb-3">{t("Disk Usage")}</h3>
              <ProgressBar
                className="mb-3"
                variant="primary"
                striped
                animated
                now={gatewayData.last_handshake === undefined ? "-" : gatewayData.last_handshake.disk_space}
              />
              <p className="mb-3">{gatewayData.last_handshake === undefined ? "-" : (gatewayData.last_handshake.disk_space / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'percent',
                maximumFractionDigits: 2
              })}</p>
            </div>
          </Card.Body>
        </Card>
      </Col>
    )
  }

  // const SupportCard = () => (
  //   <Card>
  //     <Card.Header>
  //       <Card.Title tag="h5">Support Message</Card.Title>
  //       <h6 className="card-subtitle text-muted">
  //         Please describe your support request. We will get back to you within 4 hours.
  //       </h6>
  //     </Card.Header>
  //     <Card.Body>
  //       <Form onSubmit={handleSubmit}>
  //         <Row>
  //           <Col md="6">
  //             <Form.Group className="mb-3">
  //               <Form.Label>Title</Form.Label>
  //               <Form.Control placeholder="Title" />
  //             </Form.Group>
  //           </Col>
  //           <Col md="6">
  //             <Form.Group className="mb-3">
  //               <Form.Label>Support Category</Form.Label>
  //               <Form.Select name="state">
  //                 {/* <option /> */}
  //                 <option>Technical Support</option>
  //                 <option>General Question</option>
  //                 <option>Other</option>
  //               </Form.Select>
  //             </Form.Group>
  //           </Col>
  //         </Row>
  //         <Row>
  //           <Col md="6">
  //             <Form.Group className="mb-3">
  //               <Form.Label>Description</Form.Label>
  //               <Form.Control placeholder="Description" />
  //             </Form.Group>
  //           </Col>
  //           <Col md="6">
  //             <Form.Group className="mb-3">
  //               <Form.Label>Client</Form.Label>
  //               <Form.Control disabled={true} value={gatewayData === null ? "" : gatewayData.client_name} />
  //             </Form.Group>
  //           </Col>
  //         </Row>
  //         <Row>
  //           <Col md="12">
  //             <ReactQuill placeholder="Type something" />
  //           </Col>
  //         </Row>
  //         <br />
  //         <Row>
  //           <Col md="12">
  //             <Button type="submit" variant="primary"
  //             >
  //               Send support
  //             </Button>
  //           </Col>
  //         </Row>
  //       </Form>
  //     </Card.Body>
  //   </Card>
  // );

  const PasscodeRequest = () => (
    <Card>
      <Card.Header>
        <br />
        <Card.Title tag="h5">Enter Passcode</Card.Title>
        <h6 className="card-subtitle text-muted">
          Please enter the passcode you'll find right below the QR-code.
        </h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handlePasscodeSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Passcode</Form.Label>
                <Form.Control
                  type="text"
                  name="pc"
                  onChange={handleChangePasscode}
                  placeholder=""
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Button
                type="submit"
                variant="primary"
              >
                Submit Passcode
              </Button>
            </Col>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button
                variant="outline-primary"
                className="shadow-sm me-1"
                onClick={(e) => handleSubmitButton(e)}>
                <Inbox className="feather" /> Email-Support
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );

  return (
    <React.Fragment>
      <Helmet title="cap-on IoT-Gateway (Public)" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          {/* <Breadcrumb>
            <Breadcrumb.Item href="/gateways">{t("Gateways")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Gateway details")}</Breadcrumb.Item>
          </Breadcrumb> */}
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>cap-on IoT-Gateway</h3>
          </Col>
          {
            gatewayData.length !== 0 ?
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button
                  variant="primary"
                  className="shadow-sm me-1"
                  onClick={(e) => handleSubmitButton(e)}>
                  <Inbox className="feather" /> Support
                </Button>
              </Col>
              : <></>
          }
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        {
          gatewayData.length === 0 ?
            <PasscodeRequest />
            :
            <>
              <Header />
              <Row>
                <Col md="9" className="d-flex">
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("About")}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table size="sm" className="my-2">
                        <tbody>
                          {/* <tr>
                      <th>Status</th>
                      <td>
                        <span className={data.status === "active" ? "badge bg-success" : "badge bg-warning"}>{data.status}</span>
                      </td>
                    </tr> */}
                          <tr>
                            <th>{t("Serial Number")}</th>
                            <td>{gatewayData.serial}</td>
                          </tr>
                          <tr>
                            <th>{t("Client")}</th>
                            <td>{gatewayData == null ? "-" : gatewayData.client_name}</td>
                          </tr>
                          <tr>
                            <th>{t("Public IP")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.public_ip}</td>
                          </tr>
                          <tr>
                            <th>{t("Uptime at last handshake")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : formatDuration(intervalToDuration({ start: 0, end: gatewayData.last_handshake.uptime * 1000 }), { locale: i18n.language === "de" ? de : null })}</td>
                          </tr>
                          <tr>
                            <th>{t("Internet speed (Download)")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.internet_speed_down == null ? "-" : gatewayData.last_handshake.internet_speed_down.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: "decimal",
                              maximumFractionDigits: 2
                            }) + " Mbit/s"}</td>
                          </tr>
                          <tr>
                            <th>{t("Internet speed (Upload)")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.internet_speed_up == null ? "-" : gatewayData.last_handshake.internet_speed_up.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: "decimal",
                              maximumFractionDigits: 2
                            }) + " Mbit/s"}</td>
                          </tr>
                          <tr>
                            <th>{t("Disk Usage")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : (gatewayData.last_handshake.disk_space / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'percent',
                              maximumFractionDigits: 2
                            })}</td>
                          </tr>
                          <tr>
                            <th>{t("RAM Usage")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : (gatewayData.last_handshake.ram_usage / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'percent',
                              maximumFractionDigits: 2
                            })}</td>
                          </tr>
                          <tr>
                            <th>{t("CPU Usage")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : (gatewayData.last_handshake.cpu_usage / 100).toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'percent',
                              maximumFractionDigits: 2
                            })}</td>
                          </tr>
                          <tr>
                            <th>{t("CPU Temperature")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.cpu_temp?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: "decimal",
                              maximumFractionDigits: 1
                            }) + " °C"}</td>
                          </tr>
                          <tr>
                            <th>{t("Last production unit")}</th>
                            <td>{gatewayData == null ? "n/a" : gatewayData.last_usage_log == null ? "-" : format(parseISO(gatewayData.last_usage_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                          </tr>
                          <tr>
                            <th>{t("Last activity")}</th>
                            <td>
                              {gatewayData?.last_asset_log?.time
                                ? format(parseISO(gatewayData.last_asset_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })
                                : "-"
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
                {loadProgressBars()}
              </Row>
            </>
        }
      </Container>
    </React.Fragment>
  );
};

export default GatewayDetailsPublic;
