import React, { useEffect, useRef } from "react";
import html2canvas from 'html2canvas';
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { Button, Card, Col, Container, Form, Row, Table, InputGroup, Accordion, Dropdown, Badge, } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Slider from '@mui/material/Slider';
import { useNavigate } from "react-router-dom";
import ErrorHandler from "../Helper/ErrorHandler";
import usePalette from "../../hooks/usePalette";
import Chart from "react-apexcharts";
import LottieControl from "../Helper/LottieControl";
import animationData from "../Helper/animationDataPricing.json";
import UserDataManager from "../Helper/UserDataManager";
import mainLogo from "../../assets/company/cap-on_logo_black.png";
import mainLogoLight from "../../assets/company/cap-on_logo.png";
import { useTheme } from "@emotion/react";
import { de } from "date-fns/locale";
import { MoreHorizontal } from "react-feather";
import ProductDescriptionTechBasic from "./ProductDescriptionTechBasic";
import ProductDescriptionTechPremium from "./ProductDescriptionTechPremium";
import techIcon from "../../assets/icons/cap-on_icon_pink-cyan_cloud-connectivity.png";
import esgIcon from "../../assets/icons/cap-on_icon_pink-cyan_renewable1.png";
import financeIcon from "../../assets/icons/cap-on_icon_pink-cyan_finance.png";
import ProductDescriptionModal from "./ProductDescriptionModal";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import SDL_Logo from "../../assets/img/logos/SDL_Logo_grau.png";
import { getPricingBaseURL } from "../../config";

const PricingEngine = () => {
  const [asset_price, setAsset_price] = useState(3000000);
  const [businessName, setBusinessName] = useState();
  const [base_rate, setBase_rate] = useState(50);
  const [monthly_planned_usage, setMonthly_planned_usage] = useState(4000);
  const [duration, setDuration] = useState(60);
  const [interest_rate_intermediate, setInterest_rate_intermediate] = useState(1);
  const [capon_interest_share, setCapon_interest_share] = useState(0);
  const [capon_fixed_interest, setCapon_fixed_interest] = useState(1);
  const [simulated_percentage, setSimulatedPercentage] = useState(80);
  const [additional_costs, setAdditionalCosts] = useState(2599);
  const [startingFee, setStartingFee] = useState(30000);
  const [interest_rate_refi, setInterest_rate_refi] = useState(3);
  const [adjust_price_every, setAdjust_price_every] = useState(1);
  const { t, i18n } = useTranslation();
  const [showResultsP1, setShowResultsP1] = useState(false);
  const [showResultsP2, setShowResultsP2] = useState(false);
  const [resultsP1, setResultsP1] = useState([]);
  const [resultsP2, setResultsP2] = useState([]);
  const [resultsP2ScenarioAnalysis, setResultsP2ScenarioAnalysis] = useState([]);
  const [resultsP2PlusLoading, setResultsP2PlusLoading] = useState(true);
  const [PayPerUseUnit, setPayPerUseUnit] = useState(t("Output quantity"));
  const navigate = useNavigate();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [internalView, setInternalView] = useState(false); //(UserDataManager.getInstance().isCapOn() ? true : false);
  const [discounts, setDiscounts] = useState(false);
  const [scenarioAnalysis, setScenarioAnalysis] = useState(false);
  const [stochastic, setStochastic] = useState(false);
  const [showLoadingView, setShowLoadingView] = useState(false);
  const { theme } = useTheme(); //onClick={setTheme(THEME.DEFAULT)}

  const [activeTab, setActiveTab] = useState("");

  const [P2Factors, setP2Factors] = useState([
    {
      "percent_produced": 0,
      "price_factor": 1.5
    },
    {
      "percent_produced": 0.25,
      "price_factor": 1.25
    },
    {
      "percent_produced": 0.5,
      "price_factor": 1
    },
    {
      "percent_produced": 0.75,
      "price_factor": 0.5
    },
    {
      "percent_produced": 1,
      "price_factor": 0.1
    }
  ]);

  function startLoadingP1() {
    setShowLoadingView(true)
    setTimeout(() => stopAnimationP1(), 3000);
  }

  function startLoadingP2() {
    setShowLoadingView(true)
    setTimeout(() => stopAnimationP2(), 3000);
  }

  function stopAnimationP1() {
    setShowResultsP1(true)
    setShowResultsP2(false)
    setShowLoadingView(false)
    console.log("Stopped animation 1");
  }

  function reset() {
    setShowResultsP1(false)
    setShowResultsP2(false)
    setShowLoadingView(false)
    setError({ showMessage: false, errorData: null })
  }

  function stopAnimationP2() {
    setShowResultsP1(false)
    setShowResultsP2(true)
    setShowLoadingView(false)
    console.log("Stopped animation 2");
  }

  function handleChangeStochastic(event) {
    setStochastic(!stochastic);
  }

  function handleSetScenarioAnalysis(event) {
    setScenarioAnalysis(!scenarioAnalysis)
    setResultsP2ScenarioAnalysis([]);
  }

  function boxMullerTransform() {
    const u1 = Math.random(); const u2 = Math.random();
    const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2); const z1 = Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
    return { z0, z1 };
  }

  function getNormallyDistributedRandomNumber(mean, stddev) {
    const { z0, } = boxMullerTransform();
    return z0 * stddev + mean;
  }

  const exportRef = useRef(null);

  const exportImage = () => {
    html2canvas(exportRef.current).then(canvas => {
      canvas.toBlob(function (blob) {
        let link = document.createElement('a');
        link.download = 'cap-on_Vorläufige_Angebotsdetails.png';
        link.href = URL.createObjectURL(blob);
        link.click();
      });
    });
  };

  const exportPDF = () => {

    alert("Dieses Feature wird noch entwickelt.")
    // const input = exportRef.current;
    // const inputHeight = input.offsetHeight;
    // const inputWidth = input.offsetWidth;
    // const canvas = document.createElement('canvas');
    // canvas.height = inputHeight;
    // canvas.width = inputWidth;
    // const ctx = canvas.getContext('2d');
    // const dataURL = canvas.toDataURL('image/png');
    // const pdf = new jsPDF({
    //   unit: 'px',
    //   format: [inputWidth, inputHeight]
    // });
    // pdf.addImage(dataURL, 'PNG', 0, 0);
    // pdf.save('cap-on_Vorläufige_Angebotsdetails.pdf');
  };

  function calculatePriceP1(e) {
    e?.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .post(getPricingBaseURL() + "/ppu_leasing", {
        "investment_price": asset_price,
        "refi_interest": interest_rate_refi / 100,
        "intermediate_interest": interest_rate_intermediate / 100,
        "duration_in_months": duration,
        "current_month_to_price": 1,
        "monthly_planned_usage": monthly_planned_usage,
        "realized_percentage": simulated_percentage / 100,
        "adjust_price_every": adjust_price_every,
        "base_rate_percentage": base_rate / 100,
        "capon_interest_share": capon_interest_share / 100,
        "capon_fixed_interest": capon_fixed_interest / 100,
        "planned_usages": [],
        "realised_usages": stochastic ? Array.from({ length: duration }, (_, i) => getNormallyDistributedRandomNumber(monthly_planned_usage * simulated_percentage / 100, monthly_planned_usage * simulated_percentage / 100 * 0.2)) : [], //
        "additional_monthly_costs": additional_costs
      },
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem('token'),
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type',
            'Accept': "application/json",
          }
        })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load P1");
        setResultsP1([]);
        setResultsP1(response.data);

        // setMaxValueP1(Math.max(...response.data.periods?.map(o => o?.total_rate_incl_services)) * 1.2)
        // setShowLoadingViewP1(true);
        startLoadingP1();
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function calculatePriceP2(e) {
    e?.preventDefault();

    if (scenarioAnalysis) {
      calculatePriceP2WithScenario(e);
    }

    axios
      .post(getPricingBaseURL() + "/p2", {
        "investment_price": asset_price,
        "refi_interest": interest_rate_refi / 100,
        "intermediate_interest": interest_rate_intermediate / 100,
        "duration_in_months": duration,
        "current_month_to_price": 1,
        "monthly_planned_usage": monthly_planned_usage,
        "realized_percentage": simulated_percentage / 100,
        "adjust_price_every": adjust_price_every,
        "base_rate_percentage": base_rate / 100,
        "starting_fee": startingFee,
        "capon_interest_share": capon_interest_share / 100,
        "capon_fixed_interest": capon_fixed_interest / 100,
        "planned_usages": [],
        "realised_usages": stochastic ? Array.from({ length: duration }, (_, i) => getNormallyDistributedRandomNumber(monthly_planned_usage * simulated_percentage / 100, monthly_planned_usage * simulated_percentage / 100 * 0.2)) : [], //
        "additional_monthly_costs": additional_costs,
        "price_adjustments": P2Factors
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load P2");
        setResultsP2([]);
        setResultsP2(response.data);
        setShowResultsP2(true);

        // setMaxValueP2(Math.max(...response.data.periods?.map(o => o?.cumulated_interest_profit)) * 1.2)
        // setShowLoadingViewP2(true);
        startLoadingP2();
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const [value, setValue] = useState([])

  function calculatePriceP2WithScenario(e) {
    e.preventDefault();

    // setResultsP2ScenarioAnalysis((current) =>
    //   current.filter((item) => item.id !== 2)
    // );

    // var scenarios = [
    //   simulated_percentage * 0.8,
    //   simulated_percentage,
    //   simulated_percentage * 1.2,
    // ]

    var scenarios = [
      0,
      simulated_percentage,
      100,
      120
    ]

    axios
      .post(getPricingBaseURL() + "/p2", {
        "investment_price": asset_price,
        "refi_interest": interest_rate_refi / 100,
        "intermediate_interest": interest_rate_intermediate / 100,
        "duration_in_months": duration,
        "current_month_to_price": 1,
        "monthly_planned_usage": monthly_planned_usage,
        "realized_percentage": scenarios[0] / 100,
        "adjust_price_every": adjust_price_every,
        "starting_fee": startingFee,
        "base_rate_percentage": base_rate / 100,
        "capon_interest_share": capon_interest_share / 100,
        "planned_usages": [],
        "realised_usages": stochastic ? Array.from({ length: duration }, (_, i) => getNormallyDistributedRandomNumber(monthly_planned_usage * simulated_percentage / 100, monthly_planned_usage * simulated_percentage / 100 * 0.2)) : [], //
        "additional_monthly_costs": additional_costs,
        "price_adjustments": P2Factors
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load P2");
        console.log("->", scenarios[0]);

        // setResultsP2Plus([...resultsP2Plus, response.data]);
        // setValue([...value, response.data]);
        resultsP2ScenarioAnalysis.push({
          scenarioName: "Worst-case",
          scenario: scenarios[0],
          data: response.data
        })
        setValue("datau");
        setResultsP2PlusLoading(false);
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .post(getPricingBaseURL() + "/p2", {
        "investment_price": asset_price,
        "refi_interest": interest_rate_refi / 100,
        "intermediate_interest": interest_rate_intermediate / 100,
        "duration_in_months": duration,
        "current_month_to_price": 1,
        "monthly_planned_usage": monthly_planned_usage,
        "realized_percentage": scenarios[1] / 100,
        "adjust_price_every": adjust_price_every,
        "starting_fee": startingFee,
        "base_rate_percentage": base_rate / 100,
        "capon_interest_share": capon_interest_share / 100,
        "planned_usages": [],
        "realised_usages": stochastic ? Array.from({ length: duration }, (_, i) => getNormallyDistributedRandomNumber(monthly_planned_usage * simulated_percentage / 100, monthly_planned_usage * simulated_percentage / 100 * 0.2)) : [], //
        "additional_monthly_costs": additional_costs,
        "price_adjustments": P2Factors
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load P2");
        console.log("->", scenarios[1]);

        // setResultsP2Plus([...resultsP2Plus, response.data]);
        // setValue([...value, response.data]);

        resultsP2ScenarioAnalysis.push({
          scenarioName: "Erwartete Auslastung",
          scenario: scenarios[1],
          data: response.data
        })
        setValue("datau");
        setResultsP2PlusLoading(false);
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .post(getPricingBaseURL() + "/p2", {
        "investment_price": asset_price,
        "refi_interest": interest_rate_refi / 100,
        "intermediate_interest": interest_rate_intermediate / 100,
        "duration_in_months": duration,
        "current_month_to_price": 1,
        "monthly_planned_usage": monthly_planned_usage,
        "realized_percentage": scenarios[2] / 100,
        "starting_fee": startingFee,
        "adjust_price_every": adjust_price_every,
        "base_rate_percentage": base_rate / 100,
        "capon_interest_share": capon_interest_share / 100,
        "planned_usages": [],
        "realised_usages": stochastic ? Array.from({ length: duration }, (_, i) => getNormallyDistributedRandomNumber(monthly_planned_usage * simulated_percentage / 100, monthly_planned_usage * simulated_percentage / 100 * 0.2)) : [], //
        "additional_monthly_costs": additional_costs,
        "price_adjustments": P2Factors
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load P2");
        console.log("->", scenarios[2]);

        resultsP2ScenarioAnalysis.push({
          scenarioName: "Planned-Case",
          scenario: scenarios[2],
          data: response.data
        })
        setValue("datau");

        // setResultsP2Plus([...resultsP2Plus, response.data]);
        // setValue([...value, response.data]);
        setResultsP2PlusLoading(false);
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .post(getPricingBaseURL() + "/p2", {
        "investment_price": asset_price,
        "refi_interest": interest_rate_refi / 100,
        "intermediate_interest": interest_rate_intermediate / 100,
        "duration_in_months": duration,
        "current_month_to_price": 1,
        "monthly_planned_usage": monthly_planned_usage,
        "starting_fee": startingFee,
        "realized_percentage": scenarios[3] / 100,
        "adjust_price_every": adjust_price_every,
        "base_rate_percentage": base_rate / 100,
        "capon_interest_share": capon_interest_share / 100,
        "planned_usages": [],
        "realised_usages": stochastic ? Array.from({ length: duration }, (_, i) => getNormallyDistributedRandomNumber(monthly_planned_usage * simulated_percentage / 100, monthly_planned_usage * simulated_percentage / 100 * 0.2)) : [], //
        "additional_monthly_costs": additional_costs,
        "price_adjustments": P2Factors
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Load P2");
        console.log("->", scenarios[3]);

        resultsP2ScenarioAnalysis.push({
          scenarioName: "Best-Case",
          scenario: scenarios[3],
          data: response.data
        })
        setValue("datau");

        // setResultsP2Plus([...resultsP2Plus, response.data]);
        // setValue([...value, response.data]);
        setResultsP2PlusLoading(false);
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  useEffect(() => {
    setResultsP2PlusLoading(true);
    // console.log("-->", resultsP2ScenarioAnalysis);
  }, [resultsP2PlusLoading])

  var factors = [];
  function handleChangeP2Produced(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = P2Factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["percent_produced"] = parseFloat(event.target.value) / 100;
    } else {
      factors[event.target.id]["percent_produced"] = parseFloat(event.target.value) / 100;
    }

    setP2Factors(factors)

    console.log(P2Factors);
  }

  function handleChangeP2Factor(event) {
    console.log(event.target.id, event.target.value);

    factors = [];
    factors = P2Factors;

    if (event.target.id === undefined || event.target.id === "") {
      factors[0]["price_factor"] = parseFloat(event.target.value) / 100;
    } else {
      factors[event.target.id]["price_factor"] = parseFloat(event.target.value) / 100;
    }

    setP2Factors(factors)

    console.log(P2Factors);
  }

  const renderRows = () => {
    return P2Factors?.map((entry, index) => {
      return (
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Produced}
                  id={index}
                  type="number"
                  defaultValue={(entry.percent_produced * 100)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: "decimal",
                    maximumFractionDigits: 2
                  })}
                  placeholder="120 %" />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>

          {/* <Col md={1}></Col> */}
          <Col md={6}>
            <Form.Group className="mb-3">
              <InputGroup className="mb-3">
                <Form.Control
                  onChange={handleChangeP2Factor}
                  id={index}
                  type="number"
                  defaultValue={(entry.price_factor * 100)?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: "decimal",
                    maximumFractionDigits: 2
                  })}
                  placeholder="120 %" />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      );
    });
  };

  // const Details = () => (
  //   <Row>
  //     <Col lg="12">
  //       <Card>
  //         <Card.Header>
  //           {/* <ErrorHandler error={error} /> */}
  //           <br />
  //           <h5>{t("Edit planned production")}</h5>
  //         </Card.Header>
  //         <Card.Body>
  //           <Form>
  //             <Row>
  //               <Col md={1}>
  //                 <Form.Group className="mb-3">
  //                   <Form.Label>{t("Period")}</Form.Label>
  //                 </Form.Group>
  //               </Col>
  //               <Col md={1}>
  //               </Col>
  //               <Col md={2}>
  //                 <Form.Group className="mb-3">
  //                   <Form.Label>{t("Pay-per-Use Price")}</Form.Label>
  //                 </Form.Group>
  //               </Col>
  //               <Col md={2}>
  //                 <Form.Group className="mb-3">
  //                   <Form.Label>{t("Gesamtrate")}</Form.Label>
  //                 </Form.Group>
  //               </Col>
  //               <Col md={2}>
  //                 <Form.Group className="mb-3">
  //                   <Form.Label>{t("Gesamtrate mit Zusatzservices")}</Form.Label>
  //                 </Form.Group>
  //               </Col>
  //               <Col md={1}>
  //               </Col>
  //               <Col md={2}>
  //                 <Form.Group className="mb-3">
  //                   <Form.Label>{t("IST-Production")}</Form.Label>
  //                 </Form.Group>
  //               </Col>
  //             </Row>
  //             {renderRows()}
  //             <br />
  //             <hr />
  //             <br />
  //             <Row>
  //               <Col>
  //                 <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
  //                   {t("Cancel")}
  //                 </Button>
  //                 <Button type="submit" variant="primary"
  //                 >
  //                   {t("Save")}
  //                 </Button>
  //               </Col>
  //             </Row>
  //           </Form>
  //         </Card.Body>
  //       </Card>
  //     </Col>
  //   </Row>
  // )

  function baseRateText(value: number) {
    return `${value} %`;
  }

  const ResultsP1 = () => (
    <>
      <Row>
        <Col md={internalView ? "6" : "12"} className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Pay-per-Use Offer Details")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table size="sm" className="my-2">
                <tbody>
                  <tr>
                    <th>{t("Total Claims")}</th>
                    <td>{resultsP1 == null || resultsP1.total_claims === undefined ? "" : resultsP1.total_claims?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("Duration")}</th>
                    <td>{duration} {t("months")}</td>
                  </tr>
                  <br />
                  <tr>
                    <th>{t("monthly_base_fee")}</th>
                    <td>{resultsP1?.monthly_base_rate?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })} ({base_rate} %)</td>
                  </tr>
                  <tr>
                    <th>{t("Initial Unit Price (Pay-per-Use)")}</th>
                    <td>{resultsP1?.starting_price?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  {/* <br />
                <tr>
                  <th>{t("Vergleich statische Leasingrate (Klassisches Leasing)")}</th>
                  <td>{resultsP1?.fixed_leasing_annuities?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: 'EUR',
                  })}</td>
                </tr>
                <tr>
                  <th>{t("Monatliche Investor-Rate")}</th>
                  <td>{resultsP1?.monthly_refi_payment?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: 'EUR',
                  })}</td>
                </tr> */}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        {internalView && showResultsP1 &&
          <Col md="6" className="d-flex">
            <Card className="flex-fill w-100">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Marge (Simulation)")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>Investor Marge</th>
                      <td>{interest_rate_refi} % ({resultsP1?.refi_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })})</td>
                    </tr>
                    <tr>
                      <th>Zwischenfinanzierer Marge (z.B. SDL AG)</th>
                      <td>{resultsP1?.intermediate_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>cap-on Gesamtmarge</th>
                      <td>{(resultsP1?.capon_interest_profit + resultsP1?.capon_service_revenue + resultsP1?.capon_gain_share)?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Zins-Marge</th>
                      <td>{resultsP1?.capon_interest_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Zusatzservices</th>
                      <td>{resultsP1?.capon_service_revenue?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Gain</th>
                      <td>{resultsP1?.capon_gain_share?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <br />
                    <tr>
                      <th>{t("Schlussrate")}</th>
                      <td>{resultsP1?.end_rate?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                    <tr>
                      <th>{t("Max. liquidity deficit")}</th>
                      <td>{resultsP1?.max_cumulated_deficit?.toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'currency',
                        currency: 'EUR',
                      })}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>}
      </Row>
      {/* <Row>
        <Col md={"12"} className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Cash-flow")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table size="sm" className="my-2">
                <tbody>
                  <tr>
                    <th>{t("Kunde")}</th>
                    <th>{t("zahlt monatlich →")}</th>
                    <th>{t("cap-on")}</th>
                    <th>{t("zahlt monatlich →")}</th>
                    <th>{t("Investor")}</th>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Grundrate {resultsP1?.monthly_base_rate?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                    <td>{theme === "dark"
                      ? <img src={mainLogoLight} alt="cap-on" style={{ height: "60px" }} />
                      : <img src={mainLogo} alt="cap-on" style={{ height: "60px" }} />
                    }</td>
                    <td>{resultsP1?.monthly_refi_payment?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Variable Rate ({resultsP1?.starting_price?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })} pro Stück)</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </>
  );

  const ResultsP2 = () => (
    <Row>
      <Col md={internalView ? "6" : "12"} className="d-flex">
        <Card className="flex-fill w-100" ref={exportRef}>
          <Card.Header>
            <Row className="mb-2 mb-xl-3">
              <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
                <Card.Title tag="h5" className="mb-0">
                  {t("Pay-per-Use Offer Details")}
                </Card.Title>
              </Col>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Dropdown align={{ lg: 'end' }} className="d-inline">
                  <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                    <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={exportImage}>Downlaod as PNG</Dropdown.Item>
                    <Dropdown.Item onClick={exportPDF}>Download as PDF</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Button size="sm" onClick={exportImage} variant="outline-primary" className="shadow-sm me-1">
                  <Download className="feather" />
                </Button> */}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <div>
              Die vorläufigen Angebotsdetails basierend auf der cap-on Pricing Engine.
              <br /><br />
              <Table size="sm" className="my-2">
                <tbody>
                  <tr>
                    <th>{t("Minimum Contract Duration")}</th>
                    <td>{duration} {t("months")}</td>
                  </tr>
                  <tr>
                    <th>{t("One Time Fee")}</th>
                    <td>{resultsP2?.starting_fee?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("monthly_base_fee")}</th>
                    <td>{resultsP2?.monthly_base_rate?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })} ({base_rate} %)</td>
                  </tr>
                  <tr>
                    <th>{t("Monthly Additional Costs")}</th>
                    <td>{resultsP2?.additional_monthly_cost?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <br />
                  <tr>
                    <th>{t("Pay-per-Use Unit")}</th>
                    <td>{PayPerUseUnit}</td>
                  </tr>
                  <tr>
                    <th>{t("Unit Prices (Pay-per-Use)")}</th>
                    <td></td>
                  </tr>
                  {
                    resultsP2?.price_table?.map(entry => {
                      return <tr>
                        <td className="ms-auto text-end mt-n1">{t("From")} {entry.units_produced?.toLocaleString(i18n.language === "de" ? de : "en")} {t("units")}</td>
                        <td>{entry.price?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })}</td>
                      </tr>
                    })
                  }

                  {/* <br />
                <tr>
                  <th>{t("Vergleich statische Leasingrate (Klassisches Leasing)")}</th>
                  <td>{resultsP2?.fixed_leasing_annuities?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: 'EUR',
                  })}</td>
                </tr>*/}

                </tbody>
              </Table>
              <div className="text-center">
                {
                  theme === "dark" ?
                    <img src={mainLogoLight} alt="cap-on" style={{ height: "100px", paddingTop: "30px", paddingBottom: "0px" }} />
                    : <img src={mainLogo} alt="cap-on" style={{ height: "100px", paddingTop: "30px", paddingBottom: "0px" }} />
                }
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>

      {internalView && showResultsP2 &&
        <Col md="6" className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Internal Calculation")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <a>Diese Kalkulation basiert auf einer simulierten IST-Produktion von {simulated_percentage}% der angegebenen SOLL-Produktion.</a>
              <br />
              <br />
              <Table size="sm" className="my-2">
                <tbody>
                  <tr>
                    <th>{t("Total Claims")} {t("(inkl. Bereitstellungsgebühr und Services)")}</th>
                    <td>{resultsP2 == null || resultsP2?.total_claims === undefined ? "" : (resultsP2?.total_claims)?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })} ({resultsP2 == null || resultsP2?.total_claims_interest_equivalent === undefined ? "" : (resultsP2?.total_claims_interest_equivalent)?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })})</td>
                  </tr>

                  <tr>
                    <th>{t("Total Claims")} {t("(Nutzungsüberlassung)")}</th>
                    <td>{resultsP2 == null || resultsP2?.total_claims_without_services === undefined ? "" : resultsP2?.total_claims_without_services?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })} ({resultsP2 == null || resultsP2?.interest_equivalent_without_services === undefined ? "" : resultsP2?.interest_equivalent_without_services?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })})</td>
                  </tr>
                  <tr>
                    <th>{t("refi_rate")}</th>
                    <td>{resultsP2?.monthly_refi_payment?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("Unit Price (Pay-per-Use)")}</th>
                    <td>{resultsP2?.base_price?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <br />
                  <br />
                  <tr>
                    <th>Refi / Investor Marge</th>
                    <td>{resultsP2?.refi_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })} ({interest_rate_refi} %)</td>
                  </tr>
                  <tr>
                    <th>Zwischenfinanzierer Marge (z.B. SDL AG)</th>
                    <td>{resultsP2?.intermediate_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })} ({resultsP2?.intermediate_profit_interest_equivalent?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'percent',
                    })})</td>
                  </tr>
                  <tr>
                    <th>cap-on Gesamtmarge</th>
                    <td>{(resultsP2?.capon_interest_profit + resultsP2?.capon_service_revenue + resultsP2?.capon_gain_share)?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Zins-Marge</th>
                    <td>{resultsP2?.capon_interest_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Zusatzservices</th>
                    <td>{resultsP2?.capon_service_revenue?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Gain</th>
                    <td>{resultsP2?.capon_gain_share?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <br />
                  <tr>
                    <th>{t("Max. shortage deficit")}</th>
                    <td>{resultsP2?.max_shortage_deficit?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                  <tr>
                    <th>{t("Max. liquidity deficit")}</th>
                    <td>{resultsP2?.max_liquidity_deficit?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      currency: 'EUR',
                    })}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>}
    </Row>
  );

  const ResultsScenarioAnalyses = () => (
    <>
      <Row>
        {
          resultsP2ScenarioAnalysis.map((entry) => {
            resultsP2ScenarioAnalysis.sort((a, b) => (a.scenario > b.scenario))
            return <Col md={"3"} className="d-flex">
              <Card className="flex-fill w-100">
                <Card.Header>
                  <Card.Title tag="h5" className="mb-0">
                    {t("Scenario-Analysis")} ({entry?.scenarioName}: {entry?.scenario}% Auslastung)
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table size="sm" className="my-2">
                    <tbody>
                      <tr>
                        <th>Investor Marge</th>
                        <td>{interest_rate_refi} % ({entry?.data?.refi_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })})</td>
                      </tr>
                      <tr>
                        <th>Zwischenfinanzierer Marge (z.B. SDL AG)</th>
                        <td>{entry?.data?.intermediate_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })}</td>
                      </tr>
                      <tr>
                        <th>cap-on Gesamtmarge</th>
                        <td>{(entry?.data?.capon_interest_profit + entry?.data?.capon_service_revenue)?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })}</td>
                      </tr>
                      <tr>
                        <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Zins-Marge</th>
                        <td>{entry?.data?.capon_interest_profit?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })}</td>
                      </tr>
                      <tr>
                        <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;cap-on Zusatzservices</th>
                        <td>{entry?.data?.capon_service_revenue?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })}</td>
                      </tr>
                      <br />
                      <tr>
                        <th>{t("Max. shortage deficit")}</th>
                        <td>{entry?.data?.max_shortage_deficit?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })}</td>
                      </tr>
                      <tr>
                        <th>{t("Max. liquidity deficit")}</th>
                        <td>{entry?.data?.max_liquidity_deficit?.toLocaleString(i18n.language === "de" ? de : "en", {
                          style: 'currency',
                          currency: 'EUR',
                        })}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          })
        }
      </Row>
      <GenericChartAnalysis />
    </>
  )

  const LoadingView = () => (
    <Row>
      <Col md="12">
        <Card>
          <Card.Body>
            {/* <LottieControl animation={animationData} height={200} /> */}
            <div className="h1 text-center mt-4 mb-4">
              {t("Running Pricing Engine...")}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row >
  );

  const GenericChartP1 = () => {
    const palette = usePalette();
    const { t } = useTranslation();

    const data = [
      {
        name: t("Grundrate"),
        type: "line", //column
        data: Array.from({ length: resultsP1?.periods?.length }, (_, i) => resultsP1.monthly_base_rate),
      },
      {
        name: t("Grundrate + variable Rate"),
        type: "line",
        data: resultsP1?.periods?.map((entry) => { return entry.total_rate }),
      },
      {
        name: t("Vgl. Klassische Leasingrate"),
        type: "line",
        data: Array.from({ length: resultsP1?.periods?.length }, (_, i) => resultsP1.fixed_leasing_annuities),
      },
      {
        name: t("IST-Produktion"),
        type: "column",
        data: resultsP1?.periods?.map((entry) => { return entry.billable_production_units }),
      },
      // {
      //   name: t("Variable Rate"),
      //   type: "bar",
      //   data: resultsP1?.periods?.map((entry) => { return entry.paid_variable_rate }),
      // },
    ];

    const options = {
      chart: {
        type: 'line'
      },
      stroke: {
        width: [3, 3, 3],
        curve: "smooth",
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: "100%",
      //   },
      // },
      // fill: {
      //   opacity: [0.85, 0.25],
      //   gradient: {
      //     inverseColors: false,
      //     shade: "light",
      //     type: "vertical",
      //     opacityFrom: 0.85,
      //     opacityTo: 0.55,
      //     stops: [0, 100, 100, 100],
      //   },
      // },
      labels: resultsP1?.periods?.map((entry) => { return entry.index }),
      markers: {
        size: 0,
      },
      xaxis: {
        // type: "time",
        // max: 100,
        tickAmount: undefined,
        title: {
          text: "Vergleich Raten (Leasing und Pay-per-Use)",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
        },
      },
      yaxis: {
        min: 0,
        // max: maxValueP1, // 100000,
        title: {
          text: "Monatliche Rate",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => {
            return Math.round(value)?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })
          },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: true,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return y?.toLocaleString();//{ style: style, maximumFractionDigits: 2 }
          },
        },
      },
      colors: [
        palette.primary,
        palette.success,
        palette.warning,
        palette.info,
        palette.danger,
      ],
      // colors: ['#F44336', '#E91E63', '#9C27B0']
    };

    return (
      <Card className="flex-fill w-100 h-100">
        <Card.Header>
          <Card.Title tag="h5">{t("Vergleich PPU-Leasing vs. klassisches Leasing")}</Card.Title>
          {/* <h6 className="card-subtitle text-muted">
            A line chart is a way of plotting data points on a line.
          </h6> */}
        </Card.Header>
        <Card.Body>
          {
            resultsP1?.periods?.length !== 0

              ?
              <div className="chart">
                <Chart options={options} series={data} height="340" />
              </div>
              :
              <Card.Body>
                <p>{t("No data found.")}</p>
              </Card.Body>
          }
          {/* <Col className="text-center">
            <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate("/asset-requests/new/details")}>
              {t("Details")}
            </Button>
          </Col> */}
        </Card.Body>
      </Card>
    );
  };

  const GenericChartP2 = () => {
    const palette = usePalette();
    const { t, i18n } = useTranslation();

    const data = [
      {
        name: t("Grundrate"),
        type: "line", //column
        data: Array.from({ length: resultsP2?.periods?.length }, (_, i) => resultsP2?.monthly_base_rate),
      },
      {
        name: t("Grundrate + variable Rate"),
        type: "line",
        data: resultsP2?.periods?.map((entry) => { return entry.total_rate }),
      },
      // {
      //   name: t("Vgl. Klassische Leasingrate"),
      //   type: "line",
      //   data: Array.from({ length: resultsP2?.periods?.length }, (_, i) => resultsP2?.fixed_leasing_annuities),
      // },
      {
        name: t("Zwischenfinanzierer-Marge (SDL AG)"),
        type: "line",
        data: resultsP2?.periods?.map((entry) => { return entry.total_profit_incl_services }),
      },
      // {
      //   name: t("paid_variable_rate"),
      //   type: "line",
      //   data: resultsP2?.periods?.map((entry) => { return entry.paid_variable_rate }),
      // },
      {
        name: t("IST-Produktion"),
        type: "column",
        data: resultsP2?.periods?.map((entry) => { return entry.billable_production_units }),
      },
      // {
      //   name: t("Variable Rate"),
      //   type: "bar",
      //   data: resultsP1?.periods?.map((entry) => { return entry.paid_variable_rate }),
      // },
    ];

    const options = {
      chart: {
        type: 'line'
      },
      stroke: {
        width: [3, 3, 3],
        curve: "smooth",
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: "100%",
      //   },
      // },
      // fill: {
      //   opacity: [0.85, 0.25],
      //   gradient: {
      //     inverseColors: false,
      //     shade: "light",
      //     type: "vertical",
      //     opacityFrom: 0.85,
      //     opacityTo: 0.55,
      //     stops: [0, 100, 100, 100],
      //   },
      // },
      labels: resultsP2?.periods?.map((entry) => { return entry.index }),
      markers: {
        size: 0,
      },
      xaxis: {
        // type: "time",
        // max: 100,
        tickAmount: undefined,
        title: {
          text: "Monatliche Raten",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => {
            return Math.round(value)?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'decimal',
              currency: 'EUR',
            })
          },
        },
      },
      yaxis: {
        // min: 0,
        // max: maxValueP2, // 100000,
        title: {
          text: "Monatliche Rate",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => {
            return Math.round(value)?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })
          },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: true,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return y?.toLocaleString();//{ style: style, maximumFractionDigits: 2 }
          },
        },
      },
      colors: [
        palette.primary,
        palette.success,
        palette.warning,
        palette.info,
        palette.danger,
      ],
      // colors: ['#F44336', '#E91E63', '#9C27B0']
    };

    return (
      <>
        <Card className="flex-fill w-100 h-100">
          <Card.Header>
            <Card.Title tag="h5">{t("Asset-as-a-Service")} (Simulation)</Card.Title>
            {/* <h6 className="card-subtitle text-muted">
            A line chart is a way of plotting data points on a line.
          </h6> */}
          </Card.Header>
          <Card.Body>
            {
              resultsP2?.periods?.length !== 0

                ?
                <div className="chart">
                  <Chart options={options} series={data} height="340" />
                </div>
                :
                <Card.Body>
                  <p>{t("No data found.")}</p>
                </Card.Body>
            }
            {/* <Col className="text-center">
            <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate("/asset-requests/new/details")}>
              {t("Details")}
            </Button>
          </Col> */}
          </Card.Body>
        </Card>
        <GenericChartP2ScenarioCurve />
      </>
    );
  };

  const GenericChartP2ScenarioCurve = () => {
    const palette = usePalette();
    const { t, i18n } = useTranslation();

    const data = [
      {
        name: t("Gesamteinnahmen (inkl. Dienstleistungen)"),
        type: "column",
        data: resultsP2?.scenario_curve?.map((entry) => { return entry.total_profit_including_services }),
      },
    ];

    const options = {
      chart: {
        type: 'line'
      },
      stroke: {
        width: [3, 3, 3],
        curve: "straight",
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: "100%",
      //   },
      // },
      // fill: {
      //   opacity: [0.85, 0.25],
      //   gradient: {
      //     inverseColors: false,
      //     shade: "light",
      //     type: "vertical",
      //     opacityFrom: 0.85,
      //     opacityTo: 0.55,
      //     stops: [0, 100, 100, 100],
      //   },
      // },
      labels: resultsP2?.scenario_curve?.map((entry) => { return entry.percent_produced * 100 }),
      markers: {
        size: 0,
      },
      xaxis: {
        // type: "time",
        // max: 100,
        tickAmount: undefined,
        title: {
          text: t("Percent Produced"),
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => {
            return value?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'decimal',
            }) + "%"
          },
        },
      },
      yaxis: {
        // min: 0,
        // max: maxValueP2, // 100000,
        title: {
          text: "Total Profits",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => {
            return Math.round(value)?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })
          },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: true,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        // x: {
        //   formatter: function (x) {
        //     return (x)?.toLocaleString(i18n.language === "de" ? de : "en", {
        //       style: 'decimal',
        //     }) + "% der SOLL-Produktion erreicht"
        //   },
        // },
        y: {
          formatter: function (y) {
            return y?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: "EUR"
            })
          },
        },
      },
      colors: [
        palette.primary,
        palette.success,
        palette.warning,
        palette.info,
        palette.danger,
      ],
      // colors: ['#F44336', '#E91E63', '#9C27B0']
    };

    return (
      <Card className="flex-fill w-100 h-100">
        <Card.Header>
          <Card.Title tag="h5">{t("Scenario Curve")}</Card.Title>
          {/* <h6 className="card-subtitle text-muted">
            A line chart is a way of plotting data points on a line.
          </h6> */}
        </Card.Header>
        <Card.Body>
          {
            resultsP2?.periods?.length !== 0

              ?
              <div className="chart">
                <Chart options={options} series={data} height="340" />
              </div>
              :
              <Card.Body>
                <p>{t("No data found.")}</p>
              </Card.Body>
          }
          {/* <Col className="text-center">
            <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate("/asset-requests/new/details")}>
              {t("Details")}
            </Button>
          </Col> */}
        </Card.Body>
      </Card>
    );
  };

  const GenericChartAnalysis = () => {
    const palette = usePalette();
    const { t } = useTranslation();

    const data = [
      {
        name: t("Kum. Zwischenfinanzierer-Marge (SDL AG)") + " " + resultsP2ScenarioAnalysis[0]?.scenarioName,
        type: "line",
        data: resultsP2ScenarioAnalysis[0]?.data?.periods?.map((entry) => { return entry.cumulated_interest_profit }),
      },
      {
        name: t("Kum. Zwischenfinanzierer-Marge (SDL AG)") + " " + resultsP2ScenarioAnalysis[1]?.scenarioName,
        type: "line",
        data: resultsP2ScenarioAnalysis[1]?.data?.periods?.map((entry) => { return entry.cumulated_interest_profit }),
      },
      {
        name: t("Kum. Zwischenfinanzierer-Marge (SDL AG)") + " " + resultsP2ScenarioAnalysis[2]?.scenarioName,
        type: "line",
        data: resultsP2ScenarioAnalysis[2]?.data?.periods?.map((entry) => { return entry.cumulated_interest_profit }),
      },
      {
        name: t("Kum. Zwischenfinanzierer-Marge (SDL AG)") + " " + resultsP2ScenarioAnalysis[3]?.scenarioName,
        type: "line",
        data: resultsP2ScenarioAnalysis[3]?.data?.periods?.map((entry) => { return entry.cumulated_interest_profit }),
      }
    ];

    const options = {
      chart: {
        type: 'line'
      },
      stroke: {
        width: [2, 2, 2, 2],
        curve: "smooth",
      },
      // plotOptions: {
      //   bar: {
      //     columnWidth: "100%",
      //   },
      // },
      // fill: {
      //   opacity: [0.85, 0.25],
      //   gradient: {
      //     inverseColors: false,
      //     shade: "light",
      //     type: "vertical",
      //     opacityFrom: 0.85,
      //     opacityTo: 0.55,
      //     stops: [0, 100, 100, 100],
      //   },
      // },
      labels: resultsP1?.periods?.map((entry) => { return entry.index }),
      markers: {
        size: 0,
      },
      xaxis: {
        // type: "time",
        // max: 100,
        tickAmount: undefined,
        title: {
          text: "Monatliche Raten",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
        },
      },
      yaxis: {
        // min: 0,
        // max: maxValueP1, // 100000,
        title: {
          text: "Monatliche Rate",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 600,
            cssClass: 'apexcharts-xaxis-title',
          },
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => {
            return Math.round(value)?.toLocaleString(i18n.language === "de" ? de : "en", {
              style: 'currency',
              currency: 'EUR',
            })
          },
        },
      },
      legend: {
        position: 'right',
        labels: {
          colors: palette.black,
          useSeriesColors: true,
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return y?.toLocaleString();//{ style: style, maximumFractionDigits: 2 }
          },
        },
      },
      colors: [
        palette.warning,
        palette.primary,
        palette.success,
        palette.info,
        // palette.info,
      ],
      // colors: ['#F44336', '#E91E63', '#9C27B0']
    };

    return (
      <Card className="flex-fill w-100 h-100">
        <Card.Header>
          <Card.Title tag="h5">{t("Scenario-Analysis")}: {t("Kumulierte Zwischenfinanzierer-Marge (z.B. SDL AG)")}</Card.Title>
          {/* <h6 className="card-subtitle text-muted">
            A line chart is a way of plotting data points on a line.
          </h6> */}
        </Card.Header>
        <Card.Body>
          {
            resultsP1?.periods?.length !== 0

              ?
              <div className="chart">
                <Chart options={options} series={data} height="340" />
              </div>
              :
              <Card.Body>
                <p>{t("No data found.")}</p>
              </Card.Body>
          }
          {/* <Col className="text-center">
            <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate("/asset-requests/new/details")}>
              {t("Details")}
            </Button>
          </Col> */}
        </Card.Body>
      </Card>
    );
  };

  function handleSubmitPricingForm(e) {
    e?.preventDefault();

    switch (activeTab) {
      case "ppu_leasing":
        calculatePriceP1();
        break;
      case "AaaS_Light":
        calculatePriceP2();
        break;
      // case "AaaS_Full":
      //   setError({ showMessage: true, errorData: error, customMessage: t("Full Asset-as-a-Service Pricing will be available soon.") })
      //   break;
      default:
        setError({ showMessage: true, errorData: error, customMessage: t("Bitte zunächst einen Financial Service auswählen") })
        break;
    }
  }

  return (
    <React.Fragment>
      <Helmet title={t("cap-on Pricing Engine")} />
      <Container fluid className="p-0">

        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("cap-on Pricing Engine")}</h3>
          </Col>

          {/* <Col xs="auto" className="ms-auto text-end mt-n1">

            <Button
              onClick={() => navigate("/asset-requests/new")}
              variant="primary"
              className="shadow-sm me-1"
            >{t("New Asset Request")}</Button>
          </Col> */}
        </Row>
        <ErrorHandler error={error} />
        <Row>
          <Col md="12">

            <Card>
              <br />
              <Card.Header>
                <Card.Title>Pricing Engine</Card.Title>
                Willkommen zur cap-on Pricing Engine. Wir finden Ihr passendes Angebot.
              </Card.Header>
              <Card.Body>

                <Form onSubmit={handleSubmitPricingForm}>
                  {/* defaultActiveKey="0" */}
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0" className="bg-white">
                      <Accordion.Header>{t("Business Parameters")}</Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Name des Unternehmen")}</Form.Label>
                              <Form.Control
                                type="text"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                placeholder={t("Company Ltd.")}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("Bilanzierungsmethode/Rechnungslegung")}</Form.Label>
                              <Form.Select
                                value={PayPerUseUnit}
                                onChange={(e) => setPayPerUseUnit(e.target.value)}
                                placeholder={t("Output quantity, Operating hours, ...")}>
                                {/* <option /> */}
                                <option value="">{t("-- Select --")}</option>
                                <option>{t("HGB")}</option>
                                <option>{t("International Financial Reporting Standards (IFRS)")}</option>
                                <option>{t("US-GAAP")}</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="bg-white">
                      <Accordion.Header>{t("cap-on Financial Services")}</Accordion.Header>
                      <Accordion.Body className="bg-white">

                        <div className="mb-4">
                          <img src={financeIcon} alt="cap-on" style={{ height: "65px", paddingTop: "10px", paddingBottom: "10px" }} />
                          <h4>{t("cap-on Financial-Services")}</h4>
                        </div>
                        <Row>
                          <Col md="12" xl="10" className="mx-auto">
                            <h1 className="text-center pt-4">Wählen Sie Ihr passendes Zahlungsmodell</h1>
                            <p className="lead text-center mb-4">
                              Jedes unserer Modelle beinhaltet tiefgreifende wirtschaftliche, buchhalterische und technische Vorteile.
                            </p>

                            <Row className="py-4">
                              <Col sm="6" className="mb-3 mb-md-0">
                                <Card className="text-center h-100">
                                  <Card.Body className="d-flex flex-column">
                                    <div className="mb-4">
                                      {/* <h5>Pay-per-Use Leasing</h5> */}
                                      <br />
                                      <span className="h2">Pay-per-Use Leasing</span>
                                      <br />
                                      <br />
                                    </div>
                                    <h6>Beinhaltet:</h6>
                                    <ul className="list-unstyled">
                                      <li className="mb-2">Fixe und variable Zahlung</li>
                                      <li className="mb-2">Einfache Risikoübernahme</li>
                                      <li className="mb-2">Vollamortisation / Mietkauf / SLB</li>
                                      <li className="mb-2">Fixe Laufzeit</li>
                                      <li className="mb-2">Kaufoption zum Restwert</li>
                                      <li className="mb-2">Liquiditätsschonung</li>
                                      <ProductDescriptionModal
                                        title={"Pay-per-Use Leasing"}
                                        buttonTitle={"Ihre Vorteile"}
                                        markDownDescription={
                                          `
### Beschreibung
- - - 
- Fixe und variable Zahlung
- Einfache Risikoübernahme
- Vollamortisation / Mietkauf / SLB
- Fixe Laufzeit
- Kaufoption zum Restwert
- Liquiditätsschonung
`}
                                        markDownBenefits={`
### Vorteile
- - - 
-	Liquiditätsschonung
- Kapitalschonende Investitionsmöglichkeit in neue Anlagen
- Zahlungsflexibilität
- Kostentransparenz pro Einheit
`} />
                                    </ul>
                                    <div className="mt-auto">
                                      <Form.Check
                                        className="mb-4"
                                        checked={activeTab === "ppu_leasing"}
                                        type="radio"
                                      />
                                      <Button
                                        size="lg"
                                        onClick={() => setActiveTab("ppu_leasing")}
                                        variant={activeTab === "ppu_leasing" ? "primary" : "outline-primary"}
                                      >
                                        {t("Select")}
                                      </Button>
                                      <br />
                                      <br />
                                      <br />
                                      Finanzierungsleasing mit<br />
                                      <img
                                        src={SDL_Logo}
                                        alt="avatar"
                                        width="auto"
                                        height={50}
                                      />
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                              <Col sm="6" className="mb-3 mb-md-0">
                                <Card className="text-center h-100">
                                  <Card.Body className="d-flex flex-column">
                                    <div className="mb-4">
                                      {/* <h5>Asset-as-a-Service </h5> */}
                                      <br />
                                      <span className="h2">Asset-as-a-Service</span>
                                      <br />
                                      <br />
                                    </div>
                                    <h6>Beinhaltet:</h6>
                                    <ul className="list-unstyled">
                                      <li className="mb-2">Fixe und variable Zahlung</li>
                                      <li className="mb-2">Teilweise Risikoübernahme</li>
                                      <li className="mb-2">Miete</li>
                                      <li className="mb-2">Kurze Mindestlaufzeit</li>
                                      <li className="mb-2">Rücknahme oder 2nd Life</li>
                                      <li className="mb-2">Risikoübernahme (Versicherungsleistung)</li>
                                      <ProductDescriptionModal
                                        title={"Asset-as-a-Service Light"}
                                        buttonTitle={"Ihre Vorteile"}
                                        markDownDescription={
                                          `
### Beschreibung
- - - 
- Fixe und variable Zahlung
- Teilweise Risikoübernahme
- Miete
- Kurze Mindestlaufzeit
- Rücknahme oder 2nd Life
- Risikoübernahme (Versicherungsleistung)
`}
                                        markDownBenefits={`
### Vorteile
- - - 
-	Liquiditätsschonung
- Kapitalschonende Investitionsmöglichkeit in neue Anlagen
- Zahlungsflexibilität
- Kostentransparenz pro Einheit
- Transfer des Liquidiätsrisikos
`} />
                                    </ul>
                                    <div className="mt-auto">
                                      <Form.Check
                                        className="mb-4"
                                        type="radio"
                                        checked={activeTab === "AaaS_Light"}
                                      />
                                      <Button
                                        size="lg"
                                        onClick={() => setActiveTab("AaaS_Light")}
                                        variant={activeTab === "AaaS_Light" ? "primary" : "outline-primary"}
                                      >
                                        {t("Select")}
                                      </Button>
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col>
                              {/* <Col sm="4" className="mb-3 mb-md-0">
                                <Card className="text-center h-100">
                                  <Card.Body className="d-flex flex-column">
                                    <div className="mb-4">
                                      <h5>Asset-as-a-Service </h5>
                                      <br />
                                      <span className="display-6">Full</span>
                                      <br />
                                      <br />
                                    </div>
                                    <h6>Beinhaltet:</h6>
                                    <ul className="list-unstyled">
                                      <li className="mb-2">Variable Zahlung</li>
                                      <li className="mb-2">Maximale Risikoübernahme</li>
                                      <li className="mb-2">Dienstleistung</li>
                                      <li className="mb-2">Keine Mindestlaufzeit</li>
                                      <li className="mb-2">Trading - 2nd Life</li>
                                      <li className="mb-2">Komplexität Outsourcen (Opex)</li>
                                      <ProductDescriptionModal
                                        title={"Asset-as-a-Service Full"}
                                        buttonTitle={"Ihre Vorteile"}
                                        markDownDescription={
                                          `
### Beschreibung
- - - 
- Variable Zahlung
- Maximale Risikoübernahme
- Dienstleistung
- Keine Mindestlaufzeit
- Trading oder 2nd Life
- Komplexität Outsourcen (Opex)
`}
                                        markDownBenefits={`
### Vorteile
- - - 
-	Liquiditätsschonung
- Kapitalschonende Investitionsmöglichkeit in neue Anlagen
- Zahlungsflexibilität
- Kostentransparenz pro Einheit
- CapEx-zu-OpEx (Bilanzneutralität)
- Transfer des Liquidiätsrisikos
- Transfer des Ausfallrisikos
- Transfer des Wartungs- & Betreiberrisikos
- Transfer des Restwertrisikos
`} />
                                    </ul>
                                    <div className="mt-auto">
                                      <Form.Check
                                        className="mb-4"
                                        type="radio"
                                        checked={activeTab === "AaaS_Full"}
                                      />
                                      <Button size="lg"
                                        onClick={() => setActiveTab("AaaS_Full")}
                                        variant={activeTab === "AaaS_Full" ? "primary" : "outline-primary"}
                                      >
                                        {t("Select")}
                                      </Button>
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      <Badge bg="" className="badge-soft-warning me-2">
                                        Coming Soon
                                      </Badge>
                                      <br />
                                      <br />
                                      <br />

                                    </div>
                                  </Card.Body>
                                </Card>
                              </Col> */}
                            </Row>
                          </Col>
                        </Row>
                        <br /><br />
                        <hr />
                        <br /><br />
                        {activeTab === "ppu_leasing" &&
                          <Form>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Asset Price")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("EUR")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="investVolume"
                                      value={asset_price}
                                      onChange={(e) => setAsset_price(e.target.value)}
                                      placeholder="2,300,000"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Duration")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("Months")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="duration"
                                      value={duration}
                                      onChange={(e) => setDuration(e.target.value)}
                                      placeholder="60" />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={internalView ? 3 : 12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{internalView ? t("Interest rate (Refinancing)") : t("Interest rate")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="interest"
                                      value={interest_rate_refi}
                                      onChange={(e) => setInterest_rate_refi(e.target.value)}
                                      placeholder="2 %" />
                                    <InputGroup.Text>.00</InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              {
                                internalView && <>
                                  <Col md={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Interest rate (Intermediate)")}</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text>%</InputGroup.Text>
                                        <Form.Control
                                          type="number"
                                          name="interest"
                                          value={interest_rate_intermediate}
                                          onChange={(e) => setInterest_rate_intermediate(e.target.value)}
                                          placeholder="1 %" />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Interest share (cap-on)")}</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text>%</InputGroup.Text>
                                        <Form.Control
                                          type="number"
                                          name="interest"
                                          value={capon_interest_share}
                                          onChange={(e) => setCapon_interest_share(e.target.value)}
                                          placeholder="50 % des Intermediärs" />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("cap-on Interest (fixed)")}</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text>%</InputGroup.Text>
                                        <Form.Control
                                          type="number"
                                          name="interest"
                                          value={capon_fixed_interest}
                                          onChange={(e) => setCapon_fixed_interest(e.target.value)}
                                          placeholder="2%" />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </>
                              }
                            </Row>
                            <Row>
                              {/* <Col md={4}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Monthly Additional Costs")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("Total")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="additional_costs"
                                      value={additional_costs}
                                      onChange={(e) => setAdditionalCosts(e.target.value)}
                                      placeholder="1.000 EUR" />
                                  </InputGroup>
                                </Form.Group>
                              </Col> */}
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Base rate (in %)")}</Form.Label>
                                  <Slider
                                    size="small"
                                    defaultValue={50}
                                    aria-label="Custom marks"
                                    getAriaValueText={baseRateText}
                                    onChange={(e) => setBase_rate(e.target.value)}
                                    step={10}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={100}
                                    valueLabelFormat={baseRateText}
                                    aria-labelledby="non-linear-slider"
                                  />

                                  {/* <Form.Control
                                                      type="number"
                                                      name="base_rate"
                                                      value={base_rate}
                                                      
                                                      placeholder="50 %" /> */}
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Interval of Price Adjustment")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("Every n month")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="adjust_price_every"
                                      value={adjust_price_every}
                                      onChange={(e) => setAdjust_price_every(e.target.value)}
                                      placeholder="1 Monat" />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={internalView ? 6 : 12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Monthly Planned Production")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("Units")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="monthly_planned_usage"
                                      value={monthly_planned_usage}
                                      onChange={(e) => setMonthly_planned_usage(e.target.value)}
                                      placeholder="100" />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              {
                                internalView &&
                                <Col md={6}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Simulated production percentage")}</Form.Label>
                                    <InputGroup className="mb-3">
                                      <InputGroup.Text>%</InputGroup.Text>
                                      <Form.Control
                                        type="number"
                                        name="simulated_percentage"
                                        value={simulated_percentage}
                                        onChange={(e) => setSimulatedPercentage(e.target.value)}
                                        placeholder="80 %" />
                                      <InputGroup.Text>.00</InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Check
                                      checked={stochastic}
                                      onChange={handleChangeStochastic}
                                      label={t("Stochastisch festlegen (automatisch leicht abweichende IST-Produktion simulieren)")}
                                      type="checkbox" />
                                  </Form.Group>
                                </Col>
                              }
                            </Row>
                            <br />
                          </Form>
                        }

                        {activeTab === "AaaS_Light" &&
                          <Form>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Asset Price")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("EUR")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="investVolume"
                                      required
                                      value={asset_price}
                                      onChange={(e) => setAsset_price(e.target.value)}
                                      placeholder="2,300,000"
                                    />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Duration")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("Months")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="duration"
                                      required
                                      value={duration}
                                      onChange={(e) => setDuration(e.target.value)}
                                      placeholder="60" />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={internalView ? 3 : 12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{internalView ? t("Interest rate (Refinancing)") : t("Interest rate")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="interest"
                                      required
                                      value={interest_rate_refi}
                                      onChange={(e) => setInterest_rate_refi(e.target.value)}
                                      placeholder="2 %" />
                                    <InputGroup.Text>.00</InputGroup.Text>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              {
                                internalView &&
                                <>
                                  <Col md={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Interest rate (Intermediate)")}</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text>%</InputGroup.Text>
                                        <Form.Control
                                          type="number"
                                          name="interest"
                                          required
                                          value={interest_rate_intermediate}
                                          onChange={(e) => setInterest_rate_intermediate(e.target.value)}
                                          placeholder="1 %" />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("Interest share (cap-on)")}</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text>%</InputGroup.Text>
                                        <Form.Control
                                          type="number"
                                          name="interest"
                                          value={capon_interest_share}
                                          onChange={(e) => setCapon_interest_share(e.target.value)}
                                          placeholder="50 % des Intermediärs" />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col md={3}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("cap-on Interest (fixed)")}</Form.Label>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text>%</InputGroup.Text>
                                        <Form.Control
                                          type="number"
                                          name="interest"
                                          value={capon_fixed_interest}
                                          onChange={(e) => setCapon_fixed_interest(e.target.value)}
                                          placeholder="3%" />
                                        <InputGroup.Text>.00</InputGroup.Text>
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </>
                              }
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("One Time Fee")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("EUR")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="starting_fee"
                                      value={startingFee}
                                      onChange={(e) => setStartingFee(e.target.value)}
                                      placeholder="1.000 EUR" />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              {/* <Col md={4}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Monthly Additional Costs")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("EUR")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="additional_costs"
                                      value={additional_costs}
                                      onChange={(e) => setAdditionalCosts(e.target.value)}
                                      placeholder="1.000 EUR" />
                                  </InputGroup>
                                </Form.Group>
                              </Col> */}
                              <Col md={6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Base rate (in %)")}</Form.Label>
                                  <Slider
                                    size="small"
                                    defaultValue={50}
                                    aria-label="Custom marks"
                                    getAriaValueText={baseRateText}
                                    onChange={(e) => setBase_rate(e.target.value)}
                                    step={10}
                                    valueLabelDisplay="auto"
                                    min={0}
                                    max={100}
                                    valueLabelFormat={baseRateText}
                                    aria-labelledby="non-linear-slider"
                                  />

                                  {/* <Form.Control
                                type="number"
                                name="base_rate"
                                value={base_rate}
                                
                                placeholder="50 %" /> */}
                                </Form.Group>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col md={internalView ? 4 : 6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Monthly Planned Production")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("Units")}</InputGroup.Text>
                                    <Form.Control
                                      type="number"
                                      name="monthly_planned_usage"
                                      required
                                      value={monthly_planned_usage}
                                      onChange={(e) => setMonthly_planned_usage(e.target.value)}
                                      placeholder="100" />
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              <Col md={internalView ? 4 : 6}>
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("Pay-per-Use Unit")}</Form.Label>
                                  <InputGroup className="mb-3">
                                    <InputGroup.Text>{t("Unit")}</InputGroup.Text>
                                    <Form.Select
                                      name="state"
                                      value={PayPerUseUnit}
                                      onChange={(e) => setPayPerUseUnit(e.target.value)}
                                      placeholder={t("Output quantity, Operating hours, ...")}>
                                      {/* <option /> */}
                                      <option value="">{t("-- Select --")}</option>
                                      <option>{t("Output quantity")}</option>
                                      <option>{t("Operating hours")}</option>
                                      <option>{t("Distance in km")}</option>
                                      <option>{t("Individual")}</option>
                                    </Form.Select>
                                  </InputGroup>
                                </Form.Group>
                              </Col>
                              {
                                internalView && <Col md={4}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>{t("Simulated production percentage")}</Form.Label>
                                    <InputGroup className="mb-3">
                                      <InputGroup.Text>%</InputGroup.Text>
                                      <Form.Control
                                        type="number"
                                        name="simulated_percentage"
                                        required
                                        value={simulated_percentage}
                                        onChange={(e) => setSimulatedPercentage(e.target.value)}
                                        placeholder="80 %" />
                                      <InputGroup.Text>.00</InputGroup.Text>
                                    </InputGroup>
                                  </Form.Group>
                                  <Form.Group className="mb-3">
                                    <Form.Check
                                      checked={stochastic}
                                      onChange={handleChangeStochastic}
                                      label={t("Stochastisch festlegen (automatisch leicht abweichende IST-Produktion simulieren)")}
                                      type="checkbox" />
                                  </Form.Group>
                                </Col>
                              }
                            </Row>
                            <br />
                            <Row>
                              {/* defaultActiveKey="0" */}
                              <Accordion>
                                <Accordion.Item eventKey="0" className="bg-white">
                                  <Accordion.Header>{t("Pay-per-Use Price Factors")}</Accordion.Header>
                                  <Accordion.Body>
                                    <Form>
                                      <Row>
                                        <Col md={6}>
                                          <Form.Group className="mb-3">
                                            <Form.Label>{t("Percent produced")}</Form.Label>
                                          </Form.Group>
                                        </Col>
                                        {/* <Col md={1}> </Col>*/}
                                        <Col md={6}>
                                          <Form.Group className="mb-3">
                                            <Form.Label>{t("Price factors")}</Form.Label>
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                      {renderRows()}
                                    </Form>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Row>
                          </Form>
                        }

                        {/* {activeTab === "AaaS_Full" && <div className="text-center">
                          <Badge bg="" className="badge-soft-warning me-2">
                            Coming Soon
                          </Badge>
                          <br />
                          <br />
                          <br />
                        </div>} */}


                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="bg-white">
                      <Accordion.Header>{t("cap-on Tech Services")}</Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <Row>
                          <Col md={12}>
                            <div className="pt-2 mb-4">
                              <img src={techIcon} alt="cap-on" style={{ height: "65px", paddingTop: "10px", paddingBottom: "10px" }} />
                              <h4>{t("cap-on Tech-Services")}</h4>
                            </div>
                            <Form.Group className="mb-3">
                              <Table size="sm">
                                <thead>
                                  <tr>
                                    <th scope="col">{t("")}</th>
                                    <th scope="col">{t("Specification")}</th>
                                    <th scope="col">{t("Price")}</th>
                                    <th scope="col">{t("Interval")}</th>
                                    {/* <th scope="col">{t("Live View")}</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><Form.Check
                                      label={<strong>cap-on Tech Basic</strong>}
                                      type="radio"
                                      onChange={(e) => setAdditionalCosts(799)}
                                      name="radios-example"
                                    /></td>
                                    <td>
                                      <ReactMarkdown>
                                        {`
- Asset 360° Ansicht: Analytics, digitale Gesundheitsakte
- cap-on Insights inkl. KPIs, Trendanalysen und Prognosen
- Digitales Vertrags- und Rechnungsmanagement
- cap-on Consumer API: Datenintegration in Kundenbestandssysteme`}
                                      </ReactMarkdown>
                                      <ProductDescriptionTechBasic />
                                    </td>
                                    <td>
                                      {discounts ?
                                        <Col md="4">
                                          <Form.Group>
                                            <Form.Control
                                              type="number"
                                              defaultValue={799}
                                              placeholder="799" />
                                          </Form.Group>
                                        </Col> :
                                        (799)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                          style: "currency", currency: "EUR", maximumFractionDigits: 2
                                        })
                                      }
                                    </td>
                                    <td>Monatlich</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check
                                      label={<><strong>cap-on Tech Premium</strong> <br /><p className="">(empfohlen)</p></>}
                                      defaultChecked
                                      type="radio"
                                      onChange={(e) => setAdditionalCosts(2599)}
                                      name="radios-example"
                                    /></td>
                                    <td>
                                      <ReactMarkdown>
                                        {`
- Asset 360° Ansicht: Analytics, digitale Gesundheitsakte
- cap-on Insights inkl. KPIs, Trendanalysen und Prognosen
- **cap-on Enhance**: Konkrete Handlungsempfehlungen
- **cap-on Watchtower**: KI-gestütztes Monitoring erkennt negative Produktionsmuster und -anomalien
- Digitales Vertrags- und Rechnungsmanagement
- cap-on Consumer API: Datenintegration in Kundenbestandssysteme`}
                                      </ReactMarkdown>
                                      <ProductDescriptionTechPremium />
                                    </td>
                                    <td>
                                      {discounts ?
                                        <Col md="4">
                                          <Form.Group>
                                            <Form.Control
                                              type="number"
                                              defaultValue={2599}
                                              placeholder="2599" />
                                          </Form.Group>
                                        </Col> :
                                        (2599)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                          style: "currency", currency: "EUR", maximumFractionDigits: 2
                                        })
                                      }
                                    </td>
                                    <td>Monatlich</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="bg-white">
                      <Accordion.Header>{t("cap-on ESG Services")}</Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <Row>
                          <Col md={12}>
                            <div className="pt-2 mb-4">
                              <img src={esgIcon} alt="cap-on" style={{ height: "65px", paddingTop: "10px", paddingBottom: "10px" }} />
                              <a className="h4">{t("cap-on ESG-Services")}</a>
                            </div>
                            <Form.Group className="mb-3">
                              <Table size="sm">
                                <thead>
                                  <tr>
                                    <th scope="col">{t("")}</th>
                                    <th scope="col">{t("Specification")}</th>
                                    <th scope="col">{t("Price")}</th>
                                    <th scope="col">{t("Interval")}</th>
                                    {/* <th scope="col">{t("Live View")}</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td><Form.Check
                                      type="checkbox"
                                      defaultChecked
                                      label={<strong>Datenerhebung des Stromverbrauchs</strong>} />
                                    </td>
                                    <td>
                                      <ReactMarkdown>
                                        {`
-	Bedarfsanalyse und Montage eines geeigneten Energiemeters
-	Sicherstellung der Datenkommunikation zwischen Shop-Floor und Cloud
-	Sicherstellung durch Betrieb der technischen Infrastruktur
-	Visualisierung der Energiedaten`}
                                      </ReactMarkdown>
                                      <ProductDescriptionModal
                                        title={"Datenerhebung des Stromverbrauchs"}
                                        buttonTitle={"Produktdetails"}
                                        markDownDescription={
                                          `
### Beschreibung
- - - 
-	Bedarfsanalyse und Montage eines geeigneten Energiemeters
-	Sicherstellung der Datenkommunikation zwischen Shop-Floor und Cloud
-	Sicherstellung durch Betrieb der technischen Infrastruktur
-	Visualisierung der Energiedaten
`}
                                        markDownBenefits={`
### Vorteile
- - - 
-	Identifikation von Einsparpotenzialen
-	Reduktion des Strombedarfs
-	Ermittlung Energiebedarfs pro Bauteil oder Anlagenkomponente (Reporting; LkSG) 
`} />
                                    </td>
                                    <td>{
                                      (2599)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                        style: "currency", currency: "EUR", maximumFractionDigits: 2
                                      })}
                                      <br />
                                      {(400)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                        style: "currency", currency: "EUR", maximumFractionDigits: 2
                                      })}
                                    </td>
                                    <td>Einmalig<br />Monatlich</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check
                                      type="checkbox"
                                      defaultChecked
                                      label={<strong>CO<sub>2</sub>-Footprint Kalkulation und Tracking</strong>} />
                                    </td>
                                    <td>
                                      <ReactMarkdown>
                                        {`- Bestandsaufnahme von CO\u2082 Emissionen auf Basis des Greenhouse Gas Protocol 
- Unterstützung bei der Identifikation von Emissionsquellen und Definition der Systemgrenzen
- (Erstmals) nach 3 Monaten Betriebszeit: Kalkulation des Product Carbon Footprint (PCF) 
- Ab Monat 4: Start des autom. CO\u2082 Trackings inkl. digitaler Aufbereitung 
- Bei Bedarf: Verknüpfung mit aus der Lieferkette resultierenden „Scope 3“ Emissionen (dh. Unterstützung bei Datenabfrage ggü. Zulieferern) `}
                                      </ReactMarkdown>
                                      <ProductDescriptionModal
                                        title={"CO\u2082-Footprint Kalkulation und Tracking"}
                                        buttonTitle={"Produktdetails"}
                                        markDownDescription={`
### Beschreibung
- - - 
- Bestandsaufnahme von CO\u2082 Emissionen auf Basis des Greenhouse Gas Protocol 
- Unterstützung bei der Identifikation von Emissionsquellen und Definition der Systemgrenzen
- (Erstmals) nach 3 Monaten Betriebszeit: Kalkulation des Product Carbon Footprint (PCF) 
- Ab Monat 4: Start des autom. CO\u2082 Trackings inkl. digitaler Aufbereitung 
- Bei Bedarf: Verknüpfung mit aus der Lieferkette resultierenden „Scope 3“ Emissionen (dh. Unterstützung bei Datenabfrage ggü. Zulieferern) 
                                          `}
                                        markDownBenefits={`
### Vorteile
- - - 
-	Klimabilanz auf Grundlage realer Maschinen- und Prozessdaten 
-	Ermittlung der Emissionswerte pro Bauteil oder Anlagenkomponente (Reporting; LkSG) 
-	Effektive Product Compliance 
-	Bessere Nachverfolgbarkeit von „Scope 3“ Emissionen 
                                            `} />

                                    </td>
                                    <td>
                                      {(3200)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                        style: "currency", currency: "EUR", maximumFractionDigits: 2
                                      })} <br /> {(240)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                        style: "currency", currency: "EUR", maximumFractionDigits: 2
                                      })
                                      }</td>
                                    <td>Einmalig<br />Monatlich</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check
                                      type="checkbox"
                                      label={<strong>Reduktionsmaßnahmen erarbeiten</strong>} />
                                    </td>
                                    <td>
                                      <ReactMarkdown>
                                        {`
- Wissenschaftlich begleitete Ausarbeitung von Reduktionsmaßnahme
- Ableitung konkreter Handlungsempfehlungen zur Reduktion des CO\u2082-Footprints`}
                                      </ReactMarkdown>

                                    </td>
                                    <td>{(6999)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                      style: "currency", currency: "EUR", maximumFractionDigits: 2
                                    })}</td>
                                    <td>Einmalig</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check
                                      type="checkbox"
                                      defaultChecked
                                      label={<strong>Ausgleich/Kompensation des CO<sub>2</sub>-Footprints</strong>} />
                                    </td>
                                    <td>
                                      <ReactMarkdown>
                                        {`
- Quartalsmäßige Kompensation der monatlichen Emissionen via ClimateGrid
- Übermittlung relevanter Dokumentation der Kompensation: Bericht, Zertifikat, etc.`}
                                      </ReactMarkdown>

                                    </td>
                                    <td>ca. {(26)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                      style: "currency", currency: "EUR", maximumFractionDigits: 0
                                    })} / t CO<sub>2</sub>e</td>
                                    <td>Quartalsweise</td>
                                  </tr>
                                  <tr>
                                    <td><Form.Check
                                      ype="checkbox"
                                      label={<strong>CSR-Reporting</strong>} />
                                    </td>
                                    <td>
                                      <ReactMarkdown>
                                        {`
- Rechtssichere Dokumentation der gemessenen nachhaltigkeitsrelevanten Daten
-	Weiterführende Unterstützungsleistungen bei der richtlinienkonformen nicht-finanziellen Berichterstattung (nach Standards xy…)
-	Bereitstellung von Textbausteinen für die Nachhaltigkeitsberichterstattung`}
                                      </ReactMarkdown>


                                      <ProductDescriptionModal
                                        title={"CSR-Reporting"}
                                        buttonTitle={"Produktdetails"}
                                        markDownDescription={`
### Beschreibung
- - - 
- Rechtssichere Dokumentation der gemessenen nachhaltigkeitsrelevanten Daten
-	Weiterführende Unterstützungsleistungen bei der richtlinienkonformen nicht-finanziellen Berichterstattung (nach Standards xy…)
-	Bereitstellung von Textbausteinen für die Nachhaltigkeitsberichterstattung
                                          `}
                                        markDownBenefits={`
### Vorteile
- - - 
- Rechtssichere Dokumentation der gemessenen nachhaltigkeitsrelevanten Daten
-	Weiterführende Unterstützungsleistungen bei der richtlinienkonformen nicht-finanziellen Berichterstattung (nach Standards xy…)
-	Bereitstellung von Textbausteinen für die Nachhaltigkeitsberichterstattung
                                            `} />
                                    </td>
                                    <td>{(2599)?.toLocaleString(i18n.language === "de" ? de : "en", {
                                      style: "currency", currency: "EUR", maximumFractionDigits: 2
                                    })}</td>
                                    <td>Einmalig</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <br />
                  <br />
                  {
                    UserDataManager.getInstance().isCapOn() &&
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Check
                            checked={internalView}
                            onChange={() => setInternalView(!internalView)}
                            label={t("Internal View")}
                            type="checkbox" />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Check
                            checked={discounts}
                            onChange={() => setDiscounts(!discounts)}
                            label={t("Discounts")}
                            type="checkbox" />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Check
                            checked={scenarioAnalysis}
                            onChange={handleSetScenarioAnalysis}
                            label={t("Scenario-Analysis")}
                            type="checkbox" />
                        </Form.Group>
                      </Col>
                    </Row>
                  }
                  <br />
                  <Row>
                    <Col>
                      <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                        {t("Back")}
                      </Button>
                      <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => reset()}>
                        {t("Reset")}
                      </Button>
                      <Button type="submit" variant="primary"
                      >
                        {t("Request Asset")}
                      </Button>
                    </Col>
                  </Row>
                </Form>

              </Card.Body>
            </Card>
          </Col>
        </Row>

        {showLoadingView && <LoadingView />}

        {showResultsP1 && !showLoadingView && <ResultsP1 />}
        {showResultsP1 && !showLoadingView && <GenericChartP1 />}

        {showResultsP2 && !showLoadingView && <ResultsP2 />}
        {showResultsP2 && !showLoadingView && <GenericChartP2 />}

        {showResultsP2 && scenarioAnalysis && resultsP2ScenarioAnalysis.length > 0 && !showLoadingView && <ResultsScenarioAnalyses />}

      </Container>
    </React.Fragment>
  );
};

export default PricingEngine;