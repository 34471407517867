import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { RefreshCw, Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import ContractTable from "./ContractTable";
import ErrorHandler from "../Helper/ErrorHandler";
import AddContractModal from "./ContractModal_Add";
import UserDataManager from "../Helper/UserDataManager";

const Contracts = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    setIsLoading(true);

    axios
      .get("/contracts/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/contracts");
        setData(response.data?.sort((a,b) => a?.title?.localeCompare(b?.title)));
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
        setIsLoading(false);
      });
    }, []);

  return (
    <React.Fragment>
      <Helmet title={t("Contracts")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Contracts")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <AddContractModal/>
            {/* <Button
              onClick={() => navigate("/contracts/add")}
              variant="primary"
              className="shadow-sm me-1">
              <Plus className="feather" />  {t("New")}
            </Button> */}
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm">
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        {/* {loadHeader()} */}
        <Row>
          <Col>
            <ErrorHandler error={error} />
            {ContractTable(data, null, isLoading)}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Contracts;
