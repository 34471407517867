import axios from "axios";
import { Badge, Card, Col, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SystemHealth = () => {
  const [ServicesListTEST, setServicesListTEST] = useState([]);
  const [ServicesListPROD, setServicesListPROD] = useState([]);
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  const servicesTEST = [
    {
      "system": "cap-on Core System (inkl. API-Gateway)",
      "alias": "core-system",
      "url": "https://core.test.cap-on.de/api/v1/health"
    },
    {
      "system": "Pricing and Risk Engine",
      "alias": "pricing",
      "url": "https://pricing.test.cap-on.de/api/v1/health"
    },
    {
      "system": "Log API",
      "alias": "log",
      "url": "https://logs.test.cap-on.de/api/v1/health"
    }
  ];

  const servicesPROD = [
    {
      "system": "cap-on Core System (inkl. API-Gateway)",
      "alias": "core-system",
      "url": "https://core.cap-on.de/api/v1/health"
    },
    {
      "system": "Pricing and Risk Engine",
      "alias": "pricing",
      "url": "https://pricing.cap-on.de/api/v1/health"
    },
    {
      "system": "Log API",
      "alias": "log",
      "url": "https://logs.cap-on.de/api/v1/health"
    }
  ];

  useEffect(() => {

    axios
      .get(servicesTEST[0].url)
      .then(function (response) {
        // handle success

        ServicesListTEST.push({
          "system": servicesTEST[0].system,
          "alias": servicesTEST[0].alias,
          "status": response.status,
          "data": response.data
        })
        setData("datau");
      })
      .catch(function (error) {
        // handle error
        ServicesListTEST.push({
          "system": servicesTEST[0].system,
          "alias": servicesTEST[0].alias,
          "status": error?.response?.status || "Test",
          "data": error.response.data
        })
        console.log(error);
      });

    axios
      .get(servicesTEST[1].url)
      .then(function (response) {
        // handle success

        ServicesListTEST.push({
          "system": servicesTEST[1].system,
          "alias": servicesTEST[1].alias,
          "status": response.status,
          "data": response.data
        })
        setData("data");
      })
      .catch(function (error) {
        // handle error
        ServicesListTEST.push({
          "system": servicesTEST[1].system,
          "alias": servicesTEST[1].alias,
          "status": error?.response?.status || "Test",
          "data": error.response.data
        })
        setData("data");
        console.log(error);
      });

    axios
      .get(servicesTEST[2].url)
      .then(function (response) {
        // handle success

        ServicesListTEST.push({
          "system": servicesTEST[2].system,
          "alias": servicesTEST[2].alias,
          "status": response.status,
          "data": response.data
        })
        setData("datat");
      })
      .catch(function (error) {
        // handle error
        ServicesListTEST.push({
          "system": servicesTEST[2].system,
          "alias": servicesTEST[2].alias,
          "status": error?.response?.status || "Test",
          "data": error.response.data
        })
        console.log(error);
      });

    // PROD

    axios
      .get(servicesPROD[0].url)
      .then(function (response) {
        // handle success

        ServicesListPROD.push({
          "system": servicesPROD[0].system,
          "alias": servicesPROD[0].alias,
          "status": response.status,
          "data": response.data
        })
        setData("datau");
      })
      .catch(function (error) {
        // handle error
        ServicesListPROD.push({
          "system": servicesPROD[0].system,
          "alias": servicesPROD[0].alias,
          "status": error?.response?.status || "Test",
          "data": error.response.data
        })
        console.log(error);
      });

    axios
      .get(servicesPROD[1].url)
      .then(function (response) {
        // handle success

        ServicesListPROD.push({
          "system": servicesPROD[1].system,
          "alias": servicesPROD[1].alias,
          "status": response.status,
          "data": response.data
        })
        setData("data");
      })
      .catch(function (error) {
        // handle error
        ServicesListPROD.push({
          "system": servicesPROD[1].system,
          "alias": servicesPROD[1].alias,
          "status": error?.response?.status || "Test",
          "data": error.response.data
        })
        setData("data");
        console.log(error);
      });

    axios
      .get(servicesPROD[2].url)
      .then(function (response) {
        // handle success

        ServicesListPROD.push({
          "system": servicesPROD[2].system,
          "alias": servicesPROD[2].alias,
          "status": response.status,
          "data": response.data
        })
        setData("datat");
      })
      .catch(function (error) {
        // handle error
        ServicesListPROD.push({
          "system": servicesPROD[2].system,
          "alias": servicesPROD[2].alias,
          "status": error?.response?.status || "Test",
          "data": error.response.data
        })
        console.log(error);
      });

    }, []);

  function getUnique(arr, index) {

    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const renderServicesTableTEST = () => {

    return getUnique(ServicesListTEST, "system").sort((a, b) => (a.alias > b.alias)).map((service) => {
      return (
        <tr key={service.system}>
          <td>
            {renderStatusCodeState(service)}
          </td>
          <td>{service.system}</td>
          <td>{service.alias}</td>
        </tr>
      );
    });
  };

  const renderServicesTablePROD = () => {

    return getUnique(ServicesListPROD, "system").sort((a, b) => (a.alias > b.alias)).map((service, indx) => {
      return (
        <tr key={indx}>
          <td>
            {renderStatusCodeState(service)}
          </td>
          <td>{service.system}</td>
          <td>{service.alias}</td>
        </tr>
      );
    });
  };

  const renderStatusCodeState = (service) => {
    switch (service.status) {
      case 200:
      case 201:
      case 202: 
      case 401: 
      case 403:
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Running")}</Badge>
        )
      // case 401:
      //   return (
      //     <span className="badge bg-success">{t("Running")}</span>
      //   )
      default:
        return (
          <Badge bg="" className="badge-soft-danger me-2">{t("Not running")}</Badge>
        )
    }
  }

  // const renderRunningState = (service) => {
  //   switch (service.data) {
  //     case "ok":
  //       return (
  //         <span className="badge bg-success">{t("Running")}</span>
  //       )
  //     default:
  //       return (
  //         <span className="badge bg-danger">{t("Not running")}</span>
  //       )
  //   }
  // }

  return (
    <Row>
        <Col md="6">
          <Card>
            <Card.Header>
              <Card.Title tag="h5">{t("Test-Environment")}</Card.Title>
            </Card.Header>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">{t("Status")}</th>
                  <th scope="col">{t("System")}</th>
                  <th scope="col">{t("Alias")}</th>
                </tr>
              </thead>
              <tbody>{renderServicesTableTEST()}</tbody>
            </Table>
          </Card>
        </Col>
        <Col md="6">
          <Card>
            <Card.Header>
              <Card.Title tag="h5">{t("Prod-Environment")}</Card.Title>
            </Card.Header>
            <Table responsive>
              <thead>
                <tr>
                  <th scope="col">{t("Status")}</th>
                  <th scope="col">{t("System")}</th>
                  <th scope="col">{t("Alias")}</th>
                </tr>
              </thead>
              <tbody>{renderServicesTablePROD()}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>
  );
};

export default SystemHealth;
