import React from "react";
import { Dropdown } from "react-bootstrap";
import { Settings } from "react-feather";
import avatar1 from "../../assets/img/avatars/avatar.png";
import useTheme from "../../hooks/useTheme";
import { THEME } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserDataManager from "../../pages/Helper/UserDataManager";
import { useFullScreen } from 'react-browser-hooks'
import ChangePasswordModal from "../../pages/ChangePassword_Edit";

const NavbarUser = (userData) => {
  // const [data, setData] = useState([]);
  const { theme, setTheme } = useTheme(); //onClick={setTheme(THEME.DEFAULT)}
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toggle, fullScreen } = useFullScreen()

  function doLogout(e) {
    e.preventDefault();
    localStorage.removeItem("token");
    // localStorage.removeItem("tenant_id");
    localStorage.removeItem("data");
    localStorage.clear();
    navigate("/sign-in");
    window.location.reload(false);
  }

  const loadAvatar = () => {
    return (
      <img
        src={UserDataManager.getInstance().getUserData().avatar == null ? avatar1 : UserDataManager.getInstance().getUserData().avatar.url}
        className="avatar img-fluid rounded-circle me-1"
        alt="avatar"
      />);
  };

  return (
    <Dropdown className="nav-item" align={{ lg: 'end' }}>
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <span className="text-dark"><li></li>
            {UserDataManager.getInstance().getUserData().first_name} {UserDataManager.getInstance().getUserData().last_name} {""}&nbsp;&nbsp;&nbsp;
          </span>
          {loadAvatar()}
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        <Dropdown.Item href="/me">{t("Profile")}</Dropdown.Item>
        {/* <Dropdown.Item href="/change-password">{t("Change Password")}</Dropdown.Item> */}
        <ChangePasswordModal/>
        {/* <Dropdown.Item onClick={toggle}>{fullScreen ? t('Stop fullscreen') : t('Start fullscreen')}</Dropdown.Item> */}
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() =>
            !!(theme === "dark")
              ? setTheme(THEME.DEFAULT)
              : setTheme(THEME.DARK)
          }
        >
          {!!(theme === "dark") ? t("Toggle Light Mode") : t("Toggle Dark Mode")}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={e => navigate("/support")}>{t("Support")}</Dropdown.Item>
        {/* <Dropdown.Item href="mailto:theo.schuetz@cap-on.de?subject=cap-on%20Support&body=Dear%20cap-on%20Team">{t("Support")}</Dropdown.Item> */}
        <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to log out?")) && doLogout(e)}>{t("Sign out")}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
