import axios from "axios";
import {
  Card,
  Col,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import UserDataManager from "../Helper/UserDataManager";
import NotyfContext from "../../contexts/NotyfContext";
import { getCoreBaseURL } from "../../config";


const AddSupportModal = (props) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [contractId, setContractId] = useState("");
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");

  var userData = UserDataManager.getInstance().getUserData();
  const [contractData, setContractData] = useState([]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  useEffect(() => {

    if (openModal) {

      axios
        .get("/contracts/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/contracts");
          setContractData(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get(getCoreBaseURL() + "/openapi.json")
        .then(function (response) {
          // handle success
          console.log(response.status, "/openapi.json");
          setCategories(response.data["components"]["schemas"]["SupportTicketCategory"]["enum"]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [openModal, props?.title, props?.category]);


  const LoadCategories = () => {
    return categories?.map((entry) => {
      return (
        <option key={entry} value={entry}>{t(entry)}</option>
      );
    });
  };

  const LoadContracts = () => {
    return contractData?.map((contract) => {
      return (
        <option key={contract.id} value={contract.id}>{contract.title} ({contract.client_name})</option>
      );
    });
  };

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  function handleSubmit(e) {
    e.preventDefault();

    // setError({ showMessage: false, errorData: null })

    axios
      .post("/support_tickets/", {
        "title": title,
        "description": description,
        "solved": false,
        "category": category,
        "contract_id": contractId
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Sent support ticket");

        notyf.open({
          type: "success",
          message: t("Support-Ticket created. A cap-on representative will be with you shortly."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        toggle(false)
        refreshPage();
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  return (
    <>
      <br />
      <Button
        onClick={() => toggle()}
        variant="primary"
        className="shadow-sm"
      >
        <Plus className="feather" /> {props?.buttonText}
      </Button>
      <Modal show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Row>
            <Col xs="auto" className="d-none d-sm-block">
              <Card.Title tag="h5">{t("New Support Ticket")}</Card.Title>
            </Col>
            {/* <Col xs="auto" className="ms-auto text-end">
              <Button size="sm" variant="outline-primary" className="shadow-sm me-1" href="mailto:theo.schuetz@cap-on.de?subject=cap-on%20Support&body=Dear%20cap-on%20Team">
                {t("Emailkontakt")}
              </Button>
            </Col> */}
          </Row>
          <br />
          <h6 className="card-subtitle text-muted">
            {t("Please describe your support request. We will get back to you asap.")}
          </h6>
          <br />
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("Title")}</Form.Label>
                  <Form.Control
                    placeholder={t("Title")}
                    required
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Contract")}</Form.Label>
                  <Form.Select
                    onChange={(e) => setContractId(e.target.value)}
                    required>
                    <option value={""}>{t("-- Select --")}</option>
                    {LoadContracts()}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("Email")}</Form.Label>
                  <Form.Control
                    value={userData.email}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Label>{t("Category")}</Form.Label>
                  <Form.Select
                    required
                    onChange={e => setCategory(e.target.value)}>
                    <option value="">{t("-- Select --")}</option>
                    {LoadCategories()}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group>
                <Form.Label>{t("Description")}</Form.Label>
                <Col sm={12}>
                  <Form.Control
                    placeholder="Problembeschreibung..."
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="4"
                    as="textarea"
                  />
                </Col>
              </Form.Group>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant="outline-primary" className="shadow-sm" href="mailto:theo.schuetz@cap-on.de?subject=cap-on%20Support&body=Dear%20cap-on%20Team">
                  {t("Emailkontakt")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal >
    </>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AddSupportModal;