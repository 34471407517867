import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

import "./mocks";

import axios from "axios";
import { isProdEnvironment } from "./config";



// if (process.env.REACT_APP_ENVIRONMENT !== "TEST" && useProd) {
//   console.error("This should never work!");
// } else {
//   console.log("Running with PROD data")
// }
// axios.defaults.baseURL = (useProd || process.env.REACT_APP_ENVIRONMENT === "PROD")

// axios.defaults.baseURL = (process.env.REACT_APP_ENVIRONMENT === "PROD")  
// ? "https://core.cap-on.de/api/v1/t/" + localStorage.getItem("tenant_id")
// : "https://core.test.cap-on.de/api/v1/t/" + localStorage.getItem("tenant_id");

if (isProdEnvironment()) {
  if (localStorage.getItem("tenant_id") === null || localStorage.getItem("tenant_id") === "tQ2OWkk") {
    axios.defaults.baseURL = "https://core.cap-on.de/api/v1"
  } else {
    axios.defaults.baseURL = "https://core.cap-on.de/api/v1/t/" + localStorage.getItem("tenant_id")
  }
} else {
  if (localStorage.getItem("tenant_id") === null || localStorage.getItem("tenant_id") === "tqrQzGB") {
    axios.defaults.baseURL = "https://core.test.cap-on.de/api/v1"
  } else {
    axios.defaults.baseURL = "https://core.test.cap-on.de/api/v1/t/" + localStorage.getItem("tenant_id")
  }
}

axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['accept'] = "application/json";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
