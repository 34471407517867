import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Col,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Check } from "react-feather";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import ErrorHandler from "../Helper/ErrorHandler";

const AddPaidStatusModal = ({ invoice, handleCancel, handleSave }) => {
  // let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [bankRef, setBankRef] = useState();
  const [status, set_status] = useState();
  const [paidOn, setPaidOn] = useState();

  function handleSubmit(e) {
    e.preventDefault();

    axios.put(`/invoices/${invoice?.id}/status`, null, {
      params: {
        payment_status: status,
        payment_date: paidOn?.toISOString(),
      }
    })
      .then(function (response) {
        console.log(response.status, "/invoices/status");
        handleSave()
        // refreshPage();
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error });
        console.log(error);
      });

    // axios
    //   .put("/invoices/" + id, {
    //     is_paid: true,
    //     paid_on: paidOn?.toISOString(),
    //     bank_reference: bankRef
    //   })
    //   .then(function (response) {
    //     // handle success
    //     console.log(response.status, "/invoices");
    //     toggle(false)
    //     refreshPage();
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     setError({ showMessage: true, errorData: error })
    //     console.log(error);
    //   });
  }

  return (
    <Modal
      show
      centered
      size="sm"
      backdrop="static" // Ensure the backdrop is rendered
      style={{ zIndex: 1080 }} // Higher z-index to ensure it appears above the first modal
      onHide={handleCancel}
    >
      {/* <Modal.Header closeButton>{t("Mark as \"Paid\"")}</Modal.Header> */}
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Status")}</Form.Label>
                <Form.Select onChange={(e) => set_status(e.target.value)} defaultValue={invoice?.payment_status === "overdue" ? "unpaid" : invoice?.payment_status} required>
                  <option>{t("-- Select --")}</option>
                  <option value={"paid"}>{t("Paid")}</option>
                  <option value={"unpaid"}>{t("Unpaid")}</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Payment Date")}</Form.Label>
                <Form.Control
                  type="date"
                  // required
                  onChange={e => setPaidOn(parseISO(e.target.value))}
                />
              </Form.Group>
            </Col>
            {/* <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("IBAN")} / {t("Bank Reference")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("DE2321xxx")}`}
                    onChange={(e) => setBankRef(e.target.value)}
                  />
                </Form.Group>
              </Col> */}
          </Row>
          <hr />
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button variant="outline-secondary" onClick={handleCancel}>
                {t("Cancel")}
              </Button>{" "}
              <Button variant={"primary"} type="submit">
                {t("Apply")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AddPaidStatusModal;
