export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

// This can be toggled to use PROD data in development mode!
const useProd = false;

export function isProdEnvironment() {
  return process.env.REACT_APP_ENVIRONMENT === "PROD" ? true : useProd ? true : false
}

export function getCoreBaseURL() {
  return isProdEnvironment()
    ? "https://core.cap-on.de/api/v1"
    : "https://core.test.cap-on.de/api/v1";
}

export function getIAMBaseURL() {
  return isProdEnvironment()
    ? "https://iam.cap-on.de/api/v1"
    : "https://iam.test.cap-on.de/api/v1";
}

export function getLogsBaseURL() {
  return isProdEnvironment() ?
    "https://logs.cap-on.de/api/v1" :
    "https://logs.test.cap-on.de/api/v1"
}

export function getGatewayVPNBaseURL() {
  return isProdEnvironment()
    ? "https://gw.cap-on.de/api/v1"
    : "https://gw.test.cap-on.de/api/v1";
}

export function getPricingBaseURL() {
  return isProdEnvironment() ?
    "https://pricing.cap-on.de/api/v1" :
    "https://pricing.test.cap-on.de/api/v1"
}