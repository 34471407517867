import React, { useContext, useEffect, useState } from "react";
import { Alert, Badge, Button, Card, Col, Form, InputGroup, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { de } from "date-fns/locale";
import { zonedTimeToUtc } from "date-fns-tz";
import usePalette from "../../../hooks/usePalette";
import GenericChart from "../Charts/GenericChartComponent";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import NotyfContext from "../../../contexts/NotyfContext";
import { getCoreBaseURL } from "../../../config";
import { Plus } from "react-feather";

const CreateAIReportModal = ({ asset_id, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);


  // const [data, setData] = useState();
  const [start_date, set_start_date] = useState("2024-03-27T09:46:27.165Z");
  const [end_date, set_end_date] = useState("2024-03-27T09:46:27.165Z");
  const [gap_fill, set_gap_fill] = useState("last_value");
  const [cluster_density, set_cluster_density] = useState("5 minutes");
  const [assistant_instructions, set_assistant_instructions] = useState("You are a data analyst analyzing time series data of various sensors of industrial machines.\n    You look for potential time periods, especially longer ones, that had anomalies or deviating patterns.\n    After analyzing the data you write a brief management summary of your findings in Markdown.\n    Please include the specific time periods of the anomalies as well as\n     some recommendations for the operators if any anomalies were detected.\n    ");
  const [openai_model, set_openai_model] = useState("gpt-4-turbo-preview");
  const [add_usage, set_add_usage] = useState(false);
  const [add_cycle_times, set_add_cycle_times] = useState(false);
  const [prompt, set_prompt] = useState([]);

  const [ClusterUnit, setClusterUnit] = useState([]);
  const [GapFillUnit, setGapFillUnit] = useState([]);

  const loadClusterUnit = () => {
    return ClusterUnit.map((unit) => {
      return (
        <option key={unit} value={unit}>{unit}</option>
      );
    });
  };

  const loadGapFillUnit = () => {
    return GapFillUnit.map((unit) => {
      return (
        <option key={unit} value={unit}>{unit}</option>
      );
    });
  };

  useEffect(() => {

    if (show) {

      setError({ showMessage: false, errorData: null })

      axios
        .get(getCoreBaseURL() + "/openapi.json")
        .then(function (response) {
          // handle success
          console.log(response.status, "Loaded JSON");
          setClusterUnit(response.data["components"]["schemas"]["TimeClusteringUnitExt"]["enum"]);
          setGapFillUnit(response.data["components"]["schemas"]["GapFillingMethod"]["enum"]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, [show]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    notyf.open({
      type: "success",
      message: t("Started generating a new AI-based anomaly report. This may take up to 2 minutes. 🧠 🚀"),
      duration: "9000",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })

    handleClose()

    axios
      .post("/assets/" + asset_id + "/anomaly_reports/generate/", {
        "start_date": start_date,
        "end_date": end_date,
        "gap_fill": gap_fill,
        "cluster_density": cluster_density,
        "assistant_instructions": assistant_instructions,
        "openai_model": openai_model,
        "add_usage": add_usage,
        "add_cycle_times": add_cycle_times,
        "prompt": prompt
      }
      )
      .then(function (response) {
        // handle success
        console.log(response.status, "/generate");

        notyf.open({
          type: "success",
          message: t("AI-report finished!"),
          duration: "8000",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  // const [time_rhythm, set_time_rhythm] = useState();

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{t("Generate AI-report")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Form onSubmit={handleSubmit}>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Start date")}</Form.Label>
                  <Form.Control type="date" onChange={e => set_start_date(parseISO(e.target.value))} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("End date")}</Form.Label>
                  <Form.Control type="date" onChange={e => set_end_date(parseISO(e.target.value))} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("assistant_instructions")}</Form.Label>
                  <Form.Control
                    onChange={e => set_assistant_instructions(e.target.value)}
                    value={assistant_instructions}
                    rows="4"
                    as="textarea"
                    placeholder={t("...")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("prompt")}</Form.Label>
                  <Form.Control
                    onChange={e => set_prompt(e.target.value)}
                    value={prompt}
                    rows="6"
                    as="textarea"
                    placeholder={t("...")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("gap_fill")}</Form.Label>
                  <Form.Select
                    onChange={(e) => set_gap_fill(e.target.value)}
                    value={gap_fill}>
                    <option value="">{t("-- Select --")}</option>
                    {loadGapFillUnit()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Clustering Unit")}</Form.Label>
                  <Form.Select
                    onChange={(e) => set_cluster_density(e.target.value)}
                    value={cluster_density}>
                    <option value="">{t("-- Select --")}</option>
                    {loadClusterUnit()}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Check
                    checked={add_usage}
                    onChange={() => set_add_usage(!add_usage)}
                    label={t("add_usage")}
                    type="checkbox" />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className="mb-3">
                  <Form.Check
                    checked={add_cycle_times}
                    onChange={() => set_add_cycle_times(!add_cycle_times)}
                    label={t("add_cycle_times")}
                    type="checkbox" />
                </Form.Group>
              </Col>
            </Row>

          </Form>
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Cancel")}
            </Button>{" "}
            <Button className="ms-1" variant="primary" onClick={(e) => handleSubmit(e)}>
              {t("Hinzufügen")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  )
}

export default CreateAIReportModal;
