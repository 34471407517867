import { format, formatDuration, intervalToDuration, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const OperatingTimesChart = ({ isLoading, operatingTimes, interval, ...props }) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  function getData() {
    const barData = [];
    const metadata = [];

    operatingTimes?.forEach(entry => {
      const entryData = {
        name: t(entry?.state_name),
        data: entry?.clustered_operating_times?.sort((a, b) => new Date(a?.interval_start) - new Date(b?.interval_start))?.map(e => e?.percent || 0),
      };

      // Save full data objects with percent and seconds for tooltip use
      metadata.push(entry?.clustered_operating_times?.map(e => ({ percent: e?.percent || 0, seconds: e?.seconds || 0 })));
      barData.push(entryData);
    });

    return { barData, metadata };
  }

  const { barData, metadata } = getData();

  const data = getData();

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  // function determineChartColors() {
  //   return props?.operatingTimes[2]?.state_name === "Running (Input Pressure >= 20 bar)"
  //     ? [
  //       palette["gray-500"],
  //       palette.warning,
  //       palette.success,
  //     ]
  //     : [
  //       palette["gray-500"],
  //       palette.warning,
  //       palette.danger,
  //       palette.success,
  //       palette.primary,
  //     ]
  // }

  // const dynamicColors = determineChartColors();

  function colorsFromResponse() {
    // Map over the operatingTimes and extract the display_color
    var colors = operatingTimes?.map(item => {
      const colorKey = item?.display_color || "secondary"; // Get the color string (e.g., "warning", "success")

      // If the color is "secondary", explicitly return palette["gray-500"]
      if (colorKey === "secondary") {
        return palette["gray-500"];
      }

      // If the color is "secondary", explicitly return palette["gray-500"]
      if (colorKey === "success-2") {
        return palette["info"];
      }

      // Otherwise, return the corresponding color from the palette, using the colorKey
      return palette[colorKey];
    });

    return colors; // This will return an array of colors from the palette
  }

  const firstNonEmptyCluster = operatingTimes?.find(item => item?.clustered_operating_times?.length > 0);

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "85%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    labels: firstNonEmptyCluster
      ? firstNonEmptyCluster.clustered_operating_times
        ?.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start))
        ?.map((e) => format(parseISO(e.interval_start), `${interval === "1 month" ? "" : "E, d "}MMM yyyy${interval === "1 hour" ? " HH:mm" : ""}`, { locale: i18n.language === "de" ? de : null }))
      : [],
    xaxis: {
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis: {
      max: 1,
      title: {
        text: t("State in %"),
      },
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
        formatter: (value) => value.toLocaleString(i18n.language === "de" ? de : "en", { style: "percent", maximumFractionDigits: 2 }),
      },
    },
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
        useSeriesColors: false
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val, opts) {
          const seriesIndex = opts.seriesIndex;
          const dataPointIndex = opts.dataPointIndex;

          // Access seconds from the metadata array
          const seconds = metadata[seriesIndex][dataPointIndex].seconds;
          const percent = val;

          return `${percent.toLocaleString(i18n.language === "de" ? de : "en", { style: "percent", maximumFractionDigits: 2 })} (${formatDuration(intervalToDuration({ start: 0, end: seconds * 1000 }), { format: ['days', 'hours', 'minutes', "seconds"], locale: i18n.language === "de" ? de : null })})`;
        },
      },
    },
    colors: colorsFromResponse(),
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5">{t("Operating Times")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {
          isLoading === true ? showLoadingSpinner() :
            operatingTimes?.length === 0 ? (
              <h6 className="card-subtitle text-muted">{t("No operating times available.")}</h6>
            ) : (
              <div className="chart">
                <Chart options={options} series={barData} type="bar" height="250" />
              </div>
            )
        }
      </Card.Body>
    </Card>
  );
};

export default OperatingTimesChart;       