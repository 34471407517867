import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";

const SystemRoutines = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  useEffect(() => {
    axios
      .get("/routines/")
      .then(function (response) {
        // handle success
        console.log(response.status, "routines")
        setData(response.data.sort((a, b) => a.last_run_at < b.last_run_at));
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }, []);

  const RenderRoutinesTable = () => {
    return data.map((routine) => {
      return (
        <tr key={routine.name} onClick={() => navigate("/system-routines/" + routine.name)} style={{ cursor: "pointer" }}>
          <td>
            <span className={routine.active === true ? "badge bg-success" : "badge bg-warning"}>{routine?.active === true ? t("Active") : t("Inactive")}</span>
          </td>
          <td>{t(routine.name)}</td>
          <td>
            {
              routine.last_run_at !== null && format(parseISO(routine?.last_run_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })
            } {" "}
            ({routine.last_run_at !== null && formatDistanceToNow(parseISO(routine?.last_run_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })})
          </td>
          <td>{routine.total_run_count}</td>
        </tr>
      );
    });
  };

  return (
    <React.Fragment>
      <Helmet title="cap-on • System Routines" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("System Routines")}</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <Card className="flex-fill w-100">
              {/* <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Update backend-configuration")}
                </Card.Title>
              </Card.Header> */}
              <Card.Body>
                <Row>
                  <Col>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th scope="col">{t("Status")}</th>
                          <th scope="col">{t("Name")}</th>
                          <th scope="col">{t("Last run at")}</th>
                          <th scope="col">{t("Total Run Count")}</th>
                        </tr>
                      </thead>
                      <tbody>{RenderRoutinesTable()}</tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemRoutines;
