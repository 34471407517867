import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { getCoreBaseURL } from "../../config";

const FileAccessLevelModal = ({ file, parent_id, parent_type, handleCancel, handleSave }) => {
    const { t, i18n } = useTranslation();
    const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
    const notyf = useContext(NotyfContext);

    const [permissionLevels, setPermissionLevels] = useState([])
    const [title, setTitle] = useState(file?.title);
    const [selectedAccessLevel, setSelectedAccessLevel] = useState(file?.access_level);

    useEffect(() => {

        axios
            .get(getCoreBaseURL() + "/openapi.json")
            .then(function (response) {
                // handle success
                console.log(response.status, "/openapi.json");
                setPermissionLevels(response.data["components"]["schemas"]["FileAccessLevel"]["enum"]);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });

    }, []);

    function submit(e) {

        e.preventDefault();
        setError({ showMessage: false, errorData: null })

        // let body = {
        //     updated_title: title
        // }

        // console.log("----->", parent_type, parent_id, file?.id, selectedAccessLevel)
        axios
            .put(
                `${parent_type}${parent_id}/files/${file?.id}/access_level?access_level=${selectedAccessLevel}&updated_title=${title}`,
                // body
            )
            .then(function (response) {
                // handle success
                console.log(response.status, "/assets");

                notyf.open({
                    type: "success",
                    message: t("File permission updated."),
                    duration: "4500",
                    ripple: false,
                    dismissible: true,
                    position: {
                        x: "right",
                        y: "bottom",
                    },
                })

                handleSave()
            })
            .catch(function (error) {
                // handle error
                setError({ showMessage: true, errorData: error })
                console.log(error);
            });

    }


    return (
        <Modal size="ms" show onHide={handleCancel}>
            <Modal.Header closeButton>{t("Access level")}</Modal.Header>
            <Modal.Body className="m-3">
                <Row>
                    <Col>
                        <ErrorHandler error={error} />
                    </Col>
                </Row>
                <Row>
                    <Form onSubmit={submit}>
                        <Row>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("Title")}</Form.Label>
                                    <Form.Control required onChange={(e) => setTitle(e.target.value)} defaultValue={file?.title} placeholder={t("Title")} />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("Access level")}</Form.Label>
                                    <Form.Select
                                        name="state"
                                        required
                                        onChange={e => setSelectedAccessLevel(e.target.value)}>
                                        <option value="">{t("-- Select --")}</option>
                                        {permissionLevels?.map((item) => {
                                            return (
                                                <option key={item} value={item} selected={file.access_level === item}>{t(item)}</option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center mt-4">
                                <Button variant="outline-secondary" className="me-2" onClick={handleCancel}>
                                    {t("Cancel")}
                                </Button>
                                <Button variant="primary" type="submit">
                                    {t("Apply")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default FileAccessLevelModal;