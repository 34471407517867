import { Badge, Card, Spinner, Table } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../Helper/LoadingSpinner";

const ContractTable = (data, title, isLoading) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function getStatusColor(status) {
    switch (status) {
      case "approved":
      case "active":
      case "completed":
        return "success"
      case "request":
      case "inactive":
        return "warning"
      case "draft":
        return "secondary"
      default:
        return t(status)
    }
  }

  const renderTable = () => {
    return data?.map((contract) => {
      return (
        <tr key={contract.id} onClick={() => navigate("/contracts/" + contract.id)} style={{ cursor: "pointer" }}>
          <td>
            <Badge bg="" className={`badge-soft-${getStatusColor(contract.status)} me-2`} >{t(contract.status)}</Badge>
          </td>
          {/* <td>{contract?.contract_number}</td> */}
          <td>{contract?.title}</td>
          <td>{contract?.client_name || "-"}</td>
          {/* <td>{contract.assets.length}</td> */}
          {/* <td>{GesamtInvestitionsVolumen.toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'currency',
            currency: 'EUR',
          })}</td>
          <td>
            <span className={NumOfUnpaidInvoices === 0 ? "badge bg-success" : "badge bg-warning"}>{NumOfUnpaidInvoices}</span>
          </td> */}
        </tr>
      );
    });
  };

  return (
    <Card className="flex-fill w-100">
      {
        title === null ?
          <div />
          :
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t(title)}
            </Card.Title>
          </Card.Header>
      }
      {/* <Card.Body> */}
      {
        isLoading ?
          <LoadingSpinner withStandardText /> :
          data != null && data.length > 0
            ?
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Status")}</th>
                  {/* <th scope="col">#</th> */}
                  <th scope="col">{t("Title")}</th>
                  <th scope="col">{t("Client")} / {t("Operator")}</th>
                  {/* <th scope="col">No. of Assets</th>
                  <th scope="col">Investment</th>
                  <th scope="col">Pending Invoices</th> */}
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
            :
            <Card.Body>
              <p>{t("No data found.")}</p>
            </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );
};

export default ContractTable;
