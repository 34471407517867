import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import avatar1 from "../../assets/img/avatars/avatar.png";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { format, formatDistanceStrict, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import UserDataManager from "../Helper/UserDataManager";
import { getIAMBaseURL } from "../../config";
import EditUserModal from "./UserModal_Edit";

const Profile = () => {

  const { t, i18n } = useTranslation();
  const [selectedFile, setSelectedFile] = useState();
  const hiddenFileInput = React.useRef(null);
  const [data, setData] = useState([]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleUploadAvatar = event => {
    uploadAvatar(event);
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  function loadAvatar() {
    axios
      .get(getIAMBaseURL() + "/users/me")
      .then(function (response) {
        // handle success
        console.log(response.status, "/users/me ------> 5");
        UserDataManager.getInstance().setUserData(response.data);
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const showAvatar = () => {
    return (
      <img
        src={data.avatar == null ? avatar1 : data.avatar.url}
        className="rounded-circle img-responsive mt-2"
        width="128"
        height="128"
        alt="avatar"
      />);
  };

  // const [ClientArray, setClientArray] = useState([]);

  useEffect(() => {
    loadAvatar()

  }, []);

  // const ShowClientsAvatars = () => {

  //   return ClientArray.map((client) => {

  //     if (client.avatar_url !== null) {
  //       return (
  //         <img
  //           src={client == null ? null : client.avatar_url}
  //           key={client.id}
  //           alt="avatar"
  //           className="me-3"
  //           width={40} height={40}
  //         />
  //       );
  //     }
  //     return (<></>)
  //   });
  // }

  // const ShowClientsList = () => {

  //   return ClientArray.map((client) => {
  //     return (
  //       <tr>
  //         <th>{t("Your company")}</th>
  //         <td>{client === undefined ? "" : client.business_name}</td>
  //       </tr>
  //     );
  //   });
  // }

  function uploadAvatar(e) {
    e.preventDefault();

    console.log("->", selectedFile);

    if (selectedFile === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Please select a new avatar first") })
    } else {
      var formData = new FormData();
      formData.append("file", selectedFile);

      axios
        .put(getIAMBaseURL() + "/users/me/avatar", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(function (response) {
          // handle success
          console.log("File uploaded");
          notyf.open({
            type: "success",
            message: t("Updated avatar successfully"),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })
          loadAvatar();
        })
        .catch(function (error) {
          // handle error
          // window.confirm(error)
          console.log(error);
          setError({ showMessage: true, errorData: error })
        });
    }
  }

  return (
    <React.Fragment>
      <Helmet title={t("Profile")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h1 className="h3 mb-3">{t("Profile")}</h1>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <EditUserModal id={data?.id} title={t("Edit")} pw_disabled />
          </Col>
        </Row>

        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            {/* <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {data.role === "regular" ? t("Your company") : t("Your clients")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <ShowClientsAvatars />
              </Card.Body>
            </Card> */}
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("About")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Table size="sm" className="my-2">
                  <tbody>
                    <tr>
                      <th>{t("Name")}</th>
                      <td>{data?.first_name || "-"} {data.last_name}</td>
                    </tr>
                    <tr>
                      <th>{t("Position")}</th>
                      <td>{data?.position || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("Email")}</th>
                      <td>{data?.email || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("User since")}</th>
                      <td>{data.created_at === undefined ? "-" : format(parseISO(data.created_at), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) + " (" + formatDistanceStrict(parseISO(data.created_at), new Date(), { unit: "day", locale: i18n.language === "de" ? de : null, addSuffix: false }) + ")"}</td>
                    </tr>
                    {UserDataManager.getInstance().isCapOn() && <tr>
                      <th>{t("Tenant ID")}</th>
                      <td>{data?.main_tenant_id || "-"}</td>
                    </tr>}
                    <tr>
                      <th>{t("SSO Provider")}</th>
                      <td>{data?.sso_provider || "-"}</td>
                    </tr>
                    <tr>
                      <th>{t("cap-on User Role")}</th>
                      <td>{data?.role?.toUpperCase() || "-"}</td>
                    </tr>
                    {/* {
                      data.role === "regular" ?
                        <ShowClientsList />
                        : <></>
                    } */}

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" className="d-flex">
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Avatar")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col>
                      <div className="text-center">
                        {showAvatar(data.avatar == null ? avatar1 : data.avatar.url)}
                        <div className="mt-5">
                          <Form.Group className="mb-3">
                            <Form.Control type="file" ref={hiddenFileInput} onChange={handleChange} />
                            <Form.Text className="text-muted">Max. 5 MB</Form.Text>
                          </Form.Group>
                          <Button
                            onClick={handleUploadAvatar}>
                            <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Profile;
