import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { Edit2 } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import { getIAMBaseURL } from "../../config";
import UserDataManager from "../Helper/UserDataManager";

const EditUserModal = (props) => {
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);
  const [data, setData] = useState([]);

  const [email, setEmail] = useState(data.email);
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState(data.first_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [position, setPosition] = useState(data.position);
  const [phoneNumber, setPhoneNumber] = useState(data.phone_number);
  const [status, setStatus] = useState(data.status);
  const [preferredLanguage, setPreferredLanguage] = useState(data.preferred_language);
  const [role, setRole] = useState(data.role);; // default value!
  const [gateway_id, setGatewayId] = useState(data.iot_gateway_id);

  const [UserRolesArray, setUserRolesArray] = useState([]);
  const [UserStatusArray, setUserStatusArray] = useState([]);
  const [GatewayArray, setGatewayArray] = useState([]);

  useEffect(() => {

    if (openModal) {
      axios
        .get(getIAMBaseURL() + "/users/" + props?.id)
        .then(function (response) {
          // handle success
          console.log(response.status, "/users");
          setData(response.data);
          setRole(response.data.role)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/iot_gateways/")
        .then(function (response) {
          // handle success
          console.log(response.status, "/gateways");
          setGatewayArray(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get(getIAMBaseURL() + "/openapi.json")
        .then(function (response) {
          // handle success
          console.log(response.status, "/openapi.json");
          setUserRolesArray(response.data["components"]["schemas"]["UserRole"]["enum"]);
          setUserStatusArray(response.data["components"]["schemas"]["UserStatus"]["enum"]);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, [openModal, props?.id]);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .put(getIAMBaseURL() + "/users/" + (props?.pw_disabled ? "me" : props?.id), {
        "email": email,
        "first_name": firstName,
        "last_name": lastName,
        "password": password,
        "status": status,
        "role": role,
        "iot_gateway_id": gateway_id === "" ? null : gateway_id,
        "phone_number": phoneNumber,
        "preferred_language": preferredLanguage,
        "position": position
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/users");

        notyf.open({
          type: "success",
          message: t("User \"") + data.first_name + " " + data.last_name + t("\" has been updated."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const loadRoles = () => {
    return UserRolesArray.map((role) => {
      return (
        <option value={role} selected={data.role === role}>{t(role).toUpperCase()}</option>
      );
    });
  };

  const loadUserStatus = () => {
    return UserStatusArray.map((status) => {
      return (
        <option value={status} selected={data.status === status}>{t(status)}</option>
      );
    });
  };

  const loadGateways = () => {
    return GatewayArray.map((gateway) => {
      return (
        <option key={gateway.id} value={gateway.id} selected={gateway.id === data.iot_gateway_id}>{gateway.serial}</option>
      );
    });
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={e => toggle()}
        className="shadow-sm me-1"
      >
        <Edit2 className="feather" /> {props?.title}
      </Button>
      <Modal size="lg" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Edit user")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            {!props?.pw_disabled && <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Status")}</Form.Label>
                  <Form.Select
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option />
                    {loadUserStatus()}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Language")}</Form.Label>
                  <Form.Select defaultValue={data.preferred_language} onChange={(e) => setPreferredLanguage(e.target.value)}>
                    <option />
                    <option key="en" value="en" selected={data.preferred_language === "en"}>{t("English")}</option>
                    <option key="us" value="us" selected={data.preferred_language === "us"}>{t("English (US)")}</option>
                    <option key="de" value="de" selected={data.preferred_language === "de"}>{t("German")}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>}
            <Row>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("First name")}</Form.Label>
                  <Form.Control defaultValue={data.first_name} onChange={(e) => setFirstName(e.target.value)} placeholder={t("First name")} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Last name")}</Form.Label>
                  <Form.Control defaultValue={data.last_name} onChange={(e) => setLastName(e.target.value)} placeholder={t("Last name")} />
                </Form.Group>
              </Col>
            </Row>
            {UserDataManager.getInstance().isAdmin() &&
              <Row>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      defaultValue={data.email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email" />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Password")}</Form.Label>
                    <Form.Control
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={props?.pw_disabled}
                      placeholder={t("Password")} />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Role")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setRole(e.target.value)}>
                      <option disabled value="">{t("-- Select --")}</option>
                      {loadRoles()}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>}
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Position")}</Form.Label>
                  <Form.Control defaultValue={data.position} onChange={(e) => setPosition(e.target.value)} placeholder="Position" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Phone Number")}</Form.Label>
                  <Form.Control defaultValue={data.phone_number} onChange={(e) => setPhoneNumber(e.target.value)} placeholder={t("Phone Number")} />
                </Form.Group>
              </Col>
            </Row>
            {
              role === "device" &&
              <Row>
                <Col md={12}>
                  <Form.Label>{t("cap-on IoT-Gateway")}</Form.Label>
                  <Form.Select name="state" defaultValue={data.iot_gateway_id} onChange={(e) => setGatewayId(e.target.value)}>
                    <option />
                    {loadGateways()}
                  </Form.Select>
                </Col>
              </Row>
            }
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Apply")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditUserModal;
