import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import axios from "axios";
import qs from "qs";
// import useAuth from "../../hooks/useAuth";
// import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserDataManager from "../../pages/Helper/UserDataManager";
import { getIAMBaseURL } from "../../config";

function refreshPage() {
  window.location.reload(false);
}

function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();

  const lastRoute = location.state?.from?.pathname;

  const { t } = useTranslation();

  function getErrorMessage(error) {
    switch (error.message) {
      case "Request failed with status code 400":
        return t("Incorrect credentials. Please try again.")
      case "Network Error":
        return t("We are currently working on the system. Please try again in a few minutes.")
      default:
        return error.message || "Something went wrong"
    }
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t("Must be a valid email"))
          .max(255)
          .required(t("Email is required")),
        password: Yup.string().max(255).required(t("Password is required")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        axios({
          method: "post",
          url: getIAMBaseURL() + "/login/token",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
          },
          params: {
            "add_user_info": true
          },
          data: qs.stringify({
            grant_type: "",
            username: values.email,
            password: values.password,
            scope: "",
            client_id: "",
            client_secret: "",
          }),
        })
          .then(function (response) {
            // handle success
            console.log(response.status, "Logged in successfully");

            const token = response.data.access_token
            localStorage.setItem('token', token)

            // If there is only one tenant available, automatically set this one.
            if (response.data.tenants?.length === 1) {
              localStorage.setItem("tenant_id", response.data?.tenants[0]?.id);
            }

            UserDataManager.getInstance().setUserData(response.data.user_info);              

            if (response.data.user_info.status === "initial") {
              navigate("/confirm-sign-in", { state: { userData: response.data.user_info } });
              refreshPage();
              console.log("-> Info for TS (1)", lastRoute)
            } else {
              if (lastRoute && lastRoute !== "/sign-in" && lastRoute !== "/confirm-sign-in") {
                console.log("--> Info for TS (2)", lastRoute)
                navigate(lastRoute, { state: { initialLoad: true } });
                refreshPage();
              } else {
                console.log("--> Info for TS (3)", lastRoute)
                navigate("/dashboard")
                refreshPage();
              }
            }
          })
          .catch(function (error) {
            // handle error
            const message = getErrorMessage(error) || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
            console.log("->", error);
          })
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>


          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              placeholder={t("Email")}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("Password")}</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder={t("Password")}
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}

            <br />
            <div className="text-center">
              <small >
                <a href="#" onClick={() => navigate("/forgot-password")}>{t("Forgot Password?")}</a>
                &nbsp;
                &nbsp;
                <a href="mailto:theo.schuetz@cap-on.de?subject=cap-on%20Support&body=Dear%20cap-on%20Team"> {t("Support")} </a>
                {/* <Link to="mailto:theo.schuetz@cap-on.de?subject=cap-on%20Support&body=Dear%20cap-on%20Team"></Link> */}
              </small>
            </div>
          </Form.Group>

          <div className="text-center mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {t("Sign in")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
