import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const OEEChart = (props) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  const data = [

    {
      name: t("Availability (%)"),
      type: "area",
      data: props.OEE_Array.map((e) => { return e.availability }),
    },
    {
      name: t("Quality (%)"),
      type: "area",
      data: props.OEE_Array.map((e) => { return e.quality }),
    },
    {
      name: t("Performance (%)"),
      type: "area",
      data: props.OEE_Array.map((e) => { return e.performance }),
    },
    {
      name: t("OEE (%)"),
      type: "area",
      data: props.OEE_Array.map((e) => { return e.oee }),
    },
  ];

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: [2, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "100%",
      },
    },
    fill: {
      // opacity: [0.85, 0.25],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: props.OEE_Array.map((e) => { return format(parseISO(e.interval_start), "E, d MMM yyyy", { locale: i18n.language === "de" ? de : null }) }),
    markers: {
      size: 0,
    },
    xaxis: {
      // type: "time",
      // max: 100,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 1,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
        formatter: (value) => { return value.toLocaleString(i18n.language === "de" ? de : "en", { style: "percent", maximumFractionDigits: 0, }) },
      },
    },
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
        useSeriesColors: false
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          return y?.toLocaleString(i18n.language === "de" ? de : "en", { style: "percent", maximumFractionDigits: 2 });
        },
      },
    },
    colors: [
      palette.info,
      palette.success,
      palette.warning,
      palette.primary,
      palette.danger,
    ],
  };

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  return (

    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5">{t("Overall Equipment Effectiveness (OEE) | History")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {
          props.isLoading === true ?

            showLoadingSpinner() :

            props.OEE_Array.length === 0

              ?

              <h6 className="card-subtitle text-muted">
                {t("No OEE data available.")}
              </h6>

              :

              <div className="chart">
                <Chart options={options} series={data} type="line" height="340" />
              </div>
        }

        {/* <Line data={data} options={options} /> */}
      </Card.Body>
    </Card>
  );
};

export default OEEChart;
