import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Plus, RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import InvestorTable from "./InvestorTable";
import LoadingSpinner from "../Helper/LoadingSpinner";
import SingleInvestorModal from "./SingleInvestorModal";

const Investors = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    loadData();

  }, []);

  function loadData() {

    setError({ showMessage: false, errorData: null })

    axios
      .get("/investors/", {
        // params: {
        //   "status": "approved_only",
        // }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/investors");
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
  }

  const InvestorTile = ({ investor }) => {
    return (
      <Col xxs={12} xs={6} md={6} xl={4} xxl={3} className="d-flex">
        <Card
          className="shadow-none border w-100 d-flex flex-column"
          onClick={() => navigate("/investors/" + investor.id)}
          style={{ cursor: "pointer" }}
        >
          <Card.Body className="p-3 d-flex flex-column">
            {loadAvatar(investor?.avatar_url)}
            <div className="flex-grow-1 d-flex flex-column">
              <h5 className="mb-1 mt-3 overflow-hidden">
                <span className="text-title">{investor?.business_name || investor?.name}</span>
              </h5>
              {/* <div className="d-flex justify-content-between mt-auto">
                  <div className="overflow-hidden me-auto">
                    <p className="text-muted mb-0">{client?.asset_count} {t("Assets")}</p>
                  </div>
                  <div className="align-self-end ms-2">
                    <p className="text-muted mb-0 text-truncate">{client?.contract_count} {t("Contracts")}</p>
                  </div>
                </div> */}
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const loadAvatar = (url) => {
    return (
      <img
        src={url == null ? null : url}
        className="center"
        alt="avatar"
        width={80} height={80}
      />);
  };


  const [showSingleInvestor, setShowSingleInvestor] = useState();

  return (
    <React.Fragment>
      <Helmet title={t("Investors")} />
      <Container fluid className="p-0">

        {showSingleInvestor && <SingleInvestorModal
          handleCancel={() => setShowSingleInvestor(null)}
          handleSavedData={() => {
            setShowSingleInvestor(null)
            loadData()
          }}
        />}

        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Investors")}</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              onClick={() => navigate("/pricing-engine")}
              variant="primary"
              className="shadow-sm me-1"
            >{t("New Asset Request")}</Button> */}
            <Button
              onClick={() => setShowSingleInvestor(true)}
              variant="primary"
              className="shadow-sm me-1">
              <Plus className="feather" /> {t("New")}
            </Button>

            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
            <Card>
              {/* <Card.Header>
              <Card.Title tag="h5"></Card.Title>
              <h6 className="card-subtitle text-muted">
                Hier werden alle Hersteller und Lieferanten gelistet
              </h6>
            </Card.Header> */}
              {/* <Card.Body> */}
              {
                isLoading === true ?
                  <LoadingSpinner withStandardText /> :
                  (data.length > 0)
                    ? // If data found

                    <Card.Body>
                      <div className="container">
                        <div className="row">
                          {
                            data?.map((investor) => {
                              return <InvestorTile investor={investor} key={investor.id} />;
                            })
                          }
                        </div>
                      </div>
                    </Card.Body>

                    // <Table responsive hover>
                    //   <thead>
                    //     <tr>
                    //       {/* <th scope="col">#</th> */}
                    //       <th scope="col"></th>
                    //       <th scope="col">{t("Name")}</th>
                    //       {/* <th scope="col">{t("No. of Assets")}</th> */}
                    //     </tr>
                    //   </thead>
                    //   <tbody>{renderTable()}</tbody>
                    // </Table>

                    : // If no data found

                    <Card.Body>
                      <p className="card-subtitle text-muted">
                        {t("No data found.")}
                      </p>
                    </Card.Body>
              }
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Investors;
