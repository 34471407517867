import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Form } from "react-bootstrap";
import mainLogo from "../../assets/company/cap-on_logo_black.png";
import mainLogoLight from "../../assets/company/cap-on_logo.png";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ErrorHandler from "../Helper/ErrorHandler";
import axios from "axios";
import NotyfContext from "../../contexts/NotyfContext";
import { getIAMBaseURL } from "../../config";


const ResetPasswordPage = () => {
  let { code } = useParams();
  const { theme } = useTheme(); //onClick={setTheme(THEME.DEFAULT)}
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();

  var token = ""
  const [NewPassword1, setNewPassword1] = useState();
  const [NewPassword2, setNewPassword2] = useState();;

  const handleChangePassword = event => {
    changePasswordAfterEmail(event);
  };

  function changePasswordAfterEmail(e) {
    e.preventDefault();

    token = code

    if (NewPassword1 === "" | NewPassword1 === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Password cannot be empty") })
    } else {
      if (NewPassword1 !== NewPassword2) {
        setError({ showMessage: true, errorData: error, customMessage: t("The two passwords must be the same") })
      } else {
        axios
          .post(getIAMBaseURL() + "/reset_password/", {
            "token": token,
            "new_password": NewPassword1,
          })
          .then(function (response) {
            // handle success
            console.log(response.status, "Changed password");

            notyf.open({
              type: "success",
              message: t("Updated password successfully"),
              duration: "4500",
              ripple: false,
              dismissible: true,
              position: {
                x: "right",
                y: "bottom",
              },
            })

            navigate("/sign-in");
          })
          .catch(function (error) {
            console.log(error);
            setError({ showMessage: true, errorData: error })
          });
      }
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Reset Password" />
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center mt-4">
              <h1 className="h2">{t("New password")}</h1>
              <p className="lead">{t("Make it a secure one!")}</p>
              <div className="text-center">
                {theme === "dark"
                  ? <img src={mainLogoLight} padding-top="50" alt="cap-on" style={{ height: "160px", paddingTop: "10px", paddingBottom: "10px" }} />
                  : <img src={mainLogo} padding-top="50" alt="cap-on" style={{ height: "160px", paddingTop: "10px", paddingBottom: "10px" }} />
                }
              </div>
            </div>
            <Col>
              <ErrorHandler error={error} />
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Enter a new password")}</Form.Label>
                <Form.Control
                  type="password"
                  value={NewPassword1}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  placeholder={t("New Password")}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  value={NewPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  placeholder={t("Confirm New Password")} />
              </Form.Group>
            </Col>
            <br />
            <Col>
              <Button type="submit" onClick={(e) => handleChangePassword(e)} variant="primary"
              >
                {t("Save")}
              </Button>
            </Col>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
};

export default ResetPasswordPage;
