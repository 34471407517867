import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Spinner, Dropdown, Form, Tab, Nav } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { RefreshCw, MoreHorizontal } from "react-feather";
import { format, formatDistanceToNow, max, parseISO } from 'date-fns'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import usePalette from "../../hooks/usePalette";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { de } from "date-fns/locale"
import ErrorHandler from "../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GenericChart from "../Helper/GenericChart";

const Handshakes = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [handshakesData, setHandshakesData] = useState([]);
  const [handshakesDataForChart, setHandshakesDataForChart] = useState([]);
  const [handshakesChartDataX, setHandshakesChartDataX] = useState([]);
  const [handshakesChartDataY, setHandshakesChartDataY] = useState([]);
  const [isLoadingECG, setIsLoadingECG] = useState(true);
  let { id } = useParams();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [handshakesSinceDate, setHandshakesSinceDate] = useState(new Date() - 2 * 60 * 60 * 24 * 1000);

  useEffect(() => {

    loadHandshakes();

    setIsLoadingECG(true);

    axios
      .get("/iot_gateways/" + id + "/handshakes/clustered", {
        params: {
          "iot_gateway_id": id,
          "fill_gaps": true
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Loaded");
        setHandshakesChartDataX(response.data.map((entry) => { return format(parseISO(entry.interval_start), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }));
        setHandshakesChartDataY(response.data.map((entry) => { return entry.handshake_count }));
        setIsLoadingECG(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
        setIsLoadingECG(false);
      });
    }, []);

  function loadHandshakes() {

    axios
      .get("/iot_gateways/" + id + "/handshakes/", {
        params: {
          "iot_gateway_id": id,
          "period_start": handshakesSinceDate,
          "limit" : 1000
        }
      })
      .then(function (response) {
        // handle success
        // response.data.sort((a, b) => (a.time > b.time))
        setHandshakesDataForChart(response.data.sort((a, b) => (a.time > b.time)));
        setHandshakesData(response.data);
        console.log(response.status);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderHandshakesTable = () => {
    return handshakesData.map((handshake) => {
      return (
        <tr key={handshake.id}>
          <td>{format(parseISO(handshake.time), 'd MMM, HH:mm:ss', { locale: i18n.language === "de" ? de : null })}</td>
          <td>{formatDistanceToNow(parseISO(handshake.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td>{handshake.public_ip}</td>
          <td>{handshake.internet_speed_down == null ? "n/a" : handshake.internet_speed_down.toLocaleString(i18n.language === "de" ? de : "en", {
            style: "decimal",
            maximumFractionDigits: 1
          })} Mbit/s</td>
          <td>{handshake.internet_speed_up == null ? "n/a" : handshake.internet_speed_up.toLocaleString(i18n.language === "de" ? de : "en", {
            style: "decimal",
            maximumFractionDigits: 1
          })} Mbit/s</td>
          {/* <td>{handshake.lte_signal_strength}</td> */}
          <td>{(handshake.ram_usage / 100).toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'percent',
            maximumFractionDigits: 2
          })}</td>
          <td>{(handshake.cpu_usage / 100).toLocaleString(i18n.language === "de" ? de : "en", {
            style: 'percent',
            maximumFractionDigits: 2
          })}</td>
          <td>{handshake.cpu_temp?.toLocaleString(i18n.language === "de" ? de : "en", {
            style: "decimal",
            maximumFractionDigits: 1
          })} °C</td>
        </tr>
      );
    });
  };

  const HandshakeList = () => (
    <Card>
      <Card.Body>
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">{t("Timestamp")}</th>
              <th scope="col">{t("Last handshake")}</th>
              <th scope="col">Public IP</th>
              <th scope="col">Internet Speed (Down)</th>
              <th scope="col">Internet Speed (Up)</th>
              {/* <th scope="col">LTE-Strength</th> */}
              <th scope="col">RAM-Usage</th>
              <th scope="col">CPU Usage</th>
              <th scope="col">CPU Temperature</th>
            </tr>
          </thead>
          <tbody>{renderHandshakesTable()}</tbody>
        </Table>
      </Card.Body>
    </Card>
  );

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  const HandshakesChart = () => {
    const palette = usePalette();

    const data = [
      {
        name: "Handshake received",
        type: "area",
        data: handshakesChartDataY,
      },
    ];

    const options = {
      chart: {
        stacked: false,
      },
      stroke: {
        width: [2, 2],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
        },
      },
      fill: {
        opacity: [0.85, 0.25],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: handshakesChartDataX,
      markers: {
        size: 0,
      },
      xaxis: {
        // type: "time",
        // max: 100,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
        },
      },
      yaxis: {
        // title: {
        //   text: t("Handshakes"),
        // },
        min: 0,
        max: max(handshakesChartDataY) * 1.5,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => { return value },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: false
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return y;
          },
        },
      },
      colors: [
        palette.primary,
        palette.success,
        palette.warning,
        palette.info,
        palette.danger,
      ],
    };

    return (
      <Card>
        <Card.Header>
          <Col xs="auto" className="d-none d-sm-block">
            <Card.Title tag="h5">{t("Gateway Heartbeat")}</Card.Title>
          </Col>
        </Card.Header>
        <Card.Body>
          <div className="chart">
            {
              isLoadingECG === true ?
                showLoadingSpinner() :
                <Chart options={options} series={data} type="line" height="340" />
            }
          </div>
        </Card.Body>
      </Card>
    );
  };

  // const GenericChart = (x_data, y_data, y_maxValue, title, style, description) => {
  //   const palette = usePalette();

  //   const data = [
  //     {
  //       name: title,
  //       type: "area",
  //       data: y_data,
  //     },
  //   ];

  //   const options = {
  //     chart: {
  //       stacked: false,
  //     },
  //     stroke: {
  //       width: [2, 2],
  //       curve: "smooth",
  //     },
  //     plotOptions: {
  //       bar: {
  //         columnWidth: "100%",
  //       },
  //     },
  //     fill: {
  //       opacity: [0.85, 0.25],
  //       gradient: {
  //         inverseColors: false,
  //         shade: "light",
  //         type: "vertical",
  //         opacityFrom: 0.85,
  //         opacityTo: 0.55,
  //         stops: [0, 100, 100, 100],
  //       },
  //     },
  //     labels: x_data,
  //     markers: {
  //       size: 0,
  //     },
  //     xaxis: {
  //       // type: "time",
  //       // max: 100,
  //       labels: {
  //         show: true,
  //         align: 'right',
  //         minWidth: 0,
  //         maxWidth: 160,
  //         style: {
  //           colors: palette.black,
  //         },
  //       },
  //     },
  //     yaxis: {
  //       min: 0,
  //       max: y_maxValue,
  //       labels: {
  //         show: true,
  //         align: 'right',
  //         minWidth: 0,
  //         maxWidth: 160,
  //         style: {
  //           colors: palette.black,
  //         },
  //         formatter: (value) => { return value },
  //       },
  //     },
  //     legend: {
  //       position: 'top',
  //       labels: {
  //         colors: palette.black,
  //         useSeriesColors: false
  //       },
  //     },
  //     tooltip: {
  //       shared: true,
  //       intersect: false,
  //       y: {
  //         formatter: function (y) {
  //           return y.toLocaleString({ style: style, maximumFractionDigits: 2 });
  //         },
  //       },
  //     },
  //     colors: [
  //       palette.primary,
  //       palette.success,
  //       palette.warning,
  //       palette.info,
  //       palette.danger,
  //     ],
  //   };

  //   return (
  //     <div className="chart">
  //       {
  //         isLoading === true ?
  //           showLoadingSpinner() :
  //           <Chart options={options} series={data} type="line" height="340" />
  //       }
  //     </div>
  //   );
  // };

  return (
    <React.Fragment>
      <Helmet title="Handshakes" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
              <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
                <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
              </Button>
              <Breadcrumb.Item href="/gateways">{t("Gateways")}</Breadcrumb.Item>
              <Breadcrumb.Item href={"/gateways/" + id}>{t("Gateway details")}</Breadcrumb.Item>
              <Breadcrumb.Item active>{t("Handshakes")}</Breadcrumb.Item>
            {/* </div> */}
          </Breadcrumb>
          <Col xs="auto" className="d-none d-sm-block">
            <h3>{t("Handshakes")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary" className="shadow-sm me-1">
              <RefreshCw className="feather" />
            </Button>
            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Date")}</Form.Label>
                    <Form.Control type="date" onChange={(e) => setHandshakesSinceDate(parseISO(e.target.value))} />
                  </Form.Group>
                </Dropdown>
                <Dropdown.Divider />
                <Dropdown.Item onClick={loadHandshakes}>{t("Reload handshakes")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <HandshakesChart />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <div className={"tab Default"}>
              <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="1">{t("CPU Usage")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">{t("CPU Temperature")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">{t("RAM Usage")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">{t("Disk Usage")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5">{t("Internet speed (Download)")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="6">{t("Internet speed (Upload)")}</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <br />
                    {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
                      handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
                      handshakesDataForChart.map((entry) => { return entry.cpu_usage / 100 }),
                      1, // maxValue
                      "CPU Usage",
                      "percent",
                      "Percentage"
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <br />
                    {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
                      handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
                      handshakesDataForChart.map((entry) => { return entry.cpu_temp }),
                      70, // maxValue
                      "CPU Temperature (°C)",
                      "decimal",
                      "°C"
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <br />
                    {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
                      handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
                      handshakesDataForChart.map((entry) => { return entry.ram_usage / 100 }),
                      1, // maxValue
                      "RAM Usage",
                      "percent",
                      "Percentage"
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <br />
                    {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
                      handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
                      handshakesDataForChart.map((entry) => { return entry.disk_space / 100 }),
                      1, // maxValue
                      "Disk Space Usage",
                      "percent",
                      "Percentage"
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="5">
                    <br />
                    {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
                      handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
                      handshakesDataForChart.map((entry) => { return entry.internet_speed_down }),
                      Math.max(...handshakesDataForChart.map(entry => entry.internet_speed_down)) * 1.30,
                      "Internet Speed (Down)",
                      "decimal",
                      "Mbit/s"
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="6">
                    <br />
                    {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
                      handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
                      handshakesDataForChart.map((entry) => { return entry.internet_speed_up }),
                      Math.max(...handshakesDataForChart.map(entry => entry.internet_speed_up)) * 1.30,
                      "Internet Speed (Up)",
                      "decimal",
                      "Mbit/s"
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col xl="6">
            {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
              handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
              handshakesDataForChart.map((entry) => { return entry.ram_usage }),
              "RAM Usage",
              "percent",
              "Percentage"
            )}
          </Col>
          <Col xl="6">
          {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
              handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
              handshakesDataForChart.map((entry) => { return entry.cpu_temp }),
              "CPU Temperature",
              "decimal",
              "°C"
            )}
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
              handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
              handshakesDataForChart.map((entry) => { return entry.disk_space }),
              "Disk Space Usage",
              "percent",
              "Percentage"
            )}
          </Col>
          <Col xl="6">
            {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
              handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
              handshakesDataForChart.map((entry) => { return entry.cpu_temp }),
              "CPU Temperature",
              "decimal",
              "°C"
            )}
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
              handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
              handshakesDataForChart.map((entry) => { return entry.internet_speed_down }),
              "Internet Speed (Down)",
              "decimal",
              "Mbit/s"
            )}
          </Col>
          <Col xl="6">
            {handshakesDataForChart == null | handshakesDataForChart === undefined ? null : GenericChart(
              handshakesDataForChart.map((entry) => { return format(parseISO(entry.time), "dd MM, HH:mm", { locale: i18n.language === "de" ? de : null }) }),
              handshakesDataForChart.map((entry) => { return entry.internet_speed_up }),
              "Internet Speed (Up)",
              "decimal",
              "Mbit/s"
            )}
          </Col>
        </Row> */}
        <br />
        <Row>
          <Col xl="12">
            <HandshakeList />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Handshakes;
