import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getIAMBaseURL } from "../config";
import NotyfContext from "../contexts/NotyfContext";
import ErrorHandler from "./Helper/ErrorHandler";

const ChangePasswordModal = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const notyf = useContext(NotyfContext);

  const [OldPassword, setOldPassword] = useState();
  const [NewPassword1, setNewPassword1] = useState();
  const [NewPassword2, setNewPassword2] = useState();

  const handleChangePassword = event => {
    changePassword(event);
  };

  function changePassword(e) {
    e.preventDefault();

    if (NewPassword1 === "" | NewPassword1 === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Password cannot be empty") })
    } else {
      if (NewPassword1 !== NewPassword2) {
        setError({ showMessage: true, errorData: error, customMessage: t("The two passwords must be the same") })
      } else {
        axios
          .put(getIAMBaseURL() + "/users/me/password", {
            "old_password": OldPassword,
            "new_password": NewPassword1,
          })
          .then(function (response) {
            // handle success
            console.log("Changed password", response.status);

            notyf.open({
              type: "success",
              message: t("Updated password successfully"),
              duration: "4500",
              ripple: false,
              dismissible: true,
              position: {
                x: "right",
                y: "bottom",
              },
            })
            navigate("/sign-in");
            refreshPage();
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            setError({ showMessage: true, errorData: error })
          });
      }
    }
  }
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Change Password")}</Dropdown.Item>
      <Modal size="sm" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Change Password")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>

          <Form onSubmit={e => window.confirm(t("Are you sure you want to update your password? You will need to log in again.")) && handleChangePassword(e)}>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  value={OldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder={t("Current Password")} />
              </Form.Group>
            </Col>
            <br />
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{t("Enter a new password")}</Form.Label>
                <Form.Control
                  type="password"
                  value={NewPassword1}
                  onChange={(e) => setNewPassword1(e.target.value)}
                  placeholder={t("New Password")}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  value={NewPassword2}
                  onChange={(e) => setNewPassword2(e.target.value)}
                  placeholder={t("Confirm New Password")} />
              </Form.Group>
            </Col>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
