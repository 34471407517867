import { Accordion, Card, Col, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { HelpCircle, Info } from "react-feather";
import ScheduleEnhanceModal from "./ScheduleEnhanceModal";

const AssetInsights = (props) => {

  const { t, i18n } = useTranslation();

  const renderPercentageState = (value, reversed) => {

    if (isNaN(value) || value === Infinity) {
      return "badge bg-light"
    }
    switch (reversed) {
      case true:
        return value >= 0 ? "badge bg-warning" : "badge bg-success"
      default:
        return value >= 0 ? "badge bg-success" : "badge bg-warning"
    }
  }

  const changedTextState = (value, reversed) => {
    switch (reversed) {
      case true:
        return value >= 0 ? t("decreased by") : t("increased by")
      default:
        return value >= 0 ? t("increased by") : t("decreased by")
    }
  }

  const getPercentage = (value) => {

    var last_month = value?.last_month
    var month_before = value?.month_before

    return (last_month - month_before) / month_before
    // var last_monthToAllTimePercentChange = (last_month - all_time) / all_time
  }

  return (
    <Row>
      {/* <Col md="12" className="d-flex">
        <Card className="flex-fill w-100">
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              {t("Text")}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <tbody>
                {
                  Object.entries(props.insightsText).map(([title, value], i) => {
                    return (
                      <tr key={i}>
                        <th>{title}</th>
                        <td>{value}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col> */}

      <Row>
        <Col md="12" className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Asset Insights")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <p>IoT-Daten können verwendet werden, um die Leistung von Anlagen und Ausrüstungen zu überwachen, den Zustand der Anlagen zu bewerten, Wartungsbedarf zu prognostizieren und die Effizienz der Anlagen zu optimieren. IoT-Daten können ebenfalls dazu verwendet werden, um die Produktionsleistung von Anlagen und Ausrüstungen zu überwachen, die Ausfallrate zu reduzieren, die Produktionsgeschwindigkeit zu optimieren und die Produktqualität zu verbessern.</p>
              <ScheduleEnhanceModal />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("usage")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.asset_insights?.usage?.last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', })}</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.asset_insights?.usage))}>{getPercentage(props.insights?.asset_insights?.usage)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("cycle_time")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("cycle_time")}</Popover.Header>
                        <Popover.Body>
                          <strong>Gründe für eine Verringerung der Taktzeit:</strong><br /><br />
                          <strong>1. Maschinenstörungen oder Ausfälle</strong>: Ungeplante Maschinenausfälle können zu einer Verlangsamung des Takts führen.<br /><br />
                          <strong>2. Ineffiziente Prozesse</strong>: Prozesse, die nicht optimal funktionieren, können zu einer Verlangsamung der Taktzeit führen.<br /><br />
                          <strong>3. Qualitätsprobleme</strong>: Qualitätsprobleme, die zu Nacharbeiten oder Wiederholungen führen, können die Taktzeit beeinträchtigen.<br /><br />
                          <strong>4. Mitarbeiterfehler</strong>: Fehler durch menschliches Versagen können zu einer Verlangsamung der Taktzeit führen.<br /><br />
                          <strong>5. Überlastung der Maschinen</strong>: Eine Überlastung der Maschinen kann zu einer Verlangsamung der Taktzeit führen, da sie mehr Zeit benötigen, um Aufgaben auszuführen.<br /><br />
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.asset_insights?.cycle_time?.last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} s</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.asset_insights?.cycle_time), true)}>{getPercentage(props.insights?.asset_insights?.cycle_time)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("oee")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("Overall Equipment Effectiveness (OEE)")}</Popover.Header>
                        <Popover.Body>

                          <strong>Details:</strong><br /><br />
                          <strong>Maximale Ausbringung pro Stunde</strong>: {props?.assetData?.max_output_per_hour} {t("units")}/{t("hour")}<br />
                          <strong>Tägliche Produktionszeit</strong>: {props?.assetData?.daily_operating_hours} {t("hours")}<br />
                          <strong>Arbeitstage pro Woche</strong>: {props?.assetData?.working_days_per_week || "-"} {t("days")}<br />

                          <br /><br />

                          <strong>Gründe für eine Verringerung der OEE:</strong><br /><br />
                          <strong>1. Ausfallzeiten</strong>: Maschinenausfälle oder ungeplante Stopps können die OEE beeinträchtigen.<br /><br />
                          <strong>2. Wartungsprobleme</strong>: Unzureichende Wartung oder Instandhaltung kann zu Ausfällen und einer sinkenden OEE führen.<br /><br />
                          <strong>3. Qualitätsprobleme</strong>: Fehler in der Produktion können zu Abfällen und dadurch zu einer sinkenden OEE führen.<br /><br />
                          <strong>4. Effizienzprobleme</strong>: Prozesse, die nicht optimal funktionieren, können die Geschwindigkeit und Effizienz beeinträchtigen und somit die OEE reduzieren.<br /><br />
                          <strong>5. Materialprobleme</strong>: Engpässe bei Rohstoffen oder Lieferengpässe können zu ungeplanten Stopps und einer sinkenden OEE führen.<br /><br />
                          <br /><br />
                          Um eine höhere Taktzeit zu erreichen, ist es wichtig, die Ursachen für eine geringere Taktzeit zu identifizieren und zu beheben. Dies kann durch eine regelmäßige Überwachung und Optimierung der Prozesse, eine regelmäßige Wartung und Instandhaltung der Maschinen und eine Schulung und Förderung der Mitarbeiter erreicht werden.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.asset_insights?.oee?.last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', })}</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.asset_insights?.oee))}>{getPercentage(props.insights?.asset_insights?.oee)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("share_of_planned_usage")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.asset_insights?.share_of_planned_usage?.last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', })}</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.asset_insights?.share_of_planned_usage))}>{getPercentage(props.insights?.asset_insights?.share_of_planned_usage)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("weekend_units")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("weekend_units")}</Popover.Header>
                        <Popover.Body>
                          <strong>Betriebliche Konsequenzen von Wochenendarbeit in der Industrie können sein:</strong><br /><br />
                          <strong>1. Kosten</strong>: Überstunden und Zuschläge für Wochenendarbeit können die Kosten für den Betrieb erhöhen.<br /><br />
                          <strong>2. Mitarbeiterzufriedenheit</strong>: Wochenendarbeit kann zu einer Überlastung und Unzufriedenheit der Mitarbeiter führen, was zu einer höheren Fluktuation und einer geringeren Motivation führen kann.<br /><br />
                          <strong>3. Produktivität</strong>: Eine häufige Wochenendarbeit kann zu einer Erschöpfung der Mitarbeiter und zu einer Abnahme der Produktivität führen.<br /><br />
                          <strong>4. Fehlerhäufigkeit</strong>: Erschöpfte Mitarbeiter sind anfälliger für Fehler, was zu Qualitätsproblemen und Nacharbeiten führen kann.<br /><br />
                          <strong>5. Planbarkeit</strong>: Wochenendarbeit kann die Planbarkeit des Betriebs beeinträchtigen, da es schwieriger ist, eine kontinuierliche Produktion aufrechtzuerhalten, wenn Mitarbeiter häufiger Überstunden leisten müssen.<br /><br />
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.asset_insights?.weekend_units?.last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0 })}</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.asset_insights?.weekend_units), true)}>{getPercentage(props.insights?.asset_insights?.weekend_units)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("wear_and_tear")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("wear_and_tear")}</Popover.Header>
                        <Popover.Body>
                          <strong>Wenn eine Maschine mehr genutzt wird, als vom Hersteller empfohlen, kann dies zu einer Reihe von Problemen führen, einschließlich:</strong><br /><br />
                          <strong>1. Überhitzung</strong>: Eine erhöhte Nutzung kann dazu führen, dass die Maschine überhitzt, was zu Schäden an elektrischen und elektronischen Komponenten führen kann.<br /><br />
                          <strong>2. Abnutzung</strong>: Eine erhöhte Nutzung kann dazu führen, dass Teile der Maschine schneller abgenutzt werden und somit häufiger ersetzt werden müssen.<br /><br />
                          <strong>3. Fehlerhäufigkeit</strong>: Eine erhöhte Nutzung kann die Wahrscheinlichkeit von Fehlern und Störungen erhöhen, was zu ungeplanten Ausfällen und Verzögerungen führen kann.<br /><br />
                          <strong>4. Unfälle</strong>: Eine erhöhte Nutzung kann auch das Risiko für Unfälle erhöhen, insbesondere wenn sicherheitsrelevante Funktionen beeinträchtigt werden.<br /><br />
                          <br /><br />
                          Um diese Probleme zu vermeiden, ist es wichtig, die Empfehlungen des Herstellers bezüglich der Nutzung der Maschine zu befolgen und gegebenenfalls regelmäßige Wartungs- und Instandhaltungsarbeiten durchzuführen, um sicherzustellen, dass die Maschine in einwandfreiem Zustand bleibt.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{t(props.insights?.asset_insights?.wear_and_tear?.last_month)}</span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Details")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <Accordion.Item eventKey="0" className="bg-white">
                  <Accordion.Header>{t("Asset Insights")}</Accordion.Header>
                  <Accordion.Body className="bg-white">
                    <Table responsive>

                      <thead>
                        <tr>
                          <th scope="col">{t("")}</th>
                          <th scope="col">{t("Insight")}</th>
                          <th scope="col">{t("")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props.insights?.asset_insights === undefined ? <></> :
                          Object.entries(props.insights?.asset_insights).map(([title, elementData], i) => {

                            var last_month = elementData?.last_month
                            var month_before = elementData?.month_before
                            // var all_time = elementData?.all_time

                            var last_monthToMonthBeforePercentChange = (last_month - month_before) / month_before
                            // var last_monthToAllTimePercentChange = (last_month - all_time) / all_time

                            if (isNaN(last_monthToMonthBeforePercentChange)) {
                              return (<></>)
                            }
                            return (
                              <tr key={i}>
                                <th>{t(title)}</th>
                                <td>{t(title)} {changedTextState(last_monthToMonthBeforePercentChange)} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}.</td>
                                <td className="table-action">
                                  <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                      <Popover>
                                        <Popover.Header as='h3'>{t("Details")}</Popover.Header>
                                        <Popover.Body>
                                          {elementData?.explanation}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <Info className="feather" />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                    <Col xs="auto" className="ms-auto text-end mt-n1">
                      {t("Updated")}: {props.insights?.created_at === undefined ? "-" : format(parseISO(props.insights?.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="bg-white">
                  <Accordion.Header>{t("Insights Data Overview")}</Accordion.Header>
                  <Accordion.Body>
                    <Table responsive>

                      <thead>
                        <tr>
                          <th scope="col">{t("")}</th>
                          <th scope="col">{t("Last 30 days")}</th>
                          <th scope="col">{t("Month before")}</th>
                          <th scope="col">{t("Since SOP with cap-on")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props.insights?.asset_insights === undefined ? <></> :
                          Object.entries(props.insights?.asset_insights).map(([title, elementData], i) => {

                            var last_month = elementData?.last_month
                            var month_before = elementData?.month_before
                            var all_time = elementData?.all_time

                            var last_monthToMonthBeforePercentChange = (last_month - month_before) / month_before
                            var last_monthToAllTimePercentChange = (last_month - all_time) / all_time

                            if (isNaN(last_monthToMonthBeforePercentChange)) {
                              return (<></>)
                            }
                            switch (title) {
                              case "oee":
                              case "share_of_planned_usage":
                              case "running_time_percent":
                                return (<tr key={i}>
                                  <th>{t(title)}</th>
                                  <td>{last_month?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })}</td>
                                  <td>{month_before?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                  <td>{all_time?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                </tr>)

                              case "usage":
                              case "weekend_units":
                                return (<tr key={i}>
                                  <th>{t(title)}</th>
                                  <td>{(last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} {t("Units")}</td>
                                  <td>{(month_before)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} {t("Units")} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                  <td>{(all_time)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} {t("Units")} <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                </tr>)

                              case "cycle_time":
                                return (
                                  <tr key={i}>
                                    <th>{t(title)}</th>
                                    <td>{(last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} s</td>
                                    <td>{(month_before)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} s <span className={renderPercentageState(last_monthToMonthBeforePercentChange, true)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                    <td>{(all_time)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} s <span className={renderPercentageState(last_monthToAllTimePercentChange, true)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                  </tr>
                                )
                              default:
                                return (
                                  <tr key={i}>
                                    <th>{t(title)}</th>
                                    <td>{(last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })}</td>
                                    <td>{(month_before)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })} <span className={renderPercentageState(last_monthToMonthBeforePercentChange, true)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                    <td>{(all_time)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })} <span className={renderPercentageState(last_monthToAllTimePercentChange, true)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                  </tr>
                                )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                    <Col xs="auto" className="ms-auto text-end mt-n1">
                      {t("Created")}: {props.insights?.created_at === undefined ? "-" : format(parseISO(props.insights?.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </Row >
  )
};

export default AssetInsights;