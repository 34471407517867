import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import gbFlag from "../../assets/img/flags/gb.png";
import deFlag from "../../assets/img/flags/de.png";
import usFlag from "../../assets/img/flags/us.png";
import { useEffect } from "react";
import UserDataManager from "../../pages/Helper/UserDataManager";
import { getIAMBaseURL } from "../../config";
import NotyfContext from "../../contexts/NotyfContext";

const NavbarLanguages = (userData) => {
  const { t, i18n } = useTranslation();
  const notyf = useContext(NotyfContext);

  const languageOptions = {
    en: {
      icon: gbFlag,
      name: t("English (UK)"),
    },
    us: {
      icon: usFlag,
      name: t("English (US)"),
    },
    de: {
      icon: deFlag,
      name: t("German"),
    },
  };

  var selectedLanguage = languageOptions[i18n.language];

  useEffect(() => {
    changeLanguage(userData);
  }, []);


  function changeLanguage(responseData) {
    i18n.changeLanguage(responseData.preferred_language);
  }

  function updateLanguage(language) {
    i18n.changeLanguage(language)

    axios
      .put(getIAMBaseURL() + "/users/me", {
        "preferred_language": language,
      })
      .then(function (response) {
        // handle success        
        console.log(response.status, "/me", response.data.preferred_language);
        UserDataManager.getInstance().setUserData(response.data);

        notyf.open({
          type: "success",
          message: t("Language updated"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
        // navigate("/dashboard")
      })
      .catch(function (error) {
        // handle error

        notyf.open({
          type: "warning",
          message: error.message,
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
        console.log(error);
      });
  }

  return (
    <Dropdown className="me-2 nav-item" align="end">
      <Dropdown.Toggle as="a" className="nav-link nav-flag">
        <img src={selectedLanguage.icon} alt={selectedLanguage.name} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {Object.keys(languageOptions).map((language) => (
          <Dropdown.Item
            key={language}
            onClick={() => updateLanguage(language)}
          >
            <img
              src={languageOptions[language].icon}
              alt="English"
              width="20"
              className="align-middle me-1"
            />
            <span className="align-middle">
              {languageOptions[language].name}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};


export default NavbarLanguages;
