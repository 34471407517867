import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, Button, Col, Dropdown, Form, InputGroup, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";

const SingleInvestorModal = ({ selected_investor_id, handleSavedData, handleCancel }) => {
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [data, setData] = useState([]);

  const [name, setName] = useState(data.name);
  const [registration_number, set_registration_number] = useState(data.registration_number);
  const [business_name, set_business_name] = useState(data.business_name);
  const [description, set_description] = useState(data.description);
  const [bank_name, set_bank_name] = useState(data.bank_name);
  const [swift_code, set_swift_code] = useState(data.swift_code);
  const [vat_id, set_vat_id] = useState(data.vat_id);
  const [contact_email, set_contact_email] = useState(data.contact_email);
  const [account_holder, set_account_holder] = useState(data.account_holder);
  const [tax_number, set_tax_number] = useState(data.tax_number);
  const [address, set_address] = useState(data.address);
  const [bank_reference, set_bank_reference] = useState(data.bank_reference);
  const [website, setWebsite] = useState(data.website);

  useEffect(() => {

    setError({ showMessage: false, errorData: null })

    if (selected_investor_id) {
      axios
        .get("/investors/" + selected_investor_id)
        .then(function (response) {
          // handle success
          console.log(response.status, "/investors");
          setData(response.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }

  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    let body = {
      "name": name,
      "tax_number": tax_number,
      "address": address,
      "bank_reference": bank_reference,
      "website": website,
      "registration_number": registration_number,
      "business_name": business_name,
      "description": description,
      "vat_id": vat_id,
      "bank_name": bank_name,
      "account_holder": account_holder,
      "swift_code": swift_code,
      "contact_email": contact_email
    }

    if (selected_investor_id) {
      axios
        .put("/investors/" + selected_investor_id, body)
        .then(function (response) {
          // handle success
          console.log(response.status, "/investors");

          notyf.open({
            type: "success",
            message: t("Item \"") + name + t("\" updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleSavedData();
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });

    } else {


      axios
        .post("/investors/", body)
        .then(function (response) {
          // handle success
          navigate("/investors/" + response.data.id);
          console.log(response.status, "/investors");

          notyf.open({
            type: "success",
            message: t("New item \"") + name + t("\" has been added."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
        });
    }
  }

  return (
    <Modal size="lg"
      show
      onHide={handleCancel}
    >
      <Modal.Header closeButton>{selected_investor_id ? t("Edit investor") : t("Create investor")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>{t("Name")}</Form.Label>
                <Form.Control required onChange={(e) => setName(e.target.value)} defaultValue={data.name} placeholder="Name" />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("Full Business Name")}</Form.Label>
                <Form.Control required onChange={(e) => set_business_name(e.target.value)} defaultValue={data.business_name} placeholder="Name AG / GmbH" />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Registration Number")}</Form.Label>
                    <Form.Control onChange={(e) => set_registration_number(e.target.value)} defaultValue={data.registration_number} placeholder="HRB 1234" />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Address")}</Form.Label>
                    <Form.Control onChange={(e) => set_address(e.target.value)} defaultValue={data.address} placeholder="Main Street 1, 1234 New York" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Tax Identification Number (TIN)")}</Form.Label>
                    <Form.Control onChange={(e) => set_tax_number(e.target.value)} defaultValue={data.tax_number} placeholder={t("123/456/789")} />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("VAT-ID")}</Form.Label>
                    <Form.Control onChange={(e) => set_vat_id(e.target.value)} defaultValue={data.vat_id} placeholder="DE12345678" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Website")}</Form.Label>
                    <Form.Control onChange={(e) => setWebsite(e.target.value)} defaultValue={data.website} placeholder="https://" />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Contact email")}</Form.Label>
                    <Form.Control onChange={(e) => set_contact_email(e.target.value)} defaultValue={data.contact_email} placeholder="Email" />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>{t("IBAN")} / {t("Bank Reference")}</Form.Label>
                <Form.Control onChange={(e) => set_bank_reference(e.target.value)} defaultValue={data.bank_reference} placeholder="DE02xx" />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>{t("BIC / Swift")}</Form.Label>
                <Form.Control onChange={(e) => set_swift_code(e.target.value)} defaultValue={data.swift_code} placeholder="Swift / BIC" />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>{t("Account Holder")}</Form.Label>
                <Form.Control onChange={(e) => set_account_holder(e.target.value)} defaultValue={data.account_holder} placeholder="Name" />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>{t("Bank Name")}</Form.Label>
                <Form.Control onChange={(e) => set_bank_name(e.target.value)} defaultValue={data.bank_name} placeholder="Deutsche Bank" />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button variant="outline-secondary" onClick={handleCancel}>
                {t("Cancel")}
              </Button>{" "}
              <Button variant={"primary"} type="submit">
                {selected_investor_id ? t("Apply") : t("Create")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body >
    </Modal >
  );
};

export default SingleInvestorModal;
