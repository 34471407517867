import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import NotyfContext from "../../contexts/NotyfContext";
import ErrorHandler from "../Helper/ErrorHandler";
import { getIAMBaseURL } from "../../config";

const SystemConfig = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const notyf = useContext(NotyfContext);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [ACCESS_TOKEN_EXPIRE_MINUTES, setACCESS_TOKEN_EXPIRE_MINUTES] = useState();
  const [ACCESS_TOKEN_DEVICE_EXPIRE_DAYS, setACCESS_TOKEN_DEVICE_EXPIRE_DAYS] = useState();
  // const [DEPRECATION_AFTER_DURATION, setDEPRECATION_AFTER_DURATION] = useState();
  const [EMAIL_RESET_TOKEN_EXPIRE_HOURS, setEMAIL_RESET_TOKEN_EXPIRE_HOURS] = useState();
  // const [HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES, setHANDSHAKE_MISSING_GAP_INTERVAL_MINUTES] = useState();
  const [MAX_REQUEST_SIZE, setMAX_REQUEST_SIZE] = useState();
  // const [MINUTES_AFTER_CONNECTION_LOST, setMINUTES_AFTER_CONNECTION_LOST] = useState();
  const [PASSWORD_MIN_CHARS, setPASSWORD_MIN_CHARS] = useState();
  // const [PRESIGNED_FILE_URL_VALID_SECONDS, setPRESIGNED_FILE_URL_VALID_SECONDS] = useState();
  // const [SERVICES_PER_YEAR, setSERVICES_PER_YEAR] = useState();
  // const [VAT_RATE, setVAT_RATE] = useState();
  // const [CELERY_DELETE_READ_ALERTS_AFTER_WEEKS, setCELERY_DELETE_READ_ALERTS_AFTER_WEEKS] = useState();
  // const [CELERY_DELETE_ALERTS_AFTER_WEEKS, setCELERY_DELETE_ALERTS_AFTER_WEEKS] = useState();
  // const [CELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE, setCELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE] = useState();
  // const [CELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL, setCELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL] = useState();
  const [DEFAULT_AVATAR_URL, setDEFAULT_AVATAR_URL] = useState();

  useEffect(() => {
    axios
      .get(getIAMBaseURL() + "/admin/config/")
      .then(function (response) {
        // handle success
        console.log(response.status, "config")
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    // axios
    //   .get("/openapi.json")
    //   .then(function (response) {
    //     // handle success
    //     setListData(response.data["components"]["schemas"]["DynamicConfig"]["properties"]);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //     return
    //   })
  }, []);

  function updateAndReload(reloadConfig) {
    axios
      .put(getIAMBaseURL() + "/admin/config/", {

        "ACCESS_TOKEN_EXPIRE_MINUTES": ACCESS_TOKEN_EXPIRE_MINUTES,
        "ACCESS_TOKEN_DEVICE_EXPIRE_DAYS": ACCESS_TOKEN_DEVICE_EXPIRE_DAYS,
        "PASSWORD_MIN_CHARS": PASSWORD_MIN_CHARS,
        "MAX_REQUEST_SIZE": MAX_REQUEST_SIZE,
        "DEFAULT_AVATAR_URL": DEFAULT_AVATAR_URL,
        "EMAIL_RESET_TOKEN_EXPIRE_HOURS": EMAIL_RESET_TOKEN_EXPIRE_HOURS

        // "ACCESS_TOKEN_EXPIRE_MINUTES": ACCESS_TOKEN_EXPIRE_MINUTES,
        // "ACCESS_TOKEN_DEVICE_EXPIRE_DAYS": ACCESS_TOKEN_DEVICE_EXPIRE_DAYS,
        // "PASSWORD_MIN_CHARS": PASSWORD_MIN_CHARS,
        // "HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES": HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES,
        // "PRESIGNED_FILE_URL_VALID_SECONDS": PRESIGNED_FILE_URL_VALID_SECONDS,
        // "MINUTES_AFTER_CONNECTION_LOST": MINUTES_AFTER_CONNECTION_LOST,
        // "VAT_RATE": VAT_RATE,
        // "DEPRECATION_AFTER_DURATION": DEPRECATION_AFTER_DURATION,
        // "SERVICES_PER_YEAR": SERVICES_PER_YEAR,
        // "MAX_REQUEST_SIZE": MAX_REQUEST_SIZE,
        // "DEFAULT_AVATAR_URL": DEFAULT_AVATAR_URL,
        // "EMAIL_RESET_TOKEN_EXPIRE_HOURS": EMAIL_RESET_TOKEN_EXPIRE_HOURS,
        // "CELERY_DELETE_ALERTS_AFTER_WEEKS": CELERY_DELETE_ALERTS_AFTER_WEEKS,
        // "CELERY_DELETE_READ_ALERTS_AFTER_WEEKS": CELERY_DELETE_READ_ALERTS_AFTER_WEEKS,
        // "CELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL": CELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL,
        // "CELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE": CELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE
        // "CELERY_DELETE_OLD_ALERTS_SCHEDULE": {
        //   "minute": 0,
        //   "hour": 3,
        //   "day_of_week": 1
        // },
        // "CELERY_UPDATE_CURRENT_ASSET_VALUES_SCHEDULE": {
        //   "minute": 0,
        //   "hour": 5
        // },
        // "CELERY_CREATE_MONTHLY_INVOICES_SCHEDULE": {
        //   "minute": 0,
        //   "hour": 9,
        //   "day_of_month": 15
        // },
        // "CELERY_CHECK_OVERDUE_INVOICES_SCHEDULE": {
        //   "minute": 0,
        //   "hour": 4
        // },
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Updated config");

        if (reloadConfig) {
          triggerReloadAdminConfig();
        } else {
          notyf.open({
            type: "success",
            message: t("Configuration updated. The config has not been reloaded, yet."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })
        }
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function sendAlert() {
    axios
      .post("/alerts/by_roles?send_ms_teams_alerts=true/", {
        "alert_in": {
          "message": "The configuration variables have been changed. Please verify that the system is still working",
          "source_type": "system",
          "is_read": false
        },
        "roles": [
          "admin"
        ]
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Sent alert");
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function triggerReloadAdminConfig() {
    axios
      .post("/admin/reload/")
      .then(function (response) {
        // handle success
        console.log(response.status, "Reloaded admin");

        sendAlert()
        notyf.open({
          type: "success",
          message: t("Configuration updated. This may cause a short delay for upcoming API requests"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
      })
      .catch(function (error) {
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <Helmet title="cap-on • System Configuration" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>System Configuration</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex">
            <Card className="flex-fill w-100">
              {/* <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Update backend-configuration")}
                </Card.Title>
              </Card.Header> */}
              <Card.Body>
                <Row>
                  <Col>
                    <ErrorHandler error={error} />
                  </Col>
                </Row>
                {/* <Row>
                  <Form>

                    {
                      Object.entries(listData).map(([title, elementData], i) => (
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label><strong>{title}</strong></Form.Label>
                            <InputGroup className="mb-3">
                              <InputGroup.Text>{elementData.type}</InputGroup.Text>
                              <Form.Control
                                type={elementData.type}
                                name={title}
                                defaultValue={data.ACCESS_TOKEN_EXPIRE_MINUTES}
                                // onChange={(e) => setACCESS_TOKEN_EXPIRE_MINUTES(e.target.value)}
                                placeholder={elementData.default} />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      ))
                    }
                  </Form>
                </Row> */}
                <Row>
                  <br />
                  <br />
                  <h4>Authentication Token</h4>
                  <br />
                  {/* onSubmit={updateConfig} */}
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("ACCESS_TOKEN_EXPIRE_MINUTES")}</strong>: Number of minutes after which an Access Token expires. Default 60.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Minutes")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={ACCESS_TOKEN_EXPIRE_MINUTES}
                              defaultValue={data.ACCESS_TOKEN_EXPIRE_MINUTES}
                              onChange={(e) => setACCESS_TOKEN_EXPIRE_MINUTES(e.target.value)}
                              placeholder="Recommended: 60" />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("ACCESS_TOKEN_DEVICE_EXPIRE_DAYS")}</strong>: Number of days after which a Device Access Token expires. Default 30.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Days")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={ACCESS_TOKEN_DEVICE_EXPIRE_DAYS}
                              defaultValue={data.ACCESS_TOKEN_DEVICE_EXPIRE_DAYS}
                              onChange={(e) => setACCESS_TOKEN_DEVICE_EXPIRE_DAYS(e.target.value)}
                              placeholder="Recommended: 30" />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* <Row>
                      <Col md={12}>
                        <br />
                        <h4>Celery Routines</h4>
                        <br />
                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("CELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE")}</strong>: Default: 1800 (30 Minutes)</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Seconds")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={CELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE}
                              defaultValue={data.CELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE}
                              onChange={(e) => setCELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE(e.target.value)}
                              placeholder="2" />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("CELERY_DELETE_READ_ALERTS_AFTER_WEEKS")}</strong>: Number of weeks after read alerts will be deleted. Default: 2.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Weeks")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={CELERY_DELETE_READ_ALERTS_AFTER_WEEKS}
                              defaultValue={data.CELERY_DELETE_READ_ALERTS_AFTER_WEEKS}
                              onChange={(e) => setCELERY_DELETE_READ_ALERTS_AFTER_WEEKS(e.target.value)}
                              placeholder="2" />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("CELERY_DELETE_ALERTS_AFTER_WEEKS")}</strong>: Number of weeks after alerts will be deleted. Default: 4.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Weeks")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={CELERY_DELETE_ALERTS_AFTER_WEEKS}
                              defaultValue={data.CELERY_DELETE_ALERTS_AFTER_WEEKS}
                              onChange={(e) => setCELERY_DELETE_ALERTS_AFTER_WEEKS(e.target.value)}
                              placeholder="2" />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("CELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL")}</strong>: Number of weeks after read alerts will be deleted. Default: 2.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Days")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={CELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL}
                              defaultValue={data.CELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL}
                              onChange={(e) => setCELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL(e.target.value)}
                              placeholder="2" />
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <br />
                        <br />
                        <h4>Miscellaneous</h4>
                        <br />

                        {/* <Form.Label><strong>{t("DEPRECATION_AFTER_DURATION")}</strong>: Percentage of of re-sale value that will be lost after the duration of an asset. Default 0.9. (means after the official duration it will have only 10 percent of its original re-sale value</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text>{t("in %")}</InputGroup.Text>
                          <Form.Control
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            name={DEPRECATION_AFTER_DURATION}
                            defaultValue={data.DEPRECATION_AFTER_DURATION}
                            onChange={(e) => setDEPRECATION_AFTER_DURATION(e.target.value)}
                            placeholder="100" />
                        </InputGroup> */}

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("EMAIL_RESET_TOKEN_EXPIRE_HOURS")}</strong>: Number of hours after which a Password Reset Token expires. Default 2.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Hours")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={EMAIL_RESET_TOKEN_EXPIRE_HOURS}
                              defaultValue={data.EMAIL_RESET_TOKEN_EXPIRE_HOURS}
                              onChange={(e) => setEMAIL_RESET_TOKEN_EXPIRE_HOURS(e.target.value)}
                              placeholder="100" />
                          </InputGroup>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                          <Form.Label><strong>{t("HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES")}</strong>: Number of mins after which an Access Token expires. Default 60.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Minutes")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES}
                              defaultValue={data.HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES}
                              onChange={(e) => setHANDSHAKE_MISSING_GAP_INTERVAL_MINUTES(e.target.value)}
                              placeholder="100" />
                          </InputGroup>
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("DEFAULT_AVATAR_URL")}</strong>: Default URL for client and user avatars. Default: "https://core-system.s3.eu-central-1.amazonaws.com/client_placeholder.png"</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("URL")}</InputGroup.Text>
                            <Form.Control
                              type="text"
                              name={DEFAULT_AVATAR_URL}
                              defaultValue={data.DEFAULT_AVATAR_URL}
                              onChange={(e) => setDEFAULT_AVATAR_URL(e.target.value)}
                              placeholder="Please provide a url" />
                          </InputGroup>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                          <Form.Label><strong>{t("MINUTES_AFTER_CONNECTION_LOST")}</strong>: Number of minutes without a handshake after which a gateway will be seen as disconnected. Default 30.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Minutes")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={MINUTES_AFTER_CONNECTION_LOST}
                              defaultValue={data.MINUTES_AFTER_CONNECTION_LOST}
                              onChange={(e) => setMINUTES_AFTER_CONNECTION_LOST(e.target.value)}
                              placeholder="40" />
                          </InputGroup>
                        </Form.Group> */}

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("MAX_REQUEST_SIZE")}</strong>: Maximum Size of a request in Bytes. Default: 5_000_000. (5 MegaBytes)</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Bytes")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={MAX_REQUEST_SIZE}
                              defaultValue={data.MAX_REQUEST_SIZE}
                              onChange={(e) => setMAX_REQUEST_SIZE(e.target.value)}
                              placeholder="40" />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("PASSWORD_MIN_CHARS")}</strong>: Number of minimum Chars for a Password. Default 8.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Number")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={PASSWORD_MIN_CHARS}
                              defaultValue={data.PASSWORD_MIN_CHARS}
                              onChange={(e) => setPASSWORD_MIN_CHARS(e.target.value)}
                              placeholder="10" />
                          </InputGroup>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                          <Form.Label><strong>{t("PRESIGNED_FILE_URL_VALID_SECONDS")}</strong>: Number of seconds an AWS S3 presigned URL will be valid. Default 600.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Seconds")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={PRESIGNED_FILE_URL_VALID_SECONDS}
                              defaultValue={data.PRESIGNED_FILE_URL_VALID_SECONDS}
                              onChange={(e) => setPRESIGNED_FILE_URL_VALID_SECONDS(e.target.value)}
                              placeholder="10" />
                          </InputGroup>
                        </Form.Group> */}

                        {/* <Form.Group className="mb-3">
                          <Form.Label><strong>{t("SERVICES_PER_YEAR")}</strong>: Number of services that an asset will have per year. Used to predict when the next service is happening. Default: 1.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Number")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={SERVICES_PER_YEAR}
                              defaultValue={data.SERVICES_PER_YEAR}
                              onChange={(e) => setSERVICES_PER_YEAR(e.target.value)}
                              placeholder="10" />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label><strong>{t("VAT_RATE")}</strong>: Percentage of Value-added-Tax (Mehrwertsteuer). Default 0.19.</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text>{t("Number")}</InputGroup.Text>
                            <Form.Control
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              name={VAT_RATE}
                              defaultValue={data.VAT_RATE}
                              onChange={(e) => setVAT_RATE(e.target.value)}
                              placeholder="0,10" />
                          </InputGroup>
                        </Form.Group> */}


                      </Col>
                    </Row>

                    <br />
                    <Row>
                      <Col>
                        <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                          {t("Cancel")}
                        </Button>
                        <Button variant="primary" className="shadow-sm" onClick={() => updateAndReload(false)}>
                          {t("Update")}
                        </Button>
                        {/* <Button onClick={() => window.confirm(t("Are you sure you wish to update the config file? This may break things!")) && updateAndReload(true)} variant="primary">
                          {t("Update and Reload")}
                        </Button> */}
                      </Col>
                    </Row>
                  </Form>
                </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container >
    </React.Fragment >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemConfig;
