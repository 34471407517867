import { Accordion, Card, Col, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ErrorHandler from "../Helper/ErrorHandler";
import PowerConsumptionChart from "../Assets/Charts/PowerConsumptionChart";
import climateGridLogo from "../../assets/company/climate_grid_gross.png";
import versoLogo from "../../assets/company/verso.svg";
import { HelpCircle, Info } from "react-feather";
import CompensationModal from "../Sustainability/CompensationModal";

const SustainabilityInsights = (props) => {

  let { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [powerData, setPowerData] = useState([]);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  useEffect(() => {

    setIsLoading(true)
    setError({ showMessage: false, errorData: null })

    axios
      .get("/webservice/assets/" + id + "/clustered_power_usage", {
        params: {
          "asset_id": id,
          "period_start": new Date(new Date() - 24 * 60 * 60 * 1000 * 14).toISOString(),
          "clustering_unit": "1 day"
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/clustered_power_usage");
        setPowerData(response.data.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)))
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
        setIsLoading(false);
      });

  }, []);

  const renderPercentageState = (value, reversed) => {

    if (isNaN(value) || value === Infinity) {
      return "badge bg-light"
    }
    switch (reversed) {
      case true:
        return value >= 0 ? "badge bg-warning" : "badge bg-success"
      default:
        return value >= 0 ? "badge bg-success" : "badge bg-warning"
    }
  }

  const changedTextState = (value, reversed) => {
    switch (reversed) {
      case true:
        return value >= 0 ? t("decreased by") : t("increased by")
      default:
        return value >= 0 ? t("increased by") : t("decreased by")
    }
  }

  return (
    <>
      <Row>
        <Col>
          <ErrorHandler error={error} />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Sustainability Insights")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="8">
                  <p className="mb-2">IoT-Daten können verwendet werden, um die Umweltauswirkungen von Anlagen und Ausrüstungen zu überwachen, die Energieeffizienz zu optimieren und die CO<sub>2</sub>-Emissionen zu reduzieren.</p>
                  <Card.Link onClick={() => navigate("/assets/" + id + "/pcf")}>{t("Calculate PCF")}</Card.Link>
                </Col>
                <Col md="4" xs="auto" className="ms-auto text-end mt-n1">

                  In Kooperation mit:
                  <img
                    src={climateGridLogo}
                    alt="climate_grid"
                    className="me-3"
                    width="auto"
                    height={50}
                  />
                  <img
                    src={versoLogo}
                    alt="verso"
                    className="me-3"
                    width="auto"
                    height={25}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("Stromverbrauch pro Einheit (ø)")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("Reduktion des Stromverbrauchs")}</Popover.Header>
                        <Popover.Body>
                          <strong>Drei einfache Möglichkeiten, um den Stromverbrauch von Fertigungsanlagen zu reduzieren sind:</strong><br /><br />
                          <strong>1. </strong>Energieeffiziente Ausrüstung und Maschinen einsetzen.<br /><br />
                          <strong>2. </strong>Überflüssige Beleuchtung und Anlagen im Leerlauf abschalten.<br /><br />
                          <strong>3. </strong>Prozessoptimierung und Abstimmung von Maschinen- und Anlagenbedarf.<br /><br />
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.esg_insights?.power_per_use?.all_time / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal',
                maximumFractionDigits: 2,
              })} kWh</span>
              <div className="mb-0">
                <span className="text-muted">{(props.insights?.esg_insights?.power_per_use?.all_time * props?.assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })} kg CO<sub>2</sub>e/Einheit in der Stromherstellung</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("Stromverbrauch (insgesamt)")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("Reduktion des Stromverbrauchs")}</Popover.Header>
                        <Popover.Body>
                          <strong>Drei einfache Möglichkeiten, um den Stromverbrauch von Fertigungsanlagen zu reduzieren sind:</strong><br /><br />
                          <strong>1. </strong>Energieeffiziente Ausrüstung und Maschinen einsetzen.<br /><br />
                          <strong>2. </strong>Überflüssige Beleuchtung und Anlagen im Leerlauf abschalten.<br /><br />
                          <strong>3. </strong>Prozessoptimierung und Abstimmung von Maschinen- und Anlagenbedarf.<br /><br />
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.esg_insights?.power_consumption?.all_time / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal',
                maximumFractionDigits: 0,
              })} kWh</span>
              <div className="mb-0">
                <span className="text-muted">{(props.insights?.esg_insights?.power_consumption?.all_time * props?.assetData?.co2_kg_per_wh / 1000)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'decimal',
                  maximumFractionDigits: 2,
                })} t CO<sub>2</sub>e in der Stromherstellung</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("Output Carbon Footprint")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("Output Carbon Footprint")}</Popover.Header>
                        <Popover.Body>
                          Der Output Carbon Footprint (OCF) ist ein von cap-on gemünzter Begriff, der den CO<sub>2</sub>-Fußabdruck eines Bauteils oder Produkts (PCF) nach einem bestimmten Prozessschritt in einer Produktionsanlage beschreibt.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h3 d-inline-block mt-1 mb-4"><span className="badge bg-warning">COMING SOON</span></span>
              <div className="mb-0">
                <span className="text-muted">Fußabdruck des Bauteils nach Fertigstellung</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("Stromkosten (insgesamt)")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("Stromkosten (insgesamt)")}</Popover.Header>
                        <Popover.Body>
                          Annahme: Stromkosten für Industrieunternehmen: 0,27 €/kWh
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.esg_insights?.power_consumption?.all_time / 1000 * 0.25)?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'currency',
                currency: "EUR",
                maximumFractionDigits: 0,
              })}</span>
              <div className="mb-0">
                <span className="text-muted">Geschätzt für Industrieunternehmen</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("Kosten für die Umwelt (Emissionen)")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("Kosten für die Umwelt")}</Popover.Header>
                        <Popover.Body>
                          <strong>Drei einfache Möglichkeiten, um den Stromverbrauch von Fertigungsanlagen zu reduzieren sind:</strong><br /><br />
                          <strong>1. </strong>Energieeffiziente Ausrüstung und Maschinen einsetzen.<br /><br />
                          <strong>2. </strong>Überflüssige Beleuchtung und Anlagen im Leerlauf abschalten.<br /><br />
                          <strong>3. </strong>Prozessoptimierung und Abstimmung von Maschinen- und Anlagenbedarf.<br /><br />
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.esg_insights?.power_consumption?.all_time / 1000 * props?.assetData?.co2_kg_per_wh)?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal',
                maximumFractionDigits: 0,
              })} t CO<sub>2</sub>e</span>
              <div className="mb-0">
                <CompensationModal buttonSize="small" offsettable_co2e={props.insights?.esg_insights?.power_consumption?.all_time / 1000 * props?.assetData?.co2_kg_per_wh} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <PowerConsumptionChart PowerData={powerData} isLoading={isLoading} />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Details")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <Accordion.Item eventKey="0" className="bg-white">
                  <Accordion.Header>{t("Sustainability Insights")}</Accordion.Header>
                  <Accordion.Body className="bg-white">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th scope="col">{t("")}</th>
                          <th scope="col">{t("Insight")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props.insights?.sustainability_insights === undefined ? <></> :
                          Object.entries(props.insights?.sustainability_insights).map(([title, elementData], i) => {

                            var last_month = elementData?.last_month
                            var month_before = elementData?.month_before
                            // var all_time = elementData?.all_time

                            var last_monthToMonthBeforePercentChange = (last_month - month_before) / month_before
                            // var last_monthToAllTimePercentChange = (last_month - all_time) / all_time

                            if (isNaN(last_monthToMonthBeforePercentChange)) {
                              return (<></>)
                            }
                            return (
                              <tr key={i}>
                                <th>{t(title)}</th>
                                <td>{t(title)} {changedTextState(last_monthToMonthBeforePercentChange)} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}.</td>
                                <td className="table-action">
                                  <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                      <Popover>
                                        <Popover.Header as='h3'>{t("Details")}</Popover.Header>
                                        <Popover.Body>
                                          {elementData?.explanation}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <Info className="feather" />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                    <Col xs="auto" className="ms-auto text-end mt-n1">
                      {t("Updated")}: {props.insights?.created_at === undefined ? "-" : format(parseISO(props.insights?.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="bg-white">
                  <Accordion.Header>{t("Insights Data Overview")}</Accordion.Header>
                  <Accordion.Body>
                    <Table responsive>

                      <thead>
                        <tr>
                          <th scope="col">{t("")}</th>
                          <th scope="col">{t("Last 30 days")}</th>
                          <th scope="col">{t("Month before")}</th>
                          <th scope="col">{t("Since SOP with cap-on")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props.insights?.sustainability_insights === undefined ? <></> :
                          Object.entries(props.insights?.sustainability_insights).map(([title, elementData], i) => {

                            var last_month = elementData?.last_month
                            var month_before = elementData?.month_before
                            var all_time = elementData?.all_time

                            var last_monthToMonthBeforePercentChange = (last_month - month_before) / month_before
                            var last_monthToAllTimePercentChange = (last_month - all_time) / all_time

                            if (isNaN(last_monthToMonthBeforePercentChange)) {
                              return (<></>)
                            }
                            switch (title) {
                              case "share_of_planned_revenue":
                                return (<tr key={i}>
                                  <th>{t(title)}</th>
                                  <td>{last_month?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })}</td>
                                  <td>{month_before?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                  <td>{all_time?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                </tr>)
                              case "revenue":
                                return (<tr key={i}>
                                  <th>{t(title)}</th>
                                  <td>{last_month?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, })}</td>
                                  <td>{month_before?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                  <td>{all_time?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                </tr>)
                              default:
                                return (
                                  <tr key={i}>
                                    <th>{t(title)}</th>
                                    <td>{(last_month / 60)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} min.</td>
                                    <td>{(month_before / 60)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} min. <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                    <td>{(all_time / 60)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} min. <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                  </tr>
                                )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                    <Col xs="auto" className="ms-auto text-end mt-n1">
                      {t("Created")}: {props.insights?.created_at === undefined ? "-" : format(parseISO(props.insights?.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
};

export default SustainabilityInsights;