import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function AssetCategories(props) {
  const [Data, setData] = useState([]);
  const { t } = useTranslation();
  
  useEffect(() => {

    axios
      .get("/assets/categories")
      .then(function (response) {
        // handle success
        console.log(response.status, "/assets/categories");
        setData(response.data.sort((a, b) => (a.name > b.name)));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    }, []);

  return Data.map((item) => {
    return (
      <option key={item.name} value={item.name}>{item.name} ({item.usage_years} {t("years")})</option>
    );
  });
};

export default AssetCategories;
