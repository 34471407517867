import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Dropdown,
} from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

const RemoveAvatarButton = (props) => {
  const { t } = useTranslation();
  let { id } = useParams();

  function refreshPage() {
    window.location.reload(false);
  }

  function handleDeleteAvatar(e) {
    e.preventDefault();
    axios
      .delete("/" + props?.type + "s/" + id + "/avatar")
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted avatar");
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  return (
    <Dropdown.Item onClick={e => window.confirm(t("Are you sure you wish to delete this avatar?")) && handleDeleteAvatar(e)}>{t("Remove avatar")}</Dropdown.Item>
  );
};

export default RemoveAvatarButton;
