import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import jsPDF from 'jspdf'; // For PDF generation
import { Parser } from 'json2csv'; // For CSV generation
import { de } from "date-fns/locale";
import NotyfContext from "../../../contexts/NotyfContext";
import axios from "axios";
import { parseISO } from "date-fns";
import TwinSnapshotModalSuccess from "./TwinSnapshotModalSuccess";

const TwinSnapshotModal = ({ show, asset_id, asset_logs_latest_data, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [title, setTitle] = useState("Snapshot");
  const notyf = useContext(NotyfContext);
  const [logoBase64, setLogoBase64] = useState(null); // State to store Base64 image

  useEffect(() => {
    // if (show) {
    //   console.log("---->", asset_logs_latest_data);
    // }

    // Load the image and convert it to Base64 when the modal is shown
    const loadLogo = () => {
      const imgPath = "/assets/MAAG/maag.png"; // Path to the logo

      fetch(imgPath)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setLogoBase64(reader.result); // Save Base64 data to state
          };
          reader.readAsDataURL(blob); // Convert the blob to Base64
        })
        .catch(err => console.error("Error loading logo:", err));
    };

    if (show && localStorage.getItem("tenant_id") === "tWMPWtl") {
      loadLogo(); // Call the function to load the logo
    }

  }, [show]);

  // Function to generate and download PDF
  const handleSavePDF = () => {

    if (title === "") {
      alert("Enter title first");
      return;
    }

    const doc = new jsPDF();

    // Get the current date and format it
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString(i18n.language === "de" ? "de-DE" : "en-US", {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });

    // Add Title (Bold)
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(18);
    doc.text(title || "Snapshot Data", 10, 20);

    // Add line below title and date
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.text(formattedDate, 10, 30); // Add the current date below the title

    doc.line(10, 35, 200, 35); // Horizontal line below date

    // Add logo to the top right corner if it's available
    if (logoBase64) {
      doc.addImage(logoBase64, 'PNG', 150, 10, 50, 0); // Auto height, fixed width 50px
    }

    // Define x and y positions for two columns
    let xLeft = 10;
    let xRight = 110; // Adjust this value based on the width of the page
    let y = 50;
    let columnToggle = true; // Toggle between left and right columns

    // Iterate over each log and display in two columns
    asset_logs_latest_data
      ?.filter(log => {
        const logDate = parseISO(log.time); // Parse the log's time as a date
        const threeDaysAgo = Date.now() - (3 * 24 * 60 * 60 * 1000); // Calculate the date 3 days ago
        return logDate >= threeDaysAgo; // Include only logs from the last 3 days
      })
      ?.sort((a, b) => (a?.technical_name || a?.display_name || "").localeCompare(b?.technical_name || b?.display_name || ""))
      ?.forEach((log, index) => {

        if (log.technical_name === null && log.display_name === null) {
          return
        }

        const x = columnToggle ? xLeft : xRight; // Alternate between left and right

        // Set font and add log details
        doc.setFontSize(8);
        doc.setFont('helvetica', 'bold');
        doc.text(`${log.technical_name || log.display_name || "N/A"}`, x, y);
        doc.text(`${log.value?.toLocaleString(i18n.language === "de" ? "de" : "en", { style: 'decimal', maximumFractionDigits: 1 })} ${log.unit === null || log.unit === "state" ? "" : `(${log.unit})`}`, x, y + 5);


        const formattedDate = parseISO(log.time).toLocaleString(i18n.language === "de" ? "de-DE" : "en-US", {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        });

        // Reset font to normal for the other fields
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(4);
        doc.text(`Recorded: ${formattedDate || "N/A"}`, x, y + 10);
        doc.text(`OPC UA Node-ID: ${log.plc_node_id || "N/A"}`, x, y + 12);

        // Switch to the next column after every log entry
        columnToggle = !columnToggle;

        // Adjust y position when a row (two columns) is completed
        if (columnToggle) {
          y += 25; // Add some space between rows
        }

        // Handle page breaks
        if (y > 280) {
          doc.addPage();
          y = 20; // Reset y position for new page
          columnToggle = true; // Start on the left column after a new page
        }
      });

    const filenameDate = currentDate.toISOString().replace(/[^0-9]/g, '').slice(2, 8) + '-' +
      currentDate.toISOString().slice(11, 19).replace(/:/g, ':');
    const pdfTitle = `${filenameDate} ${title || "snapshot"}`;
    // doc.save(`${pdfTitle}.pdf`);
    const pdfBlob = doc.output('blob');
    // const pdfUrl = URL.createObjectURL(pdfBlob);
    // window.open(pdfUrl, '_blank');

    // Upload the PDF file
    const pdfFile = new File([pdfBlob], `${pdfTitle || "snapshot"}.pdf`, { type: 'application/pdf' });
    uploadFile(pdfFile, `${pdfTitle || "snapshot"}`)

  };

  const handleSaveCSV = () => {
    const fields = ['Title', 'Value', 'Type', 'Unit', "OPC_UA_Node_Id", 'Date', 'Time', 'UTC_Timestamp']; // Add Date and Time fields
    const json2csvParser = new Parser({ fields, delimiter: ';' });

    const csvData = asset_logs_latest_data
      ?.filter(log => {
        const logDate = parseISO(log.time); // Parse the log's time as a date
        const threeDaysAgo = Date.now() - (3 * 24 * 60 * 60 * 1000); // Calculate the date 3 days ago
        return logDate >= threeDaysAgo; // Include only logs from the last 3 days
      })
      ?.filter(log => log.technical_name !== null || log.display_name !== null)
      ?.sort((a, b) => (a?.technical_name || a?.display_name || "").localeCompare(b?.technical_name || b?.display_name || ""))
      ?.map(log => {
        const logDate = new Date(log.time);

        // console.log("----> ", log)
        return {
          Title: log.technical_name || log.display_name || "N/A",
          Value: log.value?.toLocaleString(i18n.language === "de" ? "de-DE" : "en-US", { minimumFractionDigits: 1, maximumFractionDigits: 3 }) || "N/A",
          Type: t(log?.param_type) || t("actual"),
          Unit: log.unit === "state" ? t("custom") : (t(log.unit) || "N/A"),
          OPC_UA_Node_Id: log?.plc_node_id || "N/A",
          Date: logDate.toLocaleDateString(i18n.language === "de" ? "de-DE" : "en-US"),  // Extract the date
          Time: logDate.toLocaleTimeString(i18n.language === "de" ? "de-DE" : "en-US", { hour12: false }),  // Extract the time in 24-hour format
          UTC_Timestamp: logDate.toISOString()  // Ensure UTC date and time in one column
        };
      });

    let csv = json2csvParser.parse(csvData);

    // Add BOM for Excel compatibility with special characters
    csv = "\uFEFF" + csv;

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const currentDate = new Date();
    const filenameDate = currentDate.toISOString().replace(/[^0-9]/g, '').slice(2, 8) + '-' +
      currentDate.toISOString().slice(11, 19).replace(/:/g, ':');
    const csvTitle = `${filenameDate} ${title || "snapshot"}`;

    const csvFile = new File([blob], `${csvTitle || "snapshot"}.csv`, { type: 'text/csv' });
    uploadFile(csvFile, `${csvTitle || "snapshot"}`);
  };

  function uploadFile(file, title) {

    var formData = new FormData();
    formData.append("file", file);

    axios
      .post("/assets/" + asset_id + "/files", formData, {
        headers: {
          "Content-Type": 'multipart/form-data'
        }
      })
      .then(function (response) {
        // handle success
        // notyf.open({
        //   type: "success",
        //   message: `${title} ${t("uploaded")}`,
        //   duration: "4500",
        //   ripple: false,
        //   dismissible: true,
        //   position: {
        //     x: "right",
        //     y: "bottom",
        //   },
        // })

        handleClose()

        setShowSnapshotSuccess(true)

      })
      .catch(function (error) {
        // handle error
        // window.confirm(error)
        console.log(error);
        setError({ showMessage: true, errorData: error })
      });
  }

  const [showSnapshotSuccess, setShowSnapshotSuccess] = useState(false)

  return (
    <>
      <TwinSnapshotModalSuccess show={showSnapshotSuccess} handleClose={() => {
        setShowSnapshotSuccess(false)
        window.location.reload(false)
      }} />
      <Modal size="ms" show={show} onHide={handleClose} style={{ zIndex: 1050 }}>
        <Modal.Header closeButton>{t("Snapshot")}</Modal.Header>


        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Row>
            <Form>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("File title")}</Form.Label>
                    <Form.Control
                      onChange={e => setTitle(e.target.value)}
                      value={title}
                      required
                      placeholder={t("Title")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="text-center mt-4">
                  <Button variant="outline-secondary" className="me-2" onClick={handleClose}>
                    {t("Cancel")}
                  </Button>
                  <Button variant="primary" className="me-2" onClick={handleSavePDF}>
                    {t("Save PDF")}
                  </Button>
                  <Button variant="primary" onClick={handleSaveCSV}>
                    {t("Save CSV")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Row>
        </Modal.Body>
      </Modal>
    </>

  );
};

export default TwinSnapshotModal;