import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { endOfDay, format, parseISO, startOfDay } from "date-fns";
import { de } from "date-fns/locale";
import { zonedTimeToUtc } from "date-fns-tz";
import usePalette from "../../../hooks/usePalette";
import GenericChart from "../Charts/GenericChartComponent";

const LiveProductionChartModal = ({ asset_id, data, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [title, setTitle] = useState("");
  // const [chartType, setChartType] = useState();

  const [LiveData, setLiveData] = useState([]);
  const Live_Data_INTERVAL = 2000;
  // const [seconds, setSeconds] = useState(0);

  useEffect(() => {

    setTitle("Live View")
    // setChartType(data?.chartType)

    let timer;

    if (show) {

      reloadLiveUsageData()

      timer = setInterval(() => {
        // setSeconds((prevSeconds) => prevSeconds + 1);
        reloadLiveUsageData()
      }, Live_Data_INTERVAL);

    } else {
      // setSeconds(0);
    }

    return () => clearInterval(timer) && console.log("Stopped timer");;

  }, [show]);

  async function reloadLiveUsageData() {
    setError({ showMessage: false, errorData: null });

    try {
      const response = await axios.get("/webservice/assets/" + asset_id + "/live");

      console.log(response.status, "Loaded live");

      // setUsageCounter(response.data.last_usage_counter);
      // setInfoLightsData(response.data.info_lights.sort((a, b) => (a.time < b.time)));
      // console.log("-->",response.data.info_lights);
      // setGatewayData(response.data.iot_gateway);
      // setPowerUsage(response.data?.power_usage_current_hour);
      // setCurrentPower(response.data?.current_power);
      // setPowerPerUseData(response.data?.power_per_use_current_hour)

      if (response.data.live_usage === null) {
        setLiveData([]);
      } else {
        setLiveData(response.data.live_usage.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)));
      }
    } catch (error) {
      setError({ showMessage: true, errorData: error });
      console.log(error);
    }
  }

  const UsageBarChart = (x_data, y_data, unit, style, description) => {
    const palette = usePalette();
    const { t } = useTranslation();

    const data = {
      labels: x_data,
      datasets: [
        {
          label: t(unit),
          fill: true,
          backgroundColor: palette.primary,
          color: palette.primary,
          borderColor: palette.primary,
          borderDash: [4, 4],
          data: y_data,
        },
      ],
    };

    const options = {
      chart: {
        height: 350
      },
      animation: {
        duration: 0 // general animation time
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, });
            return label
          }
        }

      },
      hover: {
        intersect: true,
      },
      plugins: {
        filler: {
          propagate: false,
        },
      },
      scales: {
        xAxes: [
          {
            reverse: false,
            gridLines: {
              color: "rgba(0,0,0,0.05)",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: function (value, index, ticks) {
                if (Number.isInteger(value)) {
                  return value.toLocaleString();
                }
              },
              // min: Math.round(Math.min(...y_data?.map(o => o.amount)) * 0.9),
              max: 2//Math.max(x_data),
            },
            display: true,
            borderDash: [5, 5],
            gridLines: {
              color: "rgba(0,0,0,0)",
              fontColor: "#fff",
            },
          },
        ],
      },
    };

    return (
      <Card className="flex-fill">
        <Card.Header>
          <Card.Title tag="h5">{t(title)}</Card.Title>
        </Card.Header>
        <Card.Body>
          {
            y_data != null && y_data.length > 0
              ?
              <div className="chart">
                <GenericChart type={"bar"} data={data} options={options} />
              </div>
              :
              <Card.Body>
                <p>{t("No data found.")}</p>
              </Card.Body>
          }
        </Card.Body>
      </Card>
    );
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{t("Real-time Production")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="12" className="d-flex">
            {UsageBarChart(
              LiveData?.map((entry) => { return format(parseISO(entry.interval_start), "HH:mm:ss", { locale: i18n.language === "de" ? de : null }) }),
              LiveData?.map((entry) => { return entry.amount }),
              t(data?.unit))}
          </Col>
        </Row>
        <br />
        <br />
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default LiveProductionChartModal;
