import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ArrowUpRight, Check, RefreshCw, Trash } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import UserDataManager from "../Helper/UserDataManager";
import Select from "react-select";
import LoadingSpinner from "../Helper/LoadingSpinner";
import InvoiceDetailsModal from "../Invoices/InvoiceDetailsModal";

const Alerts = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [filteredAlerts, setFilteredAlerts] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    loadAlerts();
  }, []);

  function loadAlerts() {
    axios
      .get("/alerts/", {
        params: {
          // user_id: UserDataManager.getInstance().getUserData().id,
          user_email: UserDataManager.getInstance().getUserData().email,
          limit: 50
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/alerts");
        setData(response.data);
        setFilteredAlerts(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
  }

  const [showInvoice, setShowInvoice] = useState();

  function getDetailURL(alert) {

    if (alert.is_read === false) {
      markAsRead(alert)
    }

    if (alert.source_type === "invoice") {
      setShowInvoice(alert.source_id)
      return
    }

    switch (alert.source_type) {
      case "iot_gateway":
        navigate("/application_logs/?application_name=iot_gateway")
        break;
      case "asset":
        navigate("/assets/" + alert.source_id)
        break;
      case "application_log":
        navigate("/application_logs/?level=CRITICAL")
        break;
      case "system":
        navigate("/configuration")
        break;
      default:
        console.log("No deeplink")
        break;
    }
  }

  function markAsRead(alert) {
    axios
      .put("/alerts/" + alert.id + "/read")
      .then(function (response) {
        // handle success
        console.log(response.status, "/alerts");
        loadAlerts()
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function markAllAsRead() {

    axios
      .put("/alerts/mark_all_read")
      .then(function (response) {
        // handle success
        console.log(response.status, "Marked all read");
        loadAlerts()
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  function handleDelete(e, alert_id) {
    e.preventDefault();

    axios
      .delete("/alerts/" + alert_id)
      .then(function (response) {
        console.log(response.status, "Deleted alert");
        // handle success
        loadAlerts();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderTable = () => {
    return filteredAlerts?.map((alert) => {
      return (
        <tr key={alert.id} style={{ cursor: "pointer" }} onClick={() => getDetailURL(alert)}>

          {
            data.filter(e => e.is_read === false).length > 0 ? <td>
              <span className={alert.is_read === false ? "badge bg-primary" : ""}>{t(alert.is_read === false ? t("Unread") : "")}</span>
            </td> : <></>
          }
          <td>{parseISO(alert.created_at) < (new Date() - 24 * 60 * 60 * 1000) ?
            format(parseISO(alert.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null }) :
            formatDistanceToNow(parseISO(alert.created_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })
          }</td>
          <td>{t(alert.message)}</td>

          <td className="table-action">

            <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
              e.stopPropagation();
              window.confirm(t("Are you sure you wish to delete this alert?")) && handleDelete(e, alert.id)
            }} />
          </td>
        </tr>
      );
    });
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  function updateList(options) {

    setFilteredAlerts(data);
    setSelectedOptions(options);

    if (options.some(obj => obj.value === "tech") && options.some(obj => obj.value === "operations")) {
    } else if (options.some(obj => obj.value === "tech")) {
      setFilteredAlerts(data?.filter(item => item?.source_type === "iot_gateway" || item?.source_type === "system"))
    } else if (options.some(obj => obj.value === "operations")) {
      setFilteredAlerts(data?.filter(item => item?.source_type === "asset" || item?.source_type === "invoice"))
    }
  }

  return (
    <React.Fragment>

      {showInvoice &&
        <InvoiceDetailsModal
          selected_invoice_id={showInvoice}
          handleCancel={() => setShowInvoice(null)}
          handleClose={() => {
            loadAlerts();
            setShowInvoice(null)
          }}
        />}

      <Helmet title={t("Alerts")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Alerts")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={markAllAsRead}
              variant="primary"
              className="shadow-sm me-1"
            >
              <Check className="feather" /> {t("Mark all as read")}
            </Button>
            <Button
              onClick={() => loadAlerts()}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="flex-fill w-100">
              <Card.Body>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Filter</Form.Label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        options={[
                          { value: "tech", label: "Technical" },
                          { value: "operations", label: "Operations" },
                        ]}
                        onChange={(e) => {
                          updateList(e);
                        }}
                        isMulti
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Search")}</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          updateList(selectedOptions)
                          setFilteredAlerts(data?.filter(item => item?.message.toLowerCase().includes(e.target.value.toLowerCase())))
                        }
                        }
                        placeholder={t("Search")} />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              {/* <Card.Body> */}
              {
                isLoading === true ?
                  <LoadingSpinner withStandardText /> :
                  (data.length > 0)
                    ? // If data found

                    <Table responsive hover>
                      <thead>
                        <tr>
                          {data.filter(e => e.is_read === false).length > 0 ? <th scope="col">{t("Status")}</th> : <></>}
                          <th scope="col">{t("Time")}</th>
                          {/* <th scope="col">{t("Source")}</th> */}
                          <th scope="col">{t("Message")}</th>
                        </tr>
                      </thead>
                      <tbody>{renderTable()}</tbody>
                    </Table>
                    :
                    <Card.Body>
                      <p>{t("No data found.")}</p>
                    </Card.Body>
              }
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Alerts;
