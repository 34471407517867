import {
  Card,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { de } from "date-fns/locale";

import usePalette from "../../../hooks/usePalette";

const FinancialsSection = ({ assetData, assetProduction }) => {

  const palette = usePalette();
  const { t, i18n } = useTranslation();

  // const [financialsData, setFinancialsData] = useState([])
  var items = ["investment_price", "starting_fee", "monthly_base_fee", "refi_rate"]

  useEffect(() => {

    // console.log("----->", assetData)

    // if (assetProduction === undefined) {

    // axios
    //   .get("/assets/" + assetData?.id + "/financials")
    //   .then(function (response) {
    //     // handle success
    //     console.log(response.status, "/financials");
    //     setFinancialsData(response.data)
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   });
    // } else {
    //   setProductionData(assetProduction)
    //   setAssetSOLLISTArray(assetProduction?.usage_section?.planned_actual_usages?.reverse() || []);
    // }

  }, []);


  return (<>

    <Row>
      <hr />
      <div className="h4 py-2">{t("Current asset value")}</div>

      <Row>
        <Col md={6} xs={12} className="d-flex">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("residual_book_value")} {t("depreciation_factor")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-2">
                {assetData?.financials?.residual_book_value !== undefined && (assetData?.financials?.residual_book_value)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'currency',
                  currency: "EUR",
                  maximumFractionDigits: 0,
                })}
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} xs={12} className="d-flex">
          <Card className="flex-fill">
            <Card.Body>
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("market_value")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-2">
                {assetData?.financials?.market_value !== undefined && (assetData?.financials?.market_value)?.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'currency',
                  currency: "EUR",
                  maximumFractionDigits: 0,
                })}
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Row>

    <Row>

      <hr />
      <div className="h4 py-2">{t("Asset-as-a-Service")} | {t("Contract financials")}</div>

      <Col md={4} xs={6} className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("Asset-as-a-Service")} | {t("Billing Logic")}</h5>
              </Col>
            </Row>
            <span className="h1 d-inline-block mt-1 mb-2">
              {t("Number of units")} {assetData?.usage_factor === 1 ? "" : `* ${assetData?.usage_factor?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal',
                maximumFractionDigits: 2,
              })}`} {assetData?.billing_unit_type || ""} * {assetData?.per_use_price?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'currency',
                currency: "EUR",
                maximumFractionDigits: 2,
              }) || "-"}
            </span>
          </Card.Body>
        </Card>
      </Col>

      {
        items?.map(item => {
          return <Col md={8 / items?.length} xs={6} className="d-flex">
            <Card className="flex-fill">
              <Card.Body>
                <Row>
                  <Col className="mb-2">
                    <h5 className="card-title">{t(item)}</h5>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-1 mb-2">
                  {assetData?.financials && assetData?.financials[item] !== undefined && (assetData?.financials[item])?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: "EUR",
                    maximumFractionDigits: 0,
                  })}
                </span>
              </Card.Body>
            </Card>
          </Col>
        })
      }

      <Col md={12} className="d-flex">
        <Card className="flex-fill">
          <Card.Body>
            <Row>
              <Col className="mb-2">
                <h5 className="card-title">{t("price_factors")}</h5>
              </Col>
            </Row>
            <Row className="mt-2">

              <Col>

                {
                  assetData?.financials?.price_factors?.map(({ price, starting_from }) => {
                    return <Row>
                      <Col md="6" className="text-end">
                        <span className="d-inline-block mt-1 mb-2">
                          {t("From")} {starting_from?.toLocaleString(i18n.language === "de" ? de : "en", {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                          })} {t(assetData?.billing_unit_type || t("units"))}
                        </span>
                      </Col>
                      <Col md="6">
                        <span className="d-inline-block mt-1 mb-2">
                          <strong>
                            {price?.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: 'currency',
                              currency: "EUR",
                              maximumFractionDigits: 2,
                            })}
                          </strong>
                        </span>
                      </Col>

                    </Row>
                  })
                }

              </Col>

            </Row>

          </Card.Body>
        </Card>
      </Col>

    </Row>
  </>)
}

export default FinancialsSection;