import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Breadcrumb, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../../contexts/NotyfContext";
import { getLogsBaseURL } from "../../config";

const GatewayConnect = () => {
  const navigate = useNavigate();
  const [gatewaySSHTunnelListData, setGatewaySSHTunnelListData] = useState([]);
  const { t, } = useTranslation();
  const notyf = useContext(NotyfContext);

  useEffect(() => {

    axios
      .get(getLogsBaseURL() + "/ports/?ipv4_only=true")
      .then(function (response) {
        // handle success
        console.log(response.status, "/ports");
        setGatewaySSHTunnelListData(response.data.sort((a, b) => { return a.port > b.port }));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    }, []);

  const renderSSHTable = () => {

    return gatewaySSHTunnelListData.map((gateway) => {

      return (
        <tr onClick={() => copyToClipboard(gateway)}>
          <td>{gateway.ip_type}</td>
          <td>{gateway.port}</td>
          {/* <td>{gateway.send_q}</td> */}
          <td>{gateway.process_info}</td>
        </tr>
      );
    });
  };

  function copyToClipboard(data) {

    switch (data.port) {
      case 2202:
        navigator.clipboard.writeText("ssh -p " + data.port + " root@localhost")

        notyf.open({
          type: "success",
          message: t("Termin-Command copied. Just connect to the tunnel using ssh and paste"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
        break;
      case 2222:
      case 2223:
        navigator.clipboard.writeText("ssh -p " + data.port + " cap-on_gateway@localhost")

        notyf.open({
          type: "success",
          message: t("Termin-Command copied. Just connect to the tunnel using ssh and paste"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })
        break;
      case 2224:
      case 2225:
      case 2226:
        navigator.clipboard.writeText("ssh -p " + data.port + " admin@localhost")

        notyf.open({
          type: "success",
          message: t("Termin-Command copied. Just connect to the tunnel using ssh and paste"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        break;

      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Helmet title="cap-on IoT-Gateways" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
            {/* <div className="vertical-center-all"> */}
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Button>
            <Breadcrumb.Item href="/gateways">{t("Gateways")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Gateway Connect")}</Breadcrumb.Item>
            {/* </div> */}
          </Breadcrumb>
          <Col xs="auto" className="d-none d-sm-block">
            <h3>Gateway Connect</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>

        </Row>
        <Row>
          <Card>
            {/* <Card.Header>
              <Card.Title tag="h5"></Card.Title>
              <h6 className="card-subtitle text-muted">
                Diese Liste zeigt alle von cap-on finanzierten Assets
              </h6>
            </Card.Header> */}
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("IP Type")}</th>
                  <th scope="col">{t("Port")}</th>
                  {/* <th scope="col">{t("send_q")}</th> */}
                  <th scope="col">{t("Process Info")}</th>
                </tr>
              </thead>
              <tbody>{renderSSHTable()}</tbody>
            </Table>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default GatewayConnect;
