import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { parseISO, startOfDay } from "date-fns";

const AddAssetUsageModal = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const notyf = useContext(NotyfContext);
  const [openModal, setOpenModal] = useState(false);

  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [asset_usage_time, setAsset_usage_time] = useState((new Date()).toISOString());
  var asset_usage = "";

  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState((new Date(startOfDay(new Date() - 24 * 60 * 60 * 1000 * 7))).toISOString().split('T')[0]);

  function handleChangeStartDatePicker(event) {
    setPeriodStartDatePicker(event.target.value)
    setAsset_usage_time(parseISO(event.target.value).toISOString().replace('Z', ''))
  }

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  function handleChangeUsage(event) {
    asset_usage = event.target.value;
  }

  const handleUpdateUsageBuckets = async () => {

    axios
      .post(`/assets/${id}/monthly_usages/update/`)
      .then(function (response) {
        // handle success
        console.log(response.status, "Updated buckets usage");

        notyf.open({
          type: "success",
          message: t("Usage added manually."),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: {
            x: "right",
            y: "bottom",
          },
        })

        toggle(false)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post("/assets/" + id + "/usage_logs/", {
        "asset_id": id,
        "time": asset_usage_time === "" ? (new Date()).toISOString() : asset_usage_time,
        "amount": asset_usage === "" ? 0 : asset_usage,
      }
      )
      .then(function (response) {
        // handle success
        console.log(response.status, "Added usage manually");

        handleUpdateUsageBuckets()
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });


  };

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Add usage")}</Dropdown.Item>
      <Modal show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Datenpunkt hinzufügen")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Timestamp")}</Form.Label>
                  <Form.Control
                    value={PeriodStartDatePicker}
                    onChange={handleChangeStartDatePicker}
                    type="datetime-local"
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>{t("Timestamp")}</Form.Label>
                  <Form.Control
                    //placeholder={(new Date()).toISOString()} 

                    value={asset_usage_time}
                    onChange={(e) => setAsset_usage_time(e.target.value)}
                  />
                </Form.Group> */}
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Usage")}</Form.Label>
                  <Form.Control type="number" onChange={handleChangeUsage} placeholder="300" />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button className="mb-2" variant="secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button className="ms-1" variant="primary" onClick={(e) => handleSubmit(e)}>
                  {t("Hinzufügen")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>

  );
};

export default AddAssetUsageModal;
