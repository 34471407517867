import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Spinner, Form } from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { getLogsBaseURL } from "../../config";

const SystemLogReports = () => {
  const { t } = useTranslation();
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [period_start, setPeriod_start] = useState();
  const [period_end, setPeriod_end] = useState();


  function loadLogs(e) {
    e.preventDefault();

    setIsLoading(true);

    axios
      .get(getLogsBaseURL() + "/log_reports/", {
        params: {
          period_start: period_start === "" ? null : period_start,
          period_end: period_end === "" ? null : period_end,
        }
      })
      .then(function (response) {
        // handle success
        setLogData(response.data.map(e => { return e.body }))
        console.log(response.status, "/logs");
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
  }

  // const renderLogsListTable = () => {
  //   return logData.map((log) => {
  //     return (
  //       <tr key={log.id}>
  //         <td>{format(parseISO(log.period_start), 'd MMM, HH:mm:ss', { locale: i18n.language === "de" ? de : null })} (UTC)</td>
  //         <td>{format(parseISO(log.period_end), 'd MMM, HH:mm:ss', { locale: i18n.language === "de" ? de : null })} (UTC)</td>
  //         <td>{log.access_logs_number_of_logs.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })}</td>
  //         <td>{log.access_logs_sum_response_size.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })}</td>
  //         <td>{log.application_logs_number_of_logs.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })}</td>
  //         <td>{log.access_logs_most_used_application.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })}</td>
  //         <td>{log.access_logs_number_of_400er_errors.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })}</td>
  //         <td>{log.access_logs_number_of_500er_errors.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 2, })}</td>
  //         <td>{log.application_logs_most_used_application}</td>
  //       </tr>
  //     );
  //   });
  // };

  const LogsList = () => (
    <Card>
      {/* <Card.Body> */}
      {
        isLoading === true ?
          showLoadingSpinner() :
          <Table responsive hover>
            <thead>
              <tr>
                {
                  Object.entries(logData[0]).map(([title, elementData], i) => {
                    return (
                      <th scope="col">{title}</th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                Object.entries(logData).map(([t, elementData], i) => {
                  return (
                    <tr key={i}>
                      {
                        Object.entries(elementData).map(([title, element], i) => {
                          return (<td>{element}</td>)
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
      }
      {/* </Card.Body> */}
    </Card >
  );

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  return (
    <React.Fragment>
      <Helmet title={t("Log Reports")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Log Reports")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>

        {/* <Row>
          <Col xl="12">
            <LogsChart />
          </Col>
        </Row> */}

        <Card>
          <Card.Header>
            <ErrorHandler error={error} />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={loadLogs}>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Start period")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={period_start}
                      onChange={(e) => setPeriod_start(e.target.value)}
                      placeholder="(Later)" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("End period")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={period_end}
                      onChange={(e) => setPeriod_end(e.target.value)}
                      placeholder="(Later)" />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button type="submit" variant="primary"
                  >
                    {t("Query")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {
          logData.length === 0
            ?
            <Row>
              <Col xl="12">
                <Card>
                  <Card.Header>
                    <Card.Title tag="h5"></Card.Title>
                    <h6 className="card-subtitle text-muted">
                      {t("No data found.")} {t("Run new query.")}
                    </h6>
                  </Card.Header>
                </Card>
              </Col>
            </Row>
            :
            <Row>
              <Col xl="12">
                <LogsList />
              </Col>
            </Row>
        }
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemLogReports;
