import React from "react";

const SidebarFooter = () => {
  return (
    <div className="sidebar-cta" style={{ paddingTop: "100px" , paddingBottom: "20px"}}>
      <div className="text-center">
        <p className="d-inline-block mb-2">Made with ♥ by <br/>cap-on Tech Hub Berlin</p>
      </div>
    </div>
  );
};

export default SidebarFooter;
