import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Button, } from "react-bootstrap";
import { RefreshCw, } from "react-feather";
import InvoiceTable from "./InvoiceTable";
import { useTranslation } from "react-i18next";
import { de } from "date-fns/locale";
import ErrorHandler from "../Helper/ErrorHandler";

const Invoices = () => {

  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    loadData()
  }, []);

  function loadData() {
    axios
      .get("/webservice/invoices/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/invoices");
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
        setIsLoading(false);
      });
  }

  const loadHeader = () => {

    var total_paid_invoices = 0;
    var total_overdue_invoices = 0;
    var overdue_invoices = 0;
    var total_invoices = 0;

    for (var i = 0; i < data.length; i++) {
      if (data[i]["payment_status"] === "paid") {
        total_paid_invoices += data[i]["total"]
      }
    }

    for (var j = 0; j < data.length; j++) {
      if (data[j]["payment_status"] === "overdue") {
        overdue_invoices += 1
        total_overdue_invoices += data[j]["total"]
      } else if (data[j]["payment_status"] === "unpaid") {
        total_overdue_invoices += data[j]["total"]
      }

      total_invoices += data[j]["total"]
    }

    return (
      <Row>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{total_paid_invoices.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'currency',
                  // locale: de,
                  maximumFractionDigits:0,
                  currency: 'EUR',
                })}</h3>
                <p className="mb-0">{t("Paid Invoices")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{total_overdue_invoices.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'currency',
                  // locale: de,
                  maximumFractionDigits:0,
                  currency: 'EUR',
                })}</h3>
                <p className="mb-0">{t("Unpaid Invoices")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{overdue_invoices}</h3>
                <p className="mb-0">{t("Overdue Invoices")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <div className="flex-grow-1">
                <h3 className="mb-2">{total_invoices.toLocaleString(i18n.language === "de" ? de : "en", {
                  style: 'currency',
                  // locale: de,
                  maximumFractionDigits:0,
                  currency: 'EUR',
                })}</h3>
                <p className="mb-0">{t("Total Revenue")}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }

  return (
    <React.Fragment>
      <Helmet title={t("Invoices")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Invoices")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            {/* <Button
              onClick={() => navigate("/invoices/add")}
              variant="primary"
              className="shadow-sm me-1">
              <Plus className="feather" />  {t("New")}
            </Button> */}
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        {loadHeader()}
        <Row>
          <Col>
            <ErrorHandler error={error} />
            <InvoiceTable doRefresh={() => loadData()} data={data} isLoading={isLoading} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Invoices;
