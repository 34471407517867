import { Helmet } from "react-helmet-async";
import { Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React from "react";
import { RefreshCw } from "react-feather";
import ShowStatus from "./SystemHealth";

const SystemHealthPage = () => {

  return (
    <React.Fragment>
      <Helmet title="cap-on • System Health" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>System Health</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <ShowStatus />
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemHealthPage;
