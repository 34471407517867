import React from "react";

import { Dropdown, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NavbarDropdown = ({
  children,
  countAll,
  countUnread,
  showBadge,
  header,
  footer,
  footerLink,
  icon: Icon,
}) => {

  const navigate = useNavigate();

  return (
    <Dropdown className="me-2 nav-item" align="end">
      <Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle">
        <div className="position-relative">
          <Icon className="align-middle" size={18} />
          {showBadge ? <span className="indicator">{countUnread}</span> : null}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu drop="end" className="dropdown-menu-lg py-0">
        <div className="dropdown-menu-header position-relative">
          {countAll} {header}
        </div>
        <ListGroup style={{ cursor: "pointer" }}>{children}</ListGroup>
        <Dropdown.Header className="dropdown-menu-footer" onClick={() => navigate(footerLink)} style={{ cursor: "pointer" }}>
          <span className="text-muted">{footer}</span>
        </Dropdown.Header>
      </Dropdown.Menu>
    </Dropdown>
  )
};

export default NavbarDropdown;
