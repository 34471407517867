import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Globe } from "react-feather";
import axios from "axios";

const FAQ = () => {
  const { t } = useTranslation();
  const [FAQ, setFAQ] = useState([
    {
      question: "Was macht cap-on?",
      answer: `cap-on ist sowohl Technologieanbieter als auch Finanzierungspartner. Über unsere intelligente cap-on Plattform bieten wir die passende Infrastruktur für intelligentes Datenmanagement, automatisierte Billing-Prozesse und ein KI-basiertes Risikomanagement. Die Finanzierungslösung für Ihre Zukunft: Asset as a Service. Unser Geschäftsmodell für die Transformation Ihres Unternehmens — Industrie 4.0 nachhaltig umsetzen mit Asset as a Service-Lösungen auf Pay per Use- oder Pay per Output-Basis.`
    },
    {
      question: "Was ist Asset as a Service?",
      answer: `Asset as a Service (AaaS) bedeutet, dass Unternehmen benötigte Maschinen („Assets“) nicht mehr selbst erwerben oder finanzieren bzw. leasen, sondern sie nach Bedarf als Dienstleistung einkaufen, die nach Verbrauch bzw. Nutzen abgerechnet wird. Das Abrechnungsmodell wird auch als „Outcome-based Payment“ oder „Pay-per-Use“ bezeichnet und ist in manchen Branchen wie der Druckindustrie (Abrechnung nach „Click“) bereits zum Standardmodell geworden. AaaS schont die Bilanzen der nutzenden Unternehmen, erhöht ihre Liquidität und erleichtert den Beschaffungsprozess.`
    },
    {
      question: "Was sind die Vorteile von Asset as a Service?",
      answer: `Wir verlagern Capex zu Opex und sichern so die finanzielle Freiheit von Unternehmen — mit der Chance, den Schritt in Richtung Industrie 4.0 zu gehen. Das Geschäftsmodell bringt die Interessen der Akteure Nutzer, Hersteller und Finanzierungspartner zusammen.`
    },
    {
      question: "Wie bekomme ich ein weiteres cap-on IoT-Gateway?",
      answer: `Bitte klicken Sie auf der linkten Seite auf \"cap-on Marketplace\" und erstellen Sie ein entsprechendes Ticket zur Neubeauftragung.`
    },
    {
      question: "Wie unterstützt cap-on Unternehmen dabei, nachhaltiger zu werden?",
      answer: `cap-on ist bewusst, dass die einzige funktionierende Zukunft nur eine nachhaltige sein kann und möchte aktiv zu diesem Prozess beitragen. Deshalb ist cap-on eine Partnerschaft mit dem Münchner Unternehmen Verso eingegangen, welches cap-on und seinen Kunden hilft,
      Nachhaltigkeitsbilanzen für seine Assets aufzustellen und so herauszufinden, wie viel CO\u2082-Äquivalente in einem abzurechnenden “Use” stecken. Der Partner
      Plant per Use macht es weiterhin möglich, dass Asset-Nutzer ihren Maschinenverbrauch bedarfsgerecht mit zertifizierten Nachhaltigkeitsprojekten
      ausgleichen können.`
    },
  ])

  // useEffect(() => {
  //   axios
  //     .get(getIAMBaseURL() + "/faqs/")
  //     .then(function (response) {
  //       // handle success
  //       console.log(response.status, "/faq");
  //       setFAQ(response.data);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // }, []);

  function open(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  return (
    <React.Fragment>
      <Helmet title="Support" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>FAQ</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={() => open("https://cap-on.de")}
              variant="primary"
              className="shadow-sm me-1">
              <Globe className="feather" />  {t("cap-on Website")}
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title tag="h5">{t("Frequently Asked Questions")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <p>Hier beantworten wir häufig gestellte Fragen über das Unternehmen cap-on und unsere Produkte.</p>
                <Accordion>
                  {
                    FAQ?.map((item, indx) => {
                      return <Accordion.Item eventKey={indx} className="bg-white">
                        <Accordion.Header>{item.question}</Accordion.Header>
                        <Accordion.Body className="bg-white">
                          <p>{item.answer}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    })
                  }
                </Accordion>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <SupportCard />
          </Col>
        </Row> */}
      </Container>
    </React.Fragment>
  );
};

export default FAQ;
