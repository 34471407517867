import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Activity } from "react-feather";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import FinanceInsights from "./FinanceInsights";
import GatewayInsights from "./GatewayInsights";
import SustainabilityInsights from "./SustainabilityInsights";
import animationData from "../Helper/intelligence.json";
import LottieControl from "../Helper/LottieControl";
import AssetInsights from "./AssetInsights";
import axios from "axios";
import UserDataManager from "../Helper/UserDataManager";

const Insights = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let { id } = useParams();
  const [showLoadingView, setShowLoadingView] = useState(true);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [insightsText, setInsightsText] = useState([]);
  const [insights, setInsights] = useState([]);
  const [assetData, setAssetData] = useState([]);

  useEffect(() => {
    setTimeout(() => setShowLoadingView(false), UserDataManager.getInstance().isAdmin ? 4000 : 6000); //6000

    setError({ showMessage: false, errorData: null })

    axios
      .get("/webservice/assets/" + id + "/base")
      .then(function (response) {

        // handle success
        console.log(response.status, "Loaded webservice assets");

        setAssetData(response.data)
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    axios
      .get("/webservice/assets/" + id + "/insights")
      .then(function (response) {
        // handle success
        console.log(response.status, "/webservice/assets");
        setInsights(response.data);
        // setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        // setIsLoading(false);
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Insights" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Button>
            <Breadcrumb.Item href="#" onClick={() => navigate("/assets")}>{t("Assets")}</Breadcrumb.Item>
            <Breadcrumb.Item href="#" onClick={() => navigate("/assets/" + id)}>{t("Asset 360° View")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Insights")}</Breadcrumb.Item>
          </Breadcrumb>

          {/* <Row className="mb-2 mb-xl-3"> */}
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Insights")}: {assetData?.title}</h3>
          </Col>
          {/* <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button onClick={() => navigate("/assets/" + id + "/usage")} variant="primary" className="shadow-sm me-1">
              <Activity className="feather" /> {isMobile ? "" : t("More usage details")}
            </Button>
          </Col> */}
          {/* </Row> */}
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        {
          showLoadingView ?
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    {/* <LottieControl animation={animationData} height={200} /> */}
                    <div className="h1 text-center mt-4 mb-4">
                      {t("Gathering Insights...")}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            :
            <Row>
              <Col lg="12">
                <div className={"tab " + "Default"}>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="first">{t("Asset")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">{t("IoT-Gateway")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">{t("Finance/Risk")}</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="forth">{t("Sustainability")}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <br />
                        <AssetInsights insights={insights} insightsText={insightsText} assetData={assetData} />
                        {/* <AssetInsightsStatic /> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <br />
                        <GatewayInsights insights={insights} insightsText={insightsText} assetData={assetData} />
                        {/* <GatewayInsightsStatic /> */}
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <br />
                        <FinanceInsights insights={insights} insightsText={insightsText} assetData={assetData} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="forth">
                        <br />
                        <SustainabilityInsights insights={insights} insightsText={insightsText} assetData={assetData} />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
        }
      </Container>
    </React.Fragment>
  );
};

export default Insights;
