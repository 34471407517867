import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Spinner, Form, Dropdown } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import { format, max, parseISO } from "date-fns";
import { de } from "date-fns/locale"
import ErrorHandler from "../Helper/ErrorHandler";
import Chart from "react-apexcharts";
import usePalette from "../../hooks/usePalette";
import UserList from "../Helper/UserList";
import RequestMethods from "../Helper/RequestMethods";
import ApplicationNames from "../Helper/ApplicationName";
import EnvironmentNames from "../Helper/EnvironmentNames";
import { getIAMBaseURL, getLogsBaseURL } from "../../config";
import LoadingSpinner from "../Helper/LoadingSpinner";
import { useParams } from "react-router-dom";

const SystemAccessLogsCount = () => {
  const { t, i18n } = useTranslation();
  const [logCountData, setLogCountData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [clustering_unit, setClustering_unit] = useState();
  const [username, setUsername] = useState();
  const [application_name, setApplication_name] = useState();
  const [request_method, setRequest_method] = useState();
  const [request_path, setRequest_path] = useState();
  const [status_code_min, setStatus_code_min] = useState();
  const [status_code_max, setStatus_code_max] = useState();

  const [logChartDataX, setLogChartDataX] = useState([]);
  const [logChartDataY, setLogChartDataY] = useState([]);
  const [environmentName, setEnvironmentName] = useState();

  useEffect(() => {
    loadLogs(null);
  }, []);

  function loadLogs(e) {
    e?.preventDefault();

    setIsLoading(true);

    axios
      .get(getLogsBaseURL() + "/access_logs/count", {
        params: {
          username: username === "" ? null : username,
          application_name: application_name === "" ? null : application_name,
          request_method: request_method === "" ? null : request_method,
          request_path: request_path === "" ? null : request_path,
          response_status_min: status_code_min === "" ? null : status_code_min,
          response_status_max: status_code_max === "" ? null : status_code_max,
          clustering_unit: clustering_unit === "" ? null : clustering_unit,
          environment_name: environmentName === "" ? null : environmentName,
        }
      })
      .then(function (response) {
        // handle success
        setLogCountData(response.data.reverse())
        setLogChartDataX(response.data.map((entry) => { return format(parseISO(entry.interval_start), "dd MMM", { locale: i18n.language === "de" ? de : null }) }));
        setLogChartDataY(response.data.map((entry) => { return entry.count_logs === null ? 0 : entry.count_logs }));
        console.log(response.status, "/logs");
        setIsLoading(false);
        setShowChart(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setShowChart(false);
        setError({ showMessage: true, errorData: error })
        setIsLoading(false);
      });
  }

  const [ClusterUnit, setClusterUnit] = useState([]);

  useEffect(() => {

    axios
      .get(getLogsBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "Loaded JSON");
        setClusterUnit(response.data["components"]["schemas"]["ClusterUnit"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const loadClusterUnit = () => {
    return ClusterUnit.map((unit) => {
      return (
        <option value={unit} defaultValue={logCountData.clustering_unit === unit}>{unit}</option>
      );
    });
  };

  const LogsCount = () => {
    const palette = usePalette();

    const data = [
      {
        name: "Logs",
        type: "area",
        data: logChartDataY,
      },
    ];

    const options = {
      chart: {
        animations: {
          enabled: false
        },
        stacked: false,
      },
      stroke: {
        width: [2, 2],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "100%",
        },
      },
      fill: {
        opacity: [0.85, 0.25],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: logChartDataX,
      markers: {
        size: 0,
      },
      xaxis: {
        // type: "time",
        // max: 100,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
        },
      },
      yaxis: {
        // title: {
        //   text: t("Handshakes"),
        // },
        min: 0,
        max: max(logChartDataY) * 1.1,
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: palette.black,
          },
          formatter: (value) => { return Math.round(value) },
        },
      },
      legend: {
        position: 'top',
        labels: {
          colors: palette.black,
          useSeriesColors: false
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return y;
          },
        },
      },
      colors: [
        palette.primary,
        palette.success,
        palette.warning,
        palette.info,
        palette.danger,
      ],
    };

    return (
      <Card>
        <Card.Header>
          <Col xs="auto" className="d-none d-sm-block">
            <Card.Title tag="h5">{t("Access Logs")}</Card.Title>
          </Col>
        </Card.Header>
        <Card.Body>
          <div className="chart">
            {
              isLoading === true && showChart === true ?
                <LoadingSpinner withStandardText /> :
                <Chart options={options} series={data} type="line" height="340" />
            }
          </div>
        </Card.Body>
      </Card>
    );
  };

  const [suggestions, setSuggestions] = useState([]); // Store the API suggestions
  const [showDropdown, setShowDropdown] = useState(false); // Whether to show the suggestion dropdown
  let { id } = useParams();
  const [allUsers, setAllUsers] = useState(false)

  useEffect(() => {

    axios
      .get(getIAMBaseURL() + "/users/emails")
      .then(function (response) {
        // handle success
        console.log(response.status, "/users/emails");
        setAllUsers(response.data.sort((a, b) => (a?.localeCompare(b))));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  // Function to fetch suggestions from the API
  const fetchSuggestions = (inputValue, field) => {

    console.log("Filtering", inputValue, field,)
    setSuggestions([])
    var filteredSuggestions = [];

    filteredSuggestions = allUsers?.filter(item =>
      item?.toLowerCase().includes(inputValue.toLowerCase()) // Case-insensitive match
    );
    setSuggestions(filteredSuggestions);
    setShowDropdown(true);
  };

  // Debounced API call
  const handleInputChange = (e, field) => {
    const value = e.target.value;

    setUsername(value); // Update the plc_node_id with the input value

    clearTimeout(id);
    id = setTimeout(() => {
      fetchSuggestions(value, field); // Fetch suggestions after 500ms of user input
    }, 500);
  };

  // Handle selecting a suggestion from the dropdown
  const handleSuggestionSelect = (suggestion, field) => {

    setUsername(suggestion)
    setShowDropdown(false); // Hide the dropdown
  };

  return (
    <React.Fragment>
      <Helmet title={t("System Logs")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Access Logs")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        {/* <Row>
          <Card>
            <Card.Header>
              <Card.Title tag="h5"></Card.Title>
              <h6 className="card-subtitle text-muted">
                {t("No data found.")}
              </h6>
            </Card.Header>
          </Card>
        </Row> */}

        {/* <Row>
          <Col xl="12">
            <LogsChart />
          </Col>
        </Row> */}
        <Card>
          <Card.Header>
            <ErrorHandler error={error} />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={loadLogs}>
              <Row>
                <Col md={3}>
                  {/* <Form.Group className="mb-3">
                    <Form.Label>{t("Username")}</Form.Label>
                    <Form.Select
                      name="username"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setUsername(e.target.value)
                      }}
                      value={username}>
                      <option value="">{t("-- Select --")}</option>
                      <UserList />
                      <option value="unknown">{t("unknown")}</option>
                    </Form.Select>
                  </Form.Group> */}

                  <Form.Group className="mb-3">
                    <Form.Label>{t("Username")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={username}
                      placeholder="Search"
                      onChange={handleInputChange} // Update input and fetch suggestions
                    />

                    {showDropdown && suggestions.length > 0 && (
                      <Dropdown.Menu
                        show
                        style={{ width: '100%', position: 'absolute', zIndex: 1000 }}
                      >
                        {suggestions.map((suggestion, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleSuggestionSelect(suggestion)}
                          >
                            {suggestion}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Environment")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setEnvironmentName(e.target.value)}
                      value={environmentName}>
                      <option value="">{t("-- Select --")}</option>
                      <EnvironmentNames />
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Application")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setApplication_name(e.target.value)}
                      value={application_name}>
                      <option value="">{t("-- Select --")}</option>
                      <ApplicationNames />
                    </Form.Select>
                  </Form.Group>
                </Col>


                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Request Path")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={request_path}
                      onChange={(e) => setRequest_path(e.target.value)}
                      placeholder="/api/v1/assets/" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Clustering Unit")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setClustering_unit(e.target.value)}
                      value={clustering_unit}>
                      <option value="">{t("-- Select --")}</option>
                      {loadClusterUnit()}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Request Method")}</Form.Label>
                    <Form.Select
                      onChange={(e) => setRequest_method(e.target.value)}
                      value={request_method}>
                      <option value="">{t("-- Select --")}</option>
                      <RequestMethods />
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Status code (Min)")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={status_code_min}
                      onChange={(e) => setStatus_code_min(e.target.value)}
                      placeholder="Status Code (min)"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Status code (Max)")}</Form.Label>
                    <Form.Control
                      type="text"
                      value={status_code_max}
                      onChange={(e) => setStatus_code_max(e.target.value)}
                      placeholder="Status Code (max)"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => setUsername("*@maag.com")}>
                    {t("MAAG only")}
                  </Button>

                  <Button type="submit" variant="primary"
                  >
                    {t("Query")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {
          logCountData.length === 0 ?
            <p></p>
            :
            logCountData.reduce((a, v) => a = a + v.count_logs, 0) === 0
              ?
              <Card.Body>
                <p>{t("No data found.")}</p>
              </Card.Body>
              :
              <Row>
                <Col xl="12">
                  <LogsCount />
                </Col>
              </Row>
        }
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default SystemAccessLogsCount;
