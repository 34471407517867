import { format, formatDistance, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const EmissionsChart = (props) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  // Process emissions data to create category-based stacked data
  const processData = () => {
    const categories = {}; // To store cumulative emissions for each category

    props.emissionData?.forEach((entry) => {
      const date = format(parseISO(entry.interval_start), "yyyy-MM-dd");
      entry?.items?.forEach((item) => {
        if (!categories[item.title]) {
          categories[item.title] = [];
        }
        categories[item.title].push({
          date: date,
          emission: item?.co_two_total //item.co_two_kg_e_per_unit * item.amount,
        });
      });
    });

    return Object.entries(categories).map(([category, data]) => ({
      name: t(category),
      data: data.map((d) => ({ x: d.date, y: d.emission })),
    }));
  };

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    );
  };

  const seriesData = processData();
  const options = {
    chart: {
      type: 'area',
      stacked: true,
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis: {
      title: {
        text: t("CO\u2082 Emissions (kg)"),
      },
      labels: {
        formatter: (value) => value?.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 1 }),
        style: {
          colors: palette.black,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    colors: [palette.primary, palette.success, palette.warning, palette.info, palette.secondary],
    tooltip: {
      shared: true,
      y: {
        formatter: function (val) {
          return `${val.toFixed(2)} kg CO\u2082e`;
        },
      },
    },
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
      },
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title>{t("CO\u2082 emissions over time")}</Card.Title>
        <Card.Subtitle>{t("Time span") + ": " + (props?.emissionData?.length > 0 ? formatDistance(parseISO(props?.emissionData[0]?.interval_start), parseISO(props?.emissionData?.[props?.emissionData?.length - 1]?.interval_start), { locale: i18n.language === "de" ? de : null }) : "-")}</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        {
          props.isLoading === true ? showLoadingSpinner() :
            seriesData.length === 0 ? (
              <h6 className="card-subtitle text-muted">
                {t("No emissions data available.")}
              </h6>
            ) : (
              <div className="chart">
                <Chart options={options} series={seriesData} type="area" height="250" />
              </div>
            )
        }
      </Card.Body>
    </Card>
  );
};

export default EmissionsChart;