import {
  Col,
  Row,
  Modal,
  Card,
  Form,
  Table,
  Spinner,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import climateGridLogo from "../../assets/company/climate_grid_gross.png";
import ErrorHandler from "../Helper/ErrorHandler";
import axios from "axios";
import { ArrowUpRight } from "react-feather";
import NotyfContext from "../../contexts/NotyfContext";
import LoadingSpinner from "../Helper/LoadingSpinner";

const CompensationModal = (props) => {
  const { t } = useTranslation();
  const [allProjects, setAllProjects] = useState([])
  const [filteredProjects, setFilteredProjects] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [showProjects, setShowProjects] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [selectedProject, setSelectedProject] = useState(false)

  const [amountToCompensate, setAmountToCompensate] = useState(props?.offsettable_co2e);
  const notyf = useContext(NotyfContext);

  function open(url) {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  }

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  function handleSubmit(e) {
    e.preventDefault();

    setShowProjects(true);
    applyFilters();
  }

  useEffect(() => {
    setError({ showMessage: false, errorData: null })
    setIsLoading(true);

    axios
      .get("https://br.climategrid.de:443/api/database/rows/table/419/?user_field_names=true", {
        headers: {
          'Authorization': 'Token MC7aGKybnZC581rkODbnsNyOYgtWpoSQ',
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/cg-website");
        setFilteredProjects(response.data.results.filter((project) => project?.status?.value === "Öffentlich"));//.sort((a,b) => parseFloat(a?.climategrid_score) < parseFloat(b?.climategrid_score))); // && project?.co2_offset_total > amountToCompensate
        setAllProjects(response.data.results.filter((project) => project?.status?.value === "Öffentlich"));//.sort((a,b) => parseFloat(a?.climategrid_score) < parseFloat(b?.climategrid_score))); // && project?.co2_offset_total > amountToCompensate
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        console.log(error);
      });
  }, [])

  useEffect(() => {
    applyFilters()
  }, [updatingFilters])

  const [updatingFilters, setUpdatingFilters] = useState(true);
  const [filters, setFilters] = useState({
    type: undefined,
    category: undefined,
    offsettable: undefined
  });

  function updateFilter(key, value) {

    console.log(key, value)

    if (value === false || value === "all") {
      setFilters(current => {
        const copy = { ...current };
        delete copy[key];
        return copy;
      });
    } else {
      setFilters(prevState => ({
        ...prevState,
        [key]: value
      }))
    }

    setUpdatingFilters(false);
  }

  function handleClickReserveProject() {
    toggle(false)

    notyf.open({
      type: "success",
      message: t("Das Projekt \"") + selectedProject?.title + t("\" wurde vorgemerkt."),
      duration: "8000",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  }

  function applyFilters() {

    console.log("Applying filters?...", filters);
    console.log("All:", allProjects);

    const sorted = [...allProjects].filter((item) =>
      (filters.category !== undefined ? item?.project_category?.value === filters.category : true)
      && (filters.type !== undefined ? item?.project_type?.value === filters.type : true)
      && (filters.offsettable === true ? item?.offsettable === filters?.offsettable : true)
      // && (item?.co2_offset_total > amountToCompensate)
      // && (item?.climategrid_score > 0 ? item?.climategrid_score >= filters?.minRating : true)
      // && (parseFloat(item?.price_per_t) > 0 ? (parseFloat(item?.price_per_t) >= filters?.minPrice && item?.price_per_t <= filters?.maxPrice) : true)
      // && (filters?.tax_deductible !== undefined ? item?.tax_deductible == filters?.tax_deductible : true)
      // && (filters?.additionality !== undefined ? item?.additionality == filters?.additionality : true)
      // && (filters?.region !== undefined ? item?.region_continent == filters?.region : true)
      // && (filters?.tuev_certified == true ? item?.tuev_certified == filters?.tuev_certified : true)
      // && (filters?.offsettable == true ? item?.offsettable == filters?.offsettable : true)
      // && (filters?.finished == true ? item?.finished == filters?.finished : true)
    );

    // console.log("All");
    // allProjects.map((item) => console.log("Logs:", "tuev_certified:", item.tuev_certified, "offsettable:", item.offsettable, "price", item.price_per_t, "rating", item.climategrid_score));
    console.log("Filtered", sorted);
    // sorted.map((item) => console.log("Logs:", "tuev_certified:", item.tuev_certified, "offsettable:", item.offsettable, "price", item.price_per_t, "rating", item.climategrid_score));

    setFilteredProjects(sorted);
    setUpdatingFilters(true);
  }

  function resetFilters() {

    // setAllRegions("all");

    setFilters({
      // accounting: "all",
      type: undefined,
      category: undefined,
      offsettable: undefined
      // tuev_certified: false,
      // offsettable: false
      // "biogas": true,
      // "humus": true,
      // "moor": true,
      // "wald": true,
      // "wind": true,
      // "ozeane": true,
    });
    setFilteredProjects([...allProjects]);
  }

  const renderAvailabilityState = (available) => {
    switch (available) {
      case true:
        return (
          <span className="badge bg-success">{t("Available")}</span>
        )
      case false:
        return (
          <span className="badge bg-warning">{t("Request")}</span>
        )
      default:
        break;
    }
  }

  const loadAvatar = (url) => {
    return (
      <img
        src={url === null ? null : url}
        className="center rounded" //rounded-circle "img-thumbnail rounded me-2 mb-2"
        alt="avatar"
        width={48} height={48}
      />);
  };

  const ShowProjectList = () => {

    return (
      <>
        {
          filteredProjects.length === 0 && showProjects ?
            <p className="text-center">Keine passenden Projekte gefunden.</p>
            :
            <Table size="sm" responsive hover>
              <thead>
                <tr>
                  <th scope="col">{""}</th>
                  <th scope="col">{""}</th>
                  <th scope="col">{t("Title")}</th>
                  <th scope="col">{"Verfügbarkeit"}</th>
                  <th scope="col">{"Bilanziell anrechenbar"}</th>
                  <th scope="col">{"ClimateGrid Score"}</th>
                  <th scope="col">{t("Price per t")}</th>
                  <th scope="col">{t("Price (Total)")}</th>
                </tr>
              </thead>
              <tbody>{filteredProjects?.map((project, indx) => {
                return (
                  <tr key={project?.id}>
                    {/* <td><img
                src={manager?.avatar_url}
                alt="avatar"
                className="me-3 text-center rounded-circle"
                width={40} height={40}
              /></td> */}
                    <td>
                      <Form.Check
                        type="radio"
                        defaultChecked={indx === 0}
                        onChange={() => setSelectedProject(project)}
                        name="radios-example"
                      />
                    </td>

                    <td >{loadAvatar(project?.images[0]?.thumbnails?.small?.url)}</td>
                    <OverlayTrigger
                      placement='auto'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Description")}</Popover.Header>
                          <Popover.Body>
                            {project?.short_description}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <td><strong>{project?.title}</strong></td>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='auto'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Project Availability")}</Popover.Header>
                          <Popover.Body>
                            <strong>Verfügbare CO<sub>2</sub>:</strong> {(project?.co2_offset_total - project?.co2_offset_compensated).toLocaleString("de", {
                              maximumFractionDigits: 0,
                            })} t CO<sub>2</sub>
                            <br />
                            <strong>Projektfortschritt:</strong> {(project?.co2_offset_compensated / project?.co2_offset_total).toLocaleString("de", {
                              style: "percent",
                              maximumFractionDigits: 0,
                            })}
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <td>{renderAvailabilityState((project?.co2_offset_total - project?.co2_offset_compensated) > amountToCompensate)}</td>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement='auto'
                      overlay={
                        <Popover>
                          <Popover.Header as='h3'>{t("Anrechenbarkeit")}</Popover.Header>
                          <Popover.Body>
                            ...
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <td>{project?.offsettable ? <span className="badge bg-success">Ja</span> : <span className="badge bg-warning">Nein</span>}</td>
                    </OverlayTrigger>

                    <td>Coming Soon</td>
                    <td>{parseFloat(project?.price_per_t)?.toLocaleString("de", {
                      style: "currency",
                      currency: "EUR",
                      maximumFractionDigits: 2,
                    })}</td>
                    <td>{(parseFloat(project?.price_per_t) * amountToCompensate)?.toLocaleString("de", {
                      style: "currency",
                      currency: "EUR",
                      maximumFractionDigits: 2,
                    })}</td>
                    <td className="table-action">
                      <ArrowUpRight onClick={() => open("https://app.climategrid.de/projects/details?pid=" + project?.id)} alt="Log" className="align-middle" size={18} style={{ cursor: "pointer" }} />
                    </td>
                  </tr>
                )
              })}</tbody>
            </Table>
        }
      </>

    )
  }

  return (
    <>
      {
        props?.buttonSize === "regular"
          ?
          <Button variant="primary" className="shadow-sm me-1" onClick={() => toggle()}>{t("Compensate CO\u2082 emissions")}</Button>
          :
          <Card.Link onClick={() => toggle()}>{t("Compensate CO\u2082 emissions")}</Card.Link>
      }

      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>CO<sub>2</sub>-{t("Kompensation")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col md="12" className="d-flex">
              <Card className="flex-fill w-100">
                <Card.Body>

                  {/* Error Handling */}
                  <Row>
                    <Col>
                      <ErrorHandler error={error} />
                    </Col>
                  </Row>

                  {/* Filters */}
                  <Row>
                    <Col md="12">
                      <Form onSubmit={handleSubmit}>
                        <h3>Klima- und Umweltschutzprojekte</h3>
                        <p>Finden Sie Ihr passendes Klima- und Umweltschutzprojekt basierend auf Ihren Präferenzen.</p>
                        <br />
                        <Row>
                          <Col md={3}>
                            <Form.Label>{t("Amount of CO\u2082")}</Form.Label>
                            <Form.Control
                              required
                              defaultValue={amountToCompensate}
                              type="number"
                              onChange={(e) => setAmountToCompensate(e.target.value)}
                              placeholder="Amount" />
                          </Col>
                          <Col md={3}>
                            <Form.Label>{t("Intention")}</Form.Label>
                            <Form.Select
                              // defaultValue={'all'}
                              value={filters?.type}
                              className='mb-2'
                              onChange={(e) => updateFilter("type", e.target.value)}>
                              <option value='all'>Alle Projekte anzeigen</option>
                              <option value={"CO2 Reduktion / Carbon Removal"}>{t("CO\u2082 reduzieren (Removal)")}</option>
                              <option value={"CO2 Vermeidung / Carbon Avoidance"}>{t("CO\u2082 vermeiden (Avoidance)")}</option>
                              <option value={"Plastic Removal"}>Plastik beseitigen (Removal)</option>
                              <option value={"Ökosystemleistung & Biodiversität"}>{t("Ökosystem / Biodiversität stärken")}</option>
                            </Form.Select>
                          </Col>
                          <Col md={3}>
                            <Form.Label>{t("Kategorie")}</Form.Label>
                            <Form.Select
                              defaultValue={'all'}
                              value={filters?.category}
                              className='mb-2'
                              onChange={(e) => updateFilter("category", e.target.value)}>
                              <option value='all'>Alle Kategorien</option>
                              {
                                [...new Set(allProjects.filter(item => item?.project_category?.value !== "").sort((a, b) => a?.project_category?.value > b?.project_category?.value).map((project) => { return project?.project_category?.value }))].map((value, indx) => {
                                  return <option key={indx} value={value}>{value}</option>
                                })
                              }
                            </Form.Select>
                          </Col>
                          <Col md={3}>
                            <Form.Label>{t("Anrechenbarkeit")}</Form.Label>
                            <Form.Check
                              // type="switch"
                              onChange={(e) => updateFilter("offsettable", e.target.checked)}
                              defaultChecked={false}
                            // label={t("Activate routine")}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Button variant='outline-primary' className="shadow-sm me-1" onClick={() => resetFilters()}>
                              Filter zurücksetzen
                            </Button>
                            <Button type="submit" variant="primary"
                            >
                              {t("Klima- und Umweltschutzprojekte suchen")}
                            </Button>
                          </Col>
                        </Row>
                        <br />
                      </Form>
                    </Col>
                  </Row>

                  <br />
                  {/* Projects List */}
                  <Row>
                    {isLoading && <LoadingSpinner />}
                    {showProjects && <ShowProjectList />}
                  </Row>
                  <br />
                  <br />
                  <Row>
                    <Col md="12" className="text-center">
                      Transparent CO<sub>2</sub> binden mit ClimateGrid
                      <br />
                      <img
                        src={climateGridLogo}
                        alt="avatar"
                        width="auto"
                        height={100}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row >
        </Modal.Body>
        <Modal.Footer>

          <Button variant="outline-primary" href="https://app.climategrid.de/projects">
            Weitere Projekte finden
          </Button>

          <Button variant="outline-secondary" onClick={() => toggle(false)}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" onClick={() => handleClickReserveProject()}>
            {t("Reservieren")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompensationModal;
