import axios from "axios";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Table, Spinner, Form, Badge } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ArrowUpRight, RefreshCw } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import UserList from "../Helper/UserList";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import LoadingSpinner from "../Helper/LoadingSpinner";
import ObjectType from "../Helper/ObjectType";
import ActionType from "../Helper/ActionType";

const AuditLogs = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  var qs = require('qs');
  const queryParams = new URLSearchParams(window.location.search)

  // const [searchParams, setSearchParams] = useSearchParams();
  // const [query, setQuery] = useState(searchParams.get("response_status_min"));

  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (event) => {
    const { name, value } = event?.target;
    setSearchParams({ [name]: value })
  }

  const [limit, setLimit] = useState();
  const [period_start, setPeriod_start] = useState((new Date(new Date() - 24 * 60 * 60 * 1000 * 7)).toISOString().split('.')[0]);
  const [period_end, setPeriod_end] = useState();
  const [trace_id, setTrace_id] = useState(queryParams.get("trace_id"));
  const [username, setUsername] = useState();
  const [object_type, set_object_type] = useState();
  const [action_type, set_action_type] = useState();

  const [skip, setSkip] = useState(0);

  useEffect(() => {
    loadLogs(null);
  }, []);

  function loadLogs(event = null, { newSkip = null } = {}) {
    // Prevent default behavior if an event is passed
    if (event) {
      event.preventDefault();
    }

    // If newSkip is passed, update the skip state
    if (newSkip !== null) {
      setSkip(newSkip);
    }

    setIsLoading(true);
    setError({ showMessage: false });

    axios
      .get("/audit_logs/", {
        params: {
          limit: limit === "" ? 50 : limit,  // Default limit to 50 if not specified
          skip: newSkip !== null ? newSkip : skip,  // Use newSkip if provided
          period_start: period_start === "" ? null : period_start,
          period_end: period_end === "" ? null : period_end,
          trace_id: trace_id === "" ? null : trace_id,
          username: username === "" ? null : username,
          object_type: object_type === "" ? null : object_type,
          action_type: action_type === "" ? null : action_type,

          // request_method: request_method === "" ? null : request_method,
          // request_path: request_path === "" ? null : request_path,
          // response_status_min: status_code_min === "" || status_code_min === null ? 0 : status_code_min,
          // response_status_max: status_code_max === "" || status_code_max === null ? 10000 : status_code_max,
          // environment_name: environmentName === "" ? null : environmentName,
          // response_status_exclude: response_status_exclude === "" || response_status_exclude === null ? [] : response_status_exclude,
        }
        // ,
        // paramsSerializer: function (params) {
        //   return qs.stringify(params, { arrayFormat: 'repeat' });
        // }
      }
      )
      .then(function (response) {
        // handle success
        setLogData(response.data);
        console.log(response.status, "/audit_logs");
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error });
        setIsLoading(false);
      });
  }

  function resetFields() {
    setLimit("");
    setPeriod_start(new Date(new Date() - 24 * 60 * 60 * 1000 * 7).toISOString().split('.')[0])
    setPeriod_end("");
    setTrace_id("");
    setUsername("");
    set_object_type("");
    loadLogs(null);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10); // Set this based on your total results

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const newSkip = (page - 1) * (limit || 50);  // Calculate new skip value
    loadLogs(null, { newSkip });  // Call loadLogs with the new skip value
  };

  function Pagination({ currentPage, totalPages, onPageChange }) {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage - 1)}>{t("Previous")}</Button>
        )}
        {pages.map((page) => (
          <Button
            key={page}
            variant="outline-secondary" size="sm" className="shadow-sm me-1"
            onClick={() => onPageChange(page)}
            disabled={page === currentPage}
          >
            {page}
          </Button>
        ))}
        {currentPage < totalPages && (
          <Button variant="outline-secondary" size="sm" className="shadow-sm me-1" onClick={() => onPageChange(currentPage + 1)}>{t("Next")}</Button>
        )}
      </div>
    );
  }

  function renderBadgeColor(title) {

    switch (title) {
      case "delete":
        return "danger"
      case "create":
        return "success"
      case "update":
        return "info"
      default:
        return "secondary"
    }
  }

  function getDetailURL(log) {
    switch (log?.object_type) {
      case "assets":
        return `/assets/${log?.object_id}`
      case "iot_gateways":
        return `/gateways/${log?.object_id}`
      default:
        break;
    }
  }

  const renderLogsListTable = () => {
    return logData.map((log) => {
      const changedKeys = Object.keys(log.changes); // Extract keys of changed fields

      return (
        <tr key={log.id}>
          <td>{log.time}</td>
          <td>{formatDistanceToNow(parseISO(log?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td>{log?.username || "-"}</td>
          <td><Badge bg="" className={`badge-soft-${renderBadgeColor(log?.action_type)} me-2`}>{(`${t(log.action_type)}d`).toUpperCase()}</Badge> </td>
          <td>{log?.object_type || "-"}</td>
          {/* <td>{log?.object_id || "-"}</td> */}

          <td className="table-action">
            {
              (log?.object_type === "assets" || log?.object_type === "iot_gateways") &&

              <ArrowUpRight alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={() => navigate(getDetailURL(log))} />
            }
          </td>
          <td>{changedKeys.length > 0 ? changedKeys.join(", ") : "No changes"}</td> {/* New column for changed keys */}

        </tr>
      );
    });
  };

  const LogsList = () => (
    <Card>
      {
        isLoading === true ?
          <LoadingSpinner withStandardText /> :
          <div>
            <Row>
              <Col xs="auto" className="ms-auto text-center">
                <div className="px-3 py-3 mt-2 mb-2">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </Col>
            </Row>

            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Timestamp")}</th>
                  <th scope="col"></th>
                  <th scope="col">User</th>
                  <th scope="col">Action</th>
                  <th scope="col">Type</th>
                  <th scope="col">Go to Object</th>
                  <th scope="col">Fields</th>
                </tr>
              </thead>
              <tbody>{renderLogsListTable()}</tbody>
            </Table>

          </div>

      }
    </Card>
  );

  return (
    <React.Fragment>
      <Helmet title={t("System Logs")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Access Logs")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>

        {/* <Row>
          <Col xl="12">
            <LogsChart />
          </Col>
        </Row> */}

        <Card>
          <Card.Header>
            <ErrorHandler error={error} />
          </Card.Header>
          <Card.Body>
            <Form onSubmit={(e) => loadLogs(e)}>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Username")}</Form.Label>
                    <Form.Select
                      name="username"
                      onChange={(e) => {
                        updateSearchParams(e)
                        setUsername(e.target.value)
                      }}
                      value={username}>
                      <option value="">{t("-- All --")}</option>
                      <UserList />
                      <option value="unknown">{t("unknown")}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Object Type")}</Form.Label>
                    <Form.Select
                      name="application_name"
                      onChange={(e) => {
                        updateSearchParams(e)
                        set_object_type(e.target.value)
                      }}
                      value={object_type}>
                      <option value="">{t("-- All --")}</option>
                      <ObjectType />
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Action Type")}</Form.Label>
                    <Form.Select
                      name="action_type"
                      onChange={(e) => {
                        updateSearchParams(e)
                        set_action_type(e.target.value)
                      }}
                      value={action_type}>
                      <option value="">{t("-- All --")}</option>
                      <ActionType />
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Trace ID")}</Form.Label>
                    <Form.Control
                      type="trace_id"
                      value={trace_id}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setTrace_id(e.target.value)
                      }}
                      placeholder="UUID"
                    />
                  </Form.Group>
                </Col>

                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Limit")}</Form.Label>
                    <Form.Control
                      type="number"
                      name="limit"
                      value={limit}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setLimit(e.target.value)
                      }}
                      placeholder="50"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Skip")}</Form.Label>
                    <Form.Control
                      type="number"
                      name="skip"
                      value={skip}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setSkip(e.target.value)
                      }}
                      placeholder="50"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Start")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="period_start"
                      value={period_start}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setPeriod_start(e.target.value)
                      }}
                      placeholder="Start date" />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("End")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="period_end"
                      value={period_end}
                      onChange={(e) => {
                        updateSearchParams(e)
                        setPeriod_end(e.target.value)
                      }}
                      placeholder="End date" />
                  </Form.Group>
                </Col>
              </Row>
              <br />

              <Row>
                <Col>
                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate(-1)}>
                    {t("Back")}
                  </Button>
                  <Button variant="outline-primary" className="shadow-sm me-1" onClick={() => resetFields()}>
                    {t("Reset")}
                  </Button>
                  <Button type="submit" variant="primary"
                  >
                    {t("Query")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {
          logData.length === 0
            ?
            <Row>
              <Col xl="12">
                <Card>
                  <Card.Header>
                    <Card.Title tag="h5"></Card.Title>
                    <h6 className="card-subtitle text-muted">
                      {t("No data found.")} {t("Run new query.")}
                    </h6>
                  </Card.Header>
                </Card>
              </Col>
            </Row>
            :
            <Row>
              <Col xl="12">
                <LogsList />
              </Col>
            </Row>
        }
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AuditLogs;
