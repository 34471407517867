import axios from "axios";
import { formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React, { useState } from "react";

import { Row, Col, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InvoiceDetailsModal from "../../pages/Invoices/InvoiceDetailsModal";

const NavbarDropdownItem = ({ alertItem, icon, title, description, time, spacing, detailLink }) => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [showInvoice, setShowInvoice] = useState(null);

  function markAsReadAndRedirect(alertItem) {

    if (alertItem.is_read === false) {
      axios
        .put("/alerts/" + alertItem.id + "/read")
        .then(function (response) {
          // handle success
          console.log(response.status, "Marked as read");

          // Nur wenn source_type bekannt ist!

          if (detailLink !== null) {

            if (detailLink?.source_type === "invoice") {
              setShowInvoice(detailLink?.source_id)
            } else {
              navigate(detailLink);
              refreshPage();
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      if (detailLink !== null) {
        if (detailLink?.source_type === "invoice") {
          setShowInvoice(detailLink?.source_id)
        } else {
          navigate(detailLink);
          refreshPage();
        }
      }
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      {showInvoice &&
        <InvoiceDetailsModal
          selected_invoice_id={showInvoice}
          handleCancel={() => {
            setShowInvoice(null)
            console.log("<----->")
          }}
          handleClose={() => setShowInvoice(null)}
        />}
      <ListGroup.Item onClick={() => markAsReadAndRedirect(alertItem)}>



        <Row className="align-items-center g-0">
          <Col xs={2}>{icon}</Col>
          <Col xs={10} className={spacing ? "pl-2" : null}>
            <div className="text-dark">{title}</div>
            <div className="text-muted mt-1">{t(description)}</div>
            <div className="text-muted small mt-1">{formatDistanceToNow(parseISO(time), { locale: i18n.language === "de" ? de : null, addSuffix: true })} </div>
          </Col>
        </Row>
      </ListGroup.Item>
    </>
  )
};

export default NavbarDropdownItem;
