import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO } from "date-fns";

const AddOEERejectModal = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [time, setTime] = useState();
  const [log_amount, setLog_amount] = useState();
  const [openModal, setOpenModal] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    axios
      .post("/assets" + id + "/asset_logs/", {
        // "description": "",
        "asset_id": id,
        "time": parseISO(time),
        // "plc_node_id": plc_node_id,
        "log_type": "reject",
        "value": log_amount
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "Added asset_logs \"reject\" manually");
        toggle(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  return (
    <>
      <Dropdown.Item onClick={() => toggle()}>{t("Report \"Rejects\" (OEE)")}</Dropdown.Item>
      <Modal show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Report \"Rejects\" (OEE)")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Timestamp")}</Form.Label>
                  <Form.Control
                    type="date"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Amount")}: {t("Rejected Units")}</Form.Label>
                  <Form.Control
                    type="number"
                    value={log_amount}
                    onChange={(e) => setLog_amount(e.target.value)}
                    placeholder="x" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => toggle(false)}>
            {t("Cancel")}
          </Button>{" "}
          <Button variant={"primary"} onClick={handleSubmit}>
            {t("Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddOEERejectModal;
