import { Card, Col, Modal, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Download, Edit, Edit2, HelpCircle, Lock, Search, Trash } from "react-feather";
import axios from "axios";
import UserDataManager from "../Helper/UserDataManager";
import UploadFileModal from "../Helper/UploadFileModal";
import FileAccessLevelModal from "./FileAccessLevelModal";

const FileTable = ({ data, title, parent_id, parent_type }) => {
  const { t } = useTranslation();

  function handleDelete(e, file_id) {
    e.preventDefault();
    axios
      .delete(parent_type + parent_id + "/files/" + file_id)
      .then(function (response) {
        console.log(response.status, "/files");
        // handle success
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);


  const openModal = (file) => {
    setSelectedFile(file);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedFile(null);
  };

  const [showPermissionsForFile, setShowPermissionsForFile] = useState();

  const renderTable = () => {

    return data?.sort((a, b) => a?.title.localeCompare(b?.title))?.map((file) => {

      return (
        <tr
          key={file.id}
          onClick={() => (file.file_type.toLowerCase() === ("jpg") || file.file_type.toLowerCase() === ("jpeg") || file.file_type.toLowerCase() === ("png"))
            ? openModal(file)
            : openInNewTab(file.url)
          }
          style={{ cursor: "pointer" }} >
          <td>{file.title}</td>
          <td>{t(file.access_level)}</td>
          <td>{file.file_type?.toUpperCase()}</td>
          {/* <td>{file.created_at}</td> */}
          <td className="table-action">
            {/* <Search
              alt="Show"
              className="align-middle"
              size={18}
              onClick={() => window.open(file.url, '_blank')}
              style={{ cursor: "pointer" }}
            />
            &nbsp;
            &nbsp; */}
            {/* <Download
              alt="Download"
              className="align-middle"
              size={18}
              onClick={() => open(file.url, file.title)}
              style={{ cursor: "pointer" }}
            /> */}

            {/* <Search
              alt="Open"
              className="align-middle"
              size={18}
              onClick={() => openInNewTab(file.url)}
              style={{ cursor: "pointer" }}
            /> 
            &nbsp;
            &nbsp;*/}

            {/* <Download
              alt="Download"
              className="align-middle"
              size={18}
              onClick={e => {
                e.stopPropagation()
                downloadFile(file.url, file.title)
              }}
              style={{ cursor: "pointer" }}
            />

            &nbsp;
            &nbsp; */}

            <Edit alt="Delete" className="align-middle me-3" size={18} onClick={e => {
              e.stopPropagation()
              setShowPermissionsForFile(file)
            }}
              style={{ cursor: "pointer" }}
            />

            <Trash alt="Delete" className="align-middle" size={18} onClick={e => {
              e.stopPropagation()
              window.confirm(t("Are you sure you wish to delete this file?")) && handleDelete(e, file.id)
            }}
              style={{ cursor: "pointer" }}
            />
          </td>
        </tr>
      );
    });
  }

  return (
    <>
      {showPermissionsForFile && <FileAccessLevelModal
        file={showPermissionsForFile}
        parent_id={parent_id}
        parent_type={parent_type}
        handleCancel={() => setShowPermissionsForFile(null)}
        handleSave={() => {
          refreshPage();
          setShowPermissionsForFile(null)
        }} />}

      <Card className="flex-fill w-100">
        <Card.Header>

          <Row>
            <Col className="mt-0">
              <Card.Title tag="h5" className="mb-0">
                {t(title)}
              </Card.Title>
            </Col>
            <Col xs="auto">
              <UploadFileModal buttonType="small" type={parent_type?.replace("s/", "")?.replace("/", "")} />
            </Col>
          </Row>

        </Card.Header>
        {/* <Card.Body> */}
        {
          data !== null && data?.length > 0
            ?
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Title")}</th>
                  <th scope="col">
                    {t("Access level")}
                    <OverlayTrigger
                      placement='bottom'
                      // style={{ width: "300px" }}
                      overlay={<Popover>
                        <Popover.Header as='h3'>{t("Access level")}</Popover.Header>
                        <Popover.Body>
                          <div className="mb-4">
                            <strong>{t("public")}</strong>: {t("The file is public to everyone.")}<br /><br />
                            <strong>{t("all_stakeholders")}</strong>: {t("The file is visible for all stakeholders of the asset including users of the manufacturer, user / operator and client.")}<br /><br />
                            <strong>{t("manufacturer")}</strong>: {t("Only visible for users of the manufacturer")}<br /><br />
                            <strong>{t("client")}</strong>: {t("Only visible for users of the client / operator")}<br /><br />
                            <strong>{t("investor")}</strong>: {t("Only visible for users of the investor")}<br /><br />
                            <strong>{t("cap_on")}</strong>: {t("Only visible for cap-on users")}<br /><br />
                          </div>
                        </Popover.Body>
                      </Popover>}
                    >
                      <HelpCircle className="feather ms-2" />
                    </OverlayTrigger>
                  </th>
                  <th scope="col">{t("File type")}</th>
                  {/* <th scope="col">{t("Uploaded")}</th> */}
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
            :
            <Card.Body>
              <p>{t("No data found.")}</p>
            </Card.Body>
        }
        {/* </Card.Body> */}
      </Card>
      {/* Modal for Image Preview */}
      <Modal show={showModal} size="xl" onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedFile?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {selectedFile?.url ? (
            <img
              src={selectedFile.url}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          ) : (
            <p>{t("No image selected.")}</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

function downloadFile(url, fileName) {
  const link = document.createElement('a');
  link.href = url;

  // Set the `download` attribute to ensure the browser downloads the file instead of opening it
  link.setAttribute('download', fileName || 'download.csv'); // Set default name as 'download.csv' if no fileName

  // Append the link to the document body (required for Firefox)
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up by removing the link element
  document.body.removeChild(link);
}

function openInNewTab(url) {
  const win = window.open(url, '_blank'); // Open in a new tab
  if (win) {
    win.focus();  // Ensure the new tab gets focus
  } else {
    // Handle cases where the pop-up is blocked
    alert('Please allow pop-ups for this site.');
  }
}

function refreshPage() {
  window.location.reload(false);
}

export default FileTable;
