import React, { useEffect, useState } from "react";
import {Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";

const EditReportModal = ({ report_id, asset_id, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [anomalyData, setAnomalyData] = useState("");

  useEffect(() => {

    if (show && report_id) {
      axios
        .get("/assets/" + asset_id + "/anomaly_reports/" + report_id)
        .then(function (response) {
          // handle success
          console.log(response.status, "/report");
          setAnomalyData(response.data)
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    } else {
      setAnomalyData()
    }

  }, [show]);

  const [summary, setSummary] = useState("")

  function handleUpdate(e) {

    e.preventDefault();

    axios
      .put(`/assets/${asset_id}/anomaly_reports/${report_id}`, {
        "summary": summary,
      })
      .then(function (response) {
        console.log(response.status, "Update asset");
        // handle success
        handleClose()
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
      });
  }

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>{anomalyData?.title}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={handleUpdate}>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Control
                      rows="30"
                      as="textarea"
                      onChange={e => setSummary(e.target.value)}
                      defaultValue={anomalyData?.summary}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <br />
              <Row>
                <Col xs="auto" className="ms-auto text-end mt-n1">
                  <Button variant="outline-secondary" onClick={handleClose}>
                    {t("Cancel")}
                  </Button>{" "}
                  <Button variant={"primary"} type="submit">
                    {t("Apply")}
                  </Button>
                </Col>
              </Row>
            </Form >
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default EditReportModal;
