import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Dropdown, Row, Spinner, Table } from "react-bootstrap";
import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { ArrowUpRight, MoreHorizontal, RefreshCw } from "react-feather";
import { format, formatDistanceToNow, parseISO } from 'date-fns'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useTranslation } from "react-i18next";
import { de } from "date-fns/locale"
import ErrorHandler from "../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "../Helper/LoadingSpinner";

const GatewayErrorLog = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  let { id } = useParams();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [gatewayData, setGatewayData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    axios
      .get("/iot_gateway_logs/", {
        params: {
          iot_gateway_id: id,
          period_start: new Date(new Date() - 24 * 60 * 60 * 1000 * 7).toISOString(),
          limit: 50
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status);
        setData(response.data.sort((a, b) => (a.time < b.time)))
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setError({ showMessage: true, errorData: error })
      });

    axios
      .get("webservice/iot_gateways/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "/gateways");
        setGatewayData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
        setIsLoading(false);
      });

  }, []);

  // const [GatewayArray, setGatewayArray] = useState([]);

  const renderTable = () => {
    return data.map((entry) => {
      return (
        <tr key={entry.id}>
          <td>{parseISO(entry.time) < (new Date() - 24 * 60 * 60 * 1000) ?
            format(parseISO(entry.time), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null }) :
            formatDistanceToNow(parseISO(entry.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })
          }</td>
          <td>{entry.log_type}</td>
          <td>{entry.value}</td>
          <td>{entry.description}</td>
          {
            entry.description.includes("Trace_ID: ") ?
              <td className="table-action">
                <ArrowUpRight alt="Log" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={() => navigate("/application_logs/?trace_id=" + entry.description.split('Trace_ID: ').pop())} />
              </td> :
              <td></td>
          }
        </tr>
      );
    });
  };

  const ErrorList = () => (
    <Card>
      <Card.Body>
        <Table responsive hover>
          <thead>
            <tr>
              <th scope="col">{t("Time")}</th>
              <th scope="col">{t("Type")}</th>
              <th scope="col">{t("Error")}</th>
              <th scope="col">{t("Description")}</th>
              {/* <th scope="col">{t("Log type")}</th> */}
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody>
        </Table>
      </Card.Body>
    </Card>
  );

  const renderConnectionState = (status) => {

    switch (status) {
      case "connected":
        return (
          <span className="badge bg-success">{t(status)}</span>
        )
      case "connection_lost":
        return (
          <span className="badge bg-warning">{t(status)}</span>
        )
      default:
        return (
          <span className="badge bg-warning">{t("Not connected")}</span>
        )
    }
  }

  const ViewAssetsDropdownItem = () => {

    return gatewayData?.assets?.map((asset) => {
      return (
        <div>
          <Dropdown.Item onClick={() => navigate("/assets/" + asset.id)}>{t("View Asset") + ": " + asset.title}</Dropdown.Item>
          <Dropdown.Item onClick={() => navigate("/assets/" + asset.id + "/?focus=digital-twin")}>{t("View Asset Live Usage") + ": " + asset.title}</Dropdown.Item>
        </div>
      );
    });
  }

  return (
    <React.Fragment>
      <Helmet title="Error Log" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Breadcrumb>
            {/* <div className="vertical-center-all"> */}
            <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
            </Button>
            <Breadcrumb.Item href="/gateways">{t("Gateways")}</Breadcrumb.Item>
            <Breadcrumb.Item href="#" onClick={() => navigate("/gateways/" + id)}>{t("Gateway details")}</Breadcrumb.Item>
            <Breadcrumb.Item active>{t("Error Log")}</Breadcrumb.Item>
            {/* </div> */}
          </Breadcrumb>
          {/* <Col> xs="auto" className="d-none d-sm-block" */}
          <Col>
            <h3>{t("Gateway Error Log")} ({gatewayData?.serial})</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm me-1">
                <MoreHorizontal className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ViewAssetsDropdownItem />
                {/* <Dropdown.Divider />
                <Dropdown.Item onClick={refreshPage}>{t("Refresh")}</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={refreshPage}
              variant="primary" className="shadow-sm">
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>

        {
          isLoading === true ?
            <LoadingSpinner /> :
            <>
              <Row>
                <Col md="6">
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("About")}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table size="sm" className="my-2">
                        <tbody>
                          <tr>
                            <th>{t("Connection")}</th>
                            <td>{gatewayData == null ? "n/a" : renderConnectionState(gatewayData.connection_status)}</td>
                          </tr>
                          <tr>
                            <th>{t("Serial Number")}</th>
                            <td>{gatewayData.serial}</td>
                          </tr>
                          <tr>
                            <th>{t("Asset name (number)")}</th>
                            <td>{gatewayData == null ? "-" : gatewayData?.assets == null ? "--" : gatewayData?.assets?.length === 0 ? "---" : gatewayData?.assets[0]?.title + " (" + gatewayData?.assets[0]?.asset_number + ")"}</td>
                          </tr>
                          <tr>
                            <th>{t("Last production unit")}</th>
                            <td>{gatewayData == null ? "n/a" : gatewayData.last_usage_log == null ? "-" : format(parseISO(gatewayData.last_usage_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })} {gatewayData == null ? "n/a" : gatewayData.last_usage_log == null ? "-" : t(" (Units: ") + gatewayData.last_usage_log.amount + ")"}</td>
                          </tr>
                          <tr>
                            <th>{t("Last activity")}</th>
                            <td>
                              {gatewayData?.last_asset_log?.time
                                ? format(parseISO(gatewayData.last_asset_log.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })
                                : "-"
                              }
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="flex-fill w-100">
                    <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("Connectivity")}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table size="sm" className="my-2">
                        <tbody>
                          <tr>
                            <th>{t("Last handshake")}</th>
                            <td>{gatewayData.last_handshake === undefined || gatewayData.last_handshake === null ? "-" : format(parseISO(gatewayData.last_handshake.time), 'eee, d MMMM HH:mm', { locale: i18n.language === "de" ? de : null })}</td>
                          </tr>
                          <tr>
                            <th>{t("Public IP")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.public_ip == null ? "-" : gatewayData.last_handshake.public_ip}</td>
                          </tr>
                          <tr>
                            <th>{t("Internet speed (Download)")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.internet_speed_down == null ? "-" : gatewayData.last_handshake.internet_speed_down.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: "decimal",
                              maximumFractionDigits: 2
                            }) + " Mbit/s"}</td>
                          </tr>
                          <tr>
                            <th>{t("Internet speed (Upload)")}</th>
                            <td>{gatewayData.last_handshake == null ? "-" : gatewayData.last_handshake.internet_speed_up == null ? "-" : gatewayData.last_handshake.internet_speed_up.toLocaleString(i18n.language === "de" ? de : "en", {
                              style: "decimal",
                              maximumFractionDigits: 2
                            }) + " Mbit/s"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <ErrorList />
                </Col>
              </Row>
            </>
        }
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default GatewayErrorLog;
