import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Search: "Search…",
      "unpaid": "Unpaid",
      "paid": "Paid",
      "overdue": "Overdue",
      "connection_lost": "Disconnected",
      "disconnected": "Disconnected",
      "no_connection": "Not connected",
      "connected": "Connected",
      iot_only: "IoT-Contract only",
      manufacturer: "Manufacturer",
      unpublished: "Unpublished",
      draft: "Draft",
      active: "Active",

      "power": "Power Consumption",
      bill_to_capon: "Bill to cap-on",
      bill_to_investor: "Bill to investor",
      bill_to_manufacturer: "Bill to manufacturer",
      next_service: "Next Service",
      "lte": "4G / LTE",
      "wifi": "Wifi",
      "ethernet": "Ethernet",
      usage_enabled: "Usage enabled",
      created_at: "Created",
      "Log Type": "Technical Details",
      "modbus": "Modbus-TCP",

      billing_unit_type: "Billing unit",

      "public": "Public",
      "all_stakeholders": "All stakeholders",
      "cap_on": "cap-on only",

      "manual": "Manual",
      "in_progress": "In Progress",
      "completed": "Completed",
      "error": "Error",
      "plc_description": "A programmable logic controller (PLC) or programmable controller is an industrial computer that has been ruggedized and adapted for the control of manufacturing processes, such as assembly lines, machines, robotic devices, or any activity that requires high reliability, ease of programming, and process fault diagnosis.",

      "warning": "Warning",
      Notification: "Benachrichtigung",
      "You will be automatically notified in the IoT-Hub.": "Du wirst automatisch im IoT-Hub benachrichtigt.",
      "Notify me via email, too": "Benachrichtige mich auch per Email",
      "critical": "Critical",
      "info": "Info",
      weekend_usage: "Weekend Production",
      power_per_use: "Power consumption per unit",
      calculate_operating_times: "Calculate operating times",
      check_gateway_handshakes: "Check gateway handshakes",
      update_current_asset_values: "Update current asset values",
      alert_overdue_invoices: "Send alert for overdue invoices",
      delete_old_alerts: "Delete old alerts",
      calculate_insights: "Create new Insights",
      create_monthly_invoices: "Create new invoices (monthly)",
      run_watchtower: "Run Watch Tower",
      calculate_state_periods: "Calculate state_periods",
      check_tasks: "Check tasks",
      update_monthly_usages: "Update monthly usages",

      param_type: "SOLL/Ist-Wert",
      actual: "Actual value (PV)",
      target: "Set value (SV)",

      day: "Day",
      month: "Month",
      investor: "Financial Partner",
      client: "Client",

      co2_total: "Total CO\u2082-emissions",
      co2_per_use: "CO\u2082-emissions per unit",

      daily: "Daily usage",
      monthly: "Monthly usage",
      yearly: "Yearly usage",
      single_fixed: "One-time usage",
      tracked: "Primary data acquisition (real-time)",

      opc_ua: "OPC UA",
      http: "HTTP",
      mqtt: "MQTT",

      depreciation_factor: "(incl. depreciation)",
      // Financials
      financials_base_price: "Base Price",
      financials_tier: "cap-on Pricing Tier",
      financials_investment_price: "Investment Price",
      financials_monthly_base_fee: "Monthly Base Fee",
      financials_market_value: "Market Value",
      financials_residual_book_value: "Resdidual Book Value",
      financials_capon_interest_rate: "cap-on Interest Rate",
      financials_capon_rate: "cap-on Rate",
      financials_full_interest_rate: "Total Interest Rate",
      financials_price_factors: "Price Factors",
      financials_refi_interest_rate: "Refi Interest Rate",
      financials_refi_rate: "Refi Rate",
      financials_starting_fee: "Starting Fee",

      residual_book_value: "Resdidual Book Value",
      market_value: "Market Value",

      no_access: "No Permission",
      read_all: "Read Access (read_all)",
      "client_agent": "Client Agent",
      "finance_agent": "Finance Agent",
      "service_agent": "Service Agent",
      "contract_manager": "Contract Manager",

      price_factors: "Dynamic Pay-per-Use prices over time",
      refi_rate: "Refi Rate",
      investment_price: "Investment Price",
      starting_fee: "Starting Fee",
      monthly_base_fee: "Monthly Base Fee",

      plc_node_running: "Plant Running (plc_node_id)",
      plc_node_setup: "Plant Setup (plc_node_id)",
      plc_node_error: "Plant Error (plc_node_id)",
      plc_node_planned_downtimes: "Planned Downtime (plc_node_id)",
      sending_interval_minutes: "Sending Interval Minutes",
      connection_protocol: "Technical Connection Protocol",

      base_rate: "Asset Service Fee (Base Fee)",

      day_of_week: "Day of Week",
      day_of_month: "Day of Month",
      month_of_year: "Month of Year",
      hour: "Hour",
      minute: "Minute",


      "plc_node_id": "OPC UA Label (node_id)",

      core_system_prod: "Core System (Production)",
      core_system_stag: "Core System (Test)",
      pricing_engine_prod: "Pricing Engine (Production)",
      pricing_engine_stag: "Pricing Engine (Test)",
      webapp_prod: "Web App (Production)",
      webapp_stag: "Web App (Test)",
      traefik_prod: "Traefik (Production)",
      traefik_stag: "Traefik (Test)",

      setup_time_percent: "Setup time in %",
      error_time_percent: "Error time in %",
      higher: "is higher than",
      lower: "is lower than",
      equals: "equals",
      unequals: "unquals",

      higher_relative: "exceeds",
      lower_relative: "is lower than",
      equals_relative: "equals",


      one_day_average: "1-day average",
      three_consecutive_days: "3x 1-day-averages in a row",
      one_month_average: "1-month average",
      single_value_last_hour: "Checking values every hour",

      one_day_average_relative: "the 1-day average",
      three_consecutive_days_relative: "the 1-day average for 3 days in a row",
      one_month_average_relative: "the 1-month average",
      single_value_last_hour_relative: "a single value during the last hour",

      running_time: "Production time",
      setup_time: "Setup Time",
      error_time: "Error Time",
      cycle_time: "Cycle Time",
      usage: "Output quantity",
      oee: "Overall Equipment Effectiveness (OEE)",
      connection_health: "Connection Reliability",
      share_of_planned_usage: "Target-performance comparison: Planned usage",
      weekend_units: "Weekend-Production",
      running_time_percent: "Time in Production",
      revenue: "Revenue",
      share_of_planned_revenue: "Target-performance comparison: Planned revenue",
      unit_counter_share: "PLC-Analysis: Counter vs. produced units",
      maximum_capacity_share: "Manufacturer's recommended load factor (wear and tear)",
      cycle_time_outlier_removal: "Cycle Time Outlier",
      wear_and_tear: "Wear and tear",
      MUCH_LOWER_THAN_EXPECTED: "Very low",
      LOWER_THAN_EXPECTED: "Low",
      AS_EXPECTED: "As expected",
      HIGHER_THAN_EXPECTED: "High",
      cycle_time_min: "Cycle Time (min)",
      cycle_time_max: "Cycle Time (max)",
      expected_revenue_without_services: "Total Expected Revenue (excl. additional services)",
      monthly_usage_variability: "Usage Variability",

      "ns=5;s=PlantState.General.PlantRunning": "In Production",
      "ns=5;s=PlantState.General.PlantRunning_description": "The time the asset was actually in use.",

      "ns=5;s=PlantState.General.StopReasons.PreparePlant": "Plant Preparation",
      "ns=5;s=PlantState.General.StopReasons.PreparePlant_description": "The time the asset was being prepared.",

      "ns=5;s=PlantState.General.StopReasons.PlannedStop": "Planned Downtime",
      "ns=5;s=PlantState.General.StopReasons.PlannedStop_description": "The time the asset is not deliberately not in use.",

      "ns=5;s=PlantState.General.Error": "Fault",
      "ns=5;s=PlantState.General.Error_description": "The time the asset had an error (Störung).",

      "ns=6;s=::OpcUaTag:OpcUaTags.Pressure.rInput": "In Use (OEE-Availability)",
      "ns=6;s=::OpcUaTag:OpcUaTags.Pressure.rInput_description": "The time the asset was actually in use.",

      "Idle": "Idle",
      "Idle_description": "The time the asset was idle or inactive.",

      "Offline": "Offline",
      "Offline_description": "The time the asset was offline.",

      "Running (Input Pressure >= 20 bar)": "Running (Input Pressure ≥ 20 bar)",
      "Running (Input Pressure >= 20 bar)_description": "The time the asset was actually in use.",

      "No Connection": "Offline",
      "No Connection_description": "The asset was offline.",

      // support categories
      platform: "Platform (IoT Hub)",
      gateway: "IoT-Gateway",
      access_management: "User Access Management",
      feedback: "General Feedback",
      payment: "Payment Services",
      enhance: "cap-on Enhance",
      other: "Other",

      usage_factor: "Usage factor for billing",

      "Max. operating limit": "Obere Betriebsgrenze",
      "Min. operating limit": "Untere Betriebsgrenze",
      "ads": "TwinCAT (ADS)",
      asset_logs: "General Asset Log (data from sensors, actuator, etc.)",
      usage_cycles: "Usage Cycle Counter (for Pay-per-Use)",
      usage_bool: "Usage (as boolean)",
      opc_connection: "OPC UA Connection",
      // asset_logs_energy: "Asset Energy Log",
      "read": "Constant reading interval",
      "subscribe": "Subscribing to value changes",


      state_running: "State: In Production",
      state_idle: "State: Inactive",
      state_setup: "State: Setup",
      reject: "Reject",
      state_ready: "State: Ready",
      state_maintenance: "State: Setup",
      snapshot: "Snaptshot",
      alert: "PLC-Error and Alert",
      vnc_password: "VNC-Password-Logic",

      running: "In Production",
      setup: "Setup",
      short_error: "Error",
      short_maintenance: "Maintenance",

      state_planned_downtimes: "State: Planned downtime",
      state_error: "State: Error",
      faulty: "Faulty",
      maintenance: "Maintenance",
      production: "Production",
      power_consumption: "Power Consumption (Wh)",
      current_power: "Current Power (W)",
      reject: "Reject",

      "de": "Germany",
      "us": "USA / Canada",
      "eu": "European Union",
      "other_non_eu": "Non-EU",

      "vpn_description": "A VPN between our cloud and an IoT gateway ensures a secure and encrypted connection that protects the data transfer between the IoT device and our cloud."

    },
  },
  de: {
    translation: {
      Search: "Suchen…",
      "Search...": "Suchen...",
      "Searching cap-on...": "cap-on durchsuchen...",
      "What are you looking for?": "Was suchen Sie?",
      "Please enter more characters.": "Bitte mehr eingeben",
      "Welcome back": "Willkommen zurück",
      "Contract": "Vertrag",
      "Yesterday": "Gestern",
      "Contract start": "Vertragsbeginn",
      "Contract end": "Vertragsende",
      "Contract Type": "Vertragstyp",
      "Dashboard": "Dashboard",
      "Clients": "Kunden",
      "Client": "Kunde",
      "Are you sure? This can later only be undone by an administrator.": "Sind Sie sicher? Dies kann später nur durch einen berechtigten Nutzer zurückgesetzt werden.",
      "Operator": "Betreiber",
      "Operators": "Betreiber",
      "Power (last 30 days)": "Stromverbrauch (30 Tage)",
      "Contracts": "Verträge",
      "Assets": "Assets",
      "Asset": "Asset",
      "Gateways": "Gateways",
      "Invoices": "Rechnungen",
      "Manufacturers": "Lieferanten",
      "User Management": "Nutzerverwaltung",
      "Tenant Management": "Tenant Verwaltung",
      "Support": "Support",
      "New": "Anlegen",
      "Investment Volume": "Investitionsvolumen",
      "Unpaid Invoices": "Ausstehende Zahlungen",
      "usages till next service": "Einheiten bis Wartung",
      "Paid Invoices": "Bezahlt ",
      "Overdue Invoices": "Überfällige Rechnungen",
      "Total Revenue": "Einnahmen",

      "de": "Deutschland",
      "us": "USA / Kanada",
      "eu": "Europäische Union",
      "other_non_eu": "Außerhalb der EU",
      "Severity": "Priorität",

      day: "Tag",
      month: "Monat",
      Value: "Wert",
      "Inactive since": "Inaktiv seit",
      "Inactive until": "Inaktiv bis",
      "Activated since": "Aktiviert seit",
      "Activated until": "Aktiviert bis",
      "Activated": "Aktiviert",
      "Amount": "Betrag",
      "lte": "4G / LTE",
      "wifi": "Wifi",
      "ethernet": "Ethernet",
      "Activate / deactivate Gateway": "Gateway aktivieren / deaktivieren",
      "LTE active": "LTE aktiv",
      "Paused": "Pausiert",
      "Wifi Connection": "Wifi Verbindung",
      "Gateway is inactive. No operating costs will be charged.": "Gateway deaktiviert. Es werden keine Betriebskosten in Rechnung gestellt.",
      "LTE-activated": "LTE-aktiviert",
      "Due date": "Fälligkeit",
      "Activate this IoT-Gateway": "Dieses Gateway zum Stichtag aktivieren",
      "Show client": "Kunde aufrufen",
      "Billing date": "Rechnungsdatum",
      "First name": "Vorname",
      "Last name": "Nachname",
      // "Name" : "Vor- und Nachname",
      "Permission Level": "Zugriffsrechte",
      "Email": "Email",
      "Close": "Schließen",
      "Done": "Fertig",
      "The cap-on Watch Tower monitors certain sensor parameters and notifies users once values exceeded individual thresholds.": "Der cap-on Watchtower ist das automatische, im Hintergrund laufende Monitoringsystem für cap-on Assets.",
      "Position": "Position",
      "Permission updated.": "Rechte wurden angepasst",
      "Role": "Rolle",
      "Read Access (read_all)": "Lesezugriff (read_all)",
      "No Permission": "Kein Zugriff",
      "Phone Number": "Telefonnummer",
      "Toggle Light Mode": "Heller Modus",
      "Toggle Dark Mode": "Dunkler Modus",
      "Settings": "Einstellungen",
      "Sign out": "Abmelden",
      "Invoice": "Rechnung",
      "Maintenance Cockpit": "Maintenance Cockpit",
      "Condition Monitoring": "Zustandsüberwachung",
      "Get Coordinates": "Koordinaten einsetzen",
      "Maintenance Log": "Wartungsbuch",
      "Unused data points": "Ungenutzte Datenpunkte",
      "Documents": "Dokumente",
      "Invoices Chart": "Rechnungen (Verlauf)",
      "ABC Contract": "ABC Vertrag",
      "End date": "Enddatum",
      "Start date": "Startdatum",
      "Contract start date": "Vertragsbeginn",
      "You will not be notified any longer.": "Du wirst nicht mehr benachrichtigt.",
      "You will be notified.": "Du wirst benachrichtigt.",
      "Contract end date": "Vertragsende",
      "Average daily data": "Durchschnittliche Datenübermittlung",
      "data points": "Datenpunkte",
      "MB per day": "MB pro Tag",
      "Invoice details": "Details",
      "Asset details": "Details",
      "Add Asset to existing contract": "Asset zu Vertrag hinzufügen",
      "Asset 360° View": "Asset 360° Ansicht",
      "Client details": "Details",
      "Contract details": "Details",
      "Gateway details": "Details",
      "Cloud Connection": "Cloud Verbindung",
      "Quick Actions": "Schnellzugriff",
      "Manufacturer details": "Details",
      manufacturer: "Lieferant",
      "Pricing Engine": "Pricing Engine",
      "Availability": "Verfügbarkeitsfaktor",
      "Availabilities": "Verfügbarkeiten",
      "Quality": "Qualitätsfaktor",
      "OEE-Factors": "OEE-Faktoren",
      "Measures machine uptime versus downtime, taking into account events that stop production for an appreciable length of time.": "Misst die Betriebszeit der Maschine im Vergleich zur Ausfallzeit und berücksichtigt Ereignisse, die die Produktion für eine nennenswerte Zeit stoppen.",
      "Measures the speed at which products are manufactured against the ideal speed.": "Misst die Geschwindigkeit, mit der Produkte im Vergleich zur Idealgeschwindigkeit hergestellt werden.",
      "Measures the quality of the parts produced, considering the number of good parts versus the total parts produced.": "Misst die Qualität der hergestellten Teile, indem die Anzahl der guten Teile im Verhältnis zur Gesamtzahl der produzierten Teile betrachtet wird.",
      "Performance": "Leistungsfaktor",
      "Status": "Status",
      "Title": "Titel",
      "Known emission items": "Bekannte Emissions-Objekte",
      "Available": "Verfügbar",
      "Price per t": "Preis pro t",
      "Price (Total)": "Preis (gesamt)",

      daily: "Tägliche Verwendung",
      monthly: "Monatliche Verwendung",
      yearly: "Jährliche Verwendung",
      single_fixed: "Einmalige Verwendung",
      tracked: "Primärdaten (Echtzeiterfassung)",

      "Amount of CO\u2082": "Zu kompensierene t CO\u2082",
      "Project Availability": "Projektverfügbarkeit",
      "Serial #": "Seriennummer",
      "Fetch": "Laden",
      "Uptime": "Uptime",
      "Uptime at last handshake": "Uptime bei letztem Handshake",
      "About": "Infos",
      "Recommended max. value": "Empfohlener Höchstwert",
      "Recommended min. value": "Empfohlener Mindestwert",
      "Duration": "Laufzeit",
      "Minimum Contract Duration": "Mindestvertragslaufzeit",
      "Name": "Name",
      "number": "Nummer",
      "Clients / Operators": "Kunden / Betreiber",
      "Manufacturer": "Lieferant",
      "Manufacturer number": "Lieferantennummer",
      "New contract": "Vertrag anlegen",
      "Language": "Sprache",
      "User": "Nutzer",
      "API Documentation": "API Dokumentation",
      "No data found.": "Keine Daten gefunden.",
      "Run new query.": "Starte eine neue Suche.",
      "unpaid": "Ausstehend",
      "paid": "Bezahlt",
      "planned": "Geplant",
      "overdue": "Überfällig",
      "Overdue": "Überfällig",
      "Planned": "Geplant",
      "Completed": "Abgeschlossen",
      "German": "Deutsch",
      "Address": "Adresse",
      "Client number": "Kundennummer",
      "Registration Number": "Handelsregisternummer",
      "Invoice Chart": "Rechnungsübersicht",
      "Data View": "Datenaufbereitung",

      "Publish": "Veröffentlichen",
      "Invoice is now visible for the client and was notified via email. You are in CC.": "Die Rechnung ist nun sichtbar für den Kunden und wurde per Email benachrichtgt. Sie sind in CC.",
      unpublished: "Unveröffentlicht",
      "Re-Send Invoice to Client": "Rechnungsemail versenden",
      "Creating invoice...": "Rechnung wird erstellt",
      "Are you sure you want to send the invoice via email to the client's billing email address?": "Sende eine automatisch generierte Email an die hinterlegte Rechnungsemail.",
      "Commissioning date": "Inbetriebnahmedatum",
      "Manufacturing date": "Herstellungsdatum",
      "No email has been sent to client": "Es wurde noch keine Email an den Kunden geschickt",
      "OEE (yesterday)": "OEE (gestern)",
      "AI-based prediction": "KI-basierter Forecast",
      "OEE (Availability, yesterday)": "OEE (Verfügbarkeit, gestern)",
      "More usage details": "Weitere Nutzungszahlen",
      "Usage Details": "Nutzungszahlen",
      "Asset Usage": "Nutzung",
      "Asset Usage (Hourly)": "Nutzung (stündlich)",
      "Asset Usage (Weekly)": "Nutzung (wöchentlich)",
      "Asset Usage (Monthly)": "Nutzung (monatlich)",
      "Asset Usage (Daily)": "Assetnutzung (täglich)",
      "Units • Today": "Einheiten • heute",
      "Costs • today": "Kosten • heute",
      "Update Invoice Number": "Rechnungsnummer bearbeiten",
      "Invoice Number": "Rechnungsnummer",
      "Charges • today": "Kosten • heute",
      "Asset Connection Protocol": "Asset Verbindungsprotokoll",
      "Expenses": "Kosten",
      "Contract number": "Individuelle Vertragsnummer",
      "Contract ID": "Vertrags-ID",
      "Logic": "Logik",
      "Units • Current Month": "Einheiten • akt. Monat",
      "Digital Asset Record": "Digitale Maschinenakte",
      "Next Service": "Nächste Wartung",
      "Add client": "Kunde anlegen",
      "PLC": "SPS",
      "Add investor": "Finanzpartner anlegen",
      "Add manufacturer": "Lieferant anlegen",
      "Watch Tower Rule": "Watchtower-Regel",
      "Add usage": "Nutzung hinzufügen",
      "Add file": "Datei hinzufügen",
      "Files": "Dateien",
      "public": "Öffentlich",
      "all_stakeholders": "Alle Stakeholder",
      "cap_on": "cap-on Intern",
      "Real-time Mode": "Echtzeitdaten",
      "Time Mode": "Zeitlicher Modus",
      "Real-time Production": "Produktion in Echtzeit",
      "More": "Mehr",
      "plc_node_id": "OPC UA Kennung (node_id)",
      "IoT-Gateway": "IoT-Gateway",
      "or log in with": "oder",
      "Model": "Modell",
      "Sign in with Microsoft": "Mit Microsoft anmelden",
      "Model Number": "Modellnummer",
      opc_ua: "OPC UA",
      http: "HTTP",
      mqtt: "MQTT",

      depreciation_factor: "(laut AfA)",

      "Serial Number": "Seriennummer",
      "CO\u2082 Compensation": "CO\u2082 Kompensation",
      "CO\u2082 Compensation  ": "CO\u2082 Kompensation  ",
      "Further cap-on services. The sustainability services are carried out by ": "Weitere Services. Nachhaltigkeitsservices werden durchgeführt von ",
      "Compensate your CO\u2082 emission per produced unit": "Für's Klima: CO\u2082-Emissionen der Einheiten kompensieren",
      "Operating Times": "Betriebszeit",
      "Technical Settings": "Technische Details",
      "cap-on Activation Date (start of billing cycle, SOP, etc.)": "cap-on Aktivierungsdatum (Rechnungsbeginn, SOP, etc.)",
      "Asset Number": "Assetnummer",
      "General information": "Allgemeine Infos zum Asset",
      "Life Bit": "Life Bit",
      "Asset State Overview": "Überblick Anlagenstatus",
      "Alarm Pending": "Alarm ausstehend",
      "Static Pay-per-Use Price (Tier 3 only)": "Statischer Preis pro Einheit (Pay-per-Use) für Tier 3",
      "Pay-per-Use Price": "Pay-per-Use Preis",
      "Location": "Ort",

      "Current asset value": "Aktueller Asset-Wert",

      refi_rate: "Monatliche Refi-Kosten",
      investment_price: "Invesitionssumme",
      starting_fee: "Einmalige Startgebühr",
      monthly_base_fee: "Monatliche Grundrate",

      "Contract financials": "Finanzielle Vertragsdetails",
      "Model Type": "Modell",
      "Move asset": "Asset bewegen",
      "Start time": "Startzeitpunkt",
      "Set values (SV)": "Sollwertvorgaben",
      "Move": "Bewegen",
      "Pre-fill Last Month": "Ausfüllen: Letzter Monat",
      "Pre-fill Current Month": "Ausfüllen: Aktueller Monat",
      "Send invoice notification to recipient": "Benachrichtigung an Rechnungsempfänger",
      "Associated Asset": "Verbundenes Asset",
      "Gateway Serial": "Seriennummer",
      "Internet Connection": "Internet Verbindung",
      "Connected": "Verbunden",
      "Serial No.": "Seriennummer",
      "Not connected": "Nicht verbunden",
      "Disconnected": "Nicht verbunden",
      "Last handshake": "Letzter Handshake",
      "Contract number": "Vertragsnummer",
      "Description": "Beschreibung",
      "Starting date": "Vertragsbeginn",
      "Internet speed (Upload)": "Internet Upload",
      "Internet speed (Download)": "Internet Download",
      "RAM Usage": "RAM Nutzung",
      "Disk Usage": "Speichernutzung",
      "CPU Usage": "CPU Nutzung",
      "CPU Temperature": "CPU Temperatur",
      "View Asset": "Asset anzeigen",
      "View Gateway": "Gateway anzeigen",
      "View Asset Live Usage": "Asset Echtzeitnutzung anzeigen",

      running: "In Produktion",
      setup: "Rüstvorgang",
      short_error: "In Störung",
      short_maintenance: "In Wartung",

      "From": "Ab",
      price_factors: "Dynamische Pay-per-Use Preise über Laufzeit",

      "Remote Shell Command": "Remote Shell Befehl",
      "Remote Configuration": "Remote Konfiguration",
      "Sensors": "Sensoren",
      "Inlet Data": "Eingang",
      "Outlet Data": "Ausgang",
      "Processing (4 filters)": "Prozess (4 Filter)",
      "Torque": "Drehmoment",
      "Speed": "Geschwindigkeit",
      "Heating": "Heizzone",
      "Pressure": "Druck",
      "Temperature": "Temperatur",
      "Cylinder 1": "Trommel 1",
      "Cylinder 2": "Trommel 2",
      "Cylinder 3": "Trommel 3",
      "Cylinder 4": "Trommel 4",
      "Screw 1": "Schnecke 1",
      "Screw 2": "Schnecke 2",
      "Screw 3": "Schnecke 3",
      "Screw 4": "Schnecke 4",
      "Heat On": "Heizung",

      "Asset Live View": "Echtzeitnutzung",
      "View Client": "Kunde anzeigen",
      "View Contract": "Vertrag anzeigen",
      "Internal IP": "Interne IP",
      "Last seen": "Letzter Kontakt",
      "Public IP": "Öffentliche IP-Adresse",
      "Asset name (number)": "Asset Titel (Nummer)",
      "Asset name": "Asset Titel",
      "No. of Assets": "Anzahl der Assets",
      "No. of Invoices": "No. of Invoices",
      "No. of Contracts": "Anzahl der Verträge",
      "Invoice #": "ReNr.",
      "until": "bis",
      "Invoice date": "Rechnungsdatum",
      "Invoice Period": "Rechnungszeitraum",
      "Invoice No.": "Rechnungsnummer",
      "Live-Production": "Live-Produktion",
      "Contract No.": "Vertragsnummer",
      "Usage period": "Nutzungsdauer",
      "Quantity": "Menge",
      "Website": "Webseite",
      "Data Insights": "KPIs & Insights",
      "VAT": "MwSt.",
      "At": "Um",
      "starting": "ab",
      "for": "für",
      "Total": "Zahlungsbetrag",
      "Overall": "Insgesamt",
      "Subtotal": "Zwischensumme",
      "Current Value": "Aktueller Wert",
      "Submit": "Absenden",
      "State in %": "Status in %",
      "Billing Logic": "Abrechnungsmethodik",
      "No current violations detected.": "Keine aktuellen Verstöße gefunden",
      "Print invoice": "Rechnung drucken",
      "Financials": "Finanzübersicht",
      "Asset fees": "Asset Gebühren",
      "Generating updated invoice...": "Generiere neue Rechnung...",
      "Additional fees": "Zusätzliche Gebühren",
      "Contract Number": "Vertragsnummer",
      "Dear Sir or Madam,": "Sehr geehrte Damen und Herren,",
      "Mark as \"Paid\"": "Als \"bezahlt\" markieren",
      "Update Payment Status": "Zahlungsstatus bearbeiten",
      usage_factor: "Nutzungsfaktor für Rechnungserstellung",
      "Billing Email Adress": "Rechnungsemailadresse",
      "Number of units": "Nutzungseinheiten",
      "Payment Date": "Zahlungsdatum",
      "This is the monthly receipt regarding the aforementioned contract number. Please settle the amount until the given due date.": "dies ist die monatliche Rechnung für o.a. Vertragsnummer. Wir bitten Sie den Betrag fristgerecht zu überweisen.",
      "Per-Use-Price": "Pay-per-Use Preis",
      "Current month": "In diesem Monat",
      "Show configuration file": "Konfigurationsdatei anzeigen",
      "Check PLC connection": "SPS-Verbindung prüfen",
      "months": "Monate",
      "Query": "Laden",
      "Show NR Dashboard": "NR Dashboard aufrufen",
      "Gateway Interaction": "Gateway Interaktionen",
      "Months": "Monate",

      "Check Git Branch": "Git Branch checken",
      "Browse OPC UA Server": "OPC UA Server \"browsen\"",
      "Amortization": "Amortisation",
      "New Asset Request": "Neue Asset-Anfrage",
      "Admin Panel": "Administrator",
      "Asset Management": "Asset-Verwaltung",
      "is": "ist",
      "Customer Management": "Kundenmanagement",
      "Asset-as-a-Service Ecosystem": "Asset-as-a-Service Ökosystem",
      "Home": "Home",
      "File type": "Format",
      "Remove asset": "Asset löschen",
      "Remove Gateway": "Gateway löschen",
      "Remove contract": "Vertrag löschen",
      "Remove client": "Kunde löschen",
      "Remove investor": "Finanzpartner löschen",
      "Remove manufacturer": "Lieferant löschen",
      "Refresh": "Neu laden",
      "Not included": "Nicht gewünscht",
      "Included": "Inkludiert",
      "Edit asset": "Asset bearbeiten",
      "Edit user": "Nutzer bearbeiten",
      "Edit contract": "Vertrag bearbeiten",
      "Edit manufacturer": "Lieferant bearbeiten",
      "Edit investor": "Finanzpartner bearbeiten",
      "Edit client": "Kunde bearbeiten",
      "Entries": "Einträge",
      "created_at": "Erstellt",
      "part_type": "Typ",
      "interval_start": "Interval Start",
      "status": "Status",
      "All time": "Gesamter Zeitraum",
      "amount": "Quanität",
      "Edit gateway": "Gateway bearbeiten",
      "Asset Request": "Asset-Anfrage",
      "SPS Analysis (1 week)": "SPS-Analyse (1 Woche)",
      " logs missing": " Logs fehlen im Verhältnis zum Zählerstand",
      "Result": "Ergebnis",
      "Create": "Anlegen",
      // "Planned Production" : "Abrufkurve",
      "Asset Price": "Preis des Assets",
      "Duration in months": "Laufzeit in Monaten",
      "Category": "Kategorie",
      "Enter a name for your asset.": "Name des Assets eingeben",
      "Interest rate": "Zinssatz",
      "Create client": "Kunde anlegen",
      "Create contract": "Vertrag anlegen",
      "Create asset": "Asset anlegen",
      "Create Invoice": "Rechnung anlegen",
      "Create Manufacturer": "Lieferant anlegen",
      "Screw": "Schraube",
      "Current state": "Aktueller Betrieb",
      "was": "war bis zu",
      "Violated": "Verstoß",
      "Rules": "Regeln",
      "Unit recently produced": "Kürzliche Stückzahlproduktion",
      "Last production unit: >24h": "Stückzahlproduktion: >24h",
      "Last activity": "Letzte Aktivität",
      "For the (de-)activation of a gateway, a processing fee of 25 Euro is due.": "Für die (De-)Aktivierung eines Gateways wird eine Bearbeitungsgebühr in Höhe von 25 Euro fällig.",
      "Create user": "Nutzer anlegen",
      "This indicates whether a gateway is activated, i.e. maintained by cap-on, or whether it is inactive, i.e. no costs are incurred.": "Hier wird angegeben, ob ein Gateway aktiviert ist, d.h. durch cap-on aufrechterhalten wird, oder ob es inaktiv ist und keine Kosten anfallen.",
      "Create Gateway": "Gateway anlegen",
      "Cancel": "Abbrechen",
      "Copy": "Kopieren",
      "Data Room": "Datenraum",
      "Access level": "Zugriff",
      "Copied!": "Kopiert!",
      "Tax Identification Number (TIN)": "St.-Nr.",
      "TIN": "St.-Nr.",
      "VAT-ID": "USt.-Id.",

      "Upload file": "Datei hochladen",
      "Avatar file": "Avatar hochladen",
      "Contact email": "Kontakt-Email",
      "Bank Name": "Bankname",
      "-- Select --": "-- Auswählen --",
      "Published Invoices": "Unveröffentlichte Rechnungen",
      "Show NR": "NR öffnen",
      "cap-on User Role": "cap-on Nutzerrolle",
      "-- Select (optionally) --": "-- Optional auswählen --",
      "Include last day": "Einschließlich letzter Tag",
      "Period start date": "Anfang",
      "Period end date": "Ende",
      "Period": "Zeitspanne",
      "Analyses": "Analysen",
      "Clustering Unit": "Zeit-Cluster",
      "Time interval": "Zeitintervall",
      "Inlet / Outlet Pressure Diff.": "Vergleich Druck: Eingang/Ausgang",
      "Inlet / Outlet Temperature Diff.": "Vergleich Temperatur: Eingang/Ausgang",
      "Filter Speed Comparison": "Vergleich Geschwindigkeit: 4x Filter",
      "Filter Torque Comparison": "Vergleich Drehmoment: 4x Filter",
      "Screw Speed Comparison": "Vergleich Geschwindigkeit: 4x Schnecken",
      "Screw Torque Comparison": "Vergleich Drehmoment: 4x Schnecken",
      "Unit/Interval": "Einheit/Intervall",
      "Functionality": "Funktionsweise",
      "Heating Zones": "Heizzonen",
      "Other parameters": "Sonstige Parameter",
      "Invoice Period Start": "Rechnungszeitraum von",
      "Invoice Period End": "Rechnungszeitraum bis",
      "Start Date": "Startdatum",
      "Gateway-ID copied!": "Gateway ID kopiert",
      "End Date": "Enddatum",
      "Pressure Delta (In/Out Δ)": "Druck Delta (In/Out Δ)",
      "Temperature Delta (In/Out Δ)": "Temperatur Delta (In/Out Δ)",
      "Simply enter the billing period, a due date and the corresponding contract in order to create a new invoice.": "Wähle den Rechnungszeitraum und den jeweiligen Vertrag, um eine Rechnung anzulegen.",
      "New item \"": "Neuer Eintrag \"",
      "\" has been added.": "\" wurde hinzugefügt.",
      "Please enter the fields correctly. Just re-enter the content and try again.": "Bitte Daten korrekt in die Felder eingeben. Manchmal hilft es, die Felder erneut auszufüllen.",
      "Save changes": "Änderungen speichern",
      "Investors": "Finanzpartner",
      investor: "Finanzpartner",
      client: "Kunde",
      "Paid": "Bezahlt",
      "Invoice Flow": "Rechnungs-Flow",
      "Mark as \"Paid\" or \"Unpaid\"": "Als bezahlt/unbezahlt markieren",
      "Unpaid": "Nicht bezahlt",
      "State": "Status",
      "Current Asset State": "Aktueller Status",
      "Production Data": "Produktionsdaten",
      "Info Lights": "Status Ampel",
      "Energy Data": "Stromdaten",

      "warning": "Warnung",
      "critical": "Kritisch",
      "Critical": "Kritisch",
      "info": "Info",

      "VPN Connection": "VPN Verbindung",
      "Create investor": "Finanzpartner anlegen",
      "Invoice Recipient": "Rechnungsempfänger",
      "Recipient": "Rechnungsempfänger",
      "Create manufacturer": "Lieferant anlegen",
      "Account": "Konto",
      "The next fully automated, usage-based invoice creation is scheduled for": "Die nächste vollautomatisierte, nutzungsbasierte Rechnung wird erstellt am",
      "Weather condition": "Wetterbedingungen",
      "Password": "Passwort",
      "Upload": "Hochladen",
      "Update User": "Aktualisieren",
      "Edit User": "Nutzer aktualisieren",
      "Total Claims": "Gesamtbetrag",
      "Monthly Planned Production": "Monatlicher SOLL-Abruf",
      "250 units/month": "250 Einheiten/Monat",
      "Watch Towers: Intelligent data monitoring": "Watchtower: Intelligentes Datenmonitoring",
      "Base rate": "Asset Service Fee (Grundrate)",
      "Asset Usage Fee": "Asset Usage Fee (Nutzungsgebühr)",
      "base_rate": "Asset Service Fee (Grundgebühr)",
      "Base rate (in %)": "Grundrate (in %)",
      "monthly_base_fee": "Monatliche Grundrate",
      "Initial Unit Price (Pay-per-Use)": "Initialer Stückpreis (Pay-per-Use)",
      "Unit Price (Pay-per-Use)": "Basisstückpreis (Pay-per-Use)",
      "Unit Prices (Pay-per-Use)": "Stückpreise (Pay-per-Use)",
      "Estimated profit": "Berechnete Marge",
      "Help": "Hilfe",
      "years": "Jahre",
      "Interest rate (Refinancing)": "Zinssatz (ReFi-Partner)",
      "Interest rate (cap-on)": "Zinssatz (cap-on)",
      "Interest rate (Intermediate)": "Zinssatz (Intermediär)",
      "Specification": "Leistungsumfang",
      "Interest share (cap-on)": "Zinsanteil (cap-on von Intermediär)",
      "Monthly Additional Costs": "Monatliche Zusatzkosten (Plattform, etc.)",
      "Simulated production percentage": "Simulierte, monatliche IST-Produktion",
      "Total Production Tithis monthmes": "Produktionszeit",
      "Total Set-up Times": "Rüstzeit",
      "Net Asset Value": "Buchwert",
      "Plan": "Tarif",
      "this month": "diesen Monat",
      "power": "Stromverbrauch",
      "units": "Einheiten",
      "kg CO\u2082 per Wh": "kg CO\u2082 pro Wh",
      "Approximate CO\u2082e per": "Ungefähre CO2\u2082e pro",
      "kg CO\u2082e per unit": "kg CO\u2082e pro Einheit",
      "unit": "Einheit",
      "Add Emission": "Emission anlegen",
      "Unit": "Einheit",
      "VPN port 4840 forwarding": "VPN Port 4840 Forwarding",
      "Emission Data Breakdown": "Emissionsanalyse",
      "Last 30 days (ø)": "Letzte 30 Tage (ø)",
      "Asset Footprint Reporting (PCAF)": "Asset Footprint Reporting (PCAF)",
      "Asset Emissions": "Asset-Emissionen",
      "Update": "Laden",
      "We found the following categories and OPC UA data points in the connected PLC.": "Wir haben die folgenden Kategorien bei einem Suchlauf (OPC UA Browsing) gefunden.",
      "Target date": "Stichtag",
      "Filter models": "Modelle filtern",
      "Live data is not currently transmitted from this asset.": "Es werden derzeit keine Live-Daten vom Asset übertragen.",
      "Browsing (Light)": "Browsing (light)",
      "Currently Connected Assets": "Aktuell verbundene Assets",
      "Total Active Assets": "Aktivierte Assets",
      "Browsing (Full)": "Browsing (erweitert)",
      "Contract Status": "Vertragsstatus",
      "Not activated": "Nicht aktiviert",
      "Gateway activated with": "Gateway akiviert mit",
      "New gateway state": "Gewünschter Status",
      "Cellular Data / 4G / LTE": "Mobilfunk (4G / 5G / LTE)",
      "Ethernet cable": "Ethernet-Verbindung",
      "Region for cellular data": "Region für Mobilfunkanpassung",
      "Last update": "Letztes Update",
      "Same day last week": "Vor einer Woche",
      "Last month": "Letzter Monat",
      "Occurrence": "Auftreten",
      "Last weekend": "Letztes Wochenende",
      "Gateway / Asset / User Combination": "Gateway / Asset / User Kombination",
      "Not planned": "Nicht geplant",
      "Value after depreciation": "Restbuchwert laut AfA",
      "Save": "Speichern",
      "connection_lost": "Keine Verbindung",
      "connected": "Verbunden",
      "disconnected": "Unterbrochen",
      "no_connection": "Nicht verbunden",
      "Connection": "Verbindung",
      "More info": "Weitere Infos",
      "Units": "Einheiten",
      "Actual Production": "IST-Produktion",
      "Actual value": "IST-Wert",
      "Planned Production": "SOLL-Produktion",
      "Item \"": "Eintrag \"",
      "Estimated": "Schätzung",
      "Mark invoices as paid": "Markiere alle Rechnungen als gezahlt",
      "\" updated.": "\" aktualisiert.",
      "Anomaly Reports": "Anomalie-Reports",
      "Update Planned Production": "Abrufkurve aktualisieren",
      "Electricity Costs": "Stromkosten",
      "Update Asset Details": "Infos aktualisieren",
      "(current hour)": "(diese Stunde)",
      "Update Client Details": "Infos aktualisieren",
      "Update Contract Details": "Infos aktualisieren",
      "No production data available.": "Noch keine Produktionszahlen verfügbar.",
      "Edit planned production": "Abrufkurve bearbeiten",
      "Number": "Nummer",
      "Enabled": "Aktiviert",
      "Day": "Tag",
      "Month": "Monat",
      "Year": "Jahr",
      "Planned production updated.": "Abrufkurve aktualisiert",
      "Progress": "Fortschritt",
      "Revenue • Last 12 months": "Einnahmen • Letzte 12 Monate",
      "Pending or Overdue Invoices": "Ausstehende Rechnungen",
      "Since last year": "Seit letztem Jahr",
      "Total Asset Value": "Wert aller finanzierten Assets",
      "Total Invest. Volume": "Gesamt Investitionsvolumen",
      "Configuration": "Konfiguration",
      "Investment Volume • Current Year": "Investition • akt. Jahr",
      "Revenue • Current Year": "Einnahmen • akt. Jahr",
      "7 day usage": "7 Tage Nutzung",
      "Compare Metrics": "Vergleichsmodus",
      "Select Metric": "Metrik auswählen",
      "Handshakes": "Handshakes",
      "handshakes": "Handshakes",
      "Loading data": "Daten laden",
      "Automatic": "Automatisch",
      "Digital Twin": "Digitaler Zwilling",
      "Language updated": "Sprache aktualisiert",
      "Time zone": "Zeitzone",
      "Your time": "Deine Zeitzone",
      "Asset time": "Zeitzone beim Asset",
      "Contract Management": "Vertragsmanagement",
      "Today": "Heute",
      "A \"digital twin\" is a digital representation of a physical object, system or process. This digital model reflects the real-time data, properties, states and behavior of its physical counterpart. By linking it with sensor data, digital twins can be used to monitor, simulate, optimize or predict the behaviour of machines, buildings or entire cities, for example. Digital twins play a particularly important role in Industry 4.0, the Internet of Things (IoT) and building technology.": "Ein \"Digital Twin\" (digitaler Zwilling) bezeichnet eine digitale Abbildung eines physischen Objekts, Systems oder Prozesses. Dieses digitale Modell spiegelt die Echtzeitdaten, Eigenschaften, Zustände und das Verhalten seines physischen Pendants wider. Durch die Verknüpfung mit Sensordaten können Digital Twins beispielsweise zur Überwachung, Simulation, Optimierung oder Vorhersage des Verhaltens von Maschinen, Gebäuden oder ganzen Städten verwendet werden. Digital Twins spielen insbesondere in der Industrie 4.0, im Internet der Dinge (IoT) und in der Gebäudetechnik eine wichtige Rolle.",
      "User details": "Nutzerdetails",
      "Last login": "Letzter Login",
      "Recently active": "Kürzlich aktiv",
      "Production": "Produktion",
      "AI-based Monitoring": "KI-basiertes Monitoring",
      "Calculate PCF": "PCF berechnen",
      "Yes": "Ja",
      "No": "Nein",
      "Additional Services": "Zusätzliche Services",
      "Date": "Datum",
      "Reload handshakes": "Handshakes neu laden",
      "Asset Requests": "Asset-Anfragen",
      "Pay-per-Use Offer Details": "Pay-per-Use Angebot",
      "Internal Calculation": "Interne Sicht (Simulation)",
      "From": "Ab",
      "New customer": "Kunde anlegen",
      "Accounting standard": "Bilanzierung",
      "Add OEE data": "OEE-Daten hinzufügen",
      "Overall Equipment Effectiveness (OEE)": "Anlageneffektivität (GAE/OEE)",
      "Overall Equipment Effectiveness (OEE) | History": "Overall Equipment Effectiveness (OEE) Verlauf",
      "We are currently working on the system. Please try again in a few minutes.": "Wir arbeiten momentan an dem System. Bitte probieren Sie es in wenigen Minuten erneut.",
      "Last production unit": "Letzte Nutzungseinheit",
      "Last produced unit": "Zuletzt produzierte Einheit",
      "History": "Verlauf",
      "Operational Log": "Betriebslog",
      next_service: "Nächste Wartung",
      "Formatted": "Formatiertes JSON",
      "Warning": "Warnung",
      param_type: "SOLL/Ist-Wert",
      actual: "IST-Wert (Prozess Wert, PV)",
      target: "SOLL-Wert (Sollwertvorgabe, SV)",
      "Digital Twin Designer (Beta)": "Digitaler Zwilling Designer (Beta)",
      "Digital Twin Designer (Legacy)": "Digitaler Zwilling Designer (Legacy)",
      "Raw": "Roh-JSON",
      "Tile": "Kachel",
      "View chart": "Chart einblenden",
      "Anomaly Report-Instructions": "Anomalie Report-Anweisung",
      "Update rule": "Betriebsregel anpassen",
      "Recent activity": "Kürzliche SPS-Aktivität",
      "No recent activity": "Keine kürzliche SPS-Aktivität",
      "Last usage amount": "Letzte Nutzung (Anzahl)",
      "All used": "Alle verwendet",
      "Last missing handshake": "Letzter fehlender Handshake",
      "Drop-out span": "Dauer nach Signalausfall",
      "Terms of Service": "AGB",
      " Reloading every": " Aktualisiert alle",
      "PLC-Connection": "SPS-Verbindung",
      "Last 30 minutes": "Letzte 30 Minuten",
      "Currently no production.": "Aktuell keine Produktion.",
      "Usage": "Nutzung",
      "PLC Connection": "SPS-Verbindung",
      "Time": "Zeit",
      "Realtime Monitoring": "Echtzeit-Monitoring",
      "Realtime": "Echtzeit",
      "Sign In": "Anmelden",
      "Sign in": "Anmelden",
      "Must be a valid email": "Bitte eine gültige Emailadresse angeben",
      "Email is required": "Email eingeben",
      "Password is required": "Passwort eingeben",
      "Usage counter": "Zählerstand",
      "Value added percentage": "Werschöpfungsquote",
      "Automatic step %": "Automatisierungsquote",
      "Part Type": "Bauteilkennung",
      "Total asset counter": "Maschinenzählerstand",
      "Last produced unit": "Letzte produzierte Einheit",
      "Power Consumption / Meter": "Stromzählerstand",
      "Overview": "Überblick",
      "PoDV and Pelletizer": "Anfahrweiche und Granulierung",
      "Start Live Mode": "Live-Modus starten",
      "Die Plate": "Lochplatte",
      iot_only: "IoT-Vertrag",
      bill_to_capon: "Rechnung an cap-on",
      bill_to_investor: "Rechnung an Investor",
      bill_to_manufacturer: "Rechnung an Manufacturer",

      "Next": "Nächste Seite",
      "Previous": "Vorherige Seite",
      "Pelletizer": "Granulierung",
      "PoDV": "Anfahrweiche",
      "Position Piston 01": "Bolzenposition 1",
      "Position Piston 02": "Bolzenposition 2",
      "Stop Live Mode": "Live-Modus stoppen",
      "Melt Pump": "Schmelzepumpe",
      "Pump": "Schmelzepumpe",
      "Screen Changer": "Siebwechsler",
      "Water System": "Wassersystem",
      "ESG-Data": "ESG-Daten",
      "Copy shell command": "shell Befehl kopieren",
      "shell command copied!": "shell Befehl kopiert!",
      "Usage last 30 minutes": "Nutzung der letzten 30 Minuten",
      "Earnings last 30 minutes": "Einnahmen der letzten 30 Minuten",
      "Updated": "Aktualisiert",
      "Updated password successfully": "Passwort erfolgreich aktualisiert",
      "Updated avatar successfully": "Avatar erfolgreich aktualisiert",
      "Password reset": "Neues Passwort",
      "Show Chart": "Graph anzeigen",
      "Gateway-PLC Connection": "Gateway-SPS Verbindung",
      "Enter a new password": "Neues Passwort eingeben",
      "New Password": "Neues Passwort",
      "Asset-Connection": "Asset-Verbindung",
      "Recent Production": "Kürzlich in Produktion",
      "Used recently": "Kürzlich aktiv",
      "Not used recently": "Letzte Aktivität: > 24h",
      "Confirm New Password": "Passwort bestätigten",
      "Images": "Bilder",
      "Add Image": "Bild hochladen",
      "File uploaded": "Datei hochgeladen",
      "File": "Datei",
      "Image": "Bild",
      "Back": "Zurück",
      "View Public Gateway Details": "Öffentliche Gatewaydetails anzeigen",
      "• You're a user since ": "• Du bist Nutzer:in seit ",
      "User since": "Nutzer:in seit",
      "since": "seit",
      "Edit Contract Permissions": "Vertragsrechte bearbeiten",
      "New Alerts": "Neue Benachrichtigungen",
      "Show all": "Alle anzeigen",
      "Active Power": "Wirkleistung",
      "Alerts": "Benachrichtigungen",
      "Source": "Quelle",
      "Message": "Nachricht",
      "Difference": "Differenz",
      "Amount used": "Verwendete Menge",
      // "Period Type" : "",
      "Read": "Gelesen",
      billing_unit_type: "Abrechnungseinheit",
      "Unread": "Ungelesen",
      "Your clients": "Deine Kunden",
      "Your company": "Dein Unternehmen",
      "Earnings": "Einnahmen",
      "Select": "Wählen",
      "Status Lights": "Status-Ampel",
      "Add a New contract": "Neuen Vertag hinzufügen",
      "Add user": "Nutzer hinzufügen",
      "Edit User Permission": "Account Manager bearbeiten",
      "Request": "Anfrage",
      "Draft": "Entwurf",
      draft: "Entwurf",
      active: "Aktiv",
      "Active": "Aktiv",
      "Inactive": "Inaktiv",
      "Activate": "Aktivieren",
      "Pause": "Pausieren",

      "Germany": "Deutschland",
      "USA / Canada": "USA / Kanada",
      "European Union": "Europäische Union",
      "Non-EU": "Außerhalb der EU",

      "Edit": "Bearbeiten",
      "Account Manager": "Kundenbetreuer",
      "Approved": "Genehmigt",
      "Company Ltd.": "Muster AG",
      "English": "Englisch",
      // "Germany": "Deutsch",
      "General": "Allgemein",

      "I have read and agree to the ": "Hiermit bestätige ich folgendes gelesen und akzeptiert zu haben: ",
      "Privacy Policy": "Datenschutzerklärung",
      " and the ": " und der ",
      "Terms and Conditions": "AGBs",
      "Every n month": "Jeden n'ten Monat",
      "Start fullscreen": "Vollbildmodus",
      "Fullscreen": "Vollbildmodus",
      "Stop fullscreen": "Vollbild beenden",
      "Live-View": "Live-Ansicht",
      "Interval of Price Adjustment": "Intervall der Preisanpassung (Monat)",
      "Edit Watch Tower": "Watchtower bearbeiten",
      "Watch Tower updated": "Watchtower aktualisiert",
      "Daily Units (last 30 days)": "Tägliche Produktion (letzte 30 Tage)",
      "Users": "Nutzer",
      "CO\u2082 emissions over time": "CO\u2082 Emissionen über Laufzeit",
      "Cloud-Connectivity": "Cloud-Verbindung",
      "Microsoft Teams Channel": "Microsoft Teams Kanal",
      "Channel ID": "Kanal ID",
      "Usage span": "Nutzungsdauer",
      "Total contract span": "Gesamte Nutzungsdauer",
      // Pricing Engine
      "Asset Parameters": "Asset-Parameter",
      "Pay-per-Use Price Factors": "Pay-per-Use Preisfaktor",
      "Percent produced": "Produzierte Anzahl des SOLL",
      "Price factors": "Preisanpassungsfaktor",
      "More cap-on Services": "Weitere cap-on Services",
      "Internal View": "Interne Sicht",
      "Running Pricing Engine...": "Pricing Engine...",
      "": "",
      "Asset usage details": "Nutzungsdetails",

      "No Connection": "Offline",
      "No Connection_description": "Das Gerät war offline.",

      "ns=5;s=PlantState.General.PlantRunning": "In Produktion",
      "ns=5;s=PlantState.General.PlantRunning_description": "Die Zeit, in der die Maschine tatsächlich in Verwendung war.",

      "ns=5;s=PlantState.General.StopReasons.PreparePlant": "Rüstzeit",
      "ns=5;s=PlantState.General.StopReasons.PreparePlant_description": "Die Zeit, in der die Maschine vorbereitet wurde.",

      "ns=5;s=PlantState.General.StopReasons.PlannedStop": "Geplante Downtime",
      "ns=5;s=PlantState.General.StopReasons.PlannedStop_description": "Die Zeit, in der die Maschine geplant nicht produziert.",


      "ns=5;s=PlantState.General.Error": "Störung",
      "ns=5;s=PlantState.General.Error_description": "Die Zeit, in der eine Störung vorlag.",

      "ns=6;s=::OpcUaTag:OpcUaTags.Pressure.rInput": "In Verwendung (OEE-Verfügbarkeit)",
      "ns=6;s=::OpcUaTag:OpcUaTags.Pressure.rInput_description": "Die Zeit, in der die Maschine tatsächlich in Verwendung war.",

      "Idle": "Inaktiv",
      "Idle_description": "Die Zeit, die das Asset ungenutzt blieb.",

      "Offline": "Offline",
      "Offline_description": "Die Zeit, die das Asset offline war.",

      "Running (Input Pressure >= 20 bar)": "Aktiv (Eingangsdruck ≥ 20 bar)",
      "Running (Input Pressure >= 20 bar)_description": "The time the asset was actually in use.",

      "All": "Alle",
      "Error": "Störung",
      "File title": "Dateiname",
      "Save PDF": "Als PDF sichern",
      "Save CSV": "Als CSV sichern",
      "No data for this time frame": "Keine Daten transferiert für diesen Zeitabschnitt",
      "Machine fault": "Störung",
      "State in Seconds": "Status in Sekunden",

      // Digitale Maschinenakte
      "Wear": "Verschleiß",
      "Healthy": "In Ordnung",
      "PLC Connected": "SPS verbunden",
      "Currently running": "Aktuell in Benutzung",
      "Faulty": "Störung",
      "Financial": "Finanzielle KPIs",
      "Technical": "Technische KPIs",
      "Show": "Einblenden",
      "Watch Towers": "Watchtower",
      "OPC Connection State": "OPC UA Verbindung",
      "Currently under maintenance": "In Wartungsarbeiten",
      "Connection Type": "Verbindungsart",
      "Update location": "IP-Ortung aktualisieren",
      "Asset Connectivity via cap-on IoT-Gateway": "Asset-Verbindung via cap-on IoT-Gateway",
      "Is waiting for parts": "Wartet auf Material",
      "Unhealthy due to": "Zustand \"bitte prüfen\", weil:",
      // "Please check": "Inaktiv",
      "Asset Condition": "Zustand",
      "Condition Monitor": "Zustandsmontitor",
      "Reliability (7 days)": "Zuverlässigkeit (7 Tage)",

      "Remote-HMI Access": "HMI-Fernzugriff",
      // Delete messages
      "Are you sure you wish to log out?": "Sicher, dass du dich abmelden möchtest?",
      "Are you sure you wish to mark this invoice as paid?": "Sicher, dass du die Rechnung als bezahlt markieren möchtest?",
      "Are you sure you wish to delete this contract?": "Sicher, dass du diesen Vertrag löschen möchtest?",
      "Are you sure you wish to delete this asset?": "Sicher, dass du dieses Asset löschen möchtest?",
      "Are you sure you wish to delete this invoice?": "Sicher, dass du diese Rechnung löschen möchtest?",
      "Are you sure you wish to delete this user?": "Sicher, dass du diesen Nutzer löschen möchtest?",
      "Are you sure you wish to delete this client?": "Sicher, dass du diesen Kunden löschen möchtest?",
      "Are you sure you wish to delete this manufacturer?": "Sicher, dass du diesen Lieferanten löschen möchtest?",
      "Are you sure you wish to delete this gateway?": "Sicher, dass du dieses IoT-Gateway löschen möchtest?",
      "Are you sure you wish to delete this alert?": "Sicher, dass du diese Benachrichtigung löschen möchtest?",
      "Are you sure you wish to delete this file?": "Sicher, dass du diese Datei löschen möchtest?",
      "Are you sure you wish to delete this service?": "Sicher, dass du diesen Service löschen möchtest?",
      "Are you sure you wish to delete this Watch Tower?": "Sicher, dass du diesen Watchtower löschen möchtest?",

      "• Environment: ": "• Umgebung: ",
      "Connected Gateways": "Verbundene Gateways",
      "Pending Invoices": "Ausstehende Rechnungen",
      "OPC UA": "OPC UA",
      "New Support Ticket": "Neues Support Ticket",
      "Send Handshake": "Handshake senden",
      "Run speedtest": "Speedtest starten",
      "Reload configuration": "Konfiguration neustarten",
      "Accessing remote data": "Remote-Anfrage wird bearbeitet",
      "Support Tickets": "Support Tickets",
      "Please describe your support request. We will get back to you asap.": "Wie können wir Ihnen helfen? Bitte füllen Sie das untere Formular aus - wir melden uns so schnell wie möglich bei Ihnen.",
      // Error handling
      "Error ": "Fehler ",
      "Error state": "Störung",
      "Quick filter": "Schnellfilter",
      "Some fields may be missing or need to be re-entered: ": "Bestimmte Felder müssen ausgefüllt sein. Bitte ergänzen oder korrigieren: ",
      "Oops. Our bad. Please try again later.": "Ups, dieser Fehler liegt bei uns. Sorry! Probiere es später noch einmal.",
      "Please enter a client name.": "Bitte geben Sie einen Kundennamen an.",
      "Please enter a gateway serial.": "Bitte geben Sie eine Seriennummer an.",
      "Please enter a password.": "Bitte geben Sie ein Passwort ein.",
      "Please enter some more data.": "Bitte geben Sie mehr Daten ein.",
      "Frequently Asked Questions": "Häufig gestellte Fragen",
      "Latitude": "Breitengrad",
      "Longitude": "Längengrad",
      "Calculate Pay-per-Use Price": "Pay-per-Use Preis berechnen",

      // Alerts
      "A new invoice has been created.": "Eine neue Rechnung wurde erstellt.",
      "A new asset has been requested.": "Es gibt eine neue Asset-Anfrage.",

      "Raw data": "Rohdaten",
      "Project": "Projekt",
      Custom: "Individuell",
      "Consumption": "Verbrauch",
      "Communication": "Kommumikation",
      "Sustainability": "Nachhaltigkeit",
      "Risk": "Risiko",
      "Finance": "Finanzen",
      "Operational State": "Produktionszustand",
      "Just now": "Kürzlich",
      "Cycle/Min": "Umdrehungen/Min.",
      "As expected": "Erwartungsgemäß",
      "More than expected": "Leicht erhöht",
      "Condition": "Zustand",
      "Created": "Erstellt",
      "Full Business Name": "Vollständiger Unternehmensname (AG, GmbH)",
      "Activation Log": "Aktivierungsverlauf",
      "Errors / Störungen": "Störungen",
      "LTE-State": "LTE-Status",
      "Drop the file here...": "Hier loslassen...",
      "Drag & drop an image here, or click to select a file": "Ziehe ein Bild hier hinein oder klicke, um eins auszuwählen.",
      "Mark all as read": "Alle als gelesen markieren",
      "Solved": "Gelöst",
      "Business Parameters": "Unternehmensinfos",
      "cap-on Product - Catalog": "cap-on Produktkatalog",
      "cap-on Services Catalog": "cap-on Servicekatalog",
      "24h": "24 Std.",
      "6h": "6 Std.",
      "1h": "1 Std.",
      "7d": "7 Tage",
      "5m": "5 Min.",
      "Column": "Spalte",
      "Row": "Zeile",
      "Divider": "Trennlinie",
      "Data Tile": "Datenkachel",
      "Energy Meter (Wh)": "Stromzähler (Wh)",
      "30 minutes": "30 Min.",
      "Are you sure?": "Bist Du sicher?",
      "Running (Auto Automatik On)": "In Produktion (Automatik EIN)",
      "Ready (Melt Temperature >= 200°C)": "Betriebsbereit (Schmelzetemperatur >= 200°C)",
      "Successfully added a snapshot in the \"Documents\" tab.": "Der Snapshot wurde erfolgreich im Tab \"Dokumente\" abgelegt.",
      "No subscription": "Keine Subscription",
      "No reading": "Nicht regelmäßig abzulesen",
      "Last 28d": "Letzte 28 Tage",
      "Custom state": "Individueller Status",
      "state": "Individuell",
      "Time span": "Zeitspanne",
      "Stop Reasons": "Status Information",
      "Show in one chart": "In einem Chart anzeigen",
      "Gathering Insights...": "Insights werden generiert",
      "Insights Data Overview": "Insights Datenüberblick",
      "Connectivity": "Verbindung",
      "Send welcome email with credentials": "Willkommensemail mit Zugangsdaten versenden",
      "Send Email": "Email versenden",
      "Scenario-Analysis": "Szenario-Analyse",
      "Cumulated Interest Profit": "Kumulierte Zins-Marge",
      "Max. liquidity deficit": "Maximales Liquiditätsrisiko",
      "Max. shortage deficit": "Maximales Unterdeckungsrisiko",
      "Emissions": "Emissionen",
      "Price": "Preis",
      "Violation history": "Historie: Letzte Verstöße",
      "Date range": "Zeitspanne",
      "Gaps do not necessarily indicate missing data. They usually indicate that a value did not change within an interval, since only value changes are being reported and displayed for performance reasons.": "Lücken deuten nicht unbedingt auf fehlende Daten hin. Sie zeigen in der Regel an, dass sich ein Wert innerhalb eines Intervalls nicht geändert hat, da aus Gründen der Performance nur Wertänderungen erfasst und angezeigt werden.",
      "Interval": "Intervall",
      "Power Consumption": "Stromverbrauch",
      "Latest Speedtest": "Letzter Speedtest",
      "Current Power": "Aktuelle Leistung (Strom)",
      "Type": "Typ",
      "Carbon Emissions": "CO\u2082 Emissionen",
      "Carbon Emissions • Today": "CO\u2082 Emissionen • heute",
      "Production Type": "Produktionstyp",
      "Incorrect credentials. Please try again.": "Benutzername oder Passwort sind nicht korrekt. Bitte probieren Sie es erneut.",
      "Gateway Error Log": "Gateway Logs",
      "Error Logs": "Logs",
      "Raw data (latest of a kind)": "Rohdaten (jeweils aktuellster Wert)",
      "Raw Data Monitor": "Rohdatenmonitor",
      "No power consumption available.": "Keine Stromdaten erfasst.",
      "Errors": "Logs",
      "Error Log": "Logs",
      "On": "An",
      "Off": "Aus",
      "Flashing": "Blinkend",
      "Green": "Grün",
      "Removed item": "Eintrag gelöscht",
      "Gray": "Grau",
      "Blue": "Blau",
      "Running": "In Betrieb",
      "Red": "Rot",

      "Blocked Stop": "Blockiert",
      "Planned Stop": "Geplant",
      "Prepare Plant": "In Vorbereitung",
      "Starved Stop": "Materialmangel",

      "Environmental Social Governance (ESG) Data": "Environmental Social Governance (ESG) Daten",
      "Info Light": "Ampel",
      "Live View": "Live-Produktion",
      "No images available.": "Keine Bilder verfügbar.",
      "No avatar available.": "Kein Bild verfügbar",
      "min.": "Min.",
      "hrs.": "Std.",
      "Last drop-out": "Letzter Signalausfall",
      " (Units: ": " (Einheiten: ",
      "Month prediction": "Monatsproduktion Forecast",
      "Internet Service Provider": "Internetanbieter (ISP)",
      "Next Payment": "Nächste Zahlung",
      "Next invoice": "Nächste Rechnung",
      "Next Payment (Forecast)": "Nächste Zahlung (intelligenter Forecast)",
      "Next Payment • Forecast": "Nächste Zahlung • Forecast",
      "Percent change": "Unterschied",
      "Next Payment Date": "Nächstes Zahlungsdatum",
      "Not compensated": "Nicht kompensiert",
      "Forecast": "Intelligenter Forecast",
      "Difference to schedule": "Unterschied zum SOLL",
      "Profile": "Profil",
      "Crop": "Beschneiden",
      "Invalid file format. Please upload an image.": "Bitte verwende eine Bild-Datei.",
      "Relevant for AI-based anomaly report": "Relevant für den KI-Report",
      "Upload avatar": "Avatar hochladen",
      "Remove avatar": "Avatar löschen",
      "You're not allowed to view this information. Please send a request to support@cap-on.de": "Sie haben aktuell keine Berechtigung zu dieser Information bzw. Aktion. Bitte wenden Sie sich an support@cap-on.de",
      "Change Password": "Passwort ändern",
      "Current Password": "Aktuelles Passwort",
      "Reset Password": "Passwort wiederherstellen",
      "Forgot Password?": "Passwort vergessen?",
      "Please enter your email to reset your password.": "Bitte geben Sie Ihre Emailadresse an, um das Passwort zurückzusetzen.",
      "Finance/Risk": "Finanzen/Risiko",
      "Test-Environment": "Test-Umgebung",
      "Prod-Environment": "Produktiv-Umgebung",
      "Reset": "Zurücksetzen",

      "Apply to all contracts": "Auf alle Verträge anwenden",
      "no_access": "Kein Zugriff",
      "read_all": "Kompletter Lesezugriff",
      "client_agent": "Agent eines Kunden",
      "finance_agent": "Agent eines Finanzpartners",
      "service_agent": "Agent eines Service",
      "contract_manager": "Vertragsmanager",

      "Request a quote": "Angebot anfordern",
      "Request Asset": "Angebot berechnen",
      "Export": "Exportieren",
      "Approx.": "ca.",
      "of planned": "zu SOLL",
      "Invoice recipient": "Rechnungsempfänger",
      "No ESG data for this asset recorded, yet.": "Bislang keine ESG-relevanten Daten erfasst.",
      "Export CSV": "CSV Export",
      "Security Management": "Sicherheiten",
      "Securities": "Sicherheiten",
      "Scenario Curve": "Szenariokurve",
      "Download PDF": "PDF downloaden",
      "Usage Logs": "Nutzungslogs",
      "Last 50 units": "Letzte 50 Einheiten",
      "Always send bill to investor": "Immer auch eine Rechnung für den Finanzpartner erstellen",
      "last": "letzte",
      "Focus:": "Fokus:",
      "Copy ID": "ID kopieren",
      "Asset-ID copied!": "Asset-ID kopiert!",
      "Upload File": "Datei hochladen",
      "Asset Usage Configuration": "Konfigurator",
      "1 month": "1 Monat",
      "7 days": "7 Tage",
      "1 day": "1 Tag",
      "1 hour": "1 Std.",
      "15 minutes": "15 Min.",
      "5 minutes": "5 Min.",
      "1 minute": "1 Min.",
      "15 seconds": "15 Sek.",
      "5 seconds": "5 Sek. ",
      "1 second": "1 Sek.",

      Seconds: "Sekunden",
      Minutes: "Minuten",
      Days: "Tage",
      Weeks: "Wochen",
      Hours: "Stunden",
      hours: "Stunden",
      days: "Tage",
      Years: "Jahre",

      running_time: "Produktionszeit",
      setup_time: "Rüstzeit",
      error_time: "Störzeit",
      cycle_time: "Taktzeit",
      usage: "Produzierte Einheiten",
      oee: "Anlageneffektivität (OEE)",
      connection_health: "Verbindungszustand",
      share_of_planned_usage: "SOLL/IST-Vergleich: Abrufkurve vs. Produktion",
      weekend_units: "Wochenendproduktion",
      running_time_percent: "Zeit in Produktion",
      revenue: "Einnahmen",
      share_of_planned_revenue: "SOLL/Ist-Vergleich: Einnahmen",
      maximum_capacity_share: "Hersteller empfohlene Auslastung (Verschleiß)",
      cycle_time_outlier_removal: "Cycle Time Outlier",
      wear_and_tear: "Verschleiß",

      MUCH_LOWER_THAN_EXPECTED: "Sehr gering",
      LOWER_THAN_EXPECTED: "Gering",
      AS_EXPECTED: "Wie erwartet",
      HIGHER_THAN_EXPECTED: "Hoch",
      cycle_time_min: "Taktzeit (Minimum)",
      cycle_time_max: "Taktzeit (Maximum)",

      // MAAG Services

      "We offer our customers professional support in the use and optimization of systems and machines.": "Wir bieten unseren Kunden professionelle Unterstützung bei der Verwendung und Optimierung der Anlagen und Maschinen.",
      "Spare parts and consumables management": "Ersatz- und Verbrauchsteilmanagement",
      "Maximize efficiency and minimize downtime with our consumption and spare parts management service. Our 24/7 online ordering system ensures lightning-fast spare parts deliveries, while our preventive maintenance services optimize the condition of your equipment. Rely on us to reduce your operating costs and extend product life.": "Maximieren Sie Effizienz und minimieren Sie Ausfallzeiten mit unserem Verbrauchs- und Ersatzteilmanagement-Service. Unser 24/7 Online-Bestellsystem sorgt für blitzschnelle Ersatzteillieferungen, während unsere präventiven Wartungsdienste den Zustand Ihrer Anlagen optimieren. Verlassen Sie sich auf uns, um Ihre Betriebskosten zu senken und die Produktlebensdauer zu verlängern.",
      "Output optimization": "Ausbringungsoptimierung",
      "Optimize your processes and increase the efficiency of your production with intelligent bottleneck analysis, cycle time analysis and shift model optimization.": "Optimieren Sie Ihre Prozesse und erhöhen Sie die Effizienz Ihrer Produktion durch intelligente Bottleneckanalyse, Takzeitanalyse und Schichtmodelloptimierung.",
      "Energy Management": "Energiemanagement",
      "Keep an eye on your energy consumption and reduce your costs with intelligent energy management.": "Behalten Sie den Energieverbrauch im Blick und senken Sie Ihre Kosten durch intelligentes Energiemanagement.",
      "Reduce downtimes and lower your spare parts management costs through data-driven maintenance optimization, regardless of whether you already use your own SAP PM module or have access to a cap-on database.": "Reduzieren Sie Störzeiten und senken Sie Ihre Ersatzteilmanagement-Kosten durch datengesteuerte Maintenance-Optimierung, egal ob Sie bereits ein eigene SAP PM-Modul nutzen oder auf cap-on Datenbasis zurückgreifen.",
      "Reduce your production costs and increase your liquidity with innovative financing solutions tailored to your individual needs.": "Senken Sie Ihre Produktionskosten und steigern Sie Ihre Liquidität durch innovative Finanzierungslösungen, die auf Ihre individuellen Bedürfnisse zugeschnitten sind.",

      "changed": "änderte sich um",
      "compared to last month": "im Vergleich zum Vormonat",
      "increased by": "stieg um",
      "decreased by": "sank um",
      "Output quantity, Operating hours, ...": "Stückzahl, Betriebsstunden, ...",
      "Output quantity": "Ausbringungsmenge/Stückzahl",
      "Operating hours": "Betriebsstunden",
      "Distance in km": "Zurückgelegte Entfernung (km)",
      "Individual": "Individuell",
      "Percent Produced": "x% der SOLL-Produktion erreicht",
      "Pay-per-Use Unit": "Abrechnungsbasis",
      core_system_prod: "Core System (Produktion)",
      core_system_stag: "Core System (Test)",
      pricing_engine_prod: "Pricing Engine (Produktion)",
      pricing_engine_stag: "Pricing Engine (Test)",
      webapp_prod: "Web App (Produktion)",
      webapp_stag: "Web App (Test)",
      traefik_prod: "Traefik (Produktion)",
      traefik_stag: "Traefik (Test)",
      expected_revenue_without_services: "Erwartete Gesamteinnahmen (ohne Zusatzservices)",
      monthly_usage_variability: "Auslastungsvariabilität",

      "Report \"Rejects\" (OEE)": "Ausschuss melden (OEE)",
      "Rejected Units": "Ausschuss",
      "Timestamp": "Zeitpunkt",
      "Cycle Times": "Taktzeiten",
      "Process Time": "Bearbeitungszeit",
      "Cycle Time": "Taktzeit",
      "Average Cycle Time": "Durchschnittliche Taktzeit",
      "One Time Fee": "Bereitstellungsgebühr (One Time Fee)",
      unit_counter_share: "SPS-Analyse: Zählerstand vs. erhobene Einheiten",

      // Config
      ACCESS_TOKEN_EXPIRE_MINUTES: "Access Token Expiration",
      ACCESS_TOKEN_DEVICE_EXPIRE_DAYS: "Access Token (Device) Expiration",
      DEPRECATION_AFTER_DURATION: "DEPRECATION_AFTER_DURATION",
      EMAIL_RESET_TOKEN_EXPIRE_HOURS: "EMAIL_RESET_TOKEN_EXPIRE_HOURS",
      HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES: "HANDSHAKE_MISSING_GAP_INTERVAL_MINUTES",
      MAX_REQUEST_SIZE: "MAX_REQUEST_SIZE",
      MINUTES_AFTER_CONNECTION_LOST: "MINUTES_AFTER_CONNECTION_LOST",
      PASSWORD_MIN_CHARS: "PASSWORD_MIN_CHARS",
      PRESIGNED_FILE_URL_VALID_SECONDS: "PRESIGNED_FILE_URL_VALID_SECONDS",
      SERVICES_PER_YEAR: "SERVICES_PER_YEAR",
      VAT_RATE: "VAT_RATE",
      CELERY_DELETE_READ_ALERTS_AFTER_WEEKS: "Interval for deleting read alerts",
      CELERY_DELETE_ALERTS_AFTER_WEEKS: "Interval for deleting alerts",
      CELERY_CHECK_MISSED_HANDSHAKES_SCHEDULE: "Interval for checking missed handshakes",
      CELERY_SEND_OVERDUE_INVOICE_ALERTS_DAYS_INTERVAL: "Interval for overdue invoice alerts",
      DEFAULT_AVATAR_URL: "Default Avatar (URL)",

      // Financials
      financials_base_price: "Grundpreis",
      financials_tier: "cap-on Pricing Tier",
      financials_investment_price: "Investitionssumme",
      financials_monthly_base_fee: "Monatliche Grundrate",
      financials_market_value: "Marktwert",
      financials_residual_book_value: "Buch. Restwert",
      residual_book_value: "Buch. Restwert",
      market_value: "Marktwert",
      financials_capon_interest_rate: "cap-on Zins",
      financials_capon_rate: "cap-on Rate",
      financials_full_interest_rate: "Gesamtzins",
      financials_price_factors: "Preis Faktoren",
      financials_refi_interest_rate: "Refi Zins",
      financials_refi_rate: "Refinanzierungs-Rate",
      financials_starting_fee: "Startgebühr",

      // Routines
      "System Routines": "System Routinen",
      calculate_operating_times: "Berechnung von Betriebszeiten (operating times)",
      check_gateway_handshakes: "Überprüfung der Handshakes",
      update_current_asset_values: "Aktualisierung der Asset-Buchwerte",
      alert_overdue_invoices: "Benachrichtigung zu fälligen Rechnungen",
      delete_old_alerts: "Säuberung veralteter Benachrichtigungen",
      calculate_insights: "Erstellung neuer Insights",
      create_monthly_invoices: "Monatsrechnung erstellt",
      run_watchtower: "Watchtower-Durchlauf",
      "Last run at": "Letzter Durchlauf",
      "Total Run Count": "Anzahl der Durchläufe",
      day_of_week: "Tag der Woche",
      day_of_month: "Tag des Monats",
      month_of_year: "Monat des Jahres",
      hour: "Stunde",
      minute: "Minute",
      calculate_state_periods: "Berechnung von state_periods",
      check_tasks: "Tasks/Aufgaben prüfen",
      update_monthly_usages: "Monatliche Nutzungen aktualisieren",
      "Usages Today": "Heutige Ausbringung",
      "Power Emissions (intra-day)": "Stromemissionen (heute)",
      "Power Consumption (intra-day)": "Stromverbrauch (heute)",

      plc_node_running: "In Produktion (plc_node_id)",
      plc_node_setup: "Rüstvorgang (plc_node_id)",
      plc_node_error: "Störung (plc_node_id)",
      plc_node_planned_downtimes: "Geplanter Stillstand (plc_node_id)",
      sending_interval_minutes: "Sending Interval Minutes",
      connection_protocol: "Technisches Protokoll",

      seconds: "Sekunden",
      "Activate routine": "Routine aktivieren",

      "The cap-on Watch Tower automatically monitors certain sensor parameters and notifies users once values exceeded individual threshold.": "Der cap-on Watchtower überwacht gewünschte Daten im Hintergrund und schickt proaktive Meldungen.",

      power_per_use: "Stromverbrauch pro Nutzung",
      setup_time_percent: "Rüstzeit in %",
      error_time_percent: "Störungszeit in %",
      "higher than threshold": "höher als Grenzwert",
      "lower than threshold": "geriner als Grenzwert",
      "not equal to desired value": "gleicht nicht dem gewünschten Wert",

      higher: "höher ist, als",
      lower: "niedriger ist, als",
      equals: "ist gleich",
      unequals: "ist ungleich",
      "Notify me when": "Benachrichtige mich, wenn",
      "with priority": "mit Priorität",
      "No. of Violations": "Anzahl der Verstöße",
      "Last 7 days": "Letzte 7 Tage",

      "Watching and notifying if": "Prüft",
      "of": "von",
      higher_relative: "und benachrichtigt bei Überschreitung von",
      lower_relative: "und benachrichtigt bei Unterschreitung von",
      equals_relative: "und benachrichtigt bei dem genauen Wert von",

      one_day_average: "Tagesdurchschnitt",
      three_consecutive_days: "3x Tagesdurchschnitt hintereinander",
      one_month_average: "Monatsdurchschnitt",
      single_value_last_hour: "Einmalige Werterreichung in der letzten Stunde",

      one_day_average_relative: "den letzten Tagesdurchschnitt",
      three_consecutive_days_relative: "den Tagesdurchschnitt der letzten 3 Tage",
      one_month_average_relative: "den letzten Monatsdurchschnitt",
      single_value_last_hour_relative: "die Werterreichung in der letzten Stunde",


      "New Watch Tower": "Watchtower anlegen",
      Actions: "Aktionen",
      "Add Watch Tower": "Watchtower hinzufügen",
      "Power per Unit": "Stromverbrauch pro Stückzahl",
      "AaaS-Billing": "AaaS-Rechnungen",
      "Power per Unit (intra-day)": "Stromverbrauch pro Stück (heute)",

      "manual": "Manuell",
      "in_progress": "In Bearbeitung",
      "completed": "Abgeschlossen",
      "error": "Fehler",
      "Please check": "Bitte prüfen",
      "Switched off": "Abgeschaltet",
      "Power Input": "Leistungsaufnahme",
      "Power Meter": "Zählerstand",

      Metric: "Überwachter Wert / KPI",
      Threshold: "Schwelle",
      "Comparison Mode": "Abgleichsmodus",
      "Last triggered at": "Letzte Auslösung",
      "Trigger count": "Auslösungen",
      "Send Emails": "Emails senden",
      "Users to alert": "Benachrichtigte Nutzer",

      "Compensate CO\u2082 emissions": "Jetzt CO\u2082 ausgleichen",

      weekend_usage: "Wochenendproduktion",
      // support categories
      platform: "Plattform (IoT-Hub)",
      "MAAG Units": "MAAG Einheiten",
      "IoT-Contracts": "IoT-Verträge",
      gateway: "IoT-Gateway",
      access_management: "Nutzer- und Access-Management",
      feedback: "Generelles Feedback",
      payment: "Zahlungsservices",
      enhance: "cap-on Enhance",
      other: "Sonstiges",
      "and": "und",
      "No recent value found.": "Zuletzt keine Daten gefunden.",
      "Currently": "Aktuell",
      "should be between": "empfohlen zwischen",
      "should be": "empfohlener Wert",
      "Save configuration": "Konfiguration speichern",
      "Apply changes on gateway": "Auf Gateway übernehmen",

      "Average 1-day data": "Durchschnittliche Datenmenge (1 Tag)",
      "Average 30-day data": "Durchschnittlich Datenmenge (30 Tage)",
      "Average daily data points": "Durchschnittliche Datenpunkte (1 Tag)",
      "MB in 1 day": "MB pro Tag",
      "MB in 30 days": "MB in 30 Tagen",
      "Data transfer": "Datentransfer",

      "Ranking": "Rangliste",
      "Occurences by Data Point": "Häufigkeit je Datenpunkt",
      "Data Point": "Datenpunkt",
      "Occurences": "Häufigkeit",
      "Metric / KPI": "Kalkulierte Metrik / KPI",
      "Single IoT-data point": "Einzelner Datenpunkt",
      "IoT data setup": "IoT-Daten Einstellungen",
      "IoT Data Points": "IoT-Datenpunkte",
      "or": "oder",
      "IoT Data Point": "IoT-Datenpunkt",
      "IoT connector configuration": "IoT-Datenkonnektoren",
      "Log Type": "Technische Details",
      "Only show new node ids": "Nur \"neue\" Datenpunkte anzeigen",
      "ads": "TwinCAT (ADS)",
      "modbus": "Modbus-TCP",
      asset_logs: "General Asset Log (Daten von Sensoren, Aktoren, etc.)",
      usage_cycles: "Usage Cycle Counter (für Pay-per-Use)",
      usage_bool: "Usage (als Boolean)",
      usage_enabled: "Nutzungserfassung aktiviert",
      opc_connection: "OPC UA Verbindung",
      // asset_logs_energy: "Asset Energy Log",
      "read": "Regelmäßig ablesen (read)",
      "subscribe": "Änderungen abonnieren (subscribe)",
      "Apply configuration": "Konfiguration übernehmen",
      "Apply": "Übernehmen",

      "Emission Comparison": "Verbrauchsvergleich",
      "Import IoT-Nodes from an existing asset": "IoT-Datenpunkte eines Bestandsassets importieren",
      "Import IoT-Configuration from an existing asset": "IoT-Konfiguration eines Bestandsassets importieren",

      "Active Remote-HMI session for ": "HMI-Fernverbindung aktiv für ",
      "Initiate remote HMI connection": "HMI-Fernverbindung aufbauen",
      "Stop connection": "Verbindung deaktivieren",
      "Free download: RealVNC Viewer": "VNC-Viewer kostenlos herunterladen",
      "Show HMI in RealVNC Viewer": "HMI in VNC-Viewer öffnen",

      "Stopped HMI session": "HMI-Fernverbindung deaktiviert",
      "Starting HMI session": "HMI-Fernverbindung wird aktiviert",

      "Protocol": "Technisches Protokoll",
      "Display Name": "Anzeigename",
      "Polling Method": "Abfragemodus",
      "(for digital twin, etc.)": "(u.a. für digitalen Zwilling)",
      "cap-on Core Type": "cap-on Datentyp",
      "Descriptiv name of your data point": "Name des Datenpunkts",

      state_running: "Status: In Produktion",
      state_idle: "Status: Inaktiv",
      state_setup: "Status: Rüstvorgang",
      state_planned_downtimes: "Status: Geplanter Stillstand",
      state_error: "Status: Störung",
      faulty: "Fehlerhaft",
      maintenance: "Wartung",
      production: "Produktion",
      power_consumption: "Stromverbrauch",
      current_power: "Aktuelle Leistung (Strom)",
      reject: "Ausschuss",
      state_ready: "Betriebsbereit",
      state_maintenance: "Status: Rüstzustand",
      snapshot: "Snaptshot",
      alert: "SPS-Fehlermeldung",
      vnc_password: "VNC-Passwort-Logik",

      "Intent": "Zweck",
      "Edit data point": "Datenpunkt anpassen",
      "Generate AI-Report": "Anomalie-Report generieren",

      co2_total: "Gesamt CO\u2082-Emissionen",
      co2_per_use: "CO\u2082-Emissionen pro Einheit",
      "Organized": "Strukturiert",
      "Table design": "Ansicht",
      "Simplified data": "Vereinfacht",
      "Read Interval": "Lese-Intervall",
      "Subscription Interval": "Subscription-Intervall",
      "Technical Name": "Technischer Name",
      "PLC Node ID": "Datenpunkt-ID im SPS-Programm (node id)",
      "Aggregation Type": "Aggregierungsmethode",
      "Lower Limit": "Unterer Betriebswert (Empfehlung)",
      "Upper Limit": "Oberer Betriebswert (Empfehlung)",
      "Target Value": "Zielwert",
      "Operating Rules & Advanced Settings": "Betriebsregeln und erw. Einstellungen",
      "Milliseconds": "Millisekunden",
      "Add data point": "IoT-Datenpunkt hinzufügen",

      "Add": "Hinzufügen",
      "Remove": "Entfernen",
      "gap_fill": "Gap filling mode",
      "cluster_density": "Cluster density",
      "assistant_instructions": "Instructions for AI-assistant",
      "add_usage": "Nutzung einbeziehen",
      "add_cycle_times": "Taktzeit einbeziehen",
      "prompt": "Prompt / Befehl",

      "Last value": "Letzter Wert",
      "Recorded data points": "Erfasste Datenpunkte",

      "Programmable Logic Controller (OT)": "Speicherprogrammierbare Steuerung",
      "plc_description": "Eine SPS (englisch: PLC) ist ein Gerät, das zur Steuerung oder Regelung einer Maschine oder Anlage eingesetzt und auf digitaler Basis programmiert wird.",
      "vpn_description": "Ein VPN zwischen unserer Cloud und einem IoT-Gateway gewährleistet eine sichere und verschlüsselte Verbindung, die die Datenübertragung zwischen dem IoT-Gerät und unserer Cloud schützt."
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
