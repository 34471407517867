import { Badge, Button, Card, Col, OverlayTrigger, Popover, Table } from "react-bootstrap";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import LoadingSpinner from "../Helper/LoadingSpinner";
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import AddInvoiceModal from "./AddInvoiceModal";
import { Plus, Trash } from "react-feather";
import UserDataManager from "../Helper/UserDataManager";
import axios from "axios";

const InvoiceTable = ({ doRefresh, data, isLoading }) => {
  const { t, i18n } = useTranslation();
  const [showInvoice, setShowInvoice] = useState();
  const [statusFilters, setStatusFilters] = useState([]);
  const [activeStatusFilters, setActiveStatusFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [showNewInvoice, setShowNewInvoice] = useState(false);

  const renderInvoiceState = (invoice) => {
    switch (invoice.payment_status) {
      case "paid":
        return (
          <Badge bg="" className="badge-soft-success me-2">
            {t(invoice.payment_status)}
          </Badge>
        );
      case "unpaid":
        return (
          <Badge bg="" className="badge-soft-warning me-2">
            {t(invoice.payment_status)}
          </Badge>
        );
      case "overdue":
        return (
          <Badge bg="" className="badge-soft-danger me-2">
            {t(invoice.payment_status)}
          </Badge>
        );

      case "unpublished":
        return (
          <Badge bg="" className="badge-soft-secondary me-2">
            {t(invoice.payment_status)}
          </Badge>
        );
      default:
        return (
          <Badge bg="" className="badge-soft-primary me-2">
            {t(invoice.payment_status)}
          </Badge>
        );
    }
  };

  React.useEffect(() => {
    if (data) {
      const uniqueFilters = [...new Set(data.map((invoice) => invoice.billing_to))];
      setFilters(uniqueFilters);

      const uniqueStatusFilters = [...new Set(data.map((invoice) => invoice.payment_status))];
      setStatusFilters(uniqueStatusFilters);
    }
  }, [data]);

  const handleFiltration = () => {
    let tempData = data;
    if (activeFilters.length > 0) {
      tempData = tempData.filter((invoice) => activeFilters.includes(invoice.billing_to));
    }
    if (activeStatusFilters.length > 0) {
      tempData = tempData.filter((invoice) => activeStatusFilters.includes(invoice.payment_status));
    }
    setFilteredData(tempData);
  };

  React.useEffect(() => {
    handleFiltration();
  }, [activeFilters, activeStatusFilters, data]);

  function handleDelete(e, id) {
    e.preventDefault();
    axios
      .delete("/invoices/" + id)
      .then(function (response) {
        // handle success
        console.log(response.status, "Deleted invoice");
        doRefresh()
      })
      .catch(function (error) {
        // handle error
        // setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  const renderTable = () => {
    return filteredData
      ?.sort((a, b) => {
        const dateA = new Date(a.due_date);
        const dateB = new Date(b.due_date);

        if (dateA.getTime() === dateB.getTime()) {
          // If dates are the same, sort by invoice_number
          return a.invoice_number - b.invoice_number;
        }
        return dateB - dateA; // Sort by date
      })
      ?.map((invoice) => {
        return (
          <tr
            key={invoice.id}
            onClick={() => setShowInvoice(invoice.id)}
            style={{ cursor: "pointer" }}
          >
            <td>
              {/* <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover>
                    <Popover.Header as="h3">{t("Status")}</Popover.Header>
                    <Popover.Body>
                      {invoice?.sent_to_billing_date
                        ? format(parseISO(invoice?.sent_to_billing_date), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, {
                          locale: i18n.language === "de" ? de : null,
                        })
                        : t("No email has been sent to client")}
                    </Popover.Body>
                  </Popover>
                }
              > */}
              {renderInvoiceState(invoice)}
              {/* </OverlayTrigger> */}
            </td>
            <td>{t(invoice?.invoice_number_set || "-")}</td>
            <td>
              {format(
                parseISO(invoice.due_date),
                `d${i18n.language === "de" ? "." : ""} MMMM yyyy`,
                { locale: i18n.language === "de" ? de : null }
              )}
            </td>
            <td>{`${invoice?.billing_party_name || "-"} →`} <strong>{invoice?.billed_party_name || "-"}</strong></td>
            <td>{t(invoice?.billing_to)}</td>
            <td>
              {invoice.total.toLocaleString(
                i18n.language === "de" ? de : "en",
                {
                  style: "currency",
                  currency: "EUR",
                }
              )}
            </td>
            {/* {invoice?.created_at && (
              <td>
                {format(
                  parseISO(invoice.created_at),
                  `d${i18n.language === "de" ? "." : ""} MMMM yyyy`,
                  { locale: i18n.language === "de" ? de : null }
                )}
              </td>
            )} */}

            {UserDataManager.getInstance().isAdmin() && <td className="table-action">
              <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => {
                e.stopPropagation();
                window.confirm(t("Are you sure you wish to delete this invoice?")) && handleDelete(e, invoice.id)
              }} />
            </td>}
          </tr>
        );
      });
  };

  return (
    <>
      {showInvoice && (
        <InvoiceDetailsModal
          selected_invoice_id={showInvoice}
          handleCancel={() => setShowInvoice(null)}
          handleClose={() => {
            setShowInvoice(null);
            window.location.reload(false);
          }}
        />
      )}

      {showNewInvoice &&
        <AddInvoiceModal
          handleCancel={() => setShowNewInvoice(false)}
          handleSave={() => {
            doRefresh()
            setShowNewInvoice(false)
          }
          } />}

      <Card className="flex-fill w-100">
        <Card.Header>
          <Col xs="auto">
            <Card.Title tag="h5" className="mb-0">
              {t("Invoices")}
            </Card.Title>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n3">
            <Button
              onClick={() => setShowNewInvoice(true)}
              variant="outline-primary"
              size="sm"
              className="shadow-sm me-1">
              <Plus className="feather" /> {t("New")}
            </Button>
          </Col>
        </Card.Header>

        {isLoading ? (
          <LoadingSpinner withStandardText />
        ) : filteredData && filteredData.length > 0 ? (

          <div>
            {filters?.length > 1 && <div className="ms-4 mb-2"><strong>{t("Quick filter")}</strong></div>}

            <div className="d-flex">
              {filters?.length > 1 && (
                <div className="ms-4 mb-3">
                  {t("Invoice recipient")}
                  <div className="my-2"></div>
                  {filters.map((filter) => (
                    <Button
                      key={filter}
                      onClick={() =>
                        setActiveFilters((prev) =>
                          prev.includes(filter)
                            ? prev.filter((f) => f !== filter)
                            : [...prev, filter]
                        )
                      }
                      variant={
                        activeFilters.includes(filter)
                          ? "secondary"
                          : "outline-secondary"
                      }
                      size="sm"
                      className="btn-pill me-1 mb-1"
                    >
                      {t(filter)}
                    </Button>
                  ))}
                </div>
              )}
              {statusFilters?.length > 1 && (
                <div className="ms-4 mb-3">
                  {t("Status")}
                  <div className="my-2"></div>
                  {statusFilters.map((filter) => (
                    <Button
                      key={filter}
                      onClick={() =>
                        setActiveStatusFilters((prev) =>
                          prev.includes(filter)
                            ? prev.filter((f) => f !== filter)
                            : [...prev, filter]
                        )
                      }
                      variant={
                        activeStatusFilters.includes(filter)
                          ? "secondary"
                          : "outline-secondary"
                      }
                      size="sm"
                      className="btn-pill me-1 mb-1"
                    >
                      {t(filter)}
                    </Button>
                  ))}
                </div>
              )}
            </div>

            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Status")}</th>
                  <th scope="col">{t("Invoice #")}</th>
                  <th scope="col">{t("Due date")}</th>
                  <th scope="col">{t("Invoice Flow")}</th>
                  <th scope="col">{t("Invoice Recipient")}</th>
                  <th scope="col">{t("Amount")}</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </Table>
          </div>
        ) : (
          <Card.Body>
            <p>{t("No data found.")}</p>
          </Card.Body>
        )}
      </Card>
    </>
  );
};

export default InvoiceTable;