import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ErrorHandler from "../Helper/ErrorHandler";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { ArrowUpRight, RefreshCw, Trash } from "react-feather";
import AddSupportModal from "./AddSupportModal";
import { useNavigate } from "react-router-dom";

const Support = () => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [supportTickets, setSupportTickets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/support_tickets/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/tickets");
        setSupportTickets(response.data.sort((a,b) => a.created_at < b.created_at));
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });

    }, []);

  const SupportList = () => (
    <Card>
      <Card.Header>
        <Card.Title>Support Tickets</Card.Title>
      </Card.Header>
      {/* <Card.Body> */}
      {
        supportTickets.length > 0
          ? // If data found

          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col">{t("Solved")}</th>
                <th scope="col">{t("Category")}</th>
                <th scope="col">{t("Title")}</th>
                <th scope="col">{t("Description")}</th>
                <th scope="col">{t("Created")}</th>
              </tr>
            </thead>
            <tbody>{renderTable()}</tbody>
          </Table>

          : // If no data found

          <Card.Body>
            <p className="card-subtitle text-muted">
              {t("No data found.")}
            </p>
          </Card.Body>
      }
      {/* </Card.Body> */}
    </Card>
  );

  function handleDelete(e, id) {
    e.preventDefault();

    axios
      .delete("/support_tickets/" + id)
      .then(function (response) {
        console.log(response.status, "Deleted support ticket");
        // handle success
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderTable = () => {

    return supportTickets.map((ticket) => {
      return (
        <tr key={ticket.id}>
          <td>
            <span className={ticket.solved ? "badge bg-success" : "badge bg-warning"}>{ticket.solved ? "Ja" : "Nein"}</span>
          </td>
          <td>{ticket?.category}</td>
          <td>{ticket?.title}</td>
          <td>{ticket?.description || "-"}</td>
          <td>
            {format(parseISO(ticket.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}
            {" "}({ticket.created_at == null ? "-" : formatDistanceToNow(parseISO(ticket.created_at), { locale: i18n.language === "de" ? de : null, addSuffix: true })})
          </td>
          <td className="table-action">
            <ArrowUpRight alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={() => navigate("/contracts/" + ticket.contract_id)} />
            &nbsp;
            &nbsp;
            <Trash alt="Delete" className="align-middle" size={18} style={{ cursor: "pointer" }} onClick={e => window.confirm(t("Are you sure you wish to delete this ticket?")) && handleDelete(e, ticket.id)} />
          </td>
        </tr>
      );
    });
  };

  return (
    <React.Fragment>
      <Helmet title="Support" />
      <Container fluid className="p-0">

        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Support")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <AddSupportModal buttonText={t("New")} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SupportList />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Support;
