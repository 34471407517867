import axios from "axios";

export default class UserDataManager {

  static instance = null;
  userData = [];
  tenants = [];

  static getInstance() {
    if (UserDataManager.instance == null) {
      UserDataManager.instance = new UserDataManager();
    }

    return this.instance;
  }

  getUserData() {
    if (this.userData === undefined || this.userData === null || this.userData.length === 0) {
      // console.log("Retrieving from localStorage...", JSON.parse(localStorage.getItem("data")))
      if (JSON.parse(localStorage.getItem("data")) !== null) {
        return JSON.parse(localStorage.getItem("data"));
      } else {
        console.log("Loading, check.");
        return []
      }
    }
    return this.userData;
  }

  getUserRole() {
    return this.getUserData().role;
  }

  getIsFinanceFocus() {
    console.log("Checking if finance focus...");

    if (this.getUserRole() === "regular") {
      return axios
        .get("/contracts/permissions/me")
        .then((response) => {
          console.log("/permissions");

          // Check if all contracts have permission_level as "finance_agent"
          const allFinancialAgents = response.data.every(
            (contract) => contract.permission_level === "finance_agent"
          );

          return allFinancialAgents; // Resolve the promise with the result
        })
        .catch((error) => {
          console.error(error);
          return false; // Resolve the promise with false
        });
    } else {
      console.log(`Welcome, ${this.getUserRole()}`);
      return Promise.resolve(false); // Ensure it always returns a Promise
    }
  }

  isAdmin() {
    return (this.getUserRole() === "admin") ? true : false;
  }

  isTenantAdmin() {

    if (this.isAdmin()) {
      return true
    }

    const tenant = this.getUserData()?.tenants?.find(tenant => tenant.id === localStorage.getItem("tenant_id"));
    return (this.getUserRole() === "regular") && tenant?.is_tenant_admin ? true : false;
  }

  isAdminOrTenantAdmin() {

    if (this.getUserRole() === "admin") {
      return true
    } else if (this.getUserRole() === "regular") {

      if (this.getUserData()?.is_tenant_admin) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  isCapOn() {
    return (this.getUserRole() === "admin" || this.getUserRole() === "cap-on agent") ? true : false;
  }

  setUserData(input) {

    // console.log("Status", input.status);

    // if (input.status === "initial") {
    //   navigate("/confirm-sign-in");
    // }

    localStorage.setItem("data", JSON.stringify(input));
    this.userData = input;

    localStorage.setItem("tenants", JSON.stringify(input.tenants));
    this.tenants = input.tenants;

    console.log("Setting UserData and Tenants");
  }

  setContractPermissions(input) {
    localStorage.setItem("permissions", JSON.stringify(input));
    this.permissions = input;
  }
}