import React, { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { Plus } from "react-feather";

const AddAdditionalService = (props) => {

  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  const [title, setTitle] = useState();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState();

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    // hier sollte man im dropdown einen Kunden auswählen können, dessen ID dann im Post-Request gesetzt wird
    axios
      .post("/contracts/" + props?.contract_id + "/services/", {
        "name": title,
        "price": price,
        "quantity": quantity,
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/contracts / services");
        refreshPage();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }

  return (
    <>
      <Button
        onClick={() => toggle()}
        variant="outline-primary"
        size="sm"
        className="shadow-sm">
        <Plus className="feather" />{t("New")}
      </Button>
      <Modal size="lg" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t("Create additional service")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Title")}</Form.Label>
                  <Form.Control
                    placeholder={t("Name")}
                    required
                    type="text"
                    onChange={e => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Price")}</Form.Label>
                  <Form.Control
                    placeholder={t("Price")}
                    required
                    type="number"
                    onChange={e => setPrice(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Quantity")}</Form.Label>
                  <Form.Control
                    placeholder={t("Quantity")}
                    required
                    type="number"
                    onChange={e => setQuantity(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <hr />
            <Row>
              <Col xs="auto" className="ms-auto text-end mt-n1">
                <Button variant="outline-secondary" onClick={() => toggle(false)}>
                  {t("Cancel")}
                </Button>{" "}
                <Button variant={"primary"} type="submit">
                  {t("Create")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AddAdditionalService;
