import { Accordion, Badge, Card, Col, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { HelpCircle, Info } from "react-feather";

const FinanceInsights = (props) => {

  const { t, i18n } = useTranslation();

  const renderPercentageState = (value, reversed) => {

    if (isNaN(value) || value === Infinity) {
      return "badge bg-light"
    }
    switch (reversed) {
      case true:
        return value >= 0 ? "badge bg-warning" : "badge bg-success"
      default:
        return value >= 0 ? "badge bg-success" : "badge bg-warning"
    }
  }

  const changedTextState = (value, reversed) => {
    switch (reversed) {
      case true:
        return value >= 0 ? t("decreased by") : t("increased by")
      default:
        return value >= 0 ? t("increased by") : t("decreased by")
    }
  }

  const getPercentage = (value) => {

    var last_month = value?.last_month
    var month_before = value?.month_before

    return (last_month - month_before) / month_before
    // var last_monthToAllTimePercentChange = (last_month - all_time) / all_time
  }

  return (
    <Row>
      <Row>
        <Col md="12" className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Financial Insights")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <p>IoT-Daten können verwendet werden, um die Kosten von Anlagen und Ausrüstungen zu reduzieren, die Umsätze zu steigern und die Rentabilität von AaaS-Angeboten zu verbessern.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="4" className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("revenue")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.finance_insights?.revenue?.last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: "EUR", })}</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.finance_insights?.revenue))}>{getPercentage(props.insights?.finance_insights?.revenue)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', })}</span> {t("compared to last month")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md="4" className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("share_of_planned_revenue")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.finance_insights?.share_of_planned_revenue?.last_month)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', })}</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.finance_insights?.share_of_planned_revenue))}>{getPercentage(props.insights?.finance_insights?.share_of_planned_revenue)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', })}</span> {t("compared to last month")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Next Payment */}
        <Col md="4" className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("Next Payment • Forecast")}</h5>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{props.assetData?.next_payment?.payment_forecast_with_vat?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'currency', maximumFractionDigits: 2, currency: 'EUR',
              }) || "n/a"}</span>
              <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(props.assetData?.next_payment?.percent_change_to_planned_usage)}>{props.assetData?.next_payment?.percent_change_to_planned_usage?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0 }) || "n/a"}</span> {t("Difference to schedule")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Usage variability */}
        <Col md="4" className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("expected_revenue_without_services")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("expected_revenue_without_services")}</Popover.Header>
                        <Popover.Body>
                          Der erwartete Revenue für ein Asset. Vergangene Asset-Bills summiert + erwartete Zahlungen über gesamte Laufzeit faktoriert mit aktuellem Auslastungsfaktor.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.finance_insights?.expected_revenue_without_services?.value)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: "EUR" })}</span>
              {/* <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.finance_insights?.monthly_usage_variability), true)}>{getPercentage(props.insights?.finance_insights?.share_of_planned_revenue)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', })}</span> {t("compared to last month")}</span>
              </div> */}
            </Card.Body>
          </Card>
        </Col>

        {/* Residual value */}
        <Col md="4" className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("Net Asset Value")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("Net Asset Value")}</Popover.Header>
                        <Popover.Body>
                          Das cap-on System kalkuliert monatlich den aktuellen Buchwert des Assets laut AfA-Tabelle des Bundesfinanzministeriums abhängig von der eingestellten Asset-Kategorie (Anlagegut). <br /><br />Unternehmen ermitteln diesen Wert im Normalfall nur jährlich.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{props.assetData?.financials?.residual_book_value?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'currency', maximumFractionDigits: 0, currency: 'EUR',
              }) || "n/a"}</span>
              <div className="mb-0">
                <Badge bg="" className="badge-soft-success me-2">
                  {/* {AssetState == null ? "n/a" : formatDistanceToNow(parseISO(AssetState), { addSuffix: true })} */}
                </Badge>
                <span className="text-muted">{t("Value after depreciation")}</span>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Usage variability */}
        <Col md="4" className="d-flex">
          <Card className="flex-fill">
            <Card.Body className=" py-4">
              <Row>
                <Col className="mb-2">
                  <h5 className="card-title">{t("monthly_usage_variability")}</h5>
                </Col>
                <Col xs="auto" className="ms-auto text-end">
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Popover>
                        <Popover.Header as='h3'>{t("monthly_usage_variability")}</Popover.Header>
                        <Popover.Body>
                          Die relative Standardabweichung des monatlichen Nutzungsverhalten. Je höher desto risikoreicher ist das Asset. (sollte zwischen 0 und 1 liegen)
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <HelpCircle className="feather" />
                  </OverlayTrigger>
                </Col>
              </Row>
              <span className="h1 d-inline-block mt-1 mb-4">{(props.insights?.finance_insights?.monthly_usage_variability?.value)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 1, })}</span>
              {/* <div className="mb-0">
                <span className="text-muted"><span className={renderPercentageState(getPercentage(props.insights?.finance_insights?.monthly_usage_variability), true)}>{getPercentage(props.insights?.finance_insights?.share_of_planned_revenue)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', })}</span> {t("compared to last month")}</span>
              </div> */}
            </Card.Body>
          </Card>
        </Col>

      </Row>

      <Row>
        <Col md="12" className="d-flex">
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                {t("Details")}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <Accordion.Item eventKey="0" className="bg-white">
                  <Accordion.Header>{t("Financial Insights")}</Accordion.Header>
                  <Accordion.Body className="bg-white">
                    <Table responsive>

                      <thead>
                        <tr>
                          <th scope="col">{t("")}</th>
                          <th scope="col">{t("Insight")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props.insights?.finance_insights === undefined ? <></> :
                          Object.entries(props.insights?.finance_insights).map(([title, elementData], i) => {

                            var last_month = elementData?.last_month
                            var month_before = elementData?.month_before
                            var all_time = elementData?.all_time

                            var last_monthToMonthBeforePercentChange = (last_month - month_before) / month_before
                            var last_monthToAllTimePercentChange = (last_month - all_time) / all_time

                            if (isNaN(last_monthToMonthBeforePercentChange)) {
                              return (<></>)
                            }
                            return (
                              <tr key={i}>
                                <th>{t(title)}</th>
                                <td>{t(title)} {changedTextState(last_monthToMonthBeforePercentChange)} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span> {t("compared to last month")}.</td>
                                <td className="table-action">
                                  <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                      <Popover>
                                        <Popover.Header as='h3'>{t("Details")}</Popover.Header>
                                        <Popover.Body>
                                          {elementData?.explanation}
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <Info className="feather" />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                    <Col xs="auto" className="ms-auto text-end mt-n1">
                      {t("Updated")}: {props.insights?.created_at === undefined ? "-" : format(parseISO(props.insights?.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className="bg-white">
                  <Accordion.Header>{t("Insights Data Overview")}</Accordion.Header>
                  <Accordion.Body>
                    <Table responsive>

                      <thead>
                        <tr>
                          <th scope="col">{t("")}</th>
                          <th scope="col">{t("Last 30 days")}</th>
                          <th scope="col">{t("Month before")}</th>
                          <th scope="col">{t("Since SOP with cap-on")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {props.insights?.finance_insights === undefined ? <></> :
                          Object.entries(props.insights?.finance_insights).map(([title, elementData], i) => {

                            var last_month = elementData?.last_month
                            var month_before = elementData?.month_before
                            var all_time = elementData?.all_time

                            var last_monthToMonthBeforePercentChange = (last_month - month_before) / month_before
                            var last_monthToAllTimePercentChange = (last_month - all_time) / all_time

                            if (isNaN(last_monthToMonthBeforePercentChange)) {
                              return (<></>)
                            }
                            switch (title) {
                              case "share_of_planned_revenue":
                                return (<tr key={i}>
                                  <th>{t(title)}</th>
                                  <td>{last_month?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })}</td>
                                  <td>{month_before?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                  <td>{all_time?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                </tr>)
                              case "revenue":
                                return (<tr key={i}>
                                  <th>{t(title)}</th>
                                  <td>{last_month?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, })}</td>
                                  <td>{month_before?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                  <td>{all_time?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'currency', currency: 'EUR', maximumFractionDigits: 0, })} <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                  </td>
                                </tr>)
                              default:
                                return (
                                  <tr key={i}>
                                    <th>{t(title)}</th>
                                    <td>{(last_month / 60)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} min.</td>
                                    <td>{(month_before / 60)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} min. <span className={renderPercentageState(last_monthToMonthBeforePercentChange)}>{last_monthToMonthBeforePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                    <td>{(all_time / 60)?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'decimal', maximumFractionDigits: 0, })} min. <span className={renderPercentageState(last_monthToAllTimePercentChange)}>{last_monthToAllTimePercentChange?.toLocaleString(i18n.language === "de" ? de : "en", { style: 'percent', signDisplay: 'always', maximumFractionDigits: 0, })}</span>
                                    </td>
                                  </tr>
                                )
                            }
                          })
                        }
                      </tbody>
                    </Table>
                    <Col xs="auto" className="ms-auto text-end mt-n1">
                      {t("Created")}: {props.insights?.created_at === undefined ? "-" : format(parseISO(props.insights?.created_at), "dd MMM yyyy HH:mm", { locale: i18n.language === "de" ? de : null })}
                    </Col>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Row >
  )
};

export default FinanceInsights;