import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Badge, Card, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { RefreshCw, Plus } from "react-feather";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import AddClientModal from "./ClientModal_Add";
import LoadingSpinner from "../Helper/LoadingSpinner";

const Clients = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const loadAvatar = (url) => {
    return (
      <img
        src={url == null ? null : url}
        className="center"
        alt="avatar"
        width={50} height={50}
      />);
  };

  const ClientTile = ({ client }) => {
    return (
      <Col xxs={12} xs={6} md={6} xl={4} xxl={3} className="d-flex">
        <Card 
          className="shadow-none border w-100 d-flex flex-column" 
          onClick={() => navigate("/clients/" + client.id)} 
          style={{ cursor: "pointer" }}
        >
          <Card.Body className="p-3 d-flex flex-column">
            {loadAvatar(client?.avatar_url)}
            <div className="flex-grow-1 d-flex flex-column">
              <h5 className="mb-3 mt-3 overflow-hidden">
                <span className="text-title">{client?.business_name}</span>
              </h5>
              <div className="d-flex justify-content-between mt-auto">
                <div className="overflow-hidden me-auto">
                  <p className="text-muted mb-0">{client?.asset_count} {t("Assets")}</p>
                </div>
                <div className="align-self-end ms-2">
                  <p className="text-muted mb-0 text-truncate">{client?.contract_count} {t("Contracts")}</p>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  useEffect(() => {
    setIsLoading(true);

    axios
      .get("/webservice/clients/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/clients");
        setData([]);
        setData(response.data?.sort((a,b) => a?.business_name > b?.business_name));
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setIsLoading(false);
        setError({ showMessage: true, errorData: error })
        console.log(error);
      });
  }, []);

  const renderTable = () => {
    return data?.map((client) => {
      return (
        <tr key={client.id} onClick={() => navigate("/clients/" + client.id)} style={{ cursor: "pointer" }}>
          {/* <td >{client.client_number}</td> */}
          <td >{client == null ? null : loadAvatar(client.avatar_url)}</td>
          <td >{client.business_name}</td>
          <td >
            <Badge bg="" className={client.unpaid_invoice_count === 0 ? "badge-soft-success me-2" : "badge-soft-warning me-2"} >{client.unpaid_invoice_count}</Badge>
          </td>
        </tr>
      );
    });
  };

  return (
    <React.Fragment>
      <Helmet title={t("Clients")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Clients")} / {t("Operators")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <AddClientModal />
            {/* <Button
              onClick={() => navigate("/clients/add")}
              variant="primary"
              className="shadow-sm me-1"
            >
              <Plus className="feather" /> {t("New")}
            </Button> */}
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
          </Col>
        </Row>

        <Col>
          <ErrorHandler error={error} />
          <Card>
            {/* <Card.Body> */}
            {
              isLoading === true ?
                <LoadingSpinner withStandardText /> :
                (data.length > 0)
                  ? // If data found

                  <Card.Body>
                    <div className="container">
                      <div className="row">
                        {
                          data?.map((client) => {
                            return <ClientTile client={client} key={client.id}/>;
                          })
                        }
                      </div>
                    </div>
                  </Card.Body>

                  // <Table responsive hover>
                  //   <thead>
                  //     <tr>
                  //       {/* <th scope="col">#</th> */}
                  //       <th scope="col"></th>
                  //       <th scope="col">{t("Name")}</th>
                  //       <th scope="col">{t("Unpaid Invoices")}</th>
                  //     </tr>
                  //   </thead>
                  //   <tbody>{renderTable()}</tbody>
                  // </Table>

                  : // If no data found
                  <Card.Body>
                    <p className="card-subtitle text-muted">
                      {t("No data found.")}
                    </p>
                  </Card.Body>
            }
            {/* </Card.Body> */}
          </Card>
        </Col>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Clients;
