import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
} from "react-bootstrap";
import React, { useContext, useEffect, useRef } from "react";
import {
  Clipboard,
  Download,
  ExternalLink,
  HelpCircle,
} from "react-feather";
import NotyfContext from "../../../contexts/NotyfContext";
import VNCLogo from "../../../assets/img/realVNC.png";
import HMIIcon from "../../../assets/img/hmi.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { format, formatDistanceToNow, formatDuration, intervalToDuration, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import UserDataManager from "../../Helper/UserDataManager";
import { VncScreen } from '@cap-on/cap-onvnc';
import usePalette from "../../../hooks/usePalette";
import { Line } from "react-chartjs-2";
import FinancialsSection from "./FinancialsSection";
import InvoiceTable from "../../Invoices/InvoiceTable";

const AssetUsageSection = ({ assetData, assetProduction }) => {

  const palette = usePalette();
  const { t, i18n } = useTranslation();

  const [AssetLogSOLLISTArray, setAssetSOLLISTArray] = useState([])
  const [productionData, setProductionData] = useState()
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {

    if (assetProduction === undefined) {

      axios
        .get("/webservice/assets/" + assetData?.id + "/production")
        .then(function (response) {
          // handle success
          console.log(response.status, "/production");
          setProductionData(response.data)
          setAssetSOLLISTArray(response.data?.usage_section?.planned_actual_usages?.reverse());
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });

      axios
        .get("/webservice/invoices/", {
          params: {
            contract_id: assetData?.contract_id
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "/invoices");
          setInvoices(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      setProductionData(assetProduction)
      setAssetSOLLISTArray(assetProduction?.usage_section?.planned_actual_usages?.reverse() || []);
    }

  }, []);

  const ChartPlannedVsActual = () => {

    const data = {
      labels: AssetLogSOLLISTArray?.map(item => { return format(parseISO(item["month_start"]), 'MMM yyyy', { locale: i18n.language === "de" ? de : null }) }),
      datasets: [
        {
          label: t("Actual Production"),
          fill: true,
          tension: false,
          backgroundColor: "transparent",
          borderColor: palette.primary,
          data: AssetLogSOLLISTArray?.map(item => { return item.actual_usage }),
        },
        {
          label: t("Planned Production"),
          fill: true,
          tension: false,
          backgroundColor: "transparent",
          borderColor: palette["primary-dark"],
          borderDash: [4, 4],
          data: AssetLogSOLLISTArray?.map(item => { return item.planned_usage }),
        },
      ],
    };

    const options = {
      animation: false,
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom',
        horizontalAlign: 'center',
        itemMargin: {
          horizontal: 5,
          vertical: 0
        },
        onItemClick: {
          toggleDataSeries: true
        },
      },
      tooltips: {
        intersect: false,
        callbacks: {
          label: function (tooltipItem, data) {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, });
            label += ` ${assetData?.billing_unit_type}`

            return label
          }
        }

      },
      scales: {
        xAxes: [
          {
            reverse: true,
            gridLines: {
              color: "rgba(0,0,0,0.05)",
            },
          },
        ],
        yAxes: [
          {
            display: true,
            borderDash: [5, 5],
            gridLines: {
              color: "rgba(0,0,0,0)",
              fontColor: "#fff",
            },
            ticks: {
              // stepSize: 1000,
              callback: function (value, index, ticks) {
                return value.toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 0, });
              }
            },
          },
        ],
      },
    };

    return (
      <Card className="flex-fill w-100">
        <Card.Header>
          {/* <Card.Title tag="h5">{t("Asset Usage (Monthly)")}</Card.Title> */}
          {/* <h6 className="card-subtitle text-muted">
          A line chart is a way of plotting data points on a line.
        </h6> */}
          <Row>
            {/* xs="auto" className="d-none d-sm-block" */}
            <Col>
              <Card.Title tag="h5">{t("Asset Usage (Monthly)")}</Card.Title>
            </Col>
            {/* <Col xs="auto" className="ms-auto text-end">
              <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={() => navigate("/assets/" + id + "/usage")}>{t("More usage details")}</Button>
            </Col> */}
          </Row>
        </Card.Header>
        <Card.Body>
          {
            AssetLogSOLLISTArray?.length === 0 || AssetLogSOLLISTArray === null || AssetLogSOLLISTArray === undefined
              ?
              <h6 className="card-subtitle text-muted">
                {t("No production data available.")}
              </h6>

              :
              <div className="chart">
                <Line data={data} options={options} />
              </div>
          }
        </Card.Body>
      </Card>
    );
  };

  function getOperatingTimes(stateString) {
    if (productionData?.oee_section?.state_periods === null || productionData?.oee_section?.state_periods.length === 0) {
      return "-"
    } else {
      let secondsForState = assetProduction?.oee_section?.state_periods?.find(element => element.state_name === stateString)?.percent_of_total_time
      return secondsForState === undefined ? "-" : formatDuration(intervalToDuration({ start: 0, end: secondsForState * 1000 }), { format: ['days', 'hours', 'minutes'], locale: i18n.language === "de" ? de : null, delimiter: ", " })
    }
  }

  const deviation_from_planned = productionData?.usage_section?.current_month_prediction?.asset_bill_total_percent_deviation_from_planned / 100

  return (<>
    <Row>
      <Col md="6" className="d-flex">
        <ChartPlannedVsActual />
      </Col>
      <Col md="6" >
        <Row>
          {/* Usage Today Tile */}
          <Col md="6" xs="6" className="d-flex">
            <Card className="flex-fill">
              <Card.Body className=" py-4">
                <Row>
                  <Col className="mb-2">
                    <h5 className="card-title">{t("Units • Today")}</h5>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-1 mb-4">{productionData?.usage_section?.usages_today == null ? "0" : (parseFloat(productionData?.usage_section?.usages_today)).toLocaleString(i18n.language === "de" ? de : "en", { maximumFractionDigits: 2 })} {assetData?.billing_unit_type || ""}</span>
                <div className="mb-0">
                  <Badge bg="" className="badge-soft-success me-2">
                    {productionData?.usage_section?.usages_today == null ? "-" : (productionData?.usage_section?.usages_today * (assetData?.financials?.price_factors?.length > 0 ? assetData?.financials?.price_factors[0]?.price : 0)).toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'currency',
                      maximumFractionDigits: 2,
                      currency: 'EUR',
                    })}
                  </Badge>
                  <span className="text-muted">{
                    UserDataManager.getInstance().isCapOn ?
                      t("Costs • today") : t("Charges • today")
                  }</span>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {/* Usage Counter Tile */}
          <Col md="6" xs="6" className="d-flex">
            <Card className="flex-fill">
              <Card.Body className=" py-4">
                <Row>
                  <Col className="mb-2">
                    <h5 className="card-title">{t("Usage counter")}</h5>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-1 mb-4">{productionData?.usage_section?.current_usage_counter?.toLocaleString() || "-"}</span>
              </Card.Body>
            </Card>
          </Col>

          {/* Prediction Tile */}
          <Col md="6" xs="6" className="d-flex">
            <Card className="flex-fill">
              <Card.Body className="py-4">
                <Row>
                  <Col className="mb-2">
                    <h5 className="card-title">{t("Next invoice")} ({t("AI-based prediction")})</h5>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-1 mb-4">
                  {productionData?.usage_section?.current_month_prediction?.asset_bill_total_forecast?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 2,
                  }) || "-"}
                </span>
                <br />
                <span className="text-muted">
                  {t("Billing date")}: <strong>{(productionData && productionData?.usage_section?.current_month_prediction?.asset_bill_next_date)
                    ? format(parseISO(productionData?.usage_section?.current_month_prediction?.asset_bill_next_date), 'd MMM yyyy', { locale: i18n.language === "de" ? de : null })
                    : "-"}</strong>
                </span><br />
                <span className="text-muted">
                  {t("Approx.")} <strong>{productionData?.usage_section?.current_month_prediction?.usage_current_month_forecast?.toLocaleString(i18n.language === "de" ? de : "en", {
                    style: 'decimal',
                    maximumFractionDigits: 0,
                  })} {assetData?.billing_unit_type || t("units")}</strong> {t("this month")} {deviation_from_planned && <span><Badge bg="" className={`badge-soft-${deviation_from_planned < 0 ? "warning" : "success"} me-2`}>
                  {(deviation_from_planned >= 0 ? '+' : '') +
                    (deviation_from_planned).toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })} {t("of planned")}
                </Badge></span>}
                </span>
                <br />
                {/* <div className="mb-0">
                  <Badge bg="" className={`badge-soft-${productionData?.usage_section?.current_month_prediction?.asset_bill_total_percent_change < 0 ? "warning" : "success"} me-2`}>
                    {(productionData?.usage_section?.current_month_prediction?.asset_bill_total_percent_change >= 0 ? '+' : '') +
                      (productionData?.usage_section?.current_month_prediction?.asset_bill_total_percent_change).toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </Badge>
                  <span className="text-muted">{t("Compared to last month")}</span>
                </div>
                <div className="mb-0">
                  <Badge bg="" className={`badge-soft-${productionData?.usage_section?.current_month_prediction?.asset_bill_total_percent_deviation_from_planned < 0 ? "warning" : "success"} me-2`}>
                    {(productionData?.usage_section?.current_month_prediction?.asset_bill_total_percent_deviation_from_planned >= 0 ? '+' : '') +
                      (productionData?.usage_section?.current_month_prediction?.asset_bill_total_percent_deviation_from_planned).toLocaleString(i18n.language === "de" ? de : "en", {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </Badge>
                  <span className="text-muted">{t("Compared to target")}</span>
                </div> */}
              </Card.Body>
            </Card>
          </Col>

          {/* Last Usage Tile */}
          <Col md="6" xs="6" className="d-flex">
            <Card className="flex-fill">
              <Card.Body className=" py-4">
                <Row>
                  <Col className="mb-2">
                    <h5 className="card-title">{t("Last produced unit")}</h5>
                  </Col>
                </Row>
                <span className="h1 d-inline-block mt-1 mb-4">
                  {
                    productionData?.usage_section?.last_usage_log
                      ? formatDistanceToNow(parseISO(productionData?.usage_section?.last_usage_log?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })
                      : "-"
                  }
                </span>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>

    <FinancialsSection assetData={assetData} />

    <hr />
    <div className="h4 py-2">{t("Asset State Overview")}</div>
    <Row>
      {
        productionData?.oee_section?.state_periods?.map(entry => {
          return (
            <Col key={entry?.state_name} md={3} xs="6" className="d-flex">
              <Card className="flex-fill">
                <Card.Body>
                  <Row>
                    <Col className="mb-2">
                      <h5 className="card-title">{t(entry?.state_name)}</h5>
                    </Col>
                  </Row>
                  <span className="h1 d-inline-block mt-1 mb-4">
                    {(entry?.percent_of_total_time)?.toLocaleString(i18n.language === "de" ? de : "en", {
                      style: 'percent',
                      maximumFractionDigits: 1,
                    }) || "-"}
                  </span>
                  <div className="mb-0">
                    {/* <Badge bg="" className="badge-soft-success me-2">{badgeValue}</Badge> */}
                    <span className="text-muted">{t("Current month")}</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        })
      }
    </Row >

    <hr />
    <div className="h4 py-2">{t("Invoices")}</div>
    <Row>
      <Col md="12" className="d-flex">
        <InvoiceTable data={invoices} />
      </Col>
    </Row>
  </>)
}

export default AssetUsageSection;