import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../Helper/ErrorHandler";
import { parseISO, startOfDay } from "date-fns";
import NotyfContext from "../../contexts/NotyfContext";
import { zonedTimeToUtc } from 'date-fns-tz'
import LoadingSpinner from "../Helper/LoadingSpinner";

const AddInvoiceModal = ({ handleCancel, handleSave, ...props }) => {
  const notyf = useContext(NotyfContext);
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });

  const [DueDatePicker, setDueDatePicker] = useState();
  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState();
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState();
  const [data, setData] = useState([]);

  const [invoiceIncludeLastDay, setInvoiceIncludeLastDay] = useState(true);
  const [sendAlert, setSendAlert] = useState(false);

  const [DueDate, setDueDate] = useState();
  const [PeriodStartDate, setPeriodStartDate] = useState();
  const [PeriodEndDate, setPeriodEndDate] = useState();
  const [Contract, setContract] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  function handleChangeStartDatePicker(event) {
    setPeriodStartDatePicker(event.target.value)
    setPeriodStartDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeEndDatePicker(event) {
    setPeriodEndDatePicker(event.target.value)
    setPeriodEndDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeDueDatePicker(event) {
    setDueDatePicker(event.target.value)
    setDueDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeDatesLastMonth(event) {

    const startOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth() - 1, 2)
    const endOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth(), 1)
    const dueDate = new Date(new Date().getFullYear(), (new Date()).getMonth() + 1, 0)

    setPeriodStartDatePicker(startOfLastMonth.toISOString().split('T')[0])
    setPeriodEndDatePicker(endOfLastMonth.toISOString().split('T')[0])
    setDueDatePicker(dueDate.toISOString().split('T')[0])

    setPeriodStartDate(zonedTimeToUtc(parseISO(startOfLastMonth.toISOString().split('T')[0]), "GMT").toISOString())
    setPeriodEndDate(zonedTimeToUtc(parseISO(endOfLastMonth.toISOString().split('T')[0]), "GMT").toISOString())
    setDueDate(zonedTimeToUtc(parseISO(dueDate.toISOString().split('T')[0]), "GMT").toISOString())
    setInvoiceIncludeLastDay(true)

    // console.log(PeriodStartDate, PeriodEndDate, DueDate)
  }

  function handleChangeDatesCurrentMonth(event) {

    const startOfLastMonth = new Date(new Date().getFullYear(), (new Date()).getMonth(), 2)
    const yesterday = startOfDay(new Date());
    const dueDate = new Date(new Date().getFullYear(), (new Date()).getMonth() + 1, 0)

    setPeriodStartDatePicker(startOfLastMonth.toISOString().split('T')[0])
    setPeriodEndDatePicker(yesterday.toISOString().split('T')[0])
    setDueDatePicker(dueDate.toISOString().split('T')[0])

    setPeriodStartDate(zonedTimeToUtc(parseISO(startOfLastMonth.toISOString().split('T')[0]), "GMT").toISOString())
    setPeriodEndDate(zonedTimeToUtc(parseISO(yesterday.toISOString().split('T')[0]), "GMT").toISOString())
    setDueDate(zonedTimeToUtc(parseISO(dueDate.toISOString().split('T')[0]), "GMT").toISOString())
    setInvoiceIncludeLastDay(true)

    // console.log(PeriodStartDate, PeriodEndDate, DueDate)
  }


  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    setIsLoading(true)

    axios
      .post("/invoices/include_asset_bills?include_last_day=" + invoiceIncludeLastDay,
        {
          due_date: DueDate,
          contract_id: Contract,
          invoice_period_start: PeriodStartDate,
          invoice_period_end: PeriodEndDate,
          create_alerts: sendAlert,
          additional_items: [
            // {
            //   "name": "cap-on Digital Premium Package",
            //   "quantity": 1,
            //   "price": 2199,
            //   "total": 2199
            // },
            // {
            //   "name": "Taktzeitanalyse",
            //   "quantity": 1,
            //   "price": 600,
            //   "total": 600
            // },
            // {
            //   "name": "CO\u2082-Kompensation",
            //   "amount": 31
            // }
          ]
        }
      )
      .then(function (response) {
        // handle success
        console.log(response.status, "/invoices");
        setIsLoading(false)

        handleSave();
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        setIsLoading(false)
        console.log(error);
      });
  }

  useEffect(() => {
    axios
      .get("/contracts/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/contracts");
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const loadContracts = () => {
    return data.map((contract) => {
      return (
        <option key={contract.id} value={contract.id}>{contract.title} ({contract.client_name})</option>
      );
    });
  };

  return (
    <Modal size="ms" show onHide={handleCancel}>
      <Modal.Header closeButton>{t("Create Invoice")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        {isLoading
          ? <LoadingSpinner withText={t("Creating invoice...")} />
          : <div>
            <Row className="mb-5">
              <Col xs="auto" className="ms-auto text-end">
                <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={handleChangeDatesLastMonth}>
                  {t("Pre-fill Last Month")}
                </Button>
                <Button size="sm" variant="outline-primary" className="shadow-sm me-1" onClick={handleChangeDatesCurrentMonth}>
                  {t("Pre-fill Current Month")}
                </Button>
              </Col>
            </Row>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Invoice Period Start")}</Form.Label>
                    <Form.Control
                      value={PeriodStartDatePicker}
                      required
                      // value={"2022-12-03"}
                      onChange={handleChangeStartDatePicker}
                      type="date"
                    />
                  </Form.Group>
                </Col>
                {/* <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  value="2381"
                  type="number"
                  onChange={handleChangeAmount}
                />
              </Form.Group>
            </Col> */}
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Invoice Period End")}</Form.Label>
                    <Form.Control
                      value={PeriodEndDatePicker}
                      required
                      onChange={handleChangeEndDatePicker}
                      type="date"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                </Col>
                <Col md={6}>
                  <Form.Check
                    checked={invoiceIncludeLastDay}
                    onChange={e => setInvoiceIncludeLastDay(!invoiceIncludeLastDay)}
                    label={t("Include last day")}
                    type="checkbox" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col md={6}>
                  {/* <Form.Group className="mb-3">
                <Form.Label>Contract</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e59d31a7-8a41-4e35-b8d6-a89ec888b7ad"
                  onChange={handleChangeContract}
                />
              </Form.Group> */}
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Contract")}</Form.Label>
                    <Form.Select
                      name="state"
                      required
                      onChange={e => setContract(e.target.value)}>
                      <option value="">{t("-- Select --")}</option>
                      {loadContracts()}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Due date")}</Form.Label>
                    <Form.Control
                      value={DueDatePicker}
                      required
                      onChange={handleChangeDueDatePicker}
                      type="date"
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <br />
            <Row>
              <Col md={12}>
                <Form.Check
                  checked={sendAlert}
                  onChange={e => setSendAlert(!sendAlert)}
                  label={t("Send invoice notification to recipient")}
                  type="checkbox" />
              </Col>
            </Row> */}
              <br />
              <hr />
              <Row>
                <Col xs="auto" className="ms-auto text-end mt-n1">
                  <Button variant="outline-secondary" onClick={handleCancel}>
                    {t("Cancel")}
                  </Button>{" "}
                  <Button variant={"primary"} type="submit">
                    {t("Create")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default AddInvoiceModal;
