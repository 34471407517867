import React from "react";
import { Button } from "react-bootstrap";
import { LogIn } from "react-feather";

const SidebarLoginButton = () => {

  return (
    <div className="sidebar-cta" style={{ paddingTop: "30px" }}>
      <div className="sidebar-cta-content">
        <strong className="d-inline-block mb-2">Public Mode</strong>
        <p>You're currently not logged in.</p>
        <Button
          variant="primary"
          className="shadow-sm me-1"
          onClick={() => window.open("https://app.cap-on.de/sign-in")}>
          <LogIn className="feather" /> Login
        </Button>
      </div>
    </div>
  );
};

export default SidebarLoginButton;
