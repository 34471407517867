import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Card,
  Col,
  Container,
  Row,
  Breadcrumb,
  Table,
  Dropdown,
  Form,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import {
  Download,
  MoreHorizontal,
  RefreshCw
} from "react-feather";
import { Button } from "react-bootstrap";
import { endOfDay, format, formatDistanceToNow, parseISO, startOfDay } from 'date-fns'
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import ErrorHandler from "../Helper/ErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import json2csv from 'json2csv';
// import { writeFile } from 'xlsx';
import { zonedTimeToUtc } from "date-fns-tz";
import { getCoreBaseURL } from "../../config";

const AssetUsageDetailsLog = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState();
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState();
  const [unit, setUnit] = useState("1 day");
  const fortnightAgo = new Date(startOfDay(new Date() - 24 * 60 * 60 * 1000 * 14)) // new Date(new Date().getFullYear(), (new Date()).getMonth() - 1, 2)
  const endOfToday = endOfDay(new Date())
  const [PeriodStartDate, setPeriodStartDate] = useState(zonedTimeToUtc(parseISO(fortnightAgo.toISOString().split('T')[0]), "GMT").toISOString());
  const [PeriodEndDate, setPeriodEndDate] = useState(zonedTimeToUtc(endOfToday).toISOString());
  const [clusteredCheck, setClusteredCheck] = useState(false);

  useEffect(() => {
    resetFields();
    handleSubmit(null)

    axios
      .get(getCoreBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "/openapi");
        setUnits(response.data["components"]["schemas"]["TimeClusteringUnit"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  function handleChangeStartDatePicker(event) {
    setPeriodStartDatePicker(event.target.value)
    setPeriodStartDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeEndDatePicker(event) {
    setPeriodEndDatePicker(event.target.value)
    setPeriodEndDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  const [units, setUnits] = useState([]);

  const loadUnits = () => {
    return units?.map((entry) => {
      return (
        <option key={entry} selected={unit === entry}>{entry}</option>
      );
    });
  };

  function resetFields() {

    setPeriodStartDatePicker(fortnightAgo.toISOString().split('T')[0])
    setPeriodEndDatePicker(endOfToday.toISOString().split('T')[0])

    setPeriodStartDate(zonedTimeToUtc(parseISO(fortnightAgo.toISOString().split('T')[0]), "GMT").toISOString())
    setPeriodEndDate(zonedTimeToUtc(endOfToday).toISOString())
    setUnit("1 day");
  }

  function handleSubmit(item) {
    item?.preventDefault();

    setError({ showMessage: false, errorData: null })

    if (clusteredCheck) {
      axios
        .get("/assets/" + id + "/usage_logs/clustered", {
          params: {
            "asset_id": id,
            "period_start": PeriodStartDate,
            "period_end": PeriodEndDate,
            "clustering_unit": unit,
            "fill_gaps": true
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "/usage_logs/clustered");
          setData(response.data);
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    } else {
      axios
        .get("/assets/" + id + "/usage_logs/", {
          params: {
            "period_start": PeriodStartDate,
            "limit": 500
          }
        })
        .then(function (response) {
          // handle success
          console.log(response.status, "Loaded usage logs");
          setData(response.data);
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }


  }


  const renderTable = () => {
    return data?.map((usage) => {
      return (
        <tr key={usage.id}>
          <td>{format(parseISO(usage.time), 'd MMM, HH:mm:ss', { locale: i18n.language === "de" ? de : null })}</td>
          <td>{formatDistanceToNow(parseISO(usage.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td>
          <td>{usage.amount}</td>
          <td>{usage.status}</td>
        </tr>
      );
    });
  };


  // const downloadFile = (data, format, fields) => {
  //   if (format === 'csv') {
  //     const csvData = json2csv.parse(data, { fields, delimiter: ';' });
  //     const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  //     const link = document.createElement('a');
  //     if (link.download !== undefined) {
  //       const url = URL.createObjectURL(blob);
  //       link.setAttribute('href', url);
  //       link.setAttribute('download', 'usage_data_export.csv');
  //       link.style.visibility = 'hidden';
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   } else if (format === 'excel') {
  //     const filteredData = data.map(obj => {
  //       const filteredObj = {};
  //       fields.forEach(field => {
  //         filteredObj[field] = obj[field];
  //       });
  //       return filteredObj;
  //     });

  //     const sheet = writeFile(filteredData, { bookType: 'xlsx', type: 'buffer' });
  //     const blob = new Blob([sheet], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const link = document.createElement('a');
  //     if (link.download !== undefined) {
  //       const url = URL.createObjectURL(blob);
  //       link.setAttribute('href', url);
  //       link.setAttribute('download', 'usage_data_export.xlsx');
  //       link.style.visibility = 'hidden';
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // };

  const UsageLogsList = () => (
    <Card>
      <Card.Body>
        <Table responsive hover>
          {/* <thead>
            <tr>
              <th scope="col">{t("Timestamp")}</th>
              <th scope="col">{t("Timestamp")}</th>
              <th scope="col">Amount</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>{renderTable()}</tbody> */}

          <thead>
            <tr>
              {
                data.length > 0 &&
                Object.entries(data[0]).map(([title, elementData], i) => {

                  if (title !== "asset_id" && title !== "id") {
                    return (
                      <th scope="col">{t(title)}</th>
                    )
                  }
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              Object.entries(data).map(([t, elementData], i) => {
                return (
                  <tr key={i}>
                    {
                      Object.entries(elementData).map(([title, element], i) => {

                        if (title !== "asset_id" && title !== "id") {
                          return (
                            <td>{element}</td>
                          )
                        }
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );

  return (
    <React.Fragment>
      <Helmet title={data.title} />
      <Container fluid className="p-0">
        <Breadcrumb>
          {/* <div className="vertical-center-all"> */}
          <Button variant="outline-primary" size="sm" className="me-2" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} /> {t("Back")}
          </Button>
          <Breadcrumb.Item href="#" onClick={() => navigate("/assets")}>{t("Assets")}</Breadcrumb.Item>
          <Breadcrumb.Item href={"/assets/" + id}>{t("Asset 360° View")}</Breadcrumb.Item>
          <Breadcrumb.Item active>{t("Asset usage details logs")}</Breadcrumb.Item>
          {/* </div> */}
        </Breadcrumb>
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>{t("Last usages")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button>
            {/* <Dropdown align={{ lg: 'end' }} className="d-inline">
              <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
                <Download className="feather align-middle mt-n1" /> {t("More")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => downloadFile(data, 'excel', ['name', 'age', 'email'])}>{t("Excel-Spreadsheet")}</Dropdown.Item>
                <Dropdown.Item onClick={() => downloadFile(data, 'csv', ['time', 'created_at', 'amount', 'part_type', 'status'])}>{t(".csv")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </Col>
        </Row>
        <Row>
          <ErrorHandler error={error} />
        </Row>
        <Row>
          <Col md="9">
            <UsageLogsList />
          </Col>
          <Col md="3">
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5">{t("Asset Usage Configuration")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Period start date")}</Form.Label>
                        <Form.Control
                          value={PeriodStartDatePicker}
                          // value={"2022-12-03"}
                          onChange={handleChangeStartDatePicker}
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Period end date")}</Form.Label>
                        <Form.Control
                          value={PeriodEndDatePicker}
                          // value={"2022-12-03"}
                          onChange={handleChangeEndDatePicker}
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Unit/Interval")}</Form.Label>
                      <Form.Select
                        onChange={e => setUnit(e.target.value)}>
                        {loadUnits()}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        checked={clusteredCheck}
                        onChange={() => setClusteredCheck(!clusteredCheck)}
                        label={t("Load clustered")}
                        type="checkbox" />
                    </Form.Group>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Button
                        variant="outline-primary"
                        className="shadow-sm me-1 mt-1"
                        onClick={() => resetFields()}>
                        {t("Reset")}
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        className="shadow-sm me-1 mt-1"
                      >
                        {t("Query")}
                      </Button>
                      {/* <Button
                        onClick={() => downloadFile(data, 'csv', ['interval_start', 'time', 'created_at', 'amount', 'part_type', 'status'])}
                        variant="outline-primary"
                        className="shadow-sm me-1 mt-1"
                      >
                        <Download className="feather" /> Export .csv
                      </Button> */}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default AssetUsageDetailsLog;
