import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Form, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { endOfDay, format, formatDistanceToNow, parseISO, startOfDay } from "date-fns";
import { de } from "date-fns/locale";
import { zonedTimeToUtc } from "date-fns-tz";
import usePalette from "../../../hooks/usePalette";
import GenericChart from "../Charts/GenericChartComponent";
import { Download } from "react-feather";
import { getCoreBaseURL } from "../../../config";

const AssetDigitalTwinChartModalMultiple = ({ asset_id, data, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState();
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState();
  const aWeekAgo = new Date(startOfDay(new Date() - 24 * 60 * 60 * 1000 * 7)) // new Date(new Date().getFullYear(), (new Date()).getMonth() - 1, 2)
  const endOfToday = endOfDay(new Date())
  const [PeriodStartDate, setPeriodStartDate] = useState(zonedTimeToUtc(parseISO(aWeekAgo.toISOString().split('T')[0]), "GMT").toISOString());
  const [PeriodEndDate, setPeriodEndDate] = useState(zonedTimeToUtc(endOfToday).toISOString());
  const [Entries, setEntries] = useState(100);
  const [clustering_unit, setClustering_unit] = useState("1 hour");
  // const [ShowTrendView, setShowTrendView] = useState(false);
  const [Labels, setLabels] = useState([]);
  // const [ChartData, setChartData] = useState([]);
  const [chartDataClustered, setChartDataClustered] = useState([]);

  const [title, setTitle] = useState("");
  const [chartType, setChartType] = useState();

  function resetFields() {
    setPeriodStartDatePicker(aWeekAgo.toISOString().split('T')[0])
    setPeriodEndDatePicker(endOfToday.toISOString().split('T')[0])
    setEntries(100);

    setPeriodStartDate(zonedTimeToUtc(parseISO(aWeekAgo.toISOString().split('T')[0]), "GMT").toISOString())
    setPeriodEndDate(zonedTimeToUtc(endOfToday).toISOString())
    // setUnit("1 day");
  }

  const fetchData = async () => {
    const plcNodeIds = data?.plc_node_id_array || [];
    const requests = plcNodeIds.map((plcNodeId, index) =>
      axios.get(`/assets/${asset_id}/asset_logs`, {
        params: {
          period_start: PeriodStartDate,
          plc_node_id: plcNodeId,
          limit: Entries > 200000 ? 200000 : Entries,
        },
      })
    );

    try {
      const responses = await Promise.all(requests);

      const newDataSets = responses.map((response, index) => {
        const label = `ID: ${response.data?.[0].plc_node_id}`;
        const data = response.data.sort((a, b) => (a.time > b.time)).map((item) => item.value);
        const borderColor = `rgba(${75 + index * 40}, 192, ${192 - index * 40}, 1)`;

        return {
          label,
          data,
          borderColor,
          fill: false,
        };
      });

      setChartData({
        labels: responses[0].data.sort((a, b) => (a.time > b.time)).map((item) => format(parseISO(item.time), "EEE dd MM, HH:mm", { locale: i18n.language === "de" ? de : null })),
        datasets: newDataSets,
      });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchDataClustered = async () => {
    const plcNodeIds = data?.plc_node_id_array || [];
    const requests = plcNodeIds.map((plcNodeId, index) =>
      axios
        .get(`/assets/${asset_id}/asset_logs/clustered`, {
          params: {
            period_start: PeriodStartDate,
            plc_node_id: plcNodeId,
            clustering_unit: clustering_unit,
            limit: Entries > 200000 ? 200000 : Entries,
          },
        })
    );

    try {
      const responses = await Promise.all(requests);

      const newDataSets = responses.map((response, index) => {
        const label = `ID: ${response.config.params.plc_node_id}`;
        const data = response.data.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)).map((item) => item.amount);
        const borderColor = `rgba(${75 + index * 40}, 192, ${192 - index * 40}, 1)`;

        return {
          label,
          data,
          borderColor,
          fill: false,
        };
      });

      setChartDataClustered({
        labels: responses[0].data.sort((a, b) => new Date(a.interval_start) - new Date(b.interval_start)).map((item) => format(parseISO(item.interval_start), "EEE dd MM, HH:mm", { locale: i18n.language === "de" ? de : null })),
        datasets: newDataSets,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {

    resetFields();

    if (show) {
      // console.log("Now showing modal")

      fetchData();
    }

    setTitle(`${t(data?.title)} ${data?.unit ? `(${data?.unit})` : ""}`)
    setChartType(data?.chartType)

  }, [show]);

  function showTrendView(item) {
    item?.preventDefault();

    console.log("Show trend view")

    handleSubmitClustered(null);

    axios
      .get(getCoreBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "Loaded JSON");
        setClusterUnit(response.data["components"]["schemas"]["TimeClusteringUnitExt"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function handleSubmit(item, plc_node) {
    item?.preventDefault();

    fetchData();
  }

  function handleSubmitClustered(item) {
    item?.preventDefault();

    fetchDataClustered();

    // // setIsLoading(true);
    // setError({ showMessage: false, errorData: null })

    // axios
    //   .get("/assets/" + asset_id + "/asset_logs/clustered", {
    //     params: {
    //       // "asset_id": id,
    //       "period_start": PeriodStartDate,
    //       //"period_end": PeriodEndDate,
    //       "clustering_unit": clustering_unit,
    //       "plc_node_id": data?.plc_node_id,
    //       "limit": 100 // Entries > 200000 ? 200000 : Entries // This makes sure that entries cant be > 200k
    //     }
    //   })
    //   .then(function (response) {
    //     // handle success
    //     console.log(response.status, "Loaded details for node_id /clustered");
    //     setChartDataClustered(response.data.sort((a, b) => (a.time > b.time)))
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     // setError({ showMessage: true, errorData: error })
    //     console.log(error);
    //   });
  }

  const [ClusterUnit, setClusterUnit] = useState([]);

  const loadClusterUnit = () => {
    return ClusterUnit.map((unit) => {
      return (
        <option value={unit}>{unit}</option>
      );
    });
  };

  function handleChangeStartDatePicker(event) {
    setPeriodStartDatePicker(event.target.value)
    setPeriodStartDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  function handleChangeEndDatePicker(event) {
    setPeriodEndDatePicker(event.target.value)
    setPeriodEndDate(zonedTimeToUtc(parseISO(event.target.value), "GMT").toISOString())
  }

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  return (
    <Modal size="xl" show={show} onHide={handleClose} >
      <Modal.Header closeButton>{t("Data View")}</Modal.Header>
      <Modal.Body className="m-3" style={{ background: "#f5f5f5", padding: "20px" }}>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="9" className="d-flex">
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5">{t(title)}</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="chart">
                  <GenericChart type="line" data={chartData} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3" className="d-flex">
            <Card className="flex-fill">
              <Card.Header>
                <Card.Title tag="h5">{t("Configuration")}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Period start date")}</Form.Label>
                        <Form.Control
                          value={PeriodStartDatePicker}
                          // value={"2022-12-03"}
                          onChange={handleChangeStartDatePicker}
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col>
                              <Form.Group className="mb-3">
                                <Form.Label>{t("Period end date")}</Form.Label>
                                <Form.Control
                                  value={PeriodEndDatePicker}
                                  // value={"2022-12-03"}
                                  onChange={handleChangeEndDatePicker}
                                  type="date"
                                />
                              </Form.Group>
                            </Col> */}
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Entries")}</Form.Label>
                        <Form.Control
                          value={Entries}
                          onChange={e => setEntries(e.target.value)}
                          type="number"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Button variant="outline-primary" className="shadow-sm me-1 mt-2" onClick={() => resetFields()}>
                        {t("Reset")}
                      </Button>
                      <Button className="mt-2" type="submit" variant="primary">
                        {t("Query")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="primary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default AssetDigitalTwinChartModalMultiple;
