import React, { useEffect, useState } from "react";
import { Accordion, Alert, Badge, Button, Card, Col, Form, Modal, Nav, OverlayTrigger, Popover, Row, Tab, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import GatewayOPCUASetup from "./GatewayOPCUASetup";

const GatewayVPNContentModal = ({ data, show, asset_id, handleClose }) => {
  const { t, i18n } = useTranslation();

  const [title, setTitle] = useState("");
  const [JsonContent, setJsonContent] = useState();
  const [OPCUAContent, setOPCUAContent] = useState();

  useEffect(() => {

    setTitle("OPC UA")

    if (show) {
      setTitle(data?.message)

      setOPCUAContent(null)
      setJsonContent(null)

      if (data?.content && data?.content[0]?.category) {
        // This will show a different view for OPC UA modal content
        setOPCUAContent(data?.content)
      } else {
        setJsonContent(data?.content)
      }
    }

  }, [show]);

  const RenderJson = ({ jsonData }) => {
    const renderContent = (data) => {
      if (Array.isArray(data)) {
        return (
          <ul>
            {data.map((item, index) => (
              <li key={index}>
                {renderContent(item)}
              </li>
            ))}
          </ul>
        );
      } else if (typeof data === 'object' && data !== null) {
        return (
          <div style={{ marginLeft: '20px' }}>
            {Object.entries(data).map(([key, value]) => (
              <div key={key}>
                <strong>{key}:</strong> {renderContent(value)}
              </div>
            ))}
          </div>
        );
      } else {
        // Rendering for non-object/non-array data types (e.g., string, number)
        return <span>{String(data)}</span>;
      }
    };

    return <div>{renderContent(jsonData)}</div>;
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header className="h3" closeButton>{title}</Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="mt-1">

            {/* {JsonContent && <RenderNested data={JsonContent} />} */}
            {/* {JsonContent && <pre>{JSON.stringify(JsonContent, null, 2)}</pre>} */}
            {JsonContent && <RenderJson jsonData={JsonContent} />}

            {OPCUAContent && <GatewayOPCUASetup data={OPCUAContent} asset_id={asset_id} />}
          </Col>
        </Row>
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body >
    </Modal >
  );
};

export default GatewayVPNContentModal;
