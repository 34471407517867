import React, { useEffect, useState } from "react";

function isDemoMode() {

  switch (window.location.host) {
    case "demo.cap-on.de":
    case "demo.cap-on.io":
      return true
    default:
      return false
  }
};

export default isDemoMode;
