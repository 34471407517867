import axios from "axios";
import React, { useEffect, useState } from "react";
import { getIAMBaseURL } from "../../config";

function UserList(props) {
  const [Users, setUsers] = useState([]);

  useEffect(() => {

    axios
      .get(getIAMBaseURL() + "/users/emails")
      .then(function (response) {
        // handle success
        console.log(response.status, "/users/emails");
        setUsers(response.data.sort((a, b) => (a > b)));
        // setUsers(response.data.sort((a, b) => (a.role > b.role)));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  return Users.map((user) => {
    return (
      <option key={user} value={user}>{user}</option> //({user.role})
    );
  });

};

export default UserList;
