import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, } from "react-bootstrap";

const CommunicationHub = () => (
  <React.Fragment>
    <Helmet title="Communication Hub" />
    <Container fluid className="p-0">
      <Row className="mb-2 mb-xl-3">
        <Col xs="auto" className="d-none d-sm-block">
          <h3>Communication Hub</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <p>Kommt später</p>
            </Card.Header>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default CommunicationHub;
