import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import AssetTable from "./AssetTable";
import ErrorHandler from "../Helper/ErrorHandler";
import AddAssetToContractModal from "./AddAssetToContractModal";
import usePalette from "../../hooks/usePalette";
import { VectorMap } from "react-jvectormap";
import { useNavigate } from "react-router-dom";

const Assets = () => {
  const [allAssets, setAllAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null });
  const { t, i18n } = useTranslation();
  const palette = usePalette();
  const [assetGeoData, setAssetGeoData] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    setIsLoading(true);
    setError({ showMessage: false, errorData: null });

    axios
      .get("/webservice/assets/")
      .then((response) => {
        const sortedAssets = response.data?.filter(item => item?.title !== "-")?.sort((a, b) => {
          const statusComparison = a.connection_status.localeCompare(b.connection_status);
          if (statusComparison === 0) {
            const modelComparison = a?.machine_model?.localeCompare(b?.machine_model);
            return modelComparison === 0 ? a?.title?.localeCompare(b?.title) : modelComparison;
          }
          return statusComparison;
        });

        const geoData = response.data?.map((asset) => {
          const coordinates = asset.location?.coordinates || [];

          if (coordinates?.length === 0) {
            return null
          }

          const machineModel = asset.machine_model;
          const name = asset.title + (machineModel ? ` (${machineModel})` : "");
          const color = getMarkerColor(asset.connection_status);

          if (coordinates[0] !== 0 && coordinates[1] !== 0) {
            return {
              latLng: coordinates,
              name,
              id: asset.id,
              style: { fill: color }, // Set color based on connection status
            };
          }
          return null;
        }).filter(Boolean); // Remove any null entries

        console.log(response.status, "/webservice/assets")
        setAssetGeoData(geoData);
        setAllAssets(sortedAssets);
        setFilteredAssets(sortedAssets);
        setIsLoading(false);
      })
      .catch((error) => {
        setError({ showMessage: true, errorData: error });
        setIsLoading(false);
      });
  }, []);

  // Function to get marker color based on connection status
  const getMarkerColor = (status) => {
    switch (status) {
      case "connected":
        return palette.success; // Color for connected status
      case "no_connection":
        return palette.secondary; // Color for no connection
      case "disconnected":
        return palette.warning; // Color for disconnected status
      default:
        return palette.danger; // Default color for unknown status
    }
  };

  const isAllEurope = assetGeoData.every(marker => {
    const [lat, lng] = marker.latLng;
    return lng >= -10 && lng <= 40 && lat >= 35 && lat <= 70;
  });

  const [showMap, setShowMap] = useState(true);
  const [mapKey, setMapKey] = useState(0);

  const handleMarkerClick = (event, code) => {
    setMapKey((prevKey) => prevKey + 1); // Change key to force remount
    navigate(`/assets/${assetGeoData[code].id}`);
  };

  const options = {
    map: "world_mill", //isAllEurope ? "europe_mill" : 
    normalizeFunction: "polynomial",
    hoverOpacity: 0.7,
    hoverColor: false,
    zoomOnScroll: false,
    regionStyle: {
      initial: {
        fill: palette["gray-200"],
      },
    },
    containerStyle: {
      width: "100%",
      height: "100%",
    },
    markerStyle: {
      initial: {
        r: 9,
        "fill-opacity": 0.9,
        stroke: palette.white,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
      hover: {
        "fill-opacity": 0.9,
        "stroke-width": 7,
        "stroke-opacity": 0.4,
      },
    },
    backgroundColor: "transparent",
    markers: assetGeoData,
    onMarkerClick: handleMarkerClick,
  };

  useEffect(() => {
    return () => {
      // Cleanup: Remove any lingering DOM elements or internal state of VectorMap
      const mapContainer = document.querySelector(".map-container");
      if (mapContainer) {
        mapContainer.innerHTML = ""; // Clear map content
      }
    };
  }, []);

  // Rest of the component...

  return (
    <React.Fragment>
      <Helmet title={t("Assets")} />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col>
            <h3>{t("Assets")}</h3>
          </Col>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <AddAssetToContractModal />
          </Col>
        </Row>

        <Row>
          <Col>
            <ErrorHandler error={error} />
            {assetGeoData?.length > 0 && (
              <Row>
                <Col>
                  <Card className="flex-fill">
                    {/* <Card.Header>
                      <Card.Title tag="h5" className="mb-0">
                        {t("Assets")}
                      </Card.Title>
                    </Card.Header> */}
                    {/* <Card.Body> */}
                    {showMap && (
                      <div className="map-container px-1 py-1" style={{ height: 200 }}>
                        <VectorMap key={mapKey} {...options} />
                      </div>
                    )}
                    {/* </Card.Body> */}
                  </Card>
                </Col>
              </Row>
            )}
            <AssetTable data={filteredAssets} title="Assets" isLoading={isLoading} slim={false} showTitle={false} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Assets;