import axios from "axios";
import React, { useEffect, useState } from "react";
import { getLogsBaseURL } from "../../config";

function ApplicationNames(props) {
  const [ApplicationName, setApplicationName] = useState([]);

  useEffect(() => {

    axios
      .get(getLogsBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "/openapi.json");
        setApplicationName(response.data["components"]["schemas"]["ApplicationName"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  return ApplicationName.map((name) => {
    return (
      <option key={name} value={name}>{name}</option>
    );
  });
};

export default ApplicationNames;
