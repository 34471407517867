import React, { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ErrorHandler from "../../Helper/ErrorHandler";
import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import usePalette from "../../../hooks/usePalette";
import WatchTowerList from "../../Watchtower/WatchTowerList";
import Chart from "react-apexcharts";

const MetricDetailsChartModal = ({ asset_id, metric_data, show, handleClose }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const palette = usePalette()

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [data, setData] = useState([]);
  const [periodType, setPeriodType] = useState();

  useEffect(() => {
    if (show) {

      console.log("Showing chart", asset_id, metric_data)
      setTitle(metric_data?.metric_title);
      setSubtitle(metric_data?.explanation);
      setPeriodType(metric_data?.period_type);
    } else {
      setTitle("");
      setSubtitle("");
      setPeriodType("");
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      loadData();
    }
  }, [periodType]);

  async function loadData() {
    setError({ showMessage: false, errorData: null });

    try {
      const response = await axios.get(`/assets/${asset_id}/metrics`, {
        params: {
          metric_id: metric_data?.metric_id,
          period_type: periodType,
          limit: 50,
        },
      });

      setData(response.data.reverse());
    } catch (error) {
      setError({ showMessage: true, errorData: error });
    }
  }

  function renderValue(value, unit) {
    switch (unit) {
      case "#":
        return `${value?.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 })}`;
      case "%":
        return `${value?.toLocaleString(i18n.language === "de" ? de : "en", { style: "percent", maximumFractionDigits: 1 })}`;
      default:
        return `${value?.toLocaleString(i18n.language === "de" ? de : "en", { style: "decimal", maximumFractionDigits: 2 })} ${unit}`;
    }
  }

  const UsageBarChart = ({ x_data, y_data, unit, title, subtitle }) => {
    const palette = usePalette();

    const series = [
      {
        name: t(unit),
        data: y_data,
      },
    ];

    const options = {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // curve: "smooth",
        width: 2,
      },
      markers: {
        size: 4,
        colors: [palette.primary],
      },
      tooltip: {
        y: {
          formatter: (value) => renderValue(value, unit),
        },
      },
      xaxis: {
        categories: x_data,
        labels: {
          formatter: function (value) {
            return value;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => renderValue(value, unit),
        },
      },
      colors: [palette.primary],
    };

    return (
      <Card className="flex-fill">
        <Card.Header>
          <Card.Title tag="h5">{t(title)}</Card.Title>
          <Card.Subtitle>{t(subtitle)}</Card.Subtitle>
        </Card.Header>
        <Card.Body>
          {y_data != null && y_data.length > 0 ? (
            <div className="chart">
              <Chart options={options} series={series} type="line" height="200" />
            </div>
          ) : (
            <Card.Body>
              <p>{t("No data found.")}</p>
            </Card.Body>
          )}
        </Card.Body>
      </Card>
    );
  };

  if (!show) {
    return null; // This ensures the modal is not rendered if 'show' is false
  }

  return (
    <Modal size="xl" show={show} onHide={handleClose} style={{ zIndex: 1050 }}>
      <Modal.Header closeButton>{t("Details")}</Modal.Header>
      <Modal.Body className="m-3" style={{ background: palette["gray-100"], padding: "20px" }}>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="12" className="d-flex">
            <UsageBarChart
              x_data={data?.map((entry) =>
                format(
                  parseISO(periodType === "month" ? entry.period_start : entry.period_end),
                  periodType === "month" ? "MMM" : "dd MMM",
                  { locale: i18n.language === "de" ? de : undefined }
                )
              )}
              y_data={data?.map((entry) => entry.value)}
              unit={data?.length > 0 ? data[0]?.unit : null}
              title={metric_data?.metric_name}
              subtitle={metric_data?.explanation}
            />
          </Col>
        </Row>
        <Row>
          <WatchTowerList slim showAddButton watchtowers={metric_data?.watchtowers} selected_asset_id={asset_id} selected_metric={metric_data} />
        </Row>
        <br />
        <hr />
        <br />
        <Row>
          <Col xs="auto" className="ms-auto text-end mt-n1">
            <Button variant="outline-secondary" onClick={handleClose}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default MetricDetailsChartModal;