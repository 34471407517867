import { format, parseISO } from "date-fns";
import { de } from "date-fns/locale";
import React from "react";
import Chart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import usePalette from "../../../hooks/usePalette";

const InvoicesChart = (props) => {
  const palette = usePalette();
  const { t, i18n } = useTranslation();

  const data = [
    {
      name: t("Invoices"),
      type: 'line',
      data: props.data?.sort((a, b) => new Date(a.due_date) - new Date(b.due_date)).map((e) => { return e?.total }),
    },
  ];

  const showLoadingSpinner = () => {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="secondary" size="sm" /> {t("Loading data")}
      </div>
    )
  }

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "70%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: [2, 4],
      // colors: ["transparent"],
    },
    labels: props.data?.map((e) => { return format(parseISO(e.due_date), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) }),
    xaxis: {
      // type: "time",
      // max: 100,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: palette.black,
        },
      },
    },
    yaxis:
      [
        {
          title: {
            text: t("Invoice"),
          },
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: palette.black,
            },
            formatter: (value) => { return (value)?.toLocaleString(i18n.language === "de" ? de : "en", { style: "currency", currency: "EUR", maximumFractionDigits: 2, }) },
          },
        }
      ],
    legend: {
      position: 'top',
      labels: {
        colors: palette.black,
        useSeriesColors: false
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return (val)?.toLocaleString(i18n.language === "de" ? de : "en", { style: "currency", currency: "EUR", maximumFractionDigits: 2 })
        },
      },
    },
    colors: [
      palette.primary
    ],
  };

  return (

    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title tag="h5">{t("Invoices")}</Card.Title>
      </Card.Header>
      <Card.Body>
        {
          props.isLoading

            ? showLoadingSpinner()

            : props.data.length === 0

              ? <h6 className="card-subtitle text-muted">
                {t("No invoices available.")}
              </h6>

              : <div className="chart">
                <Chart options={options} series={data} height="200"  />
                {/* type="bar" */}
              </div>
        }
      </Card.Body>
    </Card>
  );
};

export default InvoicesChart;
