import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Badge, Button, Card, Col, Container, Dropdown, OverlayTrigger, Popover, Row, Spinner, Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { parseISO, formatDistanceToNow, format } from 'date-fns'
import { de } from "date-fns/locale";
import ErrorHandler from "../Helper/ErrorHandler";
import AddGatewayModal from "./SingleIoTGatewayModal";
import CardDropdown from "../../components/CardDropdown";
import UserDataManager from "../Helper/UserDataManager";
import NotyfContext from "../../contexts/NotyfContext";
import { Activity, CheckCircle, Command, Edit, HardDrive, Heart, Plus, Tool } from "react-feather";
import LoadingSpinner from "../Helper/LoadingSpinner";
import AddFullGatewayModal from "./GatewayModal_AddFull";
import SingleIoTGatewayModal from "./SingleIoTGatewayModal";

const Gateways = () => {
  const navigate = useNavigate();
  const [gatewayListData, setGatewayListData] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [filterCloud, setFilterCloud] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [filterSPS, setFilterSPS] = useState(false);
  const [filterVPN, setFilterVPN] = useState(false);

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  useEffect(() => {
    setIsLoading(true);

    axios
      .get("/webservice/iot_gateways/")
      .then(function (response) {
        // handle success
        console.log(response.status, "/gateways");

        let sortedList = response.data?.filter(item => item?.asset_title !== "-").sort((a, b) => {
          // Sort by `is_active` first, where active (true) comes before inactive (false)
          if (a?.connectivity_settings?.is_active !== b?.connectivity_settings?.is_active) {
            return a?.connectivity_settings?.is_active ? -1 : 1;
          }
          // If `is_active` values are the same, sort by `serial` next
          return a?.serial?.localeCompare(b?.serial);
        })

        setGatewayListData(sortedList);
        setFilteredList(sortedList);
        setIsLoading(false);
      })
      .catch(function (error) {
        // handle error
        setError({ showMessage: true, errorData: error })
        // console.log(error);
        setIsLoading(false);
      });
  }, []);

  const renderActivationState = (status) => {

    switch (status) {
      case true:
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Activated")}</Badge>
        )
      case false:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("Not activated")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("n/a")}</Badge>
        )
    }
  }

  const renderInternetSetting = (internet) => {

    switch (internet) {
      case "lte":
        return (<>{t("lte")}</>)
      case "wifi":
        return (<>{t("wifi")}</>)

      case "ethernet":
        return (<>{t("ethernet")}</>)

      default:
        return (<>{t("n/a")}</>)

    }
  }

  const renderConnectionState = (status) => {

    switch (status) {
      case "connected":
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("Cloud")}</Badge>
        )
      case "connection_lost":
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("Cloud")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("Cloud")}</Badge>
        )
    }
  }

  function isWithinLastXMinutes(time, minutesSince) {

    const currentTime = new Date();
    const inputTime = new Date(time);

    const timeDifference = (currentTime.getTime() - inputTime.getTime()) / (1000 * 60); // Convert milliseconds to minutes

    return timeDifference <= 5;
  }
  const renderVPNConnectionState = (time) => {

    // Has never connected to the cloud (Handshake == null)
    if (time === null) {
      return <Badge bg="" className="badge-soft-secondary me-2">{t("VPN")}</Badge>
    }

    if (isWithinLastXMinutes(time, 5)) {
      return <Badge bg="" className="badge-soft-success me-2">{t("VPN")}</Badge>
    } else {

      // if (isLongerThan3Days(parseISO(time))) {
      //   return <Badge bg="" className="badge-soft-danger me-2">{t("VPN")}</Badge>
      // } else {
      return <Badge bg="" className="badge-soft-warning me-2">{t("VPN")}</Badge>
      // }
    }
  }

  const renderPLCConnectionState = (data) => {

    if (data?.connection_status !== "connected") {
      return (
        <Badge bg="" className="badge-soft-warning me-2">{t("PLC")}</Badge>
      )
    }

    switch (data?.last_handshake?.asset_connected) {
      case true:
        return (
          <Badge bg="" className="badge-soft-success me-2">{t("PLC")}</Badge>
        )
      case false:
        return (
          <Badge bg="" className="badge-soft-warning me-2">{t("PLC")}</Badge>
        )
      default:
        return (
          <Badge bg="" className="badge-soft-secondary me-2">{t("PLC")}</Badge>
        )
    }
  }

  const handleButtonClick = (link) => {
    window.open(link, '_blank');
  };

  function handleClipboard(ip) {
    navigator.clipboard.writeText("ssh admin@" + ip)

    notyf.open({
      type: "success",
      message: t("shell command copied!"),
      duration: "4500",
      ripple: false,
      dismissible: true,
      position: {
        x: "right",
        y: "bottom",
      },
    })
  }

  const renderTable = () => {

    return filteredList?.map((gateway) => {

      return (
        <tr key={gateway?.id} style={{ cursor: "pointer" }}>

          <td onClick={() => navigate("/gateways/" + gateway.id)} >{gateway?.serial}</td>

          <td onClick={() => navigate("/gateways/" + gateway.id)} >
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Popover>
                  <Popover.Header as='h3'>{t("Contract Status")}</Popover.Header>
                  <Popover.Body>
                    {t("This indicates whether a gateway is activated, i.e. maintained by cap-on, or whether it is inactive, i.e. no costs are incurred.")}
                    <br /><br />
                    <strong>{t("Type")}:</strong> {t(gateway?.connectivity_settings?.internet_connection || "n/a")}
                    <br /><br />
                    <strong>{t("Activated since")}:</strong> {gateway?.connectivity_settings?.period_start ? format(parseISO(gateway?.connectivity_settings?.period_start), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                    <br />
                    <strong>{t("Activated until")}:</strong> {gateway?.connectivity_settings?.period_end ? format(parseISO(gateway?.connectivity_settings?.period_end), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                  </Popover.Body>

                </Popover>
              }>
              {gateway == null ? "n/a" : renderActivationState(gateway?.connectivity_settings?.is_active)}
            </OverlayTrigger>
          </td>

          <td onClick={() => navigate("/gateways/" + gateway.id)} >
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Popover>
                  <Popover.Header as='h3'>{t("Cloud Connection")}</Popover.Header>
                  <Popover.Body>
                    <strong>{t("Last handshake")}</strong>: {gateway.last_handshake == null ? "-" : formatDistanceToNow(parseISO(gateway.last_handshake.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}

                    {/* {gateway?.last_handshake?.time && isLongerThan3Days(parseISO(gateway.last_handshake.time)) && <>
                      <br /><br />
                      <h5>{t("Disconnected more than 3 days!")}</h5>
                    </>} */}
                  </Popover.Body>
                </Popover>
              }
            >
              {gateway == null ? "n/a" : renderConnectionState(gateway?.connection_status)}
            </OverlayTrigger>
            <OverlayTrigger
              placement='auto'
              overlay={
                <Popover>
                  <Popover.Header as='h3'>{t("VPN Connection")}</Popover.Header>
                  <Popover.Body>
                    {t("vpn_description")} <br /><br />

                    <strong>{t("Internal IP")}</strong>: {gateway?.internal_ip || "n/a"}<br />
                    <strong>{t("Last seen")}</strong>:   {gateway?.tailscale_last_seen !== null && `${format(parseISO(gateway?.tailscale_last_seen), "d MMM yyyy, HH:mm", { locale: i18n.language === "de" ? de : null })} (${formatDistanceToNow(parseISO(gateway?.tailscale_last_seen), { locale: i18n.language === "de" ? de : null, addSuffix: true })})`}

                    {/* {isLongerThan3Days(parseISO(gateway?.tailscale_last_seen)) && <>
                      <br /><br />
                      <h5>{t("Disconnected more than 3 days!")}</h5>
                    </>} */}

                  </Popover.Body>
                </Popover>
              }
            >
              {gateway == null ? "n/a" : renderVPNConnectionState(gateway?.tailscale_last_seen)}
            </OverlayTrigger>

            <OverlayTrigger
              placement='bottom'
              overlay={
                <Popover>
                  <Popover.Header as='h3'>{t("Programmable Logic Controller (OT)")}</Popover.Header>
                  <Popover.Body>
                    {t("plc_description")} <br /><br />
                    <strong>{t("Last activity")}</strong>: {gateway?.last_asset_log?.time ? `${format(parseISO(gateway?.last_asset_log?.time), "d MMM yyyy, HH:mm", { locale: i18n.language === "de" ? de : null })} (${formatDistanceToNow(parseISO(gateway?.last_asset_log?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })})` : "n/a"}<br />
                    <strong>{t("Last production unit")}</strong>: {gateway?.last_usage_log === null ? "n/a" : `${format(parseISO(gateway?.last_usage_log?.time), "d MMM yyyy, HH:mm", { locale: i18n.language === "de" ? de : null })} (${formatDistanceToNow(parseISO(gateway?.last_usage_log?.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })})`}<br />
                  </Popover.Body>
                </Popover>
              }
            >
              {gateway == null ? "n/a" : renderPLCConnectionState(gateway)}
            </OverlayTrigger>
          </td>

          {/* <td>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Popover>
                  <Popover.Header as='h3'>{t("Internet Connection")}</Popover.Header>
                  <Popover.Body>
                    <strong>{t("Type")}:</strong> {t(gateway?.connectivity_settings?.internet_connection || "n/a")}
                    <br /><br />
                    <strong>{t("Activated since")}:</strong> {gateway?.connectivity_settings?.period_start ? format(parseISO(gateway?.connectivity_settings?.period_start), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                    <br />
                    <strong>{t("Activated until")}:</strong> {gateway?.connectivity_settings?.period_end ? format(parseISO(gateway?.connectivity_settings?.period_end), `d${i18n.language === "de" ? "." : ""} MMM yyyy`, { locale: i18n.language === "de" ? de : null }) : "-"}
                  </Popover.Body>
                </Popover>
              }>
              {gateway == null ? "n/a" : renderInternetSetting(gateway?.connectivity_settings?.internet_connection)}
            </OverlayTrigger>
          </td> */}

          <td onClick={() => navigate("/gateways/" + gateway.id)} >{gateway?.asset_title || "-"}</td>
          {/* <td onClick={() => navigate("/gateways/" + gateway.id)}> {gateway.last_handshake == null ? "-" : formatDistanceToNow(parseISO(gateway.last_handshake.time), { locale: i18n.language === "de" ? de : null, addSuffix: true })}</td> */}

          <td className="table-action">
            {/* <ArrowUpRight
                alt={t("Remote Configuration")}
                className="align-middle"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/gateways/" + gateway.id)}
                 />
              &nbsp;&nbsp;&nbsp; */}
            <HardDrive
              alt={t("Asset")}
              className="align-middle"
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/assets/" + gateway?.asset_id)}
            />
            &nbsp;&nbsp;&nbsp;
            <Activity
              alt={t("Heart")}
              className="align-middle"
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/assets/" + gateway?.asset_id + "/?focus=digital-twin")}
            />
            &nbsp;&nbsp;&nbsp;
            <CheckCircle
              alt={t("Condition")}
              className="align-middle"
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/assets/" + gateway?.asset_id + "/?focus=maintenance")}
            />
            &nbsp;&nbsp;&nbsp;
            <Tool
              alt={t("IoT-Setup")}
              className="align-middle"
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/assets/" + gateway?.asset_id + "/setup")}
            />

            {UserDataManager.getInstance().isAdmin() &&
              <>&nbsp;&nbsp;&nbsp;
                <Edit
                  alt={t("Remote Configuration")}
                  className="align-middle"
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => window.confirm(t("Dies funktioniert nur, wenn Sie sich im cap-on VPN befinden.")) && handleButtonClick("http://" + gateway?.internal_ip + ":1880")}
                />
                &nbsp;&nbsp;&nbsp;
                <Command
                  alt={t("Remote Shell Command")}
                  className="align-middle"
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClipboard(gateway?.internal_ip)}
                />
              </>}
          </td>

          {/* <td className="text-end">
            <CardDropdown>
              <div className="py-2">
                <ActivateGatewayModal gatewayData={gateway} />
              </div>
            </CardDropdown>
          </td> */}
        </tr >
      );
    });
  };

  // Use useEffect to trigger handleFiltration after state change
  useEffect(() => {
    // Only trigger on state change
    handleFiltration();
  }, [filterActive, filterCloud, filterSPS, filterVPN]);

  function handleFiltration() {
    let updatedList = gatewayListData;

    if (filterActive) {
      updatedList = updatedList.filter(gateway => gateway?.connectivity_settings?.is_active);
    }

    if (filterCloud) {
      updatedList = updatedList.filter(gateway => gateway?.connection_status === "connected");
    }

    if (filterSPS) {
      updatedList = updatedList.filter(gateway => gateway?.connection_status === "connected" && gateway?.last_handshake?.asset_connected);
    }

    if (filterVPN) {
      updatedList = updatedList.filter(gateway => isWithinLastXMinutes(gateway?.tailscale_last_seen, 5));
    }

    setFilteredList(updatedList);
  }

  // function isLongerThan3Days(targetDate) {

  //   const currentDate = new Date();
  //   const timeDifference = currentDate - targetDate;

  //   // Check if the time difference is greater than or equal to 10 minutes in milliseconds.
  //   const isDate10MinutesOrLater = timeDifference >= 3 * 24 * 60 * 60 * 1000;

  //   return isDate10MinutesOrLater;
  // }

  const [showSingleGateway, setShowSingleGateway] = useState();

  return (
    <React.Fragment>
      <Helmet title="cap-on IoT-Gateways" />

      <SingleIoTGatewayModal show={showSingleGateway} handleClose={() => setShowSingleGateway(false)} />

      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col> {/* <Col> xs="auto" className="d-none d-sm-block" */}
            <h3>cap-on IoT-Gateways</h3>
          </Col>

          <Col xs="auto" className="ms-auto text-end mt-n1">

            {UserDataManager.getInstance().isCapOn() && <Button
              onClick={() => setShowSingleGateway(true)}
              variant="primary"
              className="shadow-sm me-1" // 
            >
              <Plus className="feather" /> {t("Create")}
            </Button>
            }

            {UserDataManager.getInstance().isCapOn() && <AddFullGatewayModal />}

            {/* <Button
              onClick={() => navigate("/gateways/connect")}
              variant="primary"
              className="shadow-sm me-1">
              <Share2 className="feather" />  {t("Gateway Connect")}
            </Button> */}
            {/* <Button
              onClick={refreshPage}
              variant="primary"
              className="shadow-sm"
            >
              <RefreshCw className="feather" />
            </Button> */}
          </Col>
        </Row>

        <Row>
          <Col>
            <ErrorHandler error={error} />
            <Card>
              {/* <Card.Body> */}
              {
                isLoading === true ?
                  <LoadingSpinner withStandardText /> :
                  (filteredList?.length > 0)
                    ? // If data found


                    <div>
                      <div className="mt-3 ms-3 mb-3">
                        {t("Quick filter")}
                        <Button
                          onClick={() => setFilterActive(!filterActive)}
                          variant={filterActive ? "primary" : "outline-primary"}
                          size="sm"
                          className="btn-pill me-1 mb-1 ms-2" >
                          {t("Activated")}
                        </Button>
                        <Button
                          onClick={() => setFilterCloud(!filterCloud)}
                          variant={filterCloud ? "primary" : "outline-primary"}
                          size="sm"
                          className="btn-pill me-1 mb-1" >
                          {t("Cloud")}
                        </Button>
                        <Button
                          onClick={() => setFilterVPN(!filterVPN)}
                          variant={filterVPN ? "primary" : "outline-primary"}
                          size="sm"
                          className="btn-pill me-1 mb-1" >
                          {t("VPN")}
                        </Button>
                        <Button
                          onClick={() => setFilterSPS(!filterSPS)}
                          variant={filterSPS ? "primary" : "outline-primary"}
                          size="sm"
                          className="btn-pill me-1 mb-1" >
                          {t("PLC-Connection")}
                        </Button>

                      </div>

                      <Table responsive hover>
                        <thead>
                          <tr>
                            {/* <th scope="col">{t("Status")}</th> */}
                            <th scope="col">{t("Serial #")}</th>
                            <th scope="col">{t("Contract Status")}</th>
                            <th scope="col">{t("Connection")}</th>
                            {/* <th scope="col">{t("Internet")}</th> */}
                            {/* <th scope="col">{t("VPN Connection")}</th> */}
                            <th scope="col">{t("Associated Asset")}</th>
                            {/* <th scope="col">{t("Last handshake")}</th> */}
                            {UserDataManager.getInstance().isAdmin() && <th scope="col">{t("Quick Actions")}</th>}
                          </tr>
                        </thead>
                        <tbody>{renderTable()}</tbody>
                      </Table>


                    </div>


                    : // If no data found

                    <Card.Body>
                      <p>{t("No data found.")}</p>
                    </Card.Body>
              }
              {/* </Card.Body> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment >
  );
};

function refreshPage() {
  window.location.reload(false);
}

export default Gateways;
