import axios from "axios";
import { useParams } from "react-router-dom";
import { Col, Row, Dropdown, Modal } from "react-bootstrap";
import React, { useContext, useState, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorHandler from "../Helper/ErrorHandler";
import NotyfContext from "../../contexts/NotyfContext";
import { faUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../Helper/cropImage";

const UploadAvatarModal = (props) => {
  let { id } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const [openModal, setOpenModal] = useState(false);
  const notyf = useContext(NotyfContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const toggle = () => {
    setOpenModal(!openModal);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropSave = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImg(selectedFile.preview, croppedAreaPixels);
      setCroppedImage(croppedImg);
    } catch (e) {
      console.error("Error cropping image:", e);
    }
  }, [croppedAreaPixels, selectedFile]);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      // Validate file type
      if (!file.type.startsWith("image/")) {
        setError({ showMessage: true, color: "secondary", customMessage: t("Invalid file format. Please upload an image.") });
        return;
      }

      setSelectedFile(Object.assign(file, { preview: URL.createObjectURL(file) }));
      setError({ showMessage: false, customMessage: "" });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const removeImage = () => {
    setSelectedFile(null);
    setCroppedImage(null);
    setError({ showMessage: false, customMessage: "" });
  };

  function uploadFile(e) {
    e.preventDefault();

    const formData = new FormData();
    const fileToUpload = props.type === "asset" ? selectedFile : croppedImage;

    if (!fileToUpload) {
      
      if (!croppedImage) {
        setError({ showMessage: true, customMessage: t("Please crop image first") });

      } else {
        setError({ showMessage: true, customMessage: t("No file selected.") });
      }
      return;
    }

    formData.append("file", fileToUpload, "avatar.png"); // Name the file appropriately

    axios
      .put(`/${props.type}s/${id}/avatar?${props.type}_id=${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Avatar uploaded");
        notyf.open({
          type: "success",
          message: t("File uploaded"),
          duration: "4500",
          ripple: false,
          dismissible: true,
          position: { x: "right", y: "bottom" },
        });

        toggle();

        window.location.reload(false);
      })
      .catch((error) => {
        console.error(error);
        setError({ showMessage: true, errorData: error });
      });
  }

  return (
    <>
      <Dropdown.Item onClick={toggle}>{t("Upload avatar")}</Dropdown.Item>
      <Modal show={openModal} onHide={toggle}>
        <Modal.Header closeButton>{t("Upload avatar")}</Modal.Header>
        <Modal.Body className="m-3">
          <Row>
            <Col>
              <ErrorHandler error={error} />
            </Col>
          </Row>
          <Row>
            <Col>
              {selectedFile ? (
                props.type !== "asset" ? (
                  <>
                    <div style={{ position: "relative", width: "100%", height: "300px" }}>
                      <Cropper
                        image={selectedFile.preview}
                        crop={crop}
                        zoom={zoom}
                        aspect={1} // Square crop
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                      />
                    </div>
                    <div className="mt-3 d-flex justify-content-between">
                      <Button variant="outline-danger" onClick={removeImage}>
                        <FontAwesomeIcon icon={faTrash} /> {t("Remove")}
                      </Button>
                      <Button variant="primary" onClick={handleCropSave}>
                        {t("Crop")}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="mt-3">
                    <p>{t("File selected:")} {selectedFile.name}</p>
                    <Button variant="outline-danger" onClick={removeImage}>
                      <FontAwesomeIcon icon={faTrash} /> {t("Remove")}
                    </Button>
                  </div>
                )
              ) : (
                <div
                  {...getRootProps()}
                  style={{
                    border: "2px dashed #cccccc",
                    borderRadius: "10px",
                    padding: "20px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>{t("Drop the file here...")}</p>
                  ) : (
                    <p>{t("Drag & drop an image here, or click to select a file")}</p>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={toggle}>
            {t("Cancel")}
          </Button>{" "}
          <Button variant={"primary"} onClick={uploadFile} disabled={!selectedFile && !croppedImage}>
            <FontAwesomeIcon icon={faUpload} /> {t("Upload")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadAvatarModal;