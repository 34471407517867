import {
  Button,
  Col,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import NotyfContext from "../../../contexts/NotyfContext";
import ErrorHandler from "../../Helper/ErrorHandler";
import { getCoreBaseURL } from "../../../config";
import DatePicker from 'react-datepicker'
import { de } from "date-fns/locale";
import { parseISO } from "date-fns";

const SingleEmissionModal = ({ desired_emission_id, handleClose, ...props }) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);
  let { id } = useParams();

  const [emissionCatalogItems, setEmissionCatalogItems] = useState();

  const [title, set_title] = useState();
  const [category, set_category] = useState();
  const [unit, set_unit] = useState();
  const [period_type, set_period_type] = useState();
  const [other_impact, set_other_impact] = useState();
  const [co_two_kg_e_per_unit, set_co_two_kg_e_per_unit] = useState();
  const [catalog_item_id, set_catalog_item_id] = useState();
  const [amount, set_amount] = useState();

  const [PeriodStartDatePicker, setPeriodStartDatePicker] = useState(new Date());
  const [PeriodEndDatePicker, setPeriodEndDatePicker] = useState(new Date());

  const [totalContractTime, setTotalContractTime] = useState(true);

  const [SwaggerPeriodTypeArray, set_SwaggerPeriodTypeArray] = useState();
  // const [SwaggerCategoryArray, set_SwaggerCategoryArray] = useState();
  // const [SwaggerUnitArray, set_SwaggerUnitArray] = useState();


  useEffect(() => {

    console.log("Showing modal for emission_id", desired_emission_id)

    set_title()
    set_category()
    set_unit()
    set_period_type()
    set_other_impact()
    set_co_two_kg_e_per_unit()
    set_catalog_item_id()
    set_amount()
    setPeriodStartDatePicker(new Date())
    setPeriodEndDatePicker(new Date())

    axios
      .get(`/assets/${id}/emissions/` + desired_emission_id)
      .then(function (response) {
        // handle success
        console.log(response.status, "/emissions");
        set_title(response.data?.title)
        set_category(response.data?.category)
        set_unit(response.data?.unit)
        set_period_type(response.data?.period_type)
        set_other_impact(response.data?.other_impacts)
        set_co_two_kg_e_per_unit(response.data?.co_two_kg_e_per_unit)
        set_catalog_item_id(response.data?.catalog_item_id)
        set_amount(response.data?.amount)

        if (response.data?.period_start || response.data?.period_end) {
          setTotalContractTime(false)
          setPeriodStartDatePicker(parseISO(response.data?.period_start) || new Date())
          setPeriodEndDatePicker(parseISO(response.data?.period_end) || new Date())
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(`/assets/${id}/emissions/catalog_items/`)
      .then(function (response) {
        // handle success
        console.log(response.status, "/emissions/catalog_items");
        setEmissionCatalogItems(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    setError({ showMessage: false, errorData: null })

    if (desired_emission_id) {

      var data = {
        "period_type": period_type,
        // "iot_node_id": "string",
        "amount": amount,
        "title": title,
        "category": category,
        "unit": unit,
        "other_impacts": other_impact,
        "co_two_kg_e_per_unit": co_two_kg_e_per_unit,
        "catalog_item_id": catalog_item_id ? catalog_item_id : null,
        "period_start": totalContractTime ? null : PeriodStartDatePicker?.toISOString().replace('Z', '') || null,
        "period_end": totalContractTime ? null : PeriodEndDatePicker?.toISOString().replace('Z', '') || null,
      }

      axios
        .put(`/assets/${id}/emissions/` + desired_emission_id, data)
        .then(function (response) {
          // handle success
          console.log(response.status, "Updated emission");

          notyf.open({
            type: "success",
            message: t("Emission updated."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    } else {

      let data = {
        "period_type": period_type,
        // "iot_node_id": "string",
        "amount": amount,
        "title": title,
        "category": category,
        "unit": unit,
        "other_impacts": other_impact,
        "co_two_kg_e_per_unit": co_two_kg_e_per_unit,
        "catalog_item_id": catalog_item_id ? catalog_item_id : null,
        "period_start": totalContractTime ? null : PeriodStartDatePicker?.toISOString().replace('Z', '') || null,
        "period_end": totalContractTime ? null : PeriodEndDatePicker?.toISOString().replace('Z', '') || null,
      }

      axios
        .post(`/assets/${id}/emissions/`, data)
        .then(function (response) {
          // handle success
          console.log(response, "Added emission");

          notyf.open({
            type: "success",
            message: t("Emission added."),
            duration: "4500",
            ripple: false,
            dismissible: true,
            position: {
              x: "right",
              y: "bottom",
            },
          })

          handleClose()
        })
        .catch(function (error) {
          // handle error
          setError({ showMessage: true, errorData: error })
          console.log(error);
        });
    }
  }

  useEffect(() => {

    axios
      .get(getCoreBaseURL() + "/openapi.json")
      .then(function (response) {
        // handle success
        console.log(response.status, "/openapi");

        set_SwaggerPeriodTypeArray(response.data["components"]["schemas"]["EmissionPeriodType"]["enum"]);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const [suggestions, setSuggestions] = useState([]); // Store the API suggestions
  const [showDropdownCategory, setShowDropdownCategory] = useState(false); // Whether to show the suggestion dropdown
  const [showDropdownTitle, setShowDropdownTitle] = useState(false); // Whether to show the suggestion dropdown
  const [used_suggestion, set_used_suggestion] = useState(false)

  // Function to fetch suggestions from the API
  const fetchSuggestions = (inputValue, field) => {

    console.log("Filtering", inputValue, field, emissionCatalogItems)
    setSuggestions([])
    var filteredSuggestions = [];

    if (field === "category") {
      filteredSuggestions = emissionCatalogItems?.filter(item =>
        item?.category?.toLowerCase().includes(inputValue.toLowerCase()) // Case-insensitive match
      );
      setSuggestions(filteredSuggestions);
      setShowDropdownCategory(true);
      setShowDropdownTitle(false);
    } else if (field === "title") {
      filteredSuggestions = emissionCatalogItems?.filter(item =>
        item?.title?.toLowerCase().includes(inputValue.toLowerCase()) // Case-insensitive match
      );
      setSuggestions(filteredSuggestions);
      setShowDropdownTitle(true);
      setShowDropdownCategory(false);
    }

  };

  // Debounced API call
  const handleInputChange = (e, field) => {
    const value = e.target.value;

    if (field === "category") {
      set_category(value); // Update the plc_node_id with the input value
    } else if (field === "title") {
      set_title(value); // Update the plc_node_id with the input value
      set_used_suggestion(false)
    }

    clearTimeout(id);
    id = setTimeout(() => {
      fetchSuggestions(value, field); // Fetch suggestions after 500ms of user input
    }, 500);
  };

  // Handle selecting a suggestion from the dropdown
  const handleSuggestionSelect = (suggestion, field) => {

    if (field === "title") {
      set_used_suggestion(true)
      set_unit(suggestion?.unit)
      set_title(suggestion?.title)
      set_other_impact(suggestion?.other_impacts)
      set_co_two_kg_e_per_unit(suggestion?.co_two_kg_e_per_unit)
      set_catalog_item_id(suggestion?.catalog_item_id)
    }

    set_category(suggestion?.category)

    setShowDropdownCategory(false); // Hide the dropdown
    setShowDropdownTitle(false); // Hide the dropdown
  };

  return (
    <Modal size="ms" show style={{ zIndex: 1060 }} onHide={() => handleClose()}>
      <Modal.Header closeButton>{desired_emission_id ? t("Edit emission") : t("Add emission")}</Modal.Header>
      <Modal.Body className="m-3">
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>

          <Row className="mt-1">
            <Col md={12}>

              <Form.Group className="mb-3">
                <Form.Label>{t("Category")}</Form.Label>
                <Form.Control
                  value={category}
                  disabled={used_suggestion}
                  type="text"
                  onChange={e => handleInputChange(e, "category")}
                  placeholder="Kategorie" />
                {showDropdownCategory && suggestions?.length > 0 && (
                  <Dropdown.Menu
                    show
                    style={{ width: '100%', position: 'absolute', zIndex: 1080 }}
                  >
                    <Dropdown.Header>
                      {t("Known emission items")}
                    </Dropdown.Header>
                    {suggestions?.map((suggestion, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleSuggestionSelect(suggestion, "category")}
                      >
                        {suggestion?.category}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                )}
              </Form.Group>

              <Form.Group className="mb-3">

                {/* "catalog_item_id": "string" */}

                {/* "period_start": "2024-09-25T07:33:23.974Z",
                "period_end": "2024-09-25T07:33:23.974Z",
                "iot_node_id": "string", */}

                <Form.Label>{t("Title")}</Form.Label>
                <Form.Control
                  value={title}
                  type="text"
                  onChange={e => handleInputChange(e, "title")}
                  placeholder="Name" />

                {showDropdownTitle && suggestions?.length > 0 && (
                  <Dropdown.Menu
                    show
                    style={{ width: '100%', position: 'absolute', zIndex: 1080 }}
                  >
                    <Dropdown.Header>
                      {t("Known emission items")}
                    </Dropdown.Header>
                    {suggestions?.map((suggestion, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleSuggestionSelect(suggestion, "title")}
                      >
                        {suggestion?.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                )}
              </Form.Group>

              <Row>
                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Amount used")}</Form.Label>
                    <Form.Control
                      value={amount}
                      type="number"
                      onChange={(e) => set_amount(e.target.value)}
                      placeholder="100" />
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Unit")}</Form.Label>
                    <Form.Select
                      name="state"
                      value={unit}
                      disabled={used_suggestion}
                      onChange={(event) => set_unit(event.target.value)}>
                      <option value="">{t("-- Select --")}</option>

                      <optgroup label={t("Energy/Power")}>
                        <option value={"Wh"}>{t("Watt-hour (Wh)")}</option>
                        <option value={"kWh"}>{t("Kilowatt-hour (kWh)")}</option>
                      </optgroup>

                      <optgroup label={t("Volume")}>
                        <option value={"ml"}>{t("Milliliters (ml)")}</option>
                        <option value={"l"}>{t("Liters (L)")}</option>
                        <option value={"gal"}>{t("Gallons (gal)")}</option>
                        <option value={"cm³"}>{t("Cubic centimeter (cm³)")}</option>
                        <option value={"m³"}>{t("Cubic meter (m³)")}</option>
                        <option value={"ft³"}>{t("Cubic feet (ft³)")}</option>
                      </optgroup>

                      <optgroup label={t("Distance/Length")}>
                        <option value={"mm"}>{t("Millimeter (mm)")}</option>
                        <option value={"cm"}>{t("Centimeter (cm)")}</option>
                        <option value={"m"}>{t("Meter (m)")}</option>
                        <option value={"km"}>{t("Kilometer (km)")}</option>
                        <option value={"mi"}>{t("Mile (mi)")}</option>
                        <option value={"in"}>{t("Inch (in)")}</option>
                        <option value={"ft"}>{t("Feet (ft)")}</option>
                      </optgroup>

                      <optgroup label={t("Weight/Mass")}>
                        <option value={"mg"}>{t("Milligrams (mg)")}</option>
                        <option value={"g"}>{t("Grams (g)")}</option>
                        <option value={"kg"}>{t("Kilograms (kg)")}</option>
                        <option value={"lb"}>{t("Pounds (lb)")}</option>
                        <option value={"t"}>{t("Tons (t)")}</option>
                        <option value={"oz"}>{t("Ounces (oz)")}</option>
                      </optgroup>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md="4">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Interval")}</Form.Label>
                    <Form.Select
                      onChange={(e) => set_period_type(e.target.value)}
                    >
                      <option value="">{t("-- Select --")}</option>
                      {SwaggerPeriodTypeArray?.map((item) => (
                        <option key={item} value={item} selected={item === period_type}>
                          {t(item)}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>{t("Approximate CO\u2082e per")} <strong>{unit || t("unit")}</strong> in <strong> kg</strong></Form.Label>
                <Form.Control
                  value={co_two_kg_e_per_unit}
                  type="number"
                  step="any"  // Allow decimal input
                  disabled={used_suggestion}
                  onChange={(e) => set_co_two_kg_e_per_unit(e.target.value)}
                  placeholder="23" />
              </Form.Group>


              <Row>
                <Col md="6">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Usage span")}</Form.Label>
                    <Form.Check
                      checked={totalContractTime}
                      onChange={() => setTotalContractTime(!totalContractTime)}
                      label={t("Total contract span")}
                      type="checkbox" />
                  </Form.Group>
                </Col>
                <Col md="6">
                  {!totalContractTime &&
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Date range")}</Form.Label><br />
                      <InputGroup className='flex-nowrap rounded pe-5'>
                        <Form.Control
                          as={DatePicker}
                          selectsStart
                          startDate={PeriodStartDatePicker}
                          endDate={PeriodEndDatePicker}
                          selected={PeriodStartDatePicker}
                          locale={i18n.language === "de" ? de : undefined}
                          onChange={(date) => setPeriodStartDatePicker(date || new Date())}  // Fallback to current date
                          dateFormat={'MMM d, yyyy'}
                          className='rounded-0'
                          placeholderText='From date'
                        />
                        <Form.Control
                          as={DatePicker}
                          selectsEnd
                          startDate={PeriodStartDatePicker}
                          endDate={PeriodEndDatePicker}
                          selected={PeriodEndDatePicker}
                          locale={i18n.language === "de" ? de : undefined}
                          minDate={PeriodStartDatePicker}
                          onChange={(date) => setPeriodEndDatePicker(date || new Date())}  // Fallback to current date
                          dateFormat={'MMM d, yyyy'}
                          className='rounded-0'
                          placeholderText='To date'
                        />
                      </InputGroup>
                    </Form.Group>}
                </Col>

              </Row>

              <Form.Group className="mb-3">
                <Form.Label>{t("Other impact")}</Form.Label>
                <Form.Control
                  value={other_impact}
                  disabled={used_suggestion}
                  type="text"
                  onChange={(e) => set_other_impact(e.target.value)}
                  placeholder="Other impact, ..." />

              </Form.Group>
            </Col>
          </Row>

          <hr />
          <Row>
            <Col xs="auto" className="ms-auto text-end mt-n1">
              <Button variant="outline-secondary" onClick={handleClose}>
                {t("Cancel")}
              </Button>{" "}
              <Button variant={"primary"} type="submit" >
                {desired_emission_id ? t("Apply") : t("Create")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body >
    </Modal >
  );
};

export default SingleEmissionModal;
