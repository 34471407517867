import React from 'react';
import { Bar, Line, Pie, Doughnut, Scatter } from 'react-chartjs-2'; 

function GenericChart({ type, data, options }) {
  const chartComponents = {
    'bar': Bar,
    'line': Line,
    'pie': Pie,
    'doughnut': Doughnut,
    "scatter" : Scatter,
    // ... add other types if needed
  };

  const ChartComponent = chartComponents[type] || Line; // default to Bar if type is not recognized

  return (
    <div className="chart">
      <ChartComponent data={data} options={options} />
    </div>
  );
}

export default GenericChart;