import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Alert, Badge, Button, Card, Col, Dropdown, Form, Modal, Nav, OverlayTrigger, Popover, Row, Tab, Table } from "react-bootstrap";
import { Filter, Info, Plus, Trash } from "react-feather";
import { useTranslation } from "react-i18next";
import SingleIoTDataPointModal from "../Assets/IoT_Data_Setup/SingleIoTDataPointModal";
import OPCUA_Logo_Gross from "../../assets/img/logos/opc_logo.png";

const GatewayOPCUASetup = ({ data, asset_id }) => {
  const { t } = useTranslation();

  const [configData, setConfigData] = useState();
  const [showDataPointModal, setShowDataPointModal] = useState(null);

  useEffect(() => {

    loadConfigs()

  }, []);

  function loadConfigs() {
    axios
      .get("/assets/" + asset_id + "/iot_configs")
      .then(function (response) {

        // handle success
        console.log(response.status, "/assets/iot_config");

        setConfigData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  const renderNodeValue = (value) => {
    if (Array.isArray(value)) {
      // Join array values with a comma or render as list
      return value.join(", ");
      // Or for a more structured format:
      // return <ul>{value.map((val, i) => <li key={i}>{val}</li>)}</ul>;
    } else if (typeof value === 'boolean') {
      // Convert boolean to readable format
      return value ? "True" : "False";
    }
    // Directly return string and other types
    return value;
  };

  const [filterButtonTitle, setFilterButtonTitle] = useState("Filter");
  const [filteredData, setFilteredData] = useState([]);

  function filterBy(role, newButtonTitle) {
    let filteredItems;

    console.log(`Filtering for role: ${role}, initial data length: ${data.length}`);

    if (role === "all") {
      filteredItems = data;
    } else {
      // Filter the data based on the presence of at least one matching item in each category's items.
      filteredItems = data.map(category => ({
        ...category,
        items: category.items.filter(item =>
          configData.filter(i =>
            i.log_type.plc_node_id !== item.plc_node_id
          )
        )
      }))
        // Optionally remove categories with no remaining items after filtering
        .filter(category => category.items.length > 0);
    }

    // Update the state with the filtered data.
    setFilteredData(filteredItems);

    // Update the button title to reflect the new state.
    setFilterButtonTitle(newButtonTitle);

    // Log to check the result of the operation.
    console.log(`Filtered data length: ${filteredItems.length}`);
  }

  function handleDelete(e, delete_id) {
    e.preventDefault();

    console.log("To be deleted", delete_id)

    // axios
    //   .delete("/assets/" + asset_id + "/iot_nodes/plc_node_id/" + delete_id)
    //   .then(function (response) {
    //     console.log(response.status, "/iot_nodes deleted");
    //     // handle success
    //     loadConfigs();
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   });
  }

  return (
    <div>
      {showDataPointModal && <SingleIoTDataPointModal
        node_id={showDataPointModal}
        asset_id={asset_id}
        handleClose={() => {
          loadConfigs();
          setShowDataPointModal(null)
        }} />}

      <div className="text-center">
        <img
          src={OPCUA_Logo_Gross}
          alt="avatar"
          className="mb-2"
          width="auto"
          height={100}
        />
        <div className="mb-4">{t("We found the following categories and OPC UA data points in the connected PLC.")}</div>


        {/* <Dropdown align={{ lg: 'end' }} className="d-inline">
          <Dropdown.Toggle variant="light" className="bg-white shadow-sm me-1">
            <Filter className="feather align-middle" /> {t(filterButtonTitle)}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => filterBy("new", "New node_ids only")}>{t("Only show new node ids")}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => filterBy("all", "Filter")}>{t("All")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

      </div>

      <div className="h4 mb-3">{t("Categories")}</div>
      <Accordion>
        {data?.map((item, indx) => <Accordion.Item key={indx} eventKey={indx} className="bg-white">
          <Accordion.Header>{item?.category}</Accordion.Header>
          <Accordion.Body className="bg-white">
            <Table responsive hover>
              <thead>
                <tr>
                  <th scope="col">{t("Name")}</th>
                  <th scope="col">{t("Datentyp")}</th>
                  <th scope="col">{t("Beispielwert")}</th>
                </tr>
              </thead>
              <tbody>{item?.items?.map((node, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td>
                        <span>
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  {node?.nodeId}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Info className="feather me-2" />
                          </OverlayTrigger>
                        </span>
                        {node.browseName}
                      </td>
                      <td><code>{node.dataType}</code></td>
                      <td>{renderNodeValue(node.value)}</td>
                      <td>
                        {
                          configData?.items?.find((item) => item?.log_type?.plc_node_id === node.nodeId)
                            ? <Button
                              onClick={e => window.confirm(t("Are you sure you wish to delete this data point?")) && handleDelete(e, node?.nodeId)}
                              variant="outline-danger"
                              className="shadow-sm"
                              size="sm"
                            >
                              <Trash className="feather me-1" /> {t("Remove")}
                            </Button>
                            : <Button
                              onClick={() => setShowDataPointModal(node?.nodeId)}
                              variant="success"
                              className="shadow-sm"
                              size="sm"
                            >
                              <Plus className="feather me-1" /> {t("Add")}
                            </Button>
                        }
                      </td>
                    </tr>
                    {node?.items && node?.items?.map((subNode, indx) =>
                      <tr key={indx}>
                        <td> |___
                          <span>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    {subNode}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Info className="feather ms-2 me-2" />
                            </OverlayTrigger>
                          </span>
                          {subNode.substring(subNode.indexOf(node.browseName))}
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          {configData?.items?.find(item => item?.log_type?.plc_node_id === subNode)
                            ? <Button
                              onClick={e => window.confirm(t("Are you sure you wish to delete this data point?")) && handleDelete(e, subNode)}
                              variant="outline-danger"
                              className="shadow-sm"
                              size="sm"
                            >
                              <Trash className="feather me-1" /> {t("Remove")}
                            </Button>
                            : <Button
                              onClick={() => setShowDataPointModal(subNode)}
                              variant="success"
                              className="shadow-sm"
                              size="sm"
                            >
                              <Plus className="feather me-1" /> {t("Add")}
                            </Button>}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
        )}

      </Accordion>
    </div>
  );
};

export default GatewayOPCUASetup;
