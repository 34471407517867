import {
  Col,
  Modal,
  Card,
  Tab,
  Nav,
} from "react-bootstrap";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const ProductDescriptionModal = (props) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);

  const toggle = () => {
    // Toggle selected element
    setOpenModal(!openModal);
  };

  return (
    <>
      <Col className="ms-auto text-center mb-2">
        <Card.Link style={{ cursor: "pointer" }} onClick={() => toggle()}>{t(props?.buttonTitle)}</Card.Link>
      </Col>
      <Modal size="xl" show={openModal} onHide={() => toggle()}>
        <Modal.Header closeButton>{t(props?.title)}</Modal.Header>
        <Modal.Body className="m-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
          <h3>{t(props?.title)}</h3>
          <br />
          <div className={"tab tab-vertical"}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="first">
                    Beschreibung
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    Vorteile
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="third">
                    Preis
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ReactMarkdown>
                    {props?.markDownDescription}
                  </ReactMarkdown>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <ReactMarkdown>
                    {props?.markDownBenefits}
                  </ReactMarkdown>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="third">
                  <h4 className="tab-title">One more</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                    commodo ligula eget dolor tellus eget condimentum rhoncus. Aenean
                    massa. Cum sociis natoque penatibus et magnis neque dis parturient
                    montes, nascetur ridiculus mus.
                  </p>
                  <p>
                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                    aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut,
                    imperdiet a, venenatis vitae, justo.
                  </p>
                </Tab.Pane> */}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => toggle(false)}>
            {t("OK")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductDescriptionModal;
