import React, { useContext, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ErrorHandler from "./Helper/ErrorHandler";
import NotyfContext from "../contexts/NotyfContext";
import { useNavigate } from "react-router-dom";
import { getIAMBaseURL } from "../config";

const ChangePassword = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState({ showMessage: false, errorData: null, customMessage: "" });
  const notyf = useContext(NotyfContext);

  const [OldPassword, setOldPassword] = useState();
  const [NewPassword1, setNewPassword1] = useState();
  const [NewPassword2, setNewPassword2] = useState();

  const handleChangePassword = event => {
      changePassword(event);
  };

  function changePassword(e) {
    e.preventDefault();

    if (NewPassword1 === "" | NewPassword1 === undefined) {
      setError({ showMessage: true, errorData: error, customMessage: t("Password cannot be empty") })
    } else {
      if (NewPassword1 !== NewPassword2) {
        setError({ showMessage: true, errorData: error, customMessage: t("The two passwords must be the same") })
      } else {
        axios
          .put(getIAMBaseURL() + "/users/me/password", {
            "old_password": OldPassword,
            "new_password": NewPassword1,
          })
          .then(function (response) {
            // handle success
            console.log("Changed password", response.status);

            notyf.open({
              type: "success",
              message: t("Updated password successfully"),
              duration: "4500",
              ripple: false,
              dismissible: true,
              position: {
                x: "right",
                y: "bottom",
              },
            })
            navigate("/dashboard");
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            setError({ showMessage: true, errorData: error })
          });
      }
    }
  }

  return (
    <React.Fragment>
      <Helmet title={t("Change Password")} />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{t("Change Password")}</h1>
        <Row>
          <Col>
            <ErrorHandler error={error} />
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  {t("Change Password")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={e => window.confirm(t("Are you sure you want to update your password?")) && handleChangePassword(e)}>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        value={OldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder={t("Current Password")} />
                    </Form.Group>
                  </Col>
                  <br />
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Enter a new password")}</Form.Label>
                      <Form.Control
                        type="password"
                        value={NewPassword1}
                        onChange={(e) => setNewPassword1(e.target.value)}
                        placeholder={t("New Password")}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        value={NewPassword2}
                        onChange={(e) => setNewPassword2(e.target.value)}
                        placeholder={t("Confirm New Password")} />
                    </Form.Group>
                  </Col>
                  <br />
                  <Col>
                    {/* <PasswordChecklist
                      // rules={["minLength", "specialChar", "number", "capital", "match"]}
                      // minLength={5}
                      value={NewPassword1}
                      valueAgain={NewPassword2}
                      onChange={(isValid) => { }}
                    /> */}
                    <Button type="submit" variant="primary"
                    >
                      {t("Save")}
                    </Button>
                  </Col>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ChangePassword;
