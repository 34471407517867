import {
  Card,
  Col,
  Row,
} from "react-bootstrap";
import React from "react";
import {
  Map,
  Thermometer
} from "react-feather";
import { useTranslation } from "react-i18next";
import { de } from 'date-fns/locale';
import DigitalTwinCard from "../AssetDigitalTwin/DigitalTwinCard";
import { isMobile } from "react-device-detect";
import usePalette from "../../../hooks/usePalette";

const DigitalTwinSection = ({ assetData, localWeather, configData }) => {

  const { t, i18n } = useTranslation();
  const palette = usePalette();
  
  const LoadIcon = () => {
    return (
      <img
        src={localWeather?.current?.condition?.icon}
        className="ms-2"
        height="24"
        alt={localWeather?.current?.condition?.icon ? "icon" : ""}
      />);
  };

  return (<div style={{ background: palette["gray-200"], padding: "20px" }}>
    <Row className="mt-3 mb-4">
      <Col>
        <div className="h3">{t("Live-View")}</div>
      </Col>
      <Col xs="auto" className="ms-auto text-end mt-n1">
        <div className="d-flex">
          {(localWeather && !isMobile) &&
            <p className="me-3 mt-1" >
              <Map className="feather" /> &nbsp;
              {localWeather?.location?.region}, &nbsp;
              {/* {formatEpochToLocalTime(LocalWeather?.location?.localtime_epoch)}, {" "} */}
              {/* {LocalWeather?.location?.localtime} {" Uhr"}, */}
              {/* {format(parse(LocalWeather?.location?.localtime, "yyyy-MM-dd HH:mm", new Date()), "HH:mm", { locale: i18n.language === "de" ? de : null })} &nbsp; &nbsp; */}

              <Thermometer className="feather" />&nbsp;
              {localWeather?.current?.temp_c != null ? `${localWeather?.current?.temp_c?.toLocaleString(i18n.language === "de" ? de : "en", {
                style: 'decimal',
                maximumFractionDigits: 1,
              }) || "n/a"} °C` : 'N/A'}, &nbsp;
              {localWeather?.current?.condition?.text}
              <LoadIcon />
            </p>}

          {/* <LottieControl animation={animationData} height={30} width={70} /> */}
        </div>
      </Col>
    </Row>
    <DigitalTwinCard webserviceData={assetData} configDataExists={configData}/>
  </div>
  );
};

export default DigitalTwinSection;
