import React, { useEffect, useState } from "react";
import { components } from "react-select";

import { useTranslation } from "react-i18next";
import { Navbar, Nav } from "react-bootstrap";
import { Briefcase, Clipboard, HardDrive, Search, Tool, Users, Wifi, } from "react-feather";
import useSidebar from "../../hooks/useSidebar";
import NavbarLanguages from "./NavbarLanguages";
import NavbarUser from "./NavbarUser";
import axios from "axios";
import UserDataManager from "../../pages/Helper/UserDataManager";
import { useNavigate } from "react-router-dom";
import NavbarAlerts from "./NavbarAlerts";
import AsyncSelect from 'react-select/async'
import NavbarTenants from "./NavbarTenants";
import { getIAMBaseURL } from "../../config";

// const notifications = [
//   {
//     type: "important",
//     title: "Update completed",
//     description: "Restart server 12 to complete the update.",
//     time: "2h ago",
//   },
//   {
//     type: "default",
//     title: "Lorem ipsum",
//     description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
//     time: "6h ago",
//   },
//   {
//     type: "default",
//     title: "Lorem ipsum",
//     description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
//     time: "6h ago",
//   },
// ];

// const messages = [
//   {
//     name: "Ashley Briggs",
//     avatar: avatar5,
//     description: "Nam pretium turpis et arcu. Duis arcu tortor.",
//     time: "15m ago",
//   },
//   {
//     name: "Theo Schütz",
//     avatar: avatar1,
//     description: "Curabitur ligula sapien euismod vitae.",
//     time: "2h ago",
//   },
// ];


const NavbarComponent = () => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = useSidebar();
  const [alertData, setAlertData] = useState([]);
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    // if (UserDataManager.getInstance().getUserData().id === undefined) {
    axios
      .get(getIAMBaseURL() + "/users/me")
      .then(function (response) {
        // handle success
        console.log(response.status, "/users/me-xxx");

        UserDataManager.getInstance().setUserData(response.data);
        setUserData(response.data);
        loadNotifications(response.data);
        setTenants(response.data.tenants);
        // console.log("--new-->", response.data, response.data.tenants)
      })
      .catch(function (error) {
        console.log(error);
      });
    // } else {
    //   console.log("UserData already set (xxx)"); // UserDataManager.getInstance().getUserData().id
    //   let userData = UserDataManager.getInstance().getUserData()
    //   loadNotifications(userData)
    //   setTenants(userData.tenants)
    //   console.log("--old-->", userData, userData.tenants)
    // }
  }, []);

  function loadNotifications(userData) {
    axios
      .get("/alerts/", {
        params: {
          user_email: userData.email
        }
      })
      .then(function (response) {
        // handle success
        console.log(response.status, "/alerts");
        setAlertData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  var id = 0;

  const loadOptions = (inputValue, callback) => {
    // perform a request

    var requestResults = []

    clearTimeout(id);
    if (inputValue.length >= 3) {
      id = setTimeout(function (x) {
        if (inputValue && inputValue.length > 0)
          axios
            .get("/webservice/search/" + inputValue)
            .then(function (response) {
              // handle success
              requestResults = response.data.map(e => ({ label: e.name, value: e.type, id: e.id }))
              callback(requestResults)
              console.log(response.status, "Search data");
            })
            .catch(function (error) {
              // handle error
              callback(requestResults)
              console.log(error);
            });
      }, 500) //0,5 Sekunden
    } else {
      callback(requestResults)
    }
  }

  function handleSelectSearchResult(event) {
    console.log(event)
    if (event?.value === null) {
      console.log("Error");
    } else {
      navigate("/" + event?.value + "/" + event?.id)
    }
  }

  const [tenants, setTenants] = useState([{ name: "test" }]);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {/* <Search /> */}
        <Search className="feather" />
      </components.DropdownIndicator>
    );
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>

        {props.selectProps.inputValue.length === 0 && <span className="custom-css-class">{t("What are you looking for?")}</span>}
        {props.selectProps.inputValue.length > 0 && props.selectProps.inputValue.length < 3 && <span className="custom-css-class">{t("Please enter more characters.")}</span>}

      </components.NoOptionsMessage>
    );
  };

  function formatType(value) {
    switch (value) {
      case "users":
        return t("User")
      case "assets":
        return t("Asset")
      case "contracts":
        return t("Contract")
      case "manufacturers":
        return t("Manufacturer")
      case "clients":
        return t("Client")
      case "gateways":
        return t("Gateway")
      default:
        return ""
    }
  }

  function formatIcon(value) {
    switch (value) {
      case "users":
        return <Users className="feather" />
      case "assets":
        return <HardDrive className="feather" />
      case "contracts":
        return <Clipboard className="feather" />
      case "manufacturers":
        return <Tool className="feather" />
      case "clients":
        return <Briefcase className="feather" />
      case "gateways":
        return <Wifi className="feather" />
      default:
        return <HardDrive className="feather" />
    }
  }

  function isCustomerSubdomain() {
    switch (window.location.host) {
      case "ettlinger.cap-on.de":
      case "ettlinger.cap-on.io":
      case "tha.cap-on.de":
      case "maag.cap-on.de":
      case "hexpol.cap-on.de":
      case "maag.cap-on.io":
      case "klexu.cap-on.de":
      case "klexu.cap-on.io":
      case "weima.cap-on.de":
      case "weima.cap-on.io":
      case "siemens.cap-on.de":
      case "siemens.cap-on.io":
      case "kontron.cap-on.de":
      case "chg-meridian.cap-on.de":
      case "kontron.cap-on.io":
      case "sueddeutsche-leasing.cap-on.de":
      case "sueddeutsche-leasing.cap-on.io":
      case "thyssenkruppAB.cap-on.de":
      case "thyssenkruppAB.cap-on.io":
      case "linde-wiemann.cap-on.de":
      case "linde-wiemann.cap-on.io":
      case "wegoma.cap-on.de":
      case "wegoma.cap-on.io":
        return true
      default:
        return false
    }
  }

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      {/* <Form className="d-none d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control placeholder={t("Search")} aria-label="Search" onChange={handleChangeSearchInput} />
          <Button variant="">
            <Search className="feather" />
          </Button>
        </InputGroup>
      </Form> */}

      <div style={{ width: '300px', }}>
        <AsyncSelect
          closeMenuOnSelect={false}
          // classNamePrefix="testSelect"
          // isClearable
          formatOptionLabel={country => (
            <div className="country-option">
              <span><strong>{formatIcon(country.value)}</strong>&nbsp;&nbsp;</span>
              <span><strong>{formatType(country.value)}</strong>&nbsp;&nbsp;</span>
              <span>{country.label}</span>
              {/* <Button className="me-1 btn btn-secondary btn-sm" onClick={e => console.log("test")}>Test</Button> */}
            </div>
          )}
          theme={(theme) => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              // primary25: 'hotpink',
              primary: 'primary',
            },
          })}
          components={{ DropdownIndicator, NoOptionsMessage }}
          loadOptions={loadOptions}
          loadingMessage={() => t('Searching cap-on...')}
          placeholder={t("Search...")}
          onChange={handleSelectSearchResult}
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: "var(--bs-white)", // Change this to your desired background color
              color: "var(--bs-black)", // Text color within the control
            }),
            input: (provided) => ({
              ...provided,
              backgroundColor: "var(--bs-white)", // Change this to match the control background if needed
              color: "var(--bs-black)", // Text color within the control
            }),
            group: provided => ({
              ...provided,
              borderBottom: '1px solid #ccc',
            }),
            placeholder: base => ({
              ...base,
              fontSize: "1em",
              color: "var(--bs-gray-700)", // Text color for the placeholder
              fontWeight: 300
            })
          }}
        />
      </div>

      {/* <Navbar.Collapse>
        <Nav className="input-group-navbar">
          {NavbarSearch(searchData)}
        </Nav>
      </Navbar.Collapse> */}

      <Navbar.Collapse>
        <Nav className="navbar-align">

          {!isCustomerSubdomain() && NavbarTenants(tenants)}
          {NavbarAlerts(alertData.sort((a, b) => (a.created_at < b.created_at)).slice(0, 5), alertData.length, alertData.filter(alert => alert.is_read === false).length)}
          {NavbarLanguages(userData)}
          {NavbarUser(userData)}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
